/* eslint-disable import/no-anonymous-default-export */
import constants from "../constants";

export default (state = {}, action) => {
  switch (action.type) {
    case constants.TEAM_ROLES:
      return {
        ...state,
        team_roles: action.payload,
      };
    case constants.TEAM_MEMBERS:
      return {
        ...state,
        team_members:
          action.payload?.sort((a, b) => parseInt(a.id) - parseInt(b.id)) || [],
      };
    case constants.MY_ROLES:
      return {
        ...state,
        my_roles: action.payload,
      };
    case constants.ALL_USERS:
      return {
        ...state,
        all_users: action.payload || [],
      };
    case constants.GET_ALL_PERMISSION:
      return {
        ...state,
        all_permissions: action.payload,
      };
    case constants.DELETE_ROLE:
      return {
        ...state,
        team_roles_deleted: action.payload,
      };
    default:
      return state;
  }
};
