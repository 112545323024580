import React, {useEffect, useState} from 'react';
import DefaultLayout from '../Layout/DefaultLayout';
import {Col, Row, Card, Divider, Table} from 'antd';
import { connect } from 'react-redux';
import DashboardCard from "./DashboardCard";
import { GroupOutlined } from '@material-ui/icons';
import {Dashhead} from "./AdminStyle.styled";
import {toast} from "react-toastify";
import {formatDateObjectHandler, GetFromNowDate} from "../../../utils";
import {GetAllTrackingRecord} from "../../../redux/actions/adminOtherServices";
// import { toast } from 'react-toastify';

const AdminDashboard = (props) => {
    const { dashboardData, allTracking, GetAllTrackingRecord, allUsers, allServices, allSubscriptions, allTeams  } = props;
    const [loadingdata, setloadingdata] = useState(false);
    useEffect(() => {
        const getAllTrackedOrders = async () => {
            setloadingdata(true);
            const res = await GetAllTrackingRecord();
            if (res.success) {
                setloadingdata(false);
            } else {
                setloadingdata(false);
                toast.error(res.message, {
                    pauseOnHover: true,
                });
            }
        };
        getAllTrackedOrders();
    }, [GetAllTrackingRecord]);

    const formatNewRecord = allTracking?.map((rec) => {
        return {
            ...rec,
            team_member: rec?.team_member?.member?.email,
            first_name: rec?.team_member?.member?.first_name,
            last_name: rec?.team_member?.member?.last_name,
            service: rec?.service.name,
            service_name: rec?.service?.service,
            date_created: rec?.date_created,
        }
    })

    const trackingColumn = [
        {
            title: 'Team Member',
            dataIndex: 'team_member',
            width: '250px',
            render: (_text, record) =>(
                <>
                    <div>Name: {record.first_name + ' ' + record.last_name}</div>
                    <span><a href={`mailto:${record.team_member}`}>{record.team_member}</a></span>
                </>
            )
        },
        {
            title: 'Service Details',
            dataIndex: 'service_name',
            render: (_text, record) =>(
                <>
                    <div>{record.service}</div>
                    <span>{record.service_name}</span>
                </>
            )
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
        },
        {
            title: 'Date Created',
            dataIndex: 'date_created',
            align: 'center',
            render: (_text, record) => (
                <>
                    {formatDateObjectHandler(record.date_created, 'DD-MM-YYYY')} <br/>
                    <small style={{ color: 'green' }}>{GetFromNowDate(record.date_created, 'YYYY-MM-DDTHH:mm:ss', 'fromNow')}</small>
                </>
            )
        },
    ];

    return (
        <>
            <DefaultLayout browserTitle="Admin Dashboard">
                <div data-aos="fade-right">
                    <Row>
                        <Col xs={24}>
                            <Dashhead>Dashboard</Dashhead>
                        </Col>
                    </Row>
                    <Row gutter={28} justify="space-between">
                        <Col xs={24} xl={8} lg={8}>
                            <DashboardCard icon={<GroupOutlined />} title="Total Users" count={allUsers?.length} />
                        </Col>
                        <Col xs={24} xl={8} lg={8}>
                            <DashboardCard
                                icon={<GroupOutlined />}
                                title="Total Services"
                                count={allServices?.length}
                                background={'rgba(123, 97, 255, 0.1)'}
                            />
                        </Col>
                        <Col xs={24} xl={8} lg={8}>
                            <DashboardCard
                                icon={<GroupOutlined />}
                                title="Total Subscriptions"
                                count={allSubscriptions?.length}
                                background={'rgba(123, 97, 255, 0.1)'}
                            />
                        </Col>
                    </Row>
                    <Row gutter={28}>
                        <Col xs={24} xl={16} lg={16}>
                            <Card>
                                <h5>Recent Tracking Activities</h5>
                                <Divider />
                                <Table
                                    loading={loadingdata}
                                    rowKey="id"
                                    bordered
                                    columns={trackingColumn}
                                    dataSource={formatNewRecord}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} xl={8} lg={8}>
                            <DashboardCard
                                icon={<GroupOutlined />}
                                title="Total Teams"
                                count={allTeams?.length}
                                background={'rgba(123, 97, 255, 0.1)'}
                            />
                        </Col>
                    </Row>
                </div>
            </DefaultLayout>
        </>
    );
};

const mapStateToProps = state => {
    return {
        allTracking: state.adminOtherReducer.allTracking,
        allUsers: state.adminOtherReducer.allUsers,
        allServices: state.adminReducer.allServices,
        allSubscriptions: state.adminReducer.allSubscriptions,
        allTeams: state.adminOtherReducer.allTeams?.results
    };
};
export default connect(mapStateToProps, { GetAllTrackingRecord })(AdminDashboard);
