import React, {useEffect, useState} from 'react';
import DefaultLayout from '../Layout/DefaultLayout';
import { ManageUserContainer } from './AdminStyle.styled';
import {Table, Modal, Row, Col, Select, Button, Tag} from 'antd';
import { UserMenu } from './menus/UserMenu';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { SearchOutlined } from '@ant-design/icons';
import {AdminDeleteOneUser, GetAllAccountUsers} from "../../../redux/actions/adminOtherServices";
import {useForm, Controller} from "react-hook-form";

const { Option } = Select;

const AdminManageUsers = props => {
    const {
        allUsers,
        GetAllAccountUsers,
        AdminDeleteOneUser,
    } = props;
    const [loading, setloading] = useState(false);
    const [deleteloading, setdeleteloading] = useState(false);
    const history = useHistory();

    const getOneUser = record => {
        const path = generatePath('/admin/manage-users/:id', {
            id: record.id,
        });
        history.push(path);
    };

    const { handleSubmit, register, control } = useForm({
        defaultValues: {
            email: null,
            first_name: null,
            last_name: null,
            team_name: null,
            // is_staff:
        },
    });

    const SearchUser = async (data) => {
        const frData = {
            ...data,
            is_staff: data.is_staff === 'admin',
        }
        setloading(true);
        const res = await GetAllAccountUsers(frData);
        setloading(false);
        if (!res.success) {
            setloading(false);
            toast.error(res.message, {
                pauseOnHover: true,
                closeOnClick: true,
            });
        }
    };

    const confirmDelete = record => {
        Modal.confirm({
            title: 'Do you want to delete this user?',
            content: 'Click yes to proceed',
            okText: 'Delete',
            loading: deleteloading,
            okType: 'danger',
            async onOk() {
                setdeleteloading(true);
                const res = await AdminDeleteOneUser(record.id);
                if (res.success) {
                    setdeleteloading(false);
                    toast.success('User Deleted Successfully ',{
                        pauseOnHover: false,
                    });
                } else {
                    toast.info(res.message, {
                        pauseOnHover: true,
                    });
                }
            },
            onCancel() {
                setdeleteloading(false);
                return false;
            },
        });
    };

    useEffect(() => {
        const getAllUsers = async () => {
            setloading(true);
            const res = await GetAllAccountUsers();
            setloading(false);
            if (!res.success) {
                setloading(false);
                toast.error(res.message, {
                    pauseOnHover: true,
                    closeOnClick: true,
                });
            }
        };
        getAllUsers();
    }, [GetAllAccountUsers]);

    const userCol = [
        {
            title: 'Last Name',
            dataIndex: 'last_name',
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
        },
        {
            title: 'Team',
            dataIndex: 'team',
            render: (_text, record) => <>{record.owned_team?.name}</>
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'User Type',
            dataIndex: 'is_staff',
            align: 'center',
            render: (_text, record) => <>{
                record.is_staff === null ?
                    <Tag color="gray">Unknown</Tag> :
                    record.is_staff === false ?
                        <Tag color="blue">Portfolio User</Tag> :
                        <Tag color="green">Admin</Tag>
            }</>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (_text, record) => (
                <>
                    <UserMenu
                        record={record}
                        getOneUser={getOneUser}
                        deleteOneUser={confirmDelete}
                    />
                </>
            ),
        },
    ];

    return (
        <DefaultLayout browserTitle="Manage Users">
            <ManageUserContainer data-aos="fade-right">
                <h3>Manage Users</h3>
                <form onSubmit={handleSubmit(SearchUser)}>
                    <Row gutter={28}>
                        <Col xs={12} xl={6} lg={6}>
                            <div className="form-group">
                                <label style={{ marginBottom: '2px'}}>Team</label>
                                <input className="ant-input" name="team" {...register('team_name')} />
                            </div>
                        </Col>
                        <Col xs={12} xl={6} lg={6}>
                            <div className="form-group">
                                <label style={{ marginBottom: '2px'}}>Last Name</label>
                                <input className="ant-input" name="last_name" {...register('last_name')} />
                            </div>
                        </Col>
                        <Col xs={12} xl={6} lg={6}>
                            <div className="form-group">
                                <label style={{ marginBottom: '2px'}}>First Name</label>
                                <input className="ant-input" name="first_name" {...register('first_name')} />
                            </div>
                        </Col>
                        <Col xs={12} xl={6} lg={6}>
                            <div className="form-group">
                                <label style={{ marginBottom: '2px'}}>Email</label>
                                <input className="ant-input" name="email" {...register('email')} />
                            </div>
                        </Col>
                        <Col xs={12} xl={6} lg={6}>
                            <div className="form-group">
                                <label style={{ marginBottom: '2px'}}>User Type</label>
                                <Controller
                                    control={control}
                                    name="is_staff"
                                    render={({ field: { onChange } }) => (
                                        <Select allowClear style={{ width: '100%' }} name="is_staff" onChange={onChange}>
                                            <Option value="admin">Admin</Option>
                                            <Option value="user">User</Option>
                                        </Select>
                                    )}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} xl={24} lg={24}>
                            <div className="sthbtn">
                                <Button loading={loading} type="primary" onClick={handleSubmit(SearchUser)} icon={<SearchOutlined />}>Search</Button>
                            </div>
                        </Col>
                    </Row>
                </form>
                <br />
                <Table loading={loading} style={{ overflowX: 'auto' }} rowKey="id" columns={userCol} dataSource={allUsers} />
            </ManageUserContainer>

        </DefaultLayout>
    );
};

const mapStateToProps = state => {
    return {
        allUsers: state.adminOtherReducer.allUsers,
    };
};

export default connect(mapStateToProps, {
    GetAllAccountUsers,
    AdminDeleteOneUser,
})(AdminManageUsers);
