import React, {useState} from 'react';
import {Table, Tag, Modal} from "antd";
import {Delete, Edit} from "@material-ui/icons";
import {toast} from "react-toastify";

const SystemConfiguredRecord = (props) => {
    const { system_config_record, loading, editRecord, DeleteSystemSettings } = props;
    const [deleteloading, setdeleteloading] = useState(false);

    const recordColumn = [
        {
            title: 'Max Users',
            dataIndex: 'max_users_per_team'
        },
        {
            title: 'Min Subscription',
            dataIndex: 'min_no_of_suscription_units'
        },
        {
            title: 'Max Subscription',
            dataIndex: 'max_no_of_suscription_units'
        },
        {
            title: 'Notification',
            dataIndex: 'notification',
            render: (_text, record) => <>{
                record.notification === null ?
                    <Tag color="gray">Unknown</Tag> :
                    record.notification === false ?
                        <Tag color="red">Not Active</Tag> :
                        <Tag color="green">Active</Tag>
            }</>
        },
        {
            title: 'Subscription',
            dataIndex: 'subscription',
            render: (_text, record) => <>{
                record.subscription === null ?
                    <Tag color="gray">Unknown</Tag> :
                    record.subscription === false ?
                        <Tag color="red">Not Active</Tag> :
                        <Tag color="green">Active</Tag>
            }</>
        },
        {
            title: 'Sub Units Available',
            dataIndex: 'subscription_units_available'
        },
        {
            title: 'Sub Units Used',
            dataIndex: 'suscription_units_used'
        },
        // {
        //     title: 'Date Updated',
        //     dataIndex: 'date_updated',
        //     render: (_text, record) => <>{record.date_updated.split('T')?.[0]}</>
        // },
        {
            title: 'action',
            dataIndex: 'action',
            align: 'center',
            render: (_text, record) => <>
                <div className="actiontable">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        className="eid"
                        href="#"
                        onClick={(e) => editRecord(e, record)}>
                        <Edit />
                    </a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        className="del"
                        href="#"
                        onClick={(e) => deleteRecord(e, record)}
                    >
                        <Delete />
                    </a>
                </div>
            </>
        }

    ]

    const deleteRecord = (e, record) => {
        e.preventDefault();
        Modal.confirm({
            title: 'Do you want to delete this record?',
            content: 'Click yes to proceed',
            okText: 'Delete',
            loading: deleteloading,
            okType: 'danger',
            async onOk() {
                setdeleteloading(true);
                const res = await DeleteSystemSettings(record.id);
                if (res.success) {
                    setdeleteloading(false);
                    toast.success(res.message, {
                        pauseOnHover: false,
                    });
                } else {
                    toast.info(res.message, {
                        pauseOnHover: true,
                    });
                }
            },
            onCancel() {
                setdeleteloading(false);
                return false;
            },
        });
    };

    return (
        <>
            <Table rowKey="id" loading={loading} columns={recordColumn} dataSource={system_config_record} />
        </>
    )
}


export default SystemConfiguredRecord;