import React, { useState, useEffect } from 'react';

import { Fade, Grid, Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ShowMoreTable, ShowMoreTd, ShowMoreTh, ShowMoreTr } from '../../styles/farmManagement/FarmSummaryStyle';

import {
    CropPlanButtonBox,
    CropPlanInputText,
    CropPlanModal,
    CropPlanModalInner,
    CropPlanModalTitle,
    CropPlanSelect,
    CropPlanTableAdd
} from '../../styles/cropPlan/CropPlanStyles';
import { rotateSeason } from '../../redux/actions/cropPlanDevelopment';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        outline: 'none'
    }
}));

const PushToRotation = (props) => {
    const classes = useStyles();
    const {
        access_token: access_key,
    } = useSelector((state) => state.authReducer);

    const [btnState, setBtnState] = useState({ text: 'Rotate Season', isDisabled: false });

    const [rotationDetails, setRotationDetails] = useState({
        name: props?.rotationList[0]?.name,
        id: props?.rotationList[0]?.id,
        field: props?.rotationList[0]?.field,
        start_date: props?.rotationList[0]?.start_date,
        end_date: props?.rotationList[0]?.end_date,
        noOfSeasons: props?.rotationList[0]?.crop_season?.length,
        crop_season: props?.rotationList[0]?.crop_season,
        crop_season_years: props?.rotationList[0]?.crop_season_years
    })

    useEffect(() => {
        setRotationDetails({
            name: props?.rotationList[0]?.name,
            id: props?.rotationList[0]?.id,
            field: props?.rotationList[0]?.field,
            start_date: props?.rotationList[0]?.start_date,
            end_date: props?.rotationList[0]?.end_date,
            noOfSeasons: props?.rotationList[0]?.crop_season?.length,
            crop_season: props?.rotationList[0]?.crop_season,
            crop_season_years: props?.rotationList[0]?.crop_season_years
        })
    }, [props?.rotationList]);


    const handleChangeRotation = (e) => {

        setRotationDetails({
            name: props?.rotationList[[e]]?.name,
            id: props?.rotationList[[e]]?.id,
            field: props?.rotationList[[e]]?.field,
            start_date: props?.rotationList[[e]]?.start_date,
            end_date: props?.rotationList[[e]]?.end_date,
            noOfSeasons: props?.rotationList[[e]]?.crop_season?.length,
            crop_season_years: props?.rotationList[[e]]?.crop_season_years,
            crop_season: props?.rotationList[[e]]?.crop_season
        })
    }

    const handleRotateSeason = async () => {

        let update = {
            crop_season: props?.selSeason
        }
        setBtnState({ text: 'Please wait!', isDisabled: true });

        let { isError } = await rotateSeason(update, access_key, rotationDetails?.id);

        if (isError) {

            toast.error('Sorry, an error occured and we could not rotate the season', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBtnState({ text: 'Rotate Season', isDisabled: false });

        } else {

            toast(`Season ${props?.selSeason} successfully rotated`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBtnState({ text: 'Rotate Season', isDisabled: false });
            // close the modal and refresh
            props?.setRenderData(10);
            props?.handleClose();
        }
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props?.open}
                onClose={props?.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
                style={{ border: 'none !important' }}
            >
                <Fade in={props?.open}>

                    <CropPlanModal>
                        <CropPlanModalInner>
                            <Grid container spacing={1}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <CropPlanModalTitle>ADD SEASON: {props?.selSeason} INTO A ROTATION</CropPlanModalTitle>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6} style={{ float: 'right' }}>
                                    <CloseIcon onClick={props?.handleClose} style={{ float: 'right', cursor: 'pointer', color: 'rgba(200,0,0,1)' }} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <CropPlanInputText>Choose Rotation</CropPlanInputText>
                                    <CropPlanSelect name='crop' type='select' onClick={(e) => handleChangeRotation(e.target.value)}>
                                        {props?.rotationList.map((eachItem, pos) => (
                                            <option key={pos} value={pos} style={{ color: 'rgba(1, 28, 20, 1)' }}>{eachItem?.name}</option>
                                        ))}
                                    </CropPlanSelect>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <CropPlanInputText>Rotation Details</CropPlanInputText>
                                    <ShowMoreTable>
                                        <thead>
                                            <ShowMoreTr>
                                                <ShowMoreTh>Rotation Name</ShowMoreTh>
                                                <ShowMoreTh>Starting Date</ShowMoreTh>
                                                <ShowMoreTh>Ending Date</ShowMoreTh>
                                                <ShowMoreTh>Number of Seasons</ShowMoreTh>
                                            </ShowMoreTr>
                                        </thead>
                                        <tbody>
                                            <ShowMoreTr>
                                                <ShowMoreTd>{rotationDetails?.name}</ShowMoreTd>
                                                <ShowMoreTd>{rotationDetails?.start_date}</ShowMoreTd>
                                                <ShowMoreTd>{rotationDetails?.end_date}</ShowMoreTd>
                                                <ShowMoreTd>{rotationDetails?.noOfSeasons}</ShowMoreTd>
                                            </ShowMoreTr>
                                        </tbody>
                                    </ShowMoreTable>
                                </Grid>

                            </Grid>

                            <CropPlanButtonBox style={{ marginTop: '2em' }}>
                                <Grid container spacing={3}>

                                    <Grid item lg={2} md={2} sm={6} xs={2}>
                                    </Grid>

                                    <Grid item lg={2} md={2} sm={6} xs={2}>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={6} xs={4}>
                                        {/* <CropPlanDelBtn onClick={props?.handleClose}>Cancel<CloseIcon /></CropPlanDelBtn> */}
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={6} xs={4}>
                                        <CropPlanTableAdd onClick={handleRotateSeason} disabled={btnState?.isDisabled}>{btnState?.text}</CropPlanTableAdd>
                                    </Grid>
                                </Grid>
                            </CropPlanButtonBox>

                        </CropPlanModalInner>
                    </CropPlanModal>
                    {/* <FarmCropRotation>
                                <p style={{ color: 'rgba(200,0,0,0.9)' }}>No Crop Rotations Yet!</p>
                            </FarmCropRotation>
                        } */}
                </Fade>

            </Modal>
        </>
    )
};

export default PushToRotation;