import styled from 'styled-components';

export const FarmSummaryNav = styled.section`
  height: fit-content;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 1);
  border-radius: 0.3em;
  margin-top: 0em;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  font-family: Avenir Next;
  font-style: normal;
  // position:absolute;
`;

export const FarmSummaryNavInner = styled.div`
  width: 100%;
  // margin-left: 2em;
  // margin-right: 2em;
  // margin-top: 2em;
  margin-bottom: 1.5em;
  @media screen and (max-width: 769px) {
    width: 100%;
    // margin-left: 1em;
    // margin-right: 1em;
  }
`;

export const FarmSummaryEachNav = styled.div`
  width: 100%;
  // background: #fafafa;
  // border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 4px;
  height: 100%;
  margin-top: 2em;
`;

export const FarmSummaryDownloadBtn = styled.button`
  height: 3em;
  width: 100%;
  background: #05674a;
  border-radius: 4px;
  color: white;
  border: none;
  font-weight: bold;
  transition: 0.5s background;
  &:hover {
    background: white;
    border: 1px solid rgba(0, 106, 75, 1);
    color: rgba(0, 106, 75, 1);
  }
`;

export const FarmViewBtn = styled.button`
  height: 2.5em;
  width: 6em;
  display:block;
  margin-top:0.5em;
  margin-bottom:0.5em;
  border-radius: 4px;
  font-weight: normal;
  background: white;
  border: 1px solid rgba(0, 106, 75, 0.5);
  color: rgba(0, 106, 75, 1);
  transition: 0.5s background;
  &:hover {
    color: white;
    border: none;
    background: #05674a;
  }
`;

export const FarmEditBtn = styled.button`
  height: 2.5em;
  width: 6em;
  display:block;
  margin-top:0.5em;
  margin-bottom:0.5em;
  border-radius: 4px;
  font-weight: normal;
  background: #05674a;
  border: 1px solid rgba(0, 106, 75, 0.5);
  color: white;
  transition: 0.5s background;
  &:hover {
    color: #05674a;
    background: white;
  }
`;

export const FarmSummaryData = styled.section`
  height: fit-content;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 1);
  margin-top: 0em;
  // border: 1px solid #d4d8db;
  display: flex;
  justify-content: space-between;
  font-family: Avenir Next;
  font-style: normal;
  // position:absolute;
`;

export const FarmSummaryDataGrid = styled.div`
  height: 30em;
  height: fit-content;
`;

export const FarmSummaryDataTable = styled.div`
  width: 100%;
  // margin-left: 2em;
  // margin-right: 2em;
  margin-top: 1em;
  margin-bottom: 2em;
  height: fit-content;
  @media screen and (max-width: 769px) {
    width: 100%;
    // margin-left: 1em;
    // margin-right: 1em;
  }
`;

export const FarmSummaryTableItemTitle = styled.h3`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: #011c14;
`;

export const FarmSummaryTableItemValue = styled.h3`
  // font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9em;
  line-height: 25px;
  // color: #011C14;
  color: #011c14;
`;

export const FarmSummarySearch = styled.input`
  height: 3.5em;
  margin-bottom: 3em;
  margin-top: 1em;
  width: 100%;
  height: 3em;
  border-radius: 0.3em;
  border: 1px solid rgba(5, 5, 5, 0.3);
  background: rgba(5, 5, 5, 0.005);
  outline: none;
  padding: 1.7em 0.5em;
  // margin: 8px 0;
  box-sizing: border-box;
  color: rgba(5, 5, 5, 1);
  &:hover {
    border: 1px solid rgba(5, 5, 5, 0.3);
  }
  &::-webkit-input-placeholder {
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    padding-left: 5px;
    padding-top: 2px;
    color: rgba(5, 5, 5, 0.5);
  }
  @media screen and (max-width: 769px) {
    // color: rgba(5, 5, 5, 1);
    // border: 1px solid rgba(255, 255, 255, 0.3);
    // background: rgba(255, 255, 255, 1);
  }
`;

// --- FarmSummaryInfo ---
export const FarmSummaryMap = styled.div`
  width: 100%;
  height: 25em;
  // border: 1px solid #d4d8db;
  @media screen and (max-width: 769px) {
    width: 100%;
    // margin-left: 4em;
    // margin-right: 1em;
  }
`;

export const FarmSummaryChart = styled.section`
  width: 100%;
  height: 25em;
  // background:#05674a;
  margin-top: 2em;
  border: 1px solid #d4d8db;
`;

// --- Farm summary side bar ---
export const FarmSumamryInfoSide = styled.section`
  width: 4em;
  // background:red;
  height: 100vh;
  background: rgba(5, 5, 5, 0.005);
  z-index: 10000;
`;

export const FarmSideBarTop = styled.div`
  width: 100%;
  height: 5em;
  border: 1px solid white;
  background: rgba(0, 106, 75, 1);
  // position:absolute;
`;

export const FarmSideBarNavIcon = styled.div`
  margin-left: 1em;
  margin-top: 2em;
  color: white;
  cursor: pointer;
`;

export const FarmSideBarInner = styled.div`
  // border-left: 1px solid rgba(5, 5, 5, 0.2);
  width: 35em;
  height: 90vh;
  overflow: scroll;
  background: white;
  border-radius: 0.3em;
  border: none;
  @media screen and (max-width: 769px) {
    height: 100vh;
  }
`;

export const FarmSideBarTitle = styled.h3`
  color: rgba(0, 106, 75, 1);
  text-align: center;
  font-size: 1.3em;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const FarmSideBarFirstCard = styled.section`
  height: 10em;
  // width:100%;
  display: flex;
  background: white;
  margin-left: 1em;
  margin-right: 1em;
`;

export const FarmSideBarCardLeft = styled.div`
  width: 50%;
  margin-top: 2em;
`;

export const FarmSideBarCardRight = styled.div`
  width: 48%;
  margin-left: 2%;
`;

export const FarmSideBarFirstCardImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const FarmSideBarFirstCardTitle = styled.h3`
  font-size: 1.2em;
  font-weight: bold;
  color: rgba(0, 106, 75, 1);
  margin-top: 3.5em;
`;

export const FarmSideBarFirstCardSubtitle = styled.p`
  color: rgba(5, 5, 5, 0.9);
`;

export const FarmSideBarSecondCard = styled.section`
  height: 8em;
  // width:100%;
  display: flex;
  background: rgba(245, 245, 245, 1);
  margin-top: 2em;
  justify-content: space-between;
`;

export const FarmSideBarSecondLeft = styled.div`
  width: 50%;
  margin-left: 1em;
  margin-top: 1em;
`;

export const FarmSideBarSecondRight = styled.div`
  width: 50%;
  text-align: right;
  margin-right: 1em;
  margin-top: 1em;
`;

export const FarmSideBarSecondTitle = styled.h4`
  font-size: 0.9em;
  font-weight: normal;
`;

export const FarmSideBarSecondSubtitle = styled.h5`
  font-size: 0.8em;
  margin-top: 2em;
  font-weight: normal;
`;

export const FarmSideBarSecondText = styled.p`
  color: rgba(5, 5, 5, 0.9);
  font-size: 1em;
  font-weight: bold;
`;

export const FarmSideBarThirdCard = styled.section`
  height: fit-content;
  // width:100%;
  background: rgba(245, 245, 245, 1);
  margin-top: 1em;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

export const FarmSideBarThirdCardTitle = styled.h3`
  font-size: 0.9em;
  font-weight: normal;
  margin-top: 1.5em;
  margin-left: 1em;
  margin-bottom: 1em;
`;

export const FarmSideBarThirdCardInner = styled.div`
  margin-left: 1em;
  margin-right: 1em;
  // display:flex;
  justify-content: space-between;
  margin-top: 3em;
  background: rgba(1, 28, 20, 0.8);
  border-radius: 0.3em;
`;

export const FarmSideBarIcon = styled.img`
  background: transparent;
  border-radius: 50px;
  width: 4em;
  height: 4em;
`;

export const FarmSideBarThirdCardSeason = styled.p`
  font-weight: bold;
  // color: rgba(0, 106, 75, 1);
  color: white;
`;

export const FarmSideBarThirdCardDate = styled.p`
  font-weight: normal;
  color: white;
`;

export const FarmSideBarThirdCardDay = styled.h3`
  // color: rgba(0, 106, 75, 1);
  color: white;

  font-size: 3em;
  margin-top: 0em;
`;

export const FarmSideBarThirdCardWeatherType = styled.h3`
  font-size: 1em;
  // color: rgba(5, 5, 5, 0.9);
  color: white;
  font-weight: normal;
`;

export const FarmSideBarThirdCardWeatherIcon = styled.img``;

export const FarmSideBarThirdCardWeatherVal = styled.p`
  // color: rgba(5, 5, 5, 0.7);
  color: white;
`;

export const FarmSideBarFourthCard = styled.section`
  height: fit-content;
  // width:100%;
  background: white;
  margin-top: 1em;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

export const FarmSideBarFourthCardTitle = styled.h5`
  // font-size: 0.9em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
`;

export const FarmSideBarFourthCardInner = styled.div`
  margin-left: 1em;
  margin-right: 1em;
  // display:flex;
  justify-content: space-between;
  margin-top: 1em;
  height: 25em;
  overflow-x: scroll;
`;

export const FarmSideBarFourthCardTItle = styled.h3`
  font-size: 1em;
  color: rgba(5, 5, 5, 0.9);
  margin-top: 0.5em;
`;

export const FarmSideBarFourthCardText = styled.p`
  font-size: 1em;
  color: rgba(5, 5, 5, 0.9);
`;

export const FarmTasksContainer = styled.div`
  margin-left: 1em;
  margin-right: 1em;
  background: rgba(0, 106, 75, 0.1);
  padding: 0.5em 1em;
`;

export const FarmResponsiveCard = styled.div`
  overflow-y: scroll;
  height: fit-content;
  min-height: 5em;
  max-height: 21.5em;
`;

export const FarmSideBarFourthCardImg = styled.img`
  width: 100%;
  height: 10em;
`;

export const FarmSideBarFourthCardBottomText = styled.p`text-align: center;`;

export const FarmCropRotation = styled.div`
  height: fit-content;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background: rgba(250, 250, 250, 1);
  margin-top: 1em;
  margin-bottom: 1em;
  // background:rgba(255,255,255,1);
  background: rgba(0, 106, 75, 0.1);
  // justify-content: space-between;
`;

export const CropPlanRotateBox = styled.div`
  max-width: 40em;
  overflow: scroll;
  width: 100%;
`;

export const FarmInfoCard = styled.section`
  max-height: 25em;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: white;
  margin-top: 0em;
  // border: 1px solid #d4d8db;
  border-radius: 0.3em;
  // background:rgba(5,5,5,0.1);
  // display: flex;
  justify-content: space-between;
  font-family: Avenir Next;
  font-style: normal;
`;

export const FarmInfoWeather = styled.div`
  height: 100%;
  // width:100%;
  background: rgba(245, 245, 245, 1);
  background: rgba(255, 255, 255, 1);
  margin-top: 1em;
  border-radius: 0.5em;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

export const FarmSummaryTitle = styled.h3`
  font-size: 1em;
  font-weight: bold;
  margin-top: 1.5em;
  margin-left: 1em;
  margin-bottom: 2em;
  color: #05674a;
`;

export const FarmInfoLink = styled.div`
  font-size: 0.9em;
  font-weight: normal;
  cursor: pointer;
  color: blue;
  margin-left: 1em;
`;

export const FarmDataNotFound = styled.p`
  margin-left: 1em;
  color: rgba(200, 0, 0, 0.9);
  margin-top: 2em;
`;

// --- preview Download styles ---
export const PreviewBody = styled.section`
  width: 90%;
  height: 90vh;
  background: rgba(245, 245, 245, 1);
  overflow-y: scroll;
`;

export const PrevieBodyTitle = styled.h6`
  color: rgba(0, 106, 75, 1);
  margin-top: 2em;
  margin-left: 10%;
  @media screen and (max-width: 769px) {
    margin-left: 5%;
  }
  @media print {
    display: none;
  }
`;

export const PreviewDoc = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background: white;
  height: fit-content;
  margin-top: 1em;
  padding: 0.1em 0em;
  @media screen and (max-width: 769px) {
    width: 90%;
  }
`;

export const PreviewDocInner = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

export const PreviewTitle = styled.h3`
  color: rgba(5, 5, 5, 1);
  text-align: center;
  font-size: 1.1em;
  margin-top: 1em;
  @media screen and (max-width: 769px) {
    font-size: 1em;
  }
`;

export const PreviewCard = styled.div`
  height: 20em;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: white;
  margin-top: 0em;
  border: 1px solid #d4d8db;
  // display: flex;
  justify-content: space-between;
  font-family: Avenir Next;
  font-style: normal;
`;

export const PrevieCardImg = styled.img`
  line-height: 2em;
  width: 100%;
  height: 12em;
  @media screen and (max-width: 769px) {
    margin-top: 1.5em;
    line-height: 2em;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
`;

export const PreviewPageSection = styled.section`
  // background:red;
  height: fit-content;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const PreviewContentTitle = styled.h4`
  color: rgba(5, 5, 5, 1);
  font-size: 1em;
  margin-top: 1em;
  font-weight: bold;
  line-height: 2em;
`;

export const PreviewContentText = styled.p`
  color: rgba(5, 5, 5, 1);
  font-size: 0.9em;
  margin-top: 1em;
  line-height: 2em;
  @media screen and (max-width: 769px) {
    font-size: 0.9em;
  }
`;

export const PreviewContentOrdering = styled.ol`line-height: 2em;`;

export const PreviewContentList = styled.li`
  font-size: 0.9em;
  font-style: italic;
`;

export const PreviewContentTable = styled.table`
  width: 100%;
  line-height: 2em;
  border: 1px solid rgba(5, 5, 5, 0.3);
  display: none;
`;

export const PreviewContentTableTr = styled.tr`
  border: 1px solid rgba(5, 5, 5, 0.3);
`;

export const PreviewContentTableTh = styled.th`
  border: 1px solid rgba(5, 5, 5, 0.3);
  text-align: center;
  padding: 1em 0.5em;
`;

export const PreviewContentTableTd = styled.td`
  border: 1px solid rgba(5, 5, 5, 0.3);
  padding: 1em 0.5em;
  text-align: center;
`;

export const PreviewDocButton = styled.button`
  height: 3em;
  width: 100%;
  background: #05674a;
  border-radius: 4px;
  color: white;
  border: none;
  font-weight: bold;
  transition: 0.5s background;
  &:hover {
    background: white;
    color: rgba(0, 106, 75, 1);
    border: 1px solid rgba(0, 106, 75, 1);
  }
  @media print {
    display: none;
  }
`;

export const PreviewCancelButton = styled.button`
  height: 3em;
  width: 100%;
  border-radius: 4px;
  border: none;
  font-weight: bold;
  transition: 0.5s background;
  background: white;
  color: rgba(0, 106, 75, 1);
  border: 1px solid rgba(0, 106, 75, 1);
  &:hover {
    background: #05674a;
    color: white;
  }
  @media print {
    display: none;
  }
`;

// --- Show More ---

export const ShowMoreBody = styled.section`
  background: white;
  // height:20em;
  height: fit-content;
  padding: 0.1em 0em;
  font-family: Avenir Next;
  font-style: normal;
  width: 50%;
  @media screen and (max-width: 769px) {
    width: 90%;
  }
`;

export const ShowMoreTitle = styled.h6`
  color: rgba(5, 5, 5, 0.9);
  text-align: center;
  margin-top: 1.5em;
`;

export const ShowMoreTable = styled.table`
  width: 100%;
  line-height: 2em;
  margin-top: 2em;
  margin-bottom: 2em;
  border: 1px solid rgba(5, 5, 5, 0.3);
`;

export const ShowMoreTh = styled.th`
  border: 1px solid rgba(5, 5, 5, 0.3);
  text-align: center;
  padding: 1em 0.5em;
  font-weight: 600;
`;

export const ShowMoreTd = styled.td`
  border: 1px solid rgba(5, 5, 5, 0.3);
  text-align: center;
  padding: 1em 0.5em;
`;

export const ShowMoreTr = styled.tr``;

export const ShowMoreCloseBtn = styled.button`
  height: 3em;
  width: 10em;
  background: #05674a;
  border-radius: 4px;
  color: white;
  border: none;
  font-weight: bold;
  float: right;
  margin-bottom: 1em;
  margin-right: 1em;
  transition: 0.5s background;
  &:hover {
    background: white;
    color: rgba(0, 106, 75, 1);
    border: 1px solid rgba(0, 106, 75, 1);
  }
`;


export const FarmSummaryInner = styled.div`
margin-left:1em;
margin-right:1em;
`;