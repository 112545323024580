import styled, { keyframes } from "styled-components";

export const MapBody = styled.div`
  // height:100vh
`;

export const BackgroundMap = styled.div`
  z-index: -1000;
  height: 50vh;
  width: 100%;
  // display:none;
`;

export const animateControl = keyframes`
from {
  opacity: 0;
  height:0em;
}
to {
  opacity : 1;
  height:21em;
}`;

export const MapSelectChart = styled.div`
  margin-top: 0em;
  height: 21em;
  animation-name: ${animateControl};
  animation-duration: 5s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
`;

export const MapControlCard = styled.section`
  width: 100%;
  height: 50vh;
  overflow-y: scroll;
  background: rgba(1, 28, 20, 1);
  z-index: 1000;
  bottom: 0;
  position: fixed;
  @media screen and (max-width: 769px) {
    width: 95%;
  }
`;

export const MapControlDate = styled.div`
  height: 4em;
  background: rgba(1, 28, 20, 1);
  width: 100%;
  // display:flex;
  position: fixed;
  z-index: 1000000;
`;

export const MapNav = styled.div`
  // width:5em;
  width: 100%;
  height: 4em;
  background: rgba(1, 28, 20, 1);
  color: white;
  display: flex;
  justify-content: space-between;
`;

export const MapControlDateInner = styled.div`
  width: 100%;
  height: 4em;
`;

export const MapControlDateCard = styled.div`
  max-width: 100%;
  margin-right: 3em;
  margin-left: 1em;
  height: 4em;
`;

export const MapControlEachDate = styled.div`
  height: 4em;
  width: 10em;
  background: rgba(5, 5, 5, 0.9);
  color: white;
  text-align: center;
  padding: 0.1em 0em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: 0.5s background;
  &:hover {
    background: rgba(5, 5, 5, 0.3);
  }
`;

export const MapDate = styled.p`
  color: white;
  font-weight: bold;
  font-size: 1.1em;
  margin-top: 1em;
`;

export const MapControlSelect = styled.div`
  background: rgba(5, 5, 5, 1);
  // margin-top: 4.5em;
  margin-top: 1em;
  width: 100%;
  background: rgba(1, 28, 20, 0.8);
`;

export const MapControlSelectInner = styled.div`
  margin-top: 1em;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

export const MapControlChart = styled.div`
  // background: rgba(5, 5, 5, 0.9);
  background: rgba(1, 28, 20, 0.8);
  width: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  // margin-top: 4em;
  @media screen and (max-width: 920px) {
    margin-top: 0em;
  }
`;

export const MapControlChartRender = styled.div`
  width: 100%;
  // margin-left:auto;
  // margin-right:auto;
  height: 20em;
`;

export const MapControlEachInput = styled.div`
  width: 100%;
  //   background: rgba(5, 5, 5, 1);
  margin-left: auto;
  margin-right: auto;
  //   color: white;
  height: fit-content;
  border-radius: 0.3em;
  background: rgba(1, 28, 20, 1);
  // margin-top: 1em;
  // margin-bottom: 1.5em;
`;

export const MapZoomCard = styled.section`
  width: 100%;
  height: 50vh;
  background: red;
  position: fixed;
  // margin-top: 2em;
  z-index: 10000;
  // display: none;
`;

export const MapDateArrowIcon = styled.div`
  // margin-left: 1em;
  @media screen and (max-width: 769px) {
    margin-left: 0em;
  }
`;

export const MapDateMinIcon = styled.div`
  margin-right: 8em;
  float: right;
  height: 2em;
  width: 2em;
  background: rgba(5, 5, 5, 5);
  margin-top: 1em;
  @media screen and (max-width: 769px) {
    margin-right: 5em;
    width: 4em;
  }
`;

export const DatesChartModal = styled.div`
  // height:fit-content;
  background: #212930;
  overflow-y: scroll;
  width: 100%;
  font-family: Roboto, sans-serif;
  // -webkit-font-smoothing: antialiased;
  border: 1px solid rgba(255, 255, 255, 1);
  &::-webkit-scrollbar {
    width: 0.5em;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 1);
  }
  background: rgba(1, 28, 20, 1);
  max-height: 80vh;
  height: fit-content;
  width: 50em;
  outline: none;
  border-radius: 0.3em;
  // z-index: 10000000;
  @media screen and (max-width: 769px) {
    width: 100%;
    height: 80vh;
  }
`;

export const ClickModalButton = styled.div`
  width: 3em;
  height: 3em;
  background: white;
  position: absolute;
  right: 3em;
  // top: 1.3em;
  top: 12em;
  margin-right: 1em;
  color: rgba(0, 106, 75, 0.7);
  text-align: center;
  cursor: pointer;
  border: 2px solid rgba(0, 106, 75, 0.3);
  transition: ease all 0.5s;
  transform: translateX(0);
  z-index: 1000;
  display: flex;
  align-items: center;
  align-self: center;
  border-radius: 0.3em 0.3em 0.3em 0.3em;
  @media screen and (max-width: 769px) {
    top: 12em;
    right: 1em;
  }
  &:hover {
    transition: ease all 0.5s;
    transform: translateX(5px);
  }
`;

export const ModalDates = styled.div`
  max-width: 100%;
  margin-right: 1em;
  margin-left: 1em;
  height: 4em;
`;
