import constants from "../constants";

const FarmReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.ALL_FARM_ACTIVITIES:
      return {
        ...state,
        all_farm_activity: action.payload,
      };
    case constants.SINGLE_FARM_ACTIVITIES:
      return {
        ...state,
        single_activities: action.payload || {},
      };
    case constants.USER_FARM_TASK:
      return {
        ...state,
        user_farm_task: action.payload || [],
      };
    case constants.GET_MONITOR_FIELD:
      return {
        ...state,
        user_field: action.payload,
      }
    default:
      return state;
  }
};

export default FarmReducer;
