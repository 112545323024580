import React, { useState, useEffect } from 'react';
import DefaultLayout from '../Layout/DefaultLayout';
import { ManageUserContainer } from './AdminStyle.styled';
import {Table} from 'antd';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {AllSystemPayments} from "../../../redux/actions/adminOtherServices";
import {CurrencySign, formatAmount, formatDateObjectHandler, GetFromNowDate} from "../../../utils";

const AdminPaymentRecord = props => {
    const { allPayments, AllSystemPayments } = props;
    const [loading, setloading] = useState(false);

    useEffect(() => {
        const getAllRecords = async () => {
            setloading(true);
            const res = await AllSystemPayments();
            setloading(false);
            if (!res.success) {
                setloading(false);
                toast.error(res.message, {
                    pauseOnHover: true,
                    closeOnClick: true,
                });
            }
        };
        getAllRecords();
    }, [AllSystemPayments]);

    const paymentCol = [
        {
            title: 'Reference',
            dataIndex: 'reference',
        },
        {
            title: <>Amount ({CurrencySign})</>,
            dataIndex: 'amount',
            render: (_text, record) => <>{formatAmount(record.amount)}</>
        },
        {
            title: 'Name',
            dataIndex: 'first_name',
            render: (_text, record) => <>{record.first_name} {record.last_name}</>
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
        },
        {
            title: 'Date',
            dataIndex: 'date_created',
            align: 'center',
            render: (_text, record) => (
                <>
                    {formatDateObjectHandler(record.date_created, 'DD-MM-YYYY')} <br/>
                    <small style={{ color: 'green' }}>{GetFromNowDate(record.date_created, 'YYYY-MM-DDTHH:mm:ss', 'fromNow')}</small>
                </>
            )
        },
    ];

    return (
        <DefaultLayout browserTitle="Payment Record">
            <ManageUserContainer data-aos="fade-right">
                <h3>Payment Record</h3>
                <Table loading={loading} style={{ overflowX: 'auto' }} rowKey="id" columns={paymentCol} dataSource={allPayments} />
            </ManageUserContainer>
        </DefaultLayout>
    );
};

const mapStateToProps = state => {
    return {
        allPayments: state.adminOtherReducer.allPayments,
    };
};

export default connect(mapStateToProps, {
    AllSystemPayments,
})(AdminPaymentRecord);
