import React from 'react';

// import Home from '../../components/home/Home';
import HomeBody from '../../components/home/HomeBody';
import HomeFooter from '../../components/home/HomeFooter';
import HomeHeader from '../../components/home/HomeHeader';

const HomePage = () => {
    return (
        <>
            <HomeHeader />
            <HomeBody />
            <HomeFooter />
            {/* <Home /> */}
        </>
    )
};

export default HomePage;