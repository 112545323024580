import React, { useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { AppContext } from '../../appRouter';
import DashboardLayout from '../../components/Layout/DashboardLayout';

import SplitView from '../../components/satelliteField/SplitView';
// import Header from "../../components/util/Header";

const SplitViewPage = (props) => {

    const { selectedCentroid, selectedCoords, selectedRealCoords, cloudCover, handleCloudCover, startDate, endDate } = useContext(AppContext);

    let history = useHistory();

    useEffect(() => {
        // check if a shape/field has been selected or not
        if (selectedCoords?.length === 0) {
            history.push('/dashboard/');
        }
    });

    return (
        <>
            {/* No need for broken link page here. But instead toasify straightaway */}
            <DashboardLayout pageTitle="Satellite View" isHome={true}>

                {/* <Header /> */}

                <SplitView
                    selectedCentroid={selectedCentroid}
                    selectedCoords={selectedCoords}
                    selectedRealCoords={selectedRealCoords}
                    cloudCover={cloudCover}
                    handleCloudCover={handleCloudCover}
                    startDate={startDate}
                    endDate={endDate}
                    height={'100vh'}
                />

            </DashboardLayout>
        </>
    )
};

export default SplitViewPage;