import React, { useEffect, useState, useMemo } from "react";

import { BasemapLayer } from "react-esri-leaflet";

import {
  MapContainer,
  Polygon,
  WMSTileLayer,
  Tooltip,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import { bbox, bboxPolygon, point, buffer } from "@turf/turf";

import getIndexData from "../../adapters/map/getIndexData";
import LoadingOverlay from "react-loading-overlay";
import { wmsKey } from "../util/Util";

const BoundTrueImage = (props) => {
  let basePolygon = React.useRef();

  const map = useMap();

  setTimeout(() => {
    try {
      map.fitBounds(basePolygon?.current?._bounds);
      props?.setIsMapLoading(false);
    } catch (e) {
      console.log("Map could not be bound");
    }
  }, 5000);

  return (
    <Polygon
      ref={basePolygon}
      pathOptions={props?.pathOptions}
      positions={props?.selectedCoords}
    ></Polygon>
  );
};

const GetMapInfo = (props) => {
  const [tooltipData, setTooltipData] = useState({
    position: [],
    text: "Click any point to read value",
  });

  let basePolygon = React.useRef();

  const map = useMap();

  setTimeout(() => {
    try {
      map.fitBounds(basePolygon?.current?._bounds);
      props?.setIsMapLoading(false);
    } catch (e) {
      console.log("Map could not be bound");
    }
  }, 5000);

  const eventHandlers = useMemo(
    () => ({
      click(e) {
        let lat = Number.parseFloat(e.latlng.lat.toFixed(3));
        let lng = Number.parseFloat(e.latlng.lng.toFixed(3));

        let hoverPoint = point([lng, lat]);

        let buffered = buffer(hoverPoint, 0.2);

        let pointBBox = bbox(buffered);

        let pointPolygon = bboxPolygon(pointBBox);

        let geometry = `POLYGON ((${pointPolygon.geometry.coordinates[0]
          .map((coord) => coord.join(" "))
          .join(", ")}))`;

        let fisUrl = `https://services.sentinel-hub.com/ogc/fis/${wmsKey}?LAYER=${props?.index}&CRS=CRS:84&TIME=${props?.dateRange}&MAXCC=${props?.cloudCover}&WIDTH=512&HEIGHT=512&GEOMETRY=${geometry}`;

        setTooltipData({ position: e.latlng, text: "Please wait..." });

        let getResp = getIndexData(fisUrl);

        getResp.then(({ isError, data }) => {
          if (isError) {
            setTooltipData({ position: e.latlng, text: "Not Found" });
          } else {
            let totalMean = 0;
            let indexVal = 0;
            if (Object.keys(data).length > 0) {
              data["C0"].map((eachData) => {
                totalMean += eachData.basicStats.mean;
                return 0;
              });
              indexVal = (totalMean / data["C0"].length).toFixed(3);
            }

            setTooltipData({
              position: e.latlng,
              text: "Click any point to read value",
            });
            map.openPopup(`${props?.index} : ${indexVal}`, e.latlng, {
              className: "popupLeaflet",
            });
          }
        });
      },
    }),
    [props?.index, props?.cloudCover, map, props.dateRange]
  );

  return (
    <Polygon
      ref={basePolygon}
      pathOptions={props?.pathOptions}
      positions={props?.selectedCoords}
      eventHandlers={eventHandlers}
    >
      <Tooltip
        direction="top"
        className="tooltip"
        sticky
        position={tooltipData?.position}
      >
        {tooltipData?.text}
      </Tooltip>
    </Polygon>
  );
};

export const IndexMapRender = (props) => {
  const purpleOptions = { color: "rgba(255,255,255,0.3)" };

  const [selectedCoords, setselectedCoords] = useState(props?.selectedCoords);
  const [centroid, setCentroid] = useState([8.6775, 9.0788]);
  const [geometry, setGeometry] = useState("");
  const [index, setIndex] = useState(props?.index);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [dateRange, setDateRange] = useState(props?.dateRange);

  useEffect(() => {
    setIsMapLoading(true);

    let swapPath = [];

    props?.selectedCoords?.map((eachPath) => {
      let p = [eachPath[1], eachPath[0]];
      swapPath.push(p);
      return 0;
    }, []);

    setselectedCoords(swapPath);
    setIndex(props?.index);
    setDateRange(props?.dateRange);
    if (props?.index === "TRUE-COLOR") {
      setIsMapLoading(false);
    }
    setIsMapLoading(false);

    let geom = "";

    if (props?.selectedCoords?.length > 0) {
      geom = `POLYGON ((${props?.selectedCoords
        .map((coord) => coord.join(" "))
        .join(", ")}))`;
      setGeometry(geom);
    }

    // --- Set the centroid of the field properly ----
    let c =
      props?.selectedCentroid?.length >= 2
        ? [props?.selectedCentroid[1], props?.selectedCentroid[0]]
        : [8.6775, 9.0788];
    setCentroid(c);
  }, [
    props?.selectedCentroid,
    props?.selectedCoords,
    props?.index,
    props?.dateRange,
  ]);

  return (
    <>
      <LoadingOverlay
        active={isMapLoading}
        spinner
        text="Please wait while we are loading the map"
      >
        <div style={{ height: props?.height }}>
          <MapContainer
            attributionControl={false}
            center={centroid}
            zoom={props?.zoomVal}
            zoomControl={false}
            style={{ height: "100%", background: "white" }}
          >
            {/* ---- S */}
            {!props?.removeBg && (
              <>
                {props?.index !== "TRUE-COLOR" ? (
                  <BasemapLayer name="Imagery" />
                ) : (
                  <BasemapLayer name="Streets" />
                )}
              </>
            )}

            {props?.index && geometry?.length && (
              <>
                {props?.index !== "TRUE-COLOR" ? (
                  <>
                    <WMSTileLayer
                      key={index + dateRange}
                      layers={index}
                      format="image/png"
                      crs={L.CRS.EPSG4326}
                      attribution='&copy; <a href="http://www.sentinel-hub.com/" target="_blank">Sentinel Hub</a>'
                      url={`https://services.sentinel-hub.com/ogc/wms/${wmsKey}?REQUEST=GetMap`}
                      geometry={geometry}
                      maxcc={props?.cloudCover}
                      minZoom="0"
                      maxZoom="500"
                      zoom={12}
                      preset={index}
                      width="512"
                      height="512"
                      time={dateRange}
                      transparent="true"
                    />
                  </>
                ) : (
                  // do not pass cloud cover and the rest for TRUE COLOR
                  <>
                    {/* <Polygon pathOptions={purpleOptions} positions={selectedCoords} /> */}
                    <WMSTileLayer
                      key={index + dateRange}
                      layers={index}
                      format="image/png"
                      crs={L.CRS.EPSG4326}
                      attribution='&copy; <a href="http://www.sentinel-hub.com/" target="_blank">Sentinel Hub</a>'
                      url={`https://services.sentinel-hub.com/ogc/wms/${wmsKey}?REQUEST=GetMap`}
                      geometry={geometry}
                      minZoom="0"
                      maxZoom="500"
                      zoom={12}
                      preset={index}
                      width="500"
                      height="500"
                      transparent="true"
                    />
                  </>
                )}
                {/* disable on TRUE COLOR or Satalite Image */}
                {props?.index !== "TRUE-COLOR" ? (
                  <GetMapInfo
                    selectedCentroid={centroid}
                    selectedCoords={selectedCoords}
                    pathOptions={purpleOptions}
                    index={props?.index}
                    cloudCover={props?.cloudCover}
                    dateRange={props?.dateRange}
                    setIsMapLoading={setIsMapLoading}
                  />
                ) : (
                  <BoundTrueImage
                    selectedCentroid={centroid}
                    selectedCoords={selectedCoords}
                    pathOptions={purpleOptions}
                    index={props?.index}
                    cloudCover={props?.cloudCover}
                    dateRange={props?.dateRange}
                    setIsMapLoading={setIsMapLoading}
                  />
                )}
              </>
            )}
          </MapContainer>
        </div>
      </LoadingOverlay>
    </>
  );
};
