import React, { useEffect, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useHistory } from 'react-router-dom';

import {
    FormControl,
    Grid,
    InputLabel,
    Select,
} from '@material-ui/core';

import {
    CropPlanAddBtn,
    CropPlanButtonBox,
    CropPlanCard,
    CropPlanCardHeader,
    CropPlanCardInner,
    CropPlanCardSearch,
    // CropPlanCardTitle,
    CropPlanContainer,
    CropPlanDelBtn,
    CropPlanTable,
    CropSwitchBox,
    CropSwitchBtn,
    CropView,
    CropPlanTableDel,
    CropPlanTableAdd
} from '../../styles/cropPlan/CropPlanStyles';

import DashboardLayout from '../Layout/DashboardLayout';
import { useSelector } from 'react-redux';
import { getCropList } from '../../redux/actions/fieldManagement';
import { getRotationsByFieldId, getSeasonsByFieldId } from '../../redux/actions/cropPlanDevelopment';
import ShowCropsModal from './ShowCropsModal';
import { AddNewSeason } from './AddNewSeason';
import { DeleteModal } from './DeleteModal';
// import { AddNewCrop } from './AddNewCrop';
import { AddNewRotationCrop } from './AddNewRotation';
import PushToRotation from './PushToRotation';
import { Card, Table } from "antd";

import { useContext } from 'react';
import { AppContext } from '../../appRouter';



const CropPlan = (props) => {

    const { selectedFieldId, handleSelectedFieldId } = useContext(AppContext);
    const history = useHistory();

    const [fetchCrops, setFetchCrops] = useState(true);
    const [fetchSeasons, setFetchSeasons] = useState(true);
    const [fetchRotations, setFetchRotations] = useState(true);


    const [cropList, setCropList] = useState([]);
    const [cropListCopy, setCropListCopy] = useState([]);

    const [seasonList, setSeasonList] = useState([]);
    const [seasonListCopy, setSeasonListCopy] = useState([]);

    const [rotationList, setRotationList] = useState([]);
    const [rotationListCopy, setRotationListCopy] = useState([]);

    const [selSeason, setSelSeason] = useState('2021');
    const [selSeasonList, setSelSeasonList] = useState([]);

    const [open, setOpen] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [deleteType, setDeleteType] = useState('Crop');

    const [openSeason, setOpenSeason] = useState(false);
    const [openRotation, setOpenRotation] = useState(false);

    const [reRenderData, setRenderData] = useState(0);

    const [selectedCrop, setSelectedCrop] = useState(0);
    const [selectedField, setSelectedField] = useState(0);
    const [openViewSeasons, setOpenViewSeasons] = useState(false);

    const [openPushRotate, setOpenPushRotate] = useState(false);
    const [seasonCropId, setSeasonCropId] = useState(null);
    // ---- The selected rotation here ---
    const [selectedRotation, setSelectedRotation] = useState([]);
    const [selRotationId, setSelRotationId] = useState(null);

    // --- get the access key of the user here ---
    const {
        access_token: access_key,
    } = useSelector((state) => state.authReducer);

    // make request to get the list of the crops here
    useEffect(() => {

        if (history.location.pathname.substr(33)) {
            handleSelectedFieldId(history.location.pathname.substr(33));
        }
        // -- if field has not been selected yet, redirect to home
        if (!selectedFieldId) {
            toast.warning('Please, kindly select a farm field as we redirect you now.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => {
                history.push('/dashboard');
            }, 2000);
        }

        async function fetchData() {
            setFetchCrops(true);

            let { isError, data } = await getCropList(access_key);

            if (isError) {
                setCropList([]);
                setCropListCopy([]);
                setFetchCrops(false);
            } else {
                setCropList(data);
                setCropListCopy(data);
                setFetchCrops(false);
            }
        }
        fetchData();
    }, [access_key, reRenderData, selectedFieldId, history, handleSelectedFieldId]);


    useEffect(() => {
        async function fetchData() {
            setFetchSeasons(true);

            let { isError2, seasonData } = await getSeasonsByFieldId(access_key, selectedFieldId, selSeason);

            if (isError2) {
                setSeasonList([]);
                setSeasonListCopy([]);
                setFetchSeasons(false);
                toast.warning('An error occurred. Please refresh the page.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                setFetchSeasons(false);
                setSeasonList(seasonData);
                setSeasonListCopy(seasonData);
            }
        }

        if (selectedFieldId) { fetchData(); }
        // eslint-disable-next-line no-use-before-define
    }, [access_key, reRenderData, selectedFieldId, selSeason]);


    useEffect(() => {
        async function fetchData() {
            setFetchRotations(true);

            let { isError, data } = await getRotationsByFieldId(access_key, selectedFieldId);

            if (isError) {

                setRotationList([])
                setRotationListCopy([])

                setFetchRotations(false);
                toast.warning('An error occurred. Please refresh the page.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                setRotationList(data?.data)
                setRotationListCopy(data?.data)
                setFetchRotations(false);
            }
        }

        if (selectedFieldId) { fetchData(); }
        // eslint-disable-next-line no-use-before-define
    }, [access_key, reRenderData, selectedFieldId, selSeason]);

    // handler for add new crop modal
    const handleClose = () => {
        setOpen(false);
    }

    // handler for del crop
    const handleDelClose = () => {
        setOpenDel(false);
    }

    // handler for add crop season
    const handleAddSeasonClose = () => {
        setOpenSeason(false);
    }

    const handleAddRotationClose = () => {
        setOpenRotation(false)
    }

    // check if a crop was selected or not
    const handleDelCheckCrop = () => {
        if (deleteType === 'Crop') {
            if (selectedCrop === 0) {
                toast.warning('Please select the crop to delete', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                setOpenDel(true);
            }
        } else {
            if (selectedField === 0) {
                toast.warning('Please select the field to delete', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                setOpenDel(true);
            }
        }
    }


    // --- event handler for deleting the rotation
    const handleDelRotation = (e) => {
        if (!selectedRotation?.length) {
            toast.warning('Please select the rotation to delete', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            setOpenDel(true);
        }
    }

    const handleDelSeason = (e) => {
        setSelectedField(e);
        setOpenDel(true);
        setSeasonCropId(e);
        setDeleteType('Season')
    }

    const [switchState, setSwitchState] = useState({
        first: { background: 'rgba(5, 5, 5, 0.7)' },
        second: { background: 'rgba(5, 5, 5, 0.1)' }
    });

    const [currSwitch, setCurrSwitch] = useState('Seasons')

    const handleSwitch = (e) => {

        if (e === "Seasons") {
            setSwitchState({
                first: { background: 'rgba(5, 5, 5, 0.7)' },
                second: { background: 'rgba(5, 5, 5, 0.1)' }
            })
            setCurrSwitch(e);
        } else {
            setSwitchState({
                first: { background: 'rgba(5, 5, 5, 0.1)' },
                second: { background: 'rgba(5, 5, 5, 0.7)' }
            })
            setCurrSwitch(e);
        }
    }


    const handleCloseViewSeasons = () => {
        setOpenViewSeasons(false)
    }

    const handleOpenViewSeasons = (seasons, rotateId) => {
        setOpenViewSeasons(true)
        setSelSeasonList(seasons)
        setSelRotationId(rotateId)
    }

    const handlePushRotate = () => {
        setOpenPushRotate(true);
    }

    const handlePushCloseRotate = () => {
        setOpenPushRotate(false)
    }

    const columns3 = [
        {
            title: <strong>Rotation</strong>,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <strong>Start Date</strong>,
            dataIndex: 'start_date',
            key: 'start_date',
        },
        {
            title: <strong>End Date</strong>,
            dataIndex: 'end_date',
            key: 'end_date',
        },
        {
            title: <strong>Action</strong>,
            dataIndex: 'action',
            render: (text, row) =>
                <CropView style={{ marginTop: '-0.5em' }} onClick={() => handleOpenViewSeasons(row?.crop_season_years, row?.id)}>View Seasons</CropView>
        }
    ];

    const columns = [
        {
            title: <strong>Crop</strong>,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <strong>Description</strong>,
            dataIndex: 'description',
            key: 'description',
        }
    ];

    const columns2 = [
        {
            title: <strong>Crop</strong>,
            dataIndex: 'crop',
            key: 'crop',
            render: crop =>
                // <CropView style={{ marginTop: '-0.5em' }} onClick={handlePushRotate}>Rotate Crop</CropView>
                <div>{crop?.name}</div>

        },
        {
            title: <strong>Sowing Date</strong>,
            dataIndex: 'sowing_date',
            key: 'sowing_date',
        },
        {
            title: <strong>Harvest Date</strong>,
            dataIndex: 'harvest_date',
            key: 'harvest_date',
        },
        {
            title: <strong>Action</strong>,
            dataIndex: 'id',
            // fixed: 'right',
            key: 'id',
            render: val =>
                // <CropView style={{ marginTop: '-0.5em' }} onClick={handlePushRotate}>Rotate Crop</CropView>
                <CropPlanTableDel onClick={() => handleDelSeason(val)}>Remove Crop</CropPlanTableDel>
        }
    ];

    function range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }

    const seasonDates = range(2021, 2040);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedCrop(selectedRowKeys[0]);
            setDeleteType('Crop')
        }
    };

    const rowSelection3 = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRotation(selectedRows);
            setDeleteType('Rotation')
        }
    };

    const tableState1 = {
        bordered: false,
        loading: fetchCrops,
        size: 'default',
        scroll: true,
        hasData: true,
        tableLayout: undefined,
        top: 'none',
        bottom: 'bottomRight',
    }
    const tableState2 = {
        bordered: false,
        loading: fetchSeasons,
        size: 'default',
        scroll: true,
        hasData: true,
        tableLayout: undefined,
        top: 'none',
        bottom: 'bottomRight',
    }

    const tableState3 = {
        bordered: false,
        loading: fetchRotations,
        size: 'default',
        scroll: true,
        hasData: true,
        tableLayout: undefined,
        top: 'none',
        bottom: 'bottomRight',
    }


    const searchCrops = (e) => {
        let searchItem = e.target.value;
        let res = [];
        cropListCopy.find(function (post, index) {
            if (
                post?.name.includes(searchItem) ||
                post?.description.includes(searchItem)
            ) {
                res.push(post);
                return setCropList(res);
            }
            return 0;
        });
    };

    const searchSeasons = (e) => {
        let searchItem = e.target.value;

        if (currSwitch === "Seasons") {
            console.log('seasons')
            let res = [];
            seasonListCopy.find(function (post, index) {
                if (
                    post?.crop?.name.includes(searchItem)
                ) {
                    res.push(post);
                    return setSeasonList(res);
                }
                return 0;
            });
        } else {
            let res = [];
            rotationListCopy.find(function (post, index) {
                if (
                    post?.name.includes(searchItem)
                ) {
                    console.log('rotate', rotationList, searchItem)

                    res.push(post);
                    return setRotationList(res);
                }
                return 0;
            });
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <DashboardLayout pageTitle="Configure Your Crops">
                <CropPlanContainer style={{ marginTop: '0em' }}>
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12} key='cropsDatabase'>
                            <CropPlanCardHeader>
                                <h4 style={{ marginTop: '2em' }}>Crops Database</h4>

                                {/* <CropPlanCardTitle>Crops Database</CropPlanCardTitle> */}
                            </CropPlanCardHeader>
                            <CropPlanCard>
                                <CropPlanCardInner>
                                    <CropPlanCardSearch
                                        type="search"
                                        placeholder="Search any Crop by Name or Description"
                                        onInput={searchCrops}
                                    />
                                    <CropPlanTable>
                                        <Table
                                            {...tableState1}
                                            rowSelection={{
                                                type: 'radio',
                                                ...rowSelection,
                                            }}
                                            dataSource={cropList} columns={columns}
                                            rowKey="id"
                                        />
                                    </CropPlanTable>
                                    {/* <CropPlanButtonBox>
                                        <Grid container spacing={3}>

                                            <Grid item lg={6} md={6} sm={6} xs={6} key='delete'>
                                                <CropPlanDelBtn onClick={handleDelCheckCrop}>Delete Crop</CropPlanDelBtn>
                                            </Grid>

                                            <Grid item lg={6} md={6} sm={6} xs={6} key='create'>
                                                <CropPlanAddBtn onClick={() => { setOpen(true) }}>Create New Crop</CropPlanAddBtn>
                                            </Grid>
                                        </Grid>
                                    </CropPlanButtonBox> */}
                                </CropPlanCardInner>
                            </CropPlanCard>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12} key='switchDatabase'>
                            <CropPlanCardHeader>
                                <h4 style={{ marginTop: '2em' }}>{currSwitch} Database</h4>
                                {/* <h4 style={{ marginTop: '2em' }}>{fieldData?.name ? `${currSwitch} For : ${fieldData?.name}` : 'Please select a farm field on the dashboard.'} </h4> */}
                            </CropPlanCardHeader>
                            <CropPlanCard>

                                <CropPlanCardInner>
                                    <CropSwitchBox>
                                        <Grid container spacing={2}>
                                            {currSwitch === "Seasons" &&
                                                <Grid item lg={4} md={4} sm={4} xs={4} key='seasons'>
                                                    <FormControl variant="outlined" style={{ width: "100%", color: 'rgba(1, 28, 20, 1)', border: "1px solid rgba(255,255,255,0.3)", borderRadius: '0.3em' }}>
                                                        <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'rgba(1, 28, 20, 1)' }}>Season</InputLabel>
                                                        <Select
                                                            native
                                                            defaultValue={seasonDates[0]}
                                                            name="index"
                                                            label="Index"
                                                            onChange={(e) => setSelSeason(e.target.value)}
                                                            style={{ border: "1px solid rgba(255,255,255,1) !important", color: 'rgba(1, 28, 20, 1)', height: '2.5em' }}
                                                        >
                                                            {seasonDates.map((eachSeason, pos) => (
                                                                <option key={pos} defaultValue={eachSeason} style={{ color: 'rgba(1, 28, 20, 1)' }}>{eachSeason}</option>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            }
                                            <Grid item lg={4} md={4} sm={4} xs={4} key='seasonsAction'>
                                                <CropSwitchBtn style={switchState?.first} onClick={() => handleSwitch('Seasons')}>Seasons</CropSwitchBtn>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={4} xs={4} key='rotationsAction'>
                                                <CropSwitchBtn style={switchState?.second} onClick={() => handleSwitch('Rotations')}>Rotations</CropSwitchBtn>
                                            </Grid>
                                        </Grid>
                                    </CropSwitchBox>


                                    <CropPlanCardSearch
                                        type="search"
                                        onInput={searchSeasons}
                                        placeholder={`Search any ${currSwitch} ....`}
                                    // onInput={searchDataGrid}
                                    />


                                    <CropPlanTable>

                                        {currSwitch === "Seasons" ?
                                            <Card bordered={false} className="card-shadow" key='seasonsTable'>
                                                <Table
                                                    key='seasonsTable'
                                                    {...tableState2}
                                                    // scroll={{ x: 150 }}
                                                    scroll={{ x: 500 }}
                                                    style={{ width: '100%' }}
                                                    dataSource={seasonList}
                                                    columns={columns2}
                                                    rowKey="id"
                                                />
                                            </Card>
                                            :
                                            <Table
                                                key='rotationsTable'
                                                {...tableState3}
                                                scroll={{ x: 500 }}
                                                style={{ width: '100%' }}
                                                rowSelection={{
                                                    type: 'radio',
                                                    ...rowSelection3,
                                                }} dataSource={rotationList} columns={columns3} rowKey="id" />
                                        }
                                    </CropPlanTable>

                                    <CropPlanButtonBox>

                                        {currSwitch === "Seasons" ?
                                            <Grid container spacing={3} key='seasons'>

                                                <Grid item lg={6} md={6} sm={6} xs={6} key='add'>
                                                    <CropPlanTableAdd onClick={() => { setOpenSeason(true) }}>Add Crop to Season</CropPlanTableAdd>
                                                </Grid>

                                                <Grid item lg={6} md={6} sm={6} xs={6} key='rotate'>
                                                    <CropPlanAddBtn onClick={handlePushRotate}>Rotate {selSeason}</CropPlanAddBtn>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container spacing={3} key='rotation'>
                                                <Grid item lg={6} md={6} sm={6} xs={6} key='delete'>
                                                    <CropPlanTableDel onClick={handleDelRotation}>Delete Rotation</CropPlanTableDel>
                                                </Grid>

                                                <Grid item lg={6} md={6} sm={6} xs={6} key='add'>
                                                    <CropPlanAddBtn onClick={() => { setOpenRotation(true) }}>Add New Rotation</CropPlanAddBtn>
                                                </Grid>
                                            </Grid>
                                        }

                                    </CropPlanButtonBox>
                                </CropPlanCardInner>
                            </CropPlanCard>
                        </Grid>
                    </Grid>
                </CropPlanContainer>
            </DashboardLayout>

            {/* call the modal to add a new crop */}
            {/* <AddNewCrop
                handleClose={handleClose}
                open={open}
                setOpen={setOpen}
                setRenderData={setRenderData} /> */}

            {/* call the modal to delete added crop - asking if yes or cancel */}
            <DeleteModal
                handleClose={handleDelClose}
                open={openDel}
                setOpen={setOpenDel}
                selectedCrop={selectedCrop}
                deleteType={deleteType}
                selectedField={selectedField}
                seasonList={seasonList}
                seasonYear={selSeason}
                seasonCropId={seasonCropId}
                selectedRotation={selectedRotation}
                setRenderData={setRenderData} />

            {/* call the modal to add a new season */}
            <AddNewSeason
                handleClose={handleAddSeasonClose}
                open={openSeason}
                setOpen={setOpenSeason}
                seasonList={seasonList}
                cropList={cropList}
                fieldId={selectedFieldId}
                seasonYear={selSeason}
                setRenderData={setRenderData} />

            {/* call the modal to show the crops under a rotation */}
            <ShowCropsModal
                handleClose={handleCloseViewSeasons}
                open={openViewSeasons}
                seasonsList={selSeasonList}
                fieldId={selectedFieldId}
                cropList={cropList}
                selRotationId={selRotationId}
                setRenderData={setRenderData}
            />

            <AddNewRotationCrop
                handleClose={handleAddRotationClose}
                open={openRotation}
                setOpen={setOpenSeason}
                seasonList={seasonList}
                cropList={cropList}
                fieldId={selectedFieldId}
                setRenderData={setRenderData}
            />

            <PushToRotation
                handleClose={handlePushCloseRotate}
                open={openPushRotate}
                selSeason={selSeason}
                rotationList={rotationList}
                setRenderData={setRenderData}
            />
        </>
    )
};

export default CropPlan;