import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { getField } from "../../redux/actions/fieldManagement";
import Weather from "../../components/weather/Weather";
import { getForeCast } from "../../redux/actions/weatherManagement";
import { BrokenLinkPage } from "../errorHandling";
import DashboardLayout from "../../components/Layout/DashboardLayout";

const WeatherPage = (props) => {
  
  const [gotError, setGotError] = useState(false);
  const [stillFetching, setStillFetching] = useState(true);
  const [weatherData, setWeatherData] = useState({
    date: [],
    weatherUrlIcon: [],
    maxMinTemp: [],
    indicator: [],
    windSpeed: [],
    humidity: [],
    cloud_over: [],
    precipitation: [],
  });

  let coord = props?.location?.pathname.substr(28).split(",");

  useEffect(() => {
    // stripe off into two separate points

    let lat = coord[1]?.substr(0, 5);
    let long = coord[0]?.substr(0, 5);

    async function fetchData() {
      let { isError, data } = await getForeCast((lat, long));
      if (isError) {
        toast.error(
          "It looks like we could not provide the weather data at the moment. Please kindly go back to select a field and try again here.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setGotError(true);
        setStillFetching(false);
      } else {
        // setGotError(false);
        let weather = {
          date: [],
          weatherUrlIcon: [],
          maxMinTemp: [],
          indicator: [],
          windSpeed: [],
          humidity: [],
          cloud_over: [],
          precipitation: [],
        };

        data.map((row) => {
          weather?.indicator?.push(row?.indicator);
          weather?.date?.push(row?.date);
          weather?.maxMinTemp?.push(
            row?.max_temperature + " / " + row?.min_temperature
          );
          weather?.windSpeed?.push(row?.windspeed);
          weather?.humidity?.push(row?.Humidity);
          weather?.cloud_over?.push(row?.cloud_over);
          weather?.precipitation?.push(row?.precipitation);
          weather?.weatherUrlIcon?.push(row?.weatherUrlIcon);
          return 0;
        });

        setWeatherData(weather)
        setStillFetching(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.location?.pathname]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardLayout pageTitle="Weather Forecast">
        {gotError ? (
          <BrokenLinkPage
            customText="Looks like something went wrong!"
            customSubText="The weather forecast could not be fetched."
          />
        ) : (
            <>
              <Weather weatherData={weatherData} stillFetching={stillFetching} urlCoords={coord} />
            </>
          )}
      </DashboardLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    all_field: state.fieldManagementReducer.fields,
  };
};
export default connect(mapStateToProps, { getField })(WeatherPage);
