import React, { useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { BasemapLayer } from 'react-esri-leaflet';
import { MapContainer, Polygon, Tooltip, useMap, useMapEvents, WMSTileLayer } from 'react-leaflet';

import "leaflet/dist/leaflet.css";
import L from 'leaflet';

import DirectionsIcon from '@material-ui/icons/Directions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { DashboardContainer } from '../../styles/util/UtilStyle';
import LoadingOverlay from 'react-loading-overlay';
import { NextBox, PreviousBtn, SatelliteContainer } from '../../styles/satelliteField/SatelliteFieldStyle';
import { wmsKey } from '../util/Util';
import { AppContext } from '../../appRouter';

const purpleOptions = { color: 'rgba(255,255,255,0.7)' }


const GetBoundMap = (props) => {
    const map = useMap();

    setTimeout(() => {

        try {
            map.fitBounds(props?.basePolygon?.current?._bounds);
            props?.setIsMapLoading(false);
        } catch (e) {
            console.log('could not bound map')
        }
    }, 5000);

    useMapEvents({
        mousemove: (e) => {
            let lat = e?.latlng?.lat?.toFixed(3);
            let lng = e?.latlng?.lat?.toFixed(3);
            let indicator = `Lat : ${lat} , Lng: ${lng}`;
            props?.setTooltipData({ position: [], text: indicator })
        }
    })
    return null;
}


const SatelliteView = (props) => {

    const { selectedFieldId } = useContext(AppContext);

    let basePolygon = React.useRef();

    let geometry = "";

    if (props?.selectedCoords.length > 0) {
        geometry = `POLYGON ((${props?.selectedRealCoords.map(coord => coord.join(' ')).join(', ')}))`;
    }

    const [isMapLoading, setIsMapLoading] = useState(true);

    const [tooltipData, setTooltipData] = useState({ position: [], text: 'Click any point to read lat lng' });

    const history = useHistory();

    const handleGoBack = () => {
        history.push(`/dashboard/farmsummary/info/${selectedFieldId}`);
    }

    return (
        <>
            <DashboardContainer>
                <SatelliteContainer>
                    <LoadingOverlay
                        active={isMapLoading}
                        spinner
                        text='Please wait while we are loading the satelite image'
                    >
                        <MapContainer attributionControl={false} scrollWheelZoom={false} center={[props?.selectedCentroid[1], props?.selectedCentroid[0]]} zoom={10} zoomControl={true} style={{ height: props?.height, backgroundRepeat: 'no-repeat', zIndex: '0' }}>

                            <BasemapLayer name="Streets" />

                            <Polygon
                                ref={basePolygon}
                                pathOptions={purpleOptions} positions={props?.selectedCoords}
                            >
                                <Tooltip direction="top" className="tooltip" sticky position={tooltipData?.position}>{tooltipData?.text}</Tooltip>
                            </Polygon>

                            {/* ---- Check if layer has been selected and cloudcover doesn't go beyond limit and as well the field has been selected too */}
                            {selectedFieldId &&

                                <WMSTileLayer
                                    layers={'TRUE-COLOR'}
                                    format="image/png"
                                    crs={L.CRS.EPSG4326}
                                    attribution='&copy; <a href="http://www.sentinel-hub.com/" target="_blank">Sentinel Hub</a>'
                                    url={`https://services.sentinel-hub.com/ogc/wms/${wmsKey}?REQUEST=GetMap`}
                                    geometry={geometry}
                                    minZoom="0"
                                    maxZoom="200"
                                    zoom={6}
                                    preset={'TRUE-COLOR'}
                                    width='250'
                                    height='250'
                                    transparent="true"
                                />

                            }

                            <GetBoundMap
                                basePolygon={basePolygon}
                                setIsMapLoading={setIsMapLoading}
                                setTooltipData={setTooltipData}
                            />

                            <PreviousBtn onClick={handleGoBack}>
                                <ArrowBackIcon /> Field Info
                            </PreviousBtn>

                            <NextBox onClick={() => history.push('/dashboard/split-view/')}>
                                <DirectionsIcon /> Split View
                            </NextBox>
                        </MapContainer>
                    </LoadingOverlay>
                </SatelliteContainer>
            </DashboardContainer>
        </>
    )
};

export default SatelliteView;