// file containing helper functions that implements the various algorithms
import { max, range } from "lodash";
import moment from "moment";

// var kbpgp = require('kbpgp');

const { convertArrayToCSV } = require("convert-array-to-csv");

export const wmsKey = "a8c99961-0ee4-407e-b5d4-8541b8e7ed62";

export const convertDate = (timestamp) => moment(timestamp).format("DD-MMM-YY");

export const getOnlyYear = (timestamp) => moment(timestamp).format("YY");

export const getOnlyMonth = (timestamp) => moment(timestamp).format("MMM");

export const getOnlyDayAndMonth = (timestamp) => moment(timestamp).format("DD MMM");

export const getOnlyDay = (timestamp) => moment(timestamp).format("DD");


export const formatDate = (timestamp) => moment(timestamp).format("DD MMMM, Y");

export const arrayToCsv = (array) => convertArrayToCSV(array);

// used this in the DownloadButton.js ₦

export const pureArrayForCsv = (impureArray) =>
  impureArray.map((item) => ({
    date: item?.date,
    min: item.basicStats?.min,
    max: item.basicStats?.max,
    mean: item.basicStats?.mean,
    stDev: item.basicStats?.stDev,
  }));

// module.exports = Util;
export const backDate = (to) =>
  moment().subtract(to, "months").format("YYYY-MM-DD");

export const getNoOfDaysDiff = (from, to) => {
  var fromDate = moment(from, "YYYY-MM-DD");
  var toDate = moment(to, "YYYY-MM-DD");
  return moment.duration(fromDate.diff(toDate)).asDays();
}

export const removeFromDate = (noOfDays, currentDate) =>
  moment(currentDate, "YYYY-MM-DD").add(-noOfDays, 'days');

export const yDateMinusXYears = (date, noOfYears) => {
  var theNewYear = Number.parseInt(date.substr(0, 5)) - noOfYears;
  return theNewYear + "-" + date.substr(5,);
}

export const getPastNYears = (startDate, endDate, noOfYears) => {
  var nYearDateRanges = [[startDate, endDate]];
  // --- If it exceeds five years, change it to the default as five years ---
  if (noOfYears > 5) {
    noOfYears = 5;
  }
  // --- If the no of years passed is one oe below. Change it to one ---
  if (noOfYears <= 1) {
    return nYearDateRanges;
  }

  var prevStartDate = startDate;
  var prevEndDate = endDate;

  for (let i = 1; i < noOfYears; i++) {
    prevStartDate = yDateMinusXYears(prevStartDate, 1);
    prevEndDate = yDateMinusXYears(prevEndDate, 1);
    nYearDateRanges.push([prevStartDate, prevEndDate]);
  }

  return nYearDateRanges;
}

export const seasonDates = range(2021, 2040);

// ---- Control of the base cloud cover ---
export const cloudLimit = 80;

// --- Implement an algorithm to filter out duplicate dates and pick the dates with the higher cloud cover percentage
// --- This approach is in O(LogN) time complexity ---
export const mapDatesHandler = (dates) => {

  let dateList = [...dates];
  let lastVisited = 0;
  let finalArr = [];
  let tempObj = {};
  let key = "";
  let maxKey = 0;
  dateList.map((eachItem, pos) => {
    if (lastVisited === eachItem?.properties?.date) {
      key = [eachItem.properties.cloudCoverPercentage];
      tempObj[key] = eachItem;
    } else {
      maxKey = max(Object.keys(tempObj));
      if (maxKey) {
        finalArr.push(tempObj[maxKey]);
      }

      tempObj = {};
      key = [eachItem.properties.cloudCoverPercentage];
      tempObj[key] = dateList[pos];
      lastVisited = eachItem?.properties?.date;
    }

    return 0;
  });
  return finalArr;
};
// export const generateKeyPair = () => {
//     kbpgp.KeyManager.generate_rsa({ userid: "Bo Jackson <user@example.com>" }, function (err, charlie) {
//         charlie.sign({}, function (err) {
//             console.log("done!", charlie);
//         });
//     });
// }

// generateKeyPair();

export const renderColorfulLegendText = (val, col) => {
  return <span style={{ color: col }}>{val}</span>;
};


export const getPath = (x, y, width, height) =>
  `M${x},${y + height}
 C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2
  }, ${y}
 C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width
  }, ${y + height}
 Z`;

export const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};
