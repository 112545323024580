import styled, { keyframes } from 'styled-components';

export const Header = styled.header`
  width: 100%;
  min-height: 6em;
  height: fit-content;
  position: fixed;
  top: 0;
  z-index: 1;
  padding: 0.1em 0em;
  background:  linear-gradient(88.57deg, #011811 20.69%, #253631 74.52%);
  border-radius: 0px 0px 16px 16px;
  color: #fff;
  padding: 0 3%;
`;

export const HeaderInner = styled.div`margin-top: 2em;`;

export const HeaderLogoText = styled.div`
padding-left: 2%;
font-weight: 900;
font-size: 24px
@media screen and (max-width: 920px) {
  display: none;
}`;


export const HeaderItem = styled.span`
  color: white;
  font-size: 20px;
  margin-left: 1.5em;
  margin-right: 1.5em;
  font-weight: 700;
  line-height: 26px;
  @media screen and (max-width: 920px) {
    margin-left: 1em;
    margin-right: 1em;
  }
`;

export const HeaderLogo = styled.img`
  color: white;
  // font-size: 1em;
  margin-left: 1em;
  width:12%;
  margin-top:-0.5em;
`;
export const HeaderItemEndBlock = styled.section`
  @media screen and (max-width: 1240px) {
    display: none;
  }
`;
export const HeaderItemMiddleBlock = styled.section`
  display: flex;
  // justify-content: space-between;
  @media screen and (max-width: 820px) {
    display: none;
  }
`;

export const HeaderItemBlock = styled.section`
  display: flex;
  // justify-content: space-between;
`;

export const HeaderButton = styled.button`
  background: #fff;
  border-radius: 4px;
  height: 3em;
  width: 8em;
  margin-top: -0.5em;
  line-height:26px;
  border: none;
  color: #0A4C3E;
  // float:right;
  margin-right: 3em;
  transition: 0.5s color, 0.5s background;
  font-weight: 700;
  font-size: 16px;
  &:hover {
    background: white;
    color: #18a379;
  }
`;

export const HeaderMenuIcon = styled.div`
  margin-right: 3em;
  color: white;
  display: none;
  cursor: pointer;
  @media screen and (max-width: 820px) {
    display: block;
  }
`;

// --- Nav Bar ---

export const dropNavBar = keyframes`
from{
  height:1em;
  opacity:0;
}
to{
  height:17em;
  opacity:1
}`;

export const removeNavBar = keyframes`
from{
  height:17em;
  opacity:1;
}
to{
  height:1em;
  opacity:0;
overflow:hidden;
}`;

export const HeaderRemoveNavBar = styled.nav`
  width: 100%;
  height: 14em;
  top: 6em;
  display: none;
  position: fixed;
  background: rgba(255, 255, 255, 1);
  z-index: 1;
  padding: 0.1em 0em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  animation: 0.5s ${removeNavBar};
  @media screen and (max-width: 769px) {
    display: block;
  }
`;

// const animation = props => css`
//     animation ${props.animationLength} infinite alternate;
//   `;

export const HeaderNavBar = styled.nav`
  width: 100%;
  // height: ${(props) => (props.state ? '14em' : '-0.5em')};
  opacity: ${(props) => (props.state ? '1' : '0')};
  top: 6em;
  display: none;
  position: fixed;
  background: rgba(255, 255, 255, 1);
  z-index: 1;
  padding: 0.1em 0em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  animation: 0.5s ${(props) => (props.state ? dropNavBar : removeNavBar)};
  @media screen and (max-width: 769px) {
    display: block;
  }
`;

export const HeaderMobileItems = styled.div`
  // display:block;
  text-align: center;
  margin-top: 1em;
  line-height: 3em;
  animation: 0.5s ${dropNavBar};
  display: ${(props) => (props.state ? 'block' : 'none')};
`;

export const MobileItem = styled.span`
  color: rgba(5, 103, 74, 1);
  font-size: 1em;
  margin-left: 1.5em;
  margin-right: 1.5em;
  font-weight: bold;
  @media screen and (max-width: 920px) {
    margin-left: 1em;
    margin-right: 1em;
  }
`;
