import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { Link, animateScroll as scroll } from "react-scroll";

import { Grid } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import {
  Header,
  HeaderLogo,
  HeaderButton,
  HeaderInner,
  HeaderItem,
  HeaderItemBlock,
  HeaderItemMiddleBlock,
  HeaderMenuIcon,
  HeaderNavBar,
  HeaderMobileItems,
  MobileItem,
  HeaderLogoText,
  HeaderItemEndBlock,
} from "../../styles/home/HeaderStyles";

import logo from "../../assets/img/home/main/logo.png";

import { useSelector } from "react-redux";
import SimpleModal from "./Modal";

const HomeHeader = () => {
  const [dropDown, setDropDown] = useState(false);
  const history = useHistory();

  const triggerMenu = () => {
    // if(dropDown){
    // }
    setDropDown(!dropDown);
  };

  const scrollTop = () => {
    scroll.scrollToTop();
  };

  const { access_token: access_key } = useSelector(
    (state) => state.authReducer
  );

  return (
    <>
      <Header>
        <HeaderInner>
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} xs={2} sm={2}>
              <HeaderItemBlock>
                <HeaderLogo src={logo} />
                <HeaderLogoText>AGROPREVER</HeaderLogoText>
              </HeaderItemBlock>
            </Grid>
            <Grid item lg={6} md={6} xs={7} sm={7}>
              <HeaderItemMiddleBlock>
                <HeaderItem>
                  <Link
                    onClick={scrollTop}
                    activeClass="active"
                    to="home"
                    spy={true}
                    duration={500}
                    smooth={true}
                  >
                    Home
                  </Link>
                </HeaderItem>
                <HeaderItem>
                  <Link
                    activeClass="active"
                    to="products"
                    spy={true}
                    duration={500}
                    smooth={true}
                  >
                    Our Products
                  </Link>
                </HeaderItem>
                <HeaderItem>
                  <Link
                    activeClass="active"
                    to="technology"
                    spy={true}
                    duration={500}
                    smooth={true}
                  >
                    Our Technologies
                  </Link>
                </HeaderItem>
                <HeaderItem>
                  <Link
                    activeClass="active"
                    to="contactus"
                    spy={true}
                    duration={500}
                    smooth={true}
                  >
                    Contact Us
                  </Link>
                </HeaderItem>
                {/* <HeaderItem>Blog</HeaderItem> */}
              </HeaderItemMiddleBlock>
            </Grid>
            <Grid item lg={3} md={3} xs={3} sm={3}>
              {access_key ? (
                <HeaderItemBlock style={{ float: "right", textAlign: "right" }}>
                  <Link
                    onClick={() => history.push("/dashboard/")}
                    to="/dashboard/"
                  >
                    <HeaderButton>Dashboard</HeaderButton>
                  </Link>
                  <HeaderMenuIcon onClick={triggerMenu}>
                    <MenuIcon />
                  </HeaderMenuIcon>
                </HeaderItemBlock>
              ) : (
                <div style={{ float: "right", textAlign: "right", display: 'flex', justifyContent:"space-between" }}>
                <HeaderItemBlock>
                  <Link
                    onClick={() => history.push("/auth/login/")}
                    to="/auth/login/"
                  >
                    <span style={{margin: "-0.5rem 3rem", fontWeight: 700, fontSize: '16px'}}>Login</span>
                  </Link>
                  <HeaderMenuIcon onClick={triggerMenu}>
                    <MenuIcon />
                  </HeaderMenuIcon>
                </HeaderItemBlock>
                <HeaderItemEndBlock>
                   <SimpleModal header={true} />
                  {/* <HeaderMenuIcon onClick={triggerMenu}>
                    <MenuIcon />
                  </HeaderMenuIcon> */}
                </HeaderItemEndBlock>
                </div>
              )}
            </Grid>
          </Grid>
        </HeaderInner>
      </Header>

      {/* {dropDown && */}
      <HeaderNavBar state={dropDown}>
        <HeaderMobileItems state={dropDown}>
          <MobileItem>
            <Link
              onClick={scrollTop}
              activeClass="activeMobile"
              to="home"
              spy={true}
              duration={500}
              smooth={true}
            >
              Home
            </Link>
          </MobileItem>
          <br />
          <MobileItem>
            <Link
              activeClass="activeMobile"
              to="aboutus"
              spy={true}
              duration={500}
              smooth={true}
            >
              About Us
            </Link>
          </MobileItem>
          <br />
          <MobileItem>
            <Link
              activeClass="activeMobile"
              to="products"
              spy={true}
              duration={500}
              smooth={true}
            >
              Products
            </Link>
          </MobileItem>
          <br />
          <MobileItem>
            <Link
              activeClass="activeMobile"
              to="contactus"
              spy={true}
              duration={500}
              smooth={true}
            >
              Contact Us
            </Link>
          </MobileItem>
          {/* <MobileItem>
            <br />
          <SimpleModal header={true} />
          </MobileItem> */}
         <br />
         <MobileItem>
            <Link
              activeClass="activeMobile"
              to="/auth/login"
              spy={true}
              duration={500}
              smooth={true}
            >
              Sign In
            </Link>
          </MobileItem>
        </HeaderMobileItems>
      </HeaderNavBar>
      {/* } */}
    </>
  );
};

export default HomeHeader;
