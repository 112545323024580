import React, { useState } from "react";
import { Modal, Button, Input } from "antd";
import { useForm } from "react-hook-form";
import { CreateCustomModal } from "../UserManagement.styled";
import { toast } from "react-toastify";

const CreateCustomRoleModal = (props) => {
  const { teamOwner, ownTeam_id, createRole, getTeamRoles } = props;
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    reset();
    props.handleOk();
  };


  const fetchTeamRoles = async () => {
    setLoading(true);
    const res = await getTeamRoles(ownTeam_id);
    if (res.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const CreateRole = async (data) => {
    setLoading(true);
    const roleData = {
      ...data,
      team: teamOwner || null,
      privileges: [],
    };
    console.log(roleData);
    const res = await createRole(roleData);
    if (res.success) {
      toast("Role created successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleClose();
      props.handleOk();
      setLoading(false);
      fetchTeamRoles();
      reset();
    } else {
      setLoading(false);
      toast.error("An unexpected error occurred. Kindly try again, please.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <Modal
        title="Create Custom Role"
        visible={props.isModalVisible}
        onOk={CreateRole}
        onCancel={props.handleCancel}
        footer={null}
        closable={false}

        // --- I added this so that the modal can overlay properly on mobile views
        zIndex={"10000"}
      >
        <CreateCustomModal>
          <div className="container">
            <form>
              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="name">Role Name</label>
                </div>
                <div className="col-md-9">
                  <Input
                    {...register("name", { required: true })}
                    className="form-control"
                    name="name"
                  />
                  {errors.name && (
                    <span className="error">Role Name is Required</span>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  <label htmlFor="description">Role Description</label>
                </div>
                <div className="col-md-9">
                  <textarea
                    {...register("description", { required: true })}
                    className="form-control"
                  />
                  {errors.description && (
                    <span className="error">Role Name is Required</span>
                  )}
                </div>
              </div>
              {/* <div className="row mt-4">
                <div className="col-md-9">
                  <h4 className="pb-1">Field Privileges</h4>
                  <label htmlFor="addField" className="check">
                    <input
                      value={"fields.add_field"}
                      {...register("addField")}
                      type="checkbox"
                      id="addField"
                    />
                    <span>
                      <strong>Can</strong> Add Field
                    </span>
                  </label>
                  <label htmlFor="changeField" className="check">
                    <input
                      value={"fields.change_field"}
                      {...register("changeField")}
                      type="checkbox"
                      id="changeField"
                    />
                    <span>
                      <strong>Can</strong> Change Field
                    </span>
                  </label>
                  <label htmlFor="fields.delete_field" className="check">
                    <input
                      value={"fields.delete_field"}
                      {...register("deleteField")}
                      type="checkbox"
                      id="deleteField"
                    />
                    <span>
                      <strong>Can</strong> Delete Field
                    </span>
                  </label>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-9">
                  <h4 className="pb-1">Assignee Privileges</h4>
                  <label htmlFor="addTeamMember" className="check">
                    <input
                      value={"fields.add_teammember"}
                      {...register("addTeammember")}
                      type="checkbox"
                      id="addTeamMember"
                    />
                    <span>
                      <strong>Can</strong> Add Team Member
                    </span>
                  </label>
                  <label htmlFor="deleteTeammember" className="check">
                    <input
                      value={"fields.delete_teammember"}
                      {...register("deleteTeammember")}
                      type="checkbox"
                      id="deleteTeammember"
                    />
                    <span>
                      <strong>Can</strong> Remove Team Member
                    </span>
                  </label>
                </div>
              </div> */}
              <div className="row justify-content-between mt-5">
                <div className="col-md-4" style={{ marginBottom: '1em' }}>
                  <Button type="default" block onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
                <div className="col-md-6">
                  <Button
                    type="primary"
                    block
                    disabled={loading}
                    onClick={handleSubmit(CreateRole)}
                  >
                    {loading ? "Creating..." : "Create Role"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </CreateCustomModal>
      </Modal>
    </div>
  );
};

export default CreateCustomRoleModal;
