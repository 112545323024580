import React, { useEffect, useState } from 'react';
import { ServiceSection } from '../../AdminStyle.styled';
import { Row, Col, Switch, Button, Divider } from 'antd';
import { connect } from 'react-redux';
import {
    ConfigureSystemSettings, DeleteSystemSettings,
    GetSystemConfiguredRecords, UpdateSystemSettings
} from '../../../../../redux/actions/adminActions';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import SystemConfiguredRecord from "./SystemConfiguredRecord";
import CloseIcon from "@material-ui/icons/Close";

const SystemConfiguration = props => {
    const {
        ConfigureSystemSettings,
        DeleteSystemSettings,
        GetSystemConfiguredRecords,
        UpdateSystemSettings,
        system_config_record = []
    } = props;
    const [loading, setloading] = useState(false);
    const [recordloading, setrecordloading] = useState(false);
    const [showform, setshowform] = useState(false);
    const [formMode, setFormMode] = useState('CREATE_MODE');
    const [sysObj, setSysObj] = useState({
        notification: false,
        subscription: false,
    });
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm();

    const _changeSwitch = () => setshowform(!showform);

    const editRecord = (e, record) => {
        e.preventDefault();
        setFormMode('UPDATE_MODE');
        setSysObj(record);
        setshowform(true);
    }

    const cancelEdit = () => {
        setFormMode('CREATE_MODE');
    }

    const performSystemConfiguration = async data => {
        setloading(true);
        if (!Number(data.max_users_per_team)) {
            toast.error('Max User... contain invalid values', {
                pauseOnHover: true,
            });
            setloading(false);
            return;
        }
        if (formMode === 'CREATE_MODE') {
            const res = await ConfigureSystemSettings(data);
            if (res.success) {
                setloading(false);
                toast.success('Service Configured', {
                    pauseOnHover: false,
                });
                setTimeout(() => {
                    reset();
                }, 800);
            } else if (!res.success && res.message === undefined) {
                setloading(false);
                toast.error('Network Error', {
                    pauseOnHover: true,
                });
            } else {
                setloading(false);
                toast.error(res.message, {
                    pauseOnHover: true,
                });
            }
        } else if (formMode === 'UPDATE_MODE') {
            const updateData = {
                id: sysObj.id,
                ...data,
            };
            console.log('dupdate', updateData);
            const res = await UpdateSystemSettings(updateData);
            if (res.success) {
                setloading(false);
                toast.success('Service Updated Successfully', {
                    pauseOnHover: false,
                });
                requestAnimationFrame(() => {
                    reset();
                    setFormMode('CREATE_MODE');
                    setshowform(false);
                    setSysObj({
                        notification: false,
                        subscription: false,
                        max_users_per_team: '',
                        min_no_of_suscription_units: '',
                        max_no_of_suscription_units: '',
                        subscription_units_available: '',
                        suscription_units_used: '',
                    })
                })
            } else if (!res.success && res.message === undefined) {
                setloading(false);
                toast.error('Network Error', {
                    pauseOnHover: true,
                });
            } else {
                setloading(false);
                toast.error(res.message, {
                    pauseOnHover: true,
                });
            }
        }
    };

    useEffect(() => {
        const getConfiguredRecord = async () => {
            setrecordloading(true);
            const res = await GetSystemConfiguredRecords();
            if (!res.success) {
                setrecordloading(false);
                toast.error(`Error`)
            }
            setTimeout(() => {
                setrecordloading(false);
            }, 300);
        };
        getConfiguredRecord();
    }, [GetSystemConfiguredRecords]);

    return (
        <ServiceSection>
            <Row>
                <Col xs={24}>
                    <div style={{ textAlign: 'right' }}>
                        <label>Show Form?</label>{' '}
                        <Switch
                            checkedChildren="Open"
                            unCheckedChildren="Close"
                            defaultChecked={false}
                            name="subscription"
                            onChange={_changeSwitch}
                        />
                    </div>
                </Col>
            </Row>
            {
                showform ? (
                    <>
                        <form onSubmit={handleSubmit(performSystemConfiguration)} autoComplete="off">
                            <div className="content">
                                <div className="form-group">
                                    <Row gutter={28}>
                                        <Col xs={12} xl={8} lg={8}>
                                            <label>Subscription Enabled</label>
                                        </Col>
                                        <Col xs={12} xl={8} lg={8}>
                                            <Controller
                                                control={control}
                                                name="subscription"
                                                render={({ field: { onChange, value } }) => (
                                                    <Switch
                                                        checkedChildren="Active"
                                                        unCheckedChildren="Inactive"
                                                        defaultChecked={sysObj.subscription}
                                                        name="subscription"
                                                        onChange={onChange}
                                                    // value={value}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <Row gutter={28}>
                                        <Col xs={12} xl={8} lg={8}>
                                            <label>Notification Enabled</label>
                                        </Col>
                                        <Col xs={12} xl={8} lg={8}>
                                            <Controller
                                                control={control}
                                                name="notification"
                                                render={({ field: { onChange, value } }) => (
                                                    <Switch
                                                        checkedChildren="Active"
                                                        unCheckedChildren="Inactive"
                                                        defaultChecked={sysObj.notification}
                                                        name="notification"
                                                        onChange={onChange}
                                                    // value={value}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <Row gutter={28}>
                                        <Col xs={24} xl={8} lg={8}>
                                            <label>Max Users Per Team</label>
                                        </Col>
                                        <Col xs={24} xl={8} lg={8}>
                                            <input type='number' defaultValue={sysObj?.max_users_per_team} {...register('max_users_per_team', { required: true })} className="ant-input-lg ant-input" name="max_users_per_team" />
                                            {errors.max_users_per_team && <small className="error">Required</small>}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <Row gutter={28}>
                                        <Col xs={24} xl={8} lg={8}>
                                            <label htmlFor="">Minimum Subscription Unit</label>
                                        </Col>
                                        <Col xs={24} xl={8} lg={8}>
                                            <input
                                                type="number"
                                                defaultValue={sysObj.min_no_of_suscription_units}
                                                {...register('min_no_of_suscription_units', { required: true })}
                                                className="ant-input-lg ant-input"
                                                name="min_no_of_suscription_units"
                                            />
                                            {errors.min_no_of_suscription_units && <small className="error">Required</small>}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <Row gutter={28}>
                                        <Col xs={24} xl={8} lg={8}>
                                            <label htmlFor="">Maximum Subscription Unit</label>
                                        </Col>
                                        <Col xs={24} xl={8} lg={8}>
                                            <input
                                                type="number"
                                                defaultValue={sysObj.max_no_of_suscription_units}
                                                {...register('max_no_of_suscription_units', { required: true })}
                                                className="ant-input-lg ant-input"
                                                name="max_no_of_suscription_units"
                                            />
                                            {errors.max_no_of_suscription_units && <small className="error">Required</small>}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <Row gutter={28}>
                                        <Col xs={24} xl={8} lg={8}>
                                            <label htmlFor="">Sub Unit Used</label>
                                        </Col>
                                        <Col xs={24} xl={8} lg={8}>
                                            <input
                                                type="number"
                                                defaultValue={sysObj.suscription_units_used}
                                                {...register('suscription_units_used', { required: true })}
                                                className="ant-input-lg ant-input"
                                                name="suscription_units_used"
                                            />
                                            {errors.suscription_units_used && <small className="error">Required</small>}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <Row gutter={28}>
                                        <Col xs={24} xl={8} lg={8}>
                                            <label htmlFor="">Sub Unit Available</label>
                                        </Col>
                                        <Col xs={24} xl={8} lg={8}>
                                            <input
                                                type="number"
                                                defaultValue={sysObj.subscription_units_available}
                                                {...register('subscription_units_available', { required: true })}
                                                className="ant-input-lg ant-input"
                                                name="subscription_units_available"
                                            />
                                            {errors.subscription_units_available && <small className="error">Required</small>}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <Divider />
                            <Row style={{ marginTop: '2rem' }}>
                                <Col xs={24} style={{ textAlign: 'right' }}>
                                    <Button
                                        loading={loading}
                                        type="primary"
                                        onClick={handleSubmit(performSystemConfiguration)}
                                        style={{ marginRight: '10px' }}
                                    >
                                        {formMode === 'CREATE_MODE' ? 'Save' : 'Update'} Configuration
                                    </Button>
                                    {formMode === 'UPDATE_MODE' ? <Button onClick={cancelEdit}><CloseIcon /></Button> : ''}
                                </Col>
                            </Row>
                        </form>
                    </>
                ) : ('')
            }

            <Divider />
            <SystemConfiguredRecord
                loading={recordloading}
                editRecord={editRecord}
                system_config_record={system_config_record}
                DeleteSystemSettings={DeleteSystemSettings}
            />
        </ServiceSection>
    );
};

const mapStateToProps = state => {
    return {
        system_config_record: state.adminReducer.system_config_record,
    };
};

export default connect(mapStateToProps, {
    ConfigureSystemSettings,
    GetSystemConfiguredRecords,
    UpdateSystemSettings,
    DeleteSystemSettings,
})(SystemConfiguration);
