import React, { useContext, useState } from "react";
import clsx from "clsx";
import { IconButton, Divider, Drawer, Tooltip } from "@material-ui/core";
import {
  ChevronLeft,
  MenuBook,
  ChevronRight,
  Satellite,
  // TrendingUpOutlined,
  CropLandscape,
  Cloud,
} from "@material-ui/icons";
import {
  EachLayoutCard,
  EachLayoutInnerCard,
  EachLayoutSubtitle,
  EachLayoutDSubtitle,
  EachLayoutTitle,
  LayoutIcons,
  LayoutSideBar,
} from "../../styles/util/UtilStyle";
import { Link } from "react-router-dom";
import { AppContext } from "../../appRouter";

const Sidebar = (props) => {
  const [layoutDropDown, setLayoutDropDown] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
  });


  const { selectedCentroid, selectedFieldId, indexing, weather } = useContext(AppContext);
console.log(weather)
  const layouts = [
    {
      name: "Farm Management",
      pos: "first",
      icon: <MenuBook />,
      innerLayouts: [
        {
          id: "1",
          name: "Dashboard",
          route: "/dashboard/",
        },
        {
          id: "2",
          name: "Farming Activities",
          route: "/dashboard/farmactivity/all-activities",
        },
        {
          id: "3",
          name: "Farm Summary",
          route: "/dashboard/farmsummary/",
        },
        {
          id: "4",
          name: "My Farm Task",
          route: "/dashboard/farmactivity/my-tasks",
        },
      ],
    },
    {
      name: "Crop Plan Development",
      pos: "second",
      icon: <CropLandscape />,
      innerLayouts: [
        {
          id: "7", name: "Crop Plan configuration",
          route: "/dashboard/crop-plan/config/farm/" + selectedFieldId
          
        },
        // { id: "9", name: "Crop Monitoring" },
      ],
    },
    {
      name: "Satellite Field Monitoring",
      pos: "fourth",
      icon: <Satellite />,
      innerLayouts: [
        {
          id: "14",
          name: "Render satellite view of Farm",
          route: "/dashboard/satellite-view/"
        },
        {
          id: "15",
          name: "Image Comparison and Processing",
          route: "/dashboard/split-view/",
          disabled: !indexing
        },
      ],
    },
    {
      name: "Weather Forecast & Update",
      pos: "fifth",
      icon: <Cloud />,
      innerLayouts: [
        {
          id: "16",
          name: "Weather and chart analysis",
          route: "/dashboard/weather/history/" + selectedCentroid,
          disabled: !weather
        },
        {
          id: "17",
          name: "Weather Forecast",
          route: "/dashboard/weather/forecast/" + selectedCentroid,
          disabled: !weather
        },
      ],
    },
  ];

  const handleNavClick = (item) => {
    if (props.open) {
      setLayoutDropDown({ [item]: true });
    }
  }

  const handleDrawClose = () => {
    props.handleDrawerClose();
    setLayoutDropDown({
      first: false,
      second: false,
      third: false,
      fourth: false,
      fifth: false,
    })
  }

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(props.classes.drawer, {
          [props.classes.drawerOpen]: props.open,
          [props.classes.drawerClose]: !props.open,
        })}
        classes={{
          paper: clsx({
            [props.classes.drawerOpen]: props.open,
            [props.classes.drawerClose]: !props.open,
          }),
        }}
      >
        <div className={props.classes.toolbar}>
          <IconButton onClick={handleDrawClose}>
            {props.theme.direction === "rtl" ? (
              <ChevronRight />
            ) : (
                <ChevronLeft />
              )}
          </IconButton>
        </div>
        <Divider />
        <LayoutSideBar>
          {layouts.map((eachLayout) => (
            <EachLayoutCard key={eachLayout?.pos}>
              <EachLayoutTitle
                onClick={() => handleNavClick(eachLayout?.pos)}
              >
                <LayoutIcons>{eachLayout?.icon} </LayoutIcons> {eachLayout.name}
              </EachLayoutTitle>
              {layoutDropDown?.[eachLayout?.pos] && (
                <>
                  <EachLayoutInnerCard>
                    {eachLayout?.innerLayouts?.map((eachInnerLayout) => (
                      // eachInnerLayout?.disabled?
                      // <Link
                      //   key={eachInnerLayout.id}
                      //   to={eachInnerLayout?.route}
                      //   onClick={ (event) => event.preventDefault()}
                      // >
                      //   <Tooltip title="Upgrade Plan to Access" arrow>
                      //   <EachLayoutDSubtitle>
                      //     {eachInnerLayout?.name}
                      //   </EachLayoutDSubtitle>
                      //   </Tooltip>
                      // </Link>
                      // :
                      <Link
                      key={eachInnerLayout.id}
                      to={eachInnerLayout?.route}
                    >
                      <EachLayoutSubtitle>
                        {eachInnerLayout?.name}
                      </EachLayoutSubtitle>
                    </Link>
                      
                    ))}
                  </EachLayoutInnerCard>
                  <Divider style={{ background: "rgba(255,255,255,0.7)" }} />
                </>
              )}
            </EachLayoutCard>
          ))}
        </LayoutSideBar>
      </Drawer>
    </>
  );
};

export default Sidebar;
