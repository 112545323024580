import React, { useState, useEffect } from "react";
import AuthLayout from "./AuthLayout";
import { Row, Col, Input, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  ResetNewPassword,
  ValidateEmailToken,
} from "../../redux/actions/authentication";
import { toast } from "react-toastify";

const ResetPassword = (props) => {
  const { ResetNewPassword, ValidateEmailToken } = props;
  const [resetload, setResetload] = useState(false);
  const [checktoken, setCheckToken] = useState(false);
  const [checktoken2, setCheckToken2] = useState(false);
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { token } = useParams();

  const resetPassword = async (data) => {
    const obj = {
      password: data.password,
      token,
    };
    if (data.password !== data.new_password2) {
      setResetload(false);
      toast.error("Password do not match", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setResetload(true);
    const res = await ResetNewPassword(obj);
    if (res.success) {
      toast.success("Password Reset Successfully, Please login to continue", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        history.push("/auth/login");
      }, 2000);
      setResetload(false);
    } else {
      setResetload(false);
      const { password } = res.message;
      toast.error(`${password}` || "Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    const checkTokenEmail = async () => {
      const getToken = {
        token,
      };
      setCheckToken(true);
      setCheckToken2(true);
      const res = await ValidateEmailToken(getToken);
      if (res.success) {
        setCheckToken(false);
      } else {
        setCheckToken(true);
        setCheckToken2(false);
      }
    };
    checkTokenEmail();
  }, [ValidateEmailToken, token]);
  const styles = {
    marginTop: "4rem",
  };
  return (
    <>
      <AuthLayout
        style={styles}
        fadeName="fade-left"
        pageTitle="Reset Password"
      >
        {checktoken ? (
          <div className={`validate_token ${!checktoken2 ? 'error' : ''}`}>
            {checktoken2 ? (
              <>
                <LoadingOutlined />
                <h4>Please wait, while validate your reset token</h4>
              </>
            ) : (
              <>
                <CloseCircleOutlined />
                <h4>Seems this reset link has expired, token is invalid</h4>
              </>
            )}
          </div>
        ) : (
          <>
            <div className="auth-title" style={{ marginBottom: "20px" }}>
              <h2>Reset Password</h2>
              <p>Enter your new password</p>
            </div>
            <Row gutter={24}>
              <Col xs={24} xl={24} lg={24}>
                <div className="form-group">
                  <input
                    name="token"
                    defaultValue={token}
                    {...register("token")}
                    hidden
                  />
                  <Input.Password
                    placeholder="Enter New Password"
                    {...register("password", {
                      required: true,
                      minLength: {
                        value: 8,
                      },
                    })}
                  />
                  <small className="text-danger">
                    {errors.new_password1 && (
                      <span className="error">
                        Password must have at least 8 characters
                      </span>
                    )}
                  </small>
                </div>
              </Col>
              <Col xs={24} xl={24} lg={24}>
                <div className="form-group">
                  <Input.Password
                    placeholder="Confirm New Password"
                    {...register("new_password2", {
                      required: true,
                      minLength: {
                        value: 8,
                      },
                    })}
                  />
                  <small className="text-danger">
                    {errors.new_password2 && (
                      <span className="error">
                        Password must have at least 8 characters
                      </span>
                    )}
                  </small>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={24} lg={24}>
                <Button
                  loading={resetload}
                  onClick={handleSubmit(resetPassword)}
                  block
                  disabled={checktoken}
                  type="primary"
                >
                  {resetload ? "Processing..." : "RESET PASSWORD"}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </AuthLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  ResetNewPassword,
  ValidateEmailToken,
})(ResetPassword);
