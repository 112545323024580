import styled from 'styled-components';

export const CropPlanContainer = styled.main`
  width: 100%;
  height: fit-content;
  // background:red;
`;

export const CropPlanCardHeader = styled.section`
  height: 4em;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // background: rgba(255, 255, 255, 0.7);
  border-radius: 0.3em;
  margin-top: 0em;
  margin-bottom: 4em;
  display: flex;
  justify-content: space-between;
  // font-family: Avenir Next;
  // font-style: normal;
`;

export const CropPlanCard = styled.section`
  height: fit-content;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0.3em;
  margin-top: 0em;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  font-family: Avenir Next;
  font-style: normal;
`;

export const CropPlanCardInner = styled.div`
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  width: 100%;
`;

export const CropPlanCardTitle = styled.h3`
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 1.5em;
  // margin-left: 1em;
  margin-bottom: 2em;
  color: #05674a;
`;

export const CropPlanCardSearch = styled.input`
  width: 100%;
  height: 3.5em;
  margin-bottom: 2em;
  margin-top: 1em;
  width: 100%;
  height: 3em;
  border-radius: 0.3em;
  border: 1px solid rgba(5, 5, 5, 0.3);
  background: rgba(5, 5, 5, 0.005);
  outline: none;
  padding: 1.7em 0.5em;
  box-sizing: border-box;
  color: rgba(5, 5, 5, 1);
  &:hover {
    border: 1px solid rgba(5, 5, 5, 0.3);
  }
  &::-webkit-input-placeholder {
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    padding-left: 5px;
    padding-top: 2px;
    color: rgba(5, 5, 5, 0.5);
  }
`;

export const CropPlanTable = styled.div`
  // height:20em;
  width: 100%;
`;

export const CropPlanButtonBox = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const CropPlanAddBtn = styled.button`
  height: 3em;
  width: 100%;
  background: #05674a;
  border-radius: 4px;
  color: white;
  border: none;
  font-weight: bold;
  transition: 0.5s background;
  &:hover {
    background: white;
    border: 1px solid rgba(0, 106, 75, 1);
    color: rgba(0, 106, 75, 1);
  }
`;

export const CropPlanTableAdd = styled.button`
  height: 3em;
  width: 100%;
  border-radius: 4px;
  background: white;
  border: 1px solid rgba(0, 106, 75, 1);
  color: rgba(0, 106, 75, 1);
  font-weight: bold;
  transition: 0.5s background;
  &:hover {
    border: none;
    background: #05674a;
    color: white;
  }
`;

export const CropPlanDelBtn = styled.button`
  height: 3em;
  width: 100%;
  background: rgba(200, 0, 0, 0.7);
  border-radius: 4px;
  color: white;
  border: none;
  font-weight: bold;
  transition: 0.5s background;
  &:hover {
    background: white;
    border: 1px solid rgba(200, 0, 0, 0.9);
    color: rgba(200, 0, 0, 0.7);
  }
`;

export const CropPlanTableDel = styled.button`
  height: 3em;
  width: 100%;
  border-radius: 4px;
  background: white;
  border: 1px solid rgba(200, 0, 0, 0.9);
  color: rgba(200, 0, 0, 0.7);
  font-weight: bold;
  transition: 0.5s background;
  &:hover {
    background: rgba(200, 0, 0, 0.7);
    border: none;
    color: white;
  }
`;

// -- Styles for the  Add New Crop Modal ---
export const CropPlanModal = styled.section`
  width: 40em;
  height: fit-content;
  background: white;
  border-radius: 0.3em;
  @media screen and (max-width:769px){
    width:90%;
  }
`;

export const CropPlanModalInner = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 1.5em;
  margin-right: 1.5em;
  margin-top: 1em;
  // width:100%;
`;

export const CropPlanModalTitle = styled.h3`
  font-size: 1em;
  font-weight: normal;
  margin-top: 0.5em;
  margin-bottom: 2em;
  color: rgba(1, 28, 20, 1);
`;

export const CropPlanInput = styled.input`
  width: 100%;
  height: 3.5em;
  margin-bottom: 0.5em;
  margin-top: 1em;
  width: 100%;
  height: 3em;
  border-radius: 0.3em;
  border: 1px solid rgba(0, 106, 75, 0.1);
  background: rgba(0, 106, 75, 0.05);
  outline: none;
  padding: 1.7em 0.5em;
  box-sizing: border-box;
  color: rgba(1, 28, 20, 1);
  &:hover {
    border: 1px solid rgba(5, 5, 5, 0.3);
  }
  &::-webkit-input-placeholder {
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    padding-left: 5px;
    padding-top: 2px;
    color: rgba(1, 28, 20, 0.5);
  }
`;

export const CropPlanSelect = styled.select`
  width: 100%;
  height: 3.5em;
  margin-bottom: 0.5em;
  margin-top: 1em;
  width: 100%;
  height: 3.5em;
  border-radius: 0.3em;
  border: 1px solid rgba(0, 106, 75, 0.1);
  background: rgba(0, 106, 75, 0.05);
  outline: none;
  // padding: 1.7em 0.5em;
  box-sizing: border-box;
  color: rgba(1, 28, 20, 1);
  &:hover {
    border: 1px solid rgba(5, 5, 5, 0.3);
  }
  &::-webkit-input-placeholder {
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    padding-left: 5px;
    padding-top: 2px;
    color: rgba(1, 28, 20, 0.5);
  }
`;

export const CropModalTextArea = styled.textarea`
  width: 100%;
  margin-bottom: 0.5em;
  margin-top: 1em;
  width: 100%;
  height: 5em;
  border-radius: 0.3em;
  border: 1px solid rgba(0, 106, 75, 0.1);
  background: rgba(0, 106, 75, 0.05);
  outline: none;
  padding: 1.7em 0.5em;
  box-sizing: border-box;
  color: rgba(1, 28, 20, 1);
  &:hover {
    border: 1px solid rgba(5, 5, 5, 0.3);
  }
  &::-webkit-input-placeholder {
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    padding-left: 5px;
    padding-top: 2px;
    color: rgba(1, 28, 20, 0.5);
  }
`;

export const CropPlanInputText = styled.label`
  font-size: 0.9em;
  // font-weight: bold;
  margin-bottom: 0em;
  color: rgba(1, 28, 20, 1);
`;

export const CropPlanInputError = styled.h6`
  color: rgba(200, 0, 0, 1);
  margin-top: 0em;
  margin-bottom: 2em;
  font-weight: normal;
  font-size: 0.9em;
`;


export const CropSwitchBox = styled.div`
width:100%;
height:5em;
// background:red;
// display:flex;
// justify-content:space-between;
border-bottom: 2px solid rgba(0, 106, 75, 1);
`;

export const CropSwitchBtn = styled.button`
height: 2.7em;
// width: 8em;
width:100%;
background: rgba(5, 5, 5, 0.7);
border-radius: 4px;
color: white;
border: none;
font-weight: bold;
transition: 0.5s background;
&:hover {
  background: red;
  border: 1px solid rgba(0, 106, 75, 1);
  color: rgba(0, 106, 75, 1);
}
`;

export const CropSwitchDisabledBtn = styled.button`
height: 2.5em;
width: 8em;
background: #05674a;
border-radius: 4px;
color: white;
border: none;
font-weight: bold;
transition: 0.5s background;
&:hover {
  background: white;
  border: 1px solid rgba(0, 106, 75, 1);
  color: rgba(0, 106, 75, 1);
}
`;

export const CropView = styled.div`
color:rgba(0, 106, 75, 1);
cursor:pointer;
font-size:0.9em;
text-decoration: 1px solid rgba(0, 106, 75, 0.7)
`;