import constants from '../constants';
import axios from "axios";
import { baseUrl } from "../../config";

export const getFieldMonitorData = () => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios
        .get(`${baseUrl}/monitoring/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        })
        .then((res) => {
            console.log('accc mo', res?.data);
            dispatch({ type: constants.GET_MONITOR_FIELD, payload: res?.data });
            return { success: true, message: "message" };
        })
        .catch((err) => {
            console.log('err', err?.response);
            return { success: false, message: "message" };
        });
}



export const assignFarmActivities = (data) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios
    .post(`${baseUrl}/activity/create/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      // dispatch({ type: constants.ALL_FARM_ACTIVITIES, payload: res.data });
      return { success: true, message: "message" };
    })
    .catch((err) => {
      console.log('farm error', err.response);
      return { success: false, message: "message" };
    });
};

export const updateFarmActivities = (data, id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
  let userId = getState().authReducer.user_details.id;
    return axios
    .put(`${baseUrl}/activity/${id}/update/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(getAllFarmActivities(userId));
      // dispatch({ type: constants.ALL_FARM_ACTIVITIES, payload: res.data });
      return { success: true, message: "message" };
    })
    .catch((err) => {
      return { success: false, message: err?.response?.data?.detail || 'Something went wrong' };
    });
};

export const getAllFarmActivities = (id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios
    .get(`${baseUrl}/activity/field/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.ALL_FARM_ACTIVITIES, payload: res.data });
      return { success: true, message: "message" };
    })
    .catch((err) => {
      console.log('err', err?.response);
      return { success: false, message: "message" };
    });
};

export const getOneFarmActivities = (id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios
    .get(`${baseUrl}/activity/${id}/retrieve/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.SINGLE_FARM_ACTIVITIES, payload: res.data });
      return { success: true, message: res?.data };
    })
    .catch((err) => {
      return { success: false, message: "message" };
    });
};

export const getUserFarmTask = () => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
  let userId = getState().authReducer.user_details.id;
    return axios
    .get(`${baseUrl}/activity/user/${userId}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.USER_FARM_TASK, payload: res.data });
      return { success: true, message: "message" };
    })
    .catch((err) => {
      return { success: false, message: "message" };
    });
};

export const closeFarmActivities = (data, id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    let userId = getState().authReducer.user_details.id;
    return axios
    .put(`${baseUrl}/activity/${id}/close/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(getAllFarmActivities(userId));
      return { success: true, message: "message" };
    })
    .catch((err) => {
      console.log('err', err.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const DeleteFarmActivities = (id) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  let userId = getState().authReducer.user_details.id;
  return axios
      .delete(`${baseUrl}/activity/${id}/delete/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        dispatch(getAllFarmActivities(userId));
        return { success: true, message: "message" };
      })
      .catch((err) => {
        console.log('err', err.response);
        return { success: false, message: err?.response?.data?.detail };
      });
};

export const ReAssignFarmTask = (data, id) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  let userId = getState().authReducer.user_details.id;
  return axios
      .patch(`${baseUrl}/activity/${id}/assign/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        dispatch(getAllFarmActivities(userId));
        return { success: true, message: "message" };
      })
      .catch((err) => {
        console.log('err', err.response);
        return { success: false, message: err?.response?.data?.detail };
      });
};
