
import './App.css';
import AppRoute from './appRouter';
import {ToastContainer} from "react-toastify";

function App() {
  return (
      <>
        <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
        />
        <AppRoute/>
      </>
  );
}

export default App;
