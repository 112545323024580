import React, { useState, useEffect } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSelector } from 'react-redux';

import FarmSummary from '../../components/farmManagement/FarmSummary';
import { getUserFarmSummaries } from '../../redux/actions/fieldManagement';
import { BrokenLinkPage } from '../errorHandling';

const FarmSummaryPage = () => {

    const { access_token: access_key } = useSelector(state => state.authReducer);

    const [stillFetching, setStillFetching] = useState(false);
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);

    const [gotError, setGotError] = useState(false);
    const [reRenderData, setRenderData] = useState(0);

    useEffect(() => {
        setStillFetching(true);
        async function fetchData() {
            let { isError, data } = await getUserFarmSummaries(access_key);

            if (isError) {
                setStillFetching(false);
                // setGotError(true);
                // toast.error('It looks like we could not provide the farm summary list at the moment.', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // });
            } else {
                
                let allFields = [];
                console.log(data);
                data.map((eachField, pos) => {
                    let geometry = `POLYGON ((${eachField?.coordinates.map(coord => coord.join(' ')).join(', ')}))`;
                    eachField.geometry = geometry;
                    allFields.push(eachField);
                    return 0;
                })

                setRows(allFields);
                setOriginalRows(allFields);
                setStillFetching(false);
                setGotError(false);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access_key, reRenderData])


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {gotError ? (
                <BrokenLinkPage customText="Looks like something went wrong!" customSubText="The Farm summary list could not be fetched at the moment." />
            ) :
                <>
                    <FarmSummary rows={rows}
                        setRenderData={setRenderData} stillFetching={stillFetching} setRows={setRows} originalRows={originalRows} getRowId={(rows) => rows.name} />
                </>
            }
        </>
    )
};

export default FarmSummaryPage;