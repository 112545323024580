import React from "react";
import { CloudRange, SatelliteRange } from "../../styles/satelliteField/SatelliteFieldStyle";

function CloudControl(props) {

    return (
        <>
        
            <SatelliteRange>
                <CloudRange
                    defaultValue={props?.cloudCover}
                    type='range'
                    min={0}
                    max={100}
                    // onChange={(e) => handleChangeCloud(e, handleCloudCover, props?.setIsMapLoading)}
                    onChange={(e) => props?.handleCloud(Number.parseInt(e.target.value))}
                // cloudCover={cloudCover}
                />
            </SatelliteRange>
        </>
    );
}


export default CloudControl;