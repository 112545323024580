import { useState, useEffect } from 'react';
import { Bar, BarChart, Brush, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { convertDate, renderColorfulLegendText } from "../../util/Util";

const HumidityChart = ({ chartData, type, startYear, endYear }) => {

    const [data, setData] = useState(chartData);

    const handleOnChange = (event) => {
        // const startIndex = event.startIndex;
        // const endIndex = event.endIndex;
        console.log(event);
        // const newData = data.slice(startIndex, endIndex + 1)
        // setData(newData)
    }

    useEffect(() => {
        setData(chartData);
    }, [chartData]);

    return (
        <>
            {type === "5 Years" ?
                <ResponsiveContainer width={"100%"} height="100%">
                    <BarChart
                        data={data}
                        margin={{ top: 25, right: 30, bottom: 35 }}
                    // layout="vertical"
                    >
                        <CartesianGrid strokeDasharray="0.5 0.5" />
                        <XAxis
                            angle={45}
                            textAnchor="start"
                            dataKey="dayMonth"
                            stroke="white"
                            type="category"
                            allowDuplicatedCategory={false}
                        />
                        <YAxis unit=" %"
                            type="number"
                            domain={["0", "dataMax + 100"]}
                            stroke="rgba(255,255,255,1)" />
                        <Tooltip cursor={{ fill: 'transparent' }}
                            wrapperStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                            itemStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                        />
                        <Legend verticalAlign="top" height={50} />

                        {data.map((eachSegment, key) => (
                            <Bar
                                fillOpacity={0.7}
                                type="monotone"
                                dataKey="Humidity"
                                data={eachSegment?.data}
                                key={key}
                                name={`Year ${eachSegment?.year}`}
                                stroke={eachSegment?.color}
                                fill={eachSegment?.color}
                            />
                        ))}

                        <Brush
                            dataKey="dayMonth"
                            onChange={handleOnChange}
                            height={20}
                            stroke="blue"
                            y={400}
                            // data={chartData[0].data.dayMonth}
                            gap={20}
                            style={{ marginTop: "20em" }}
                            travellerWidth={50}
                        />
                    </BarChart>
                </ResponsiveContainer>
                :
                <>
                    {type === "None" ?
                        <ResponsiveContainer width={"100%"} height="100%">
                            <BarChart
                                data={chartData}
                                margin={{ top: 15, right: 30, left: 20, bottom: 35 }}
                            >
                                <CartesianGrid strokeDasharray="1 1" />
                                <XAxis
                                    tickFormatter={convertDate}
                                    angle={45}
                                    textAnchor="start"
                                    dataKey="date"
                                    stroke="rgba(255,255,255,1)"
                                />
                                <YAxis
                                    unit=" %"
                                    type="number"
                                    domain={["0", "dataMax + 100"]}
                                    stroke="rgba(255,255,255,1)"
                                />
                                <Tooltip cursor={{ fill: 'transparent' }}
                                    wrapperStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                                    itemStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                                    contentStyle={{ background: "white" }}
                                />
                                {/* <Legend verticalAlign="top"  height={36} /> */}
                                <Legend
                                    verticalAlign="top"
                                    formatter={() =>
                                        renderColorfulLegendText(
                                            "Humidity - " + startYear + endYear,
                                            "white"
                                        )
                                    }
                                    height={50}
                                />

                                <Bar
                                    name="Humidity"
                                    fillOpacity={0.7}
                                    type="monotone"
                                    dataKey="Humidity"
                                    stroke="rgba(255,255,255,0.5)"
                                    fill="#82ca9d"
                                />

                                <Brush
                                    dataKey="date"
                                    height={20}
                                    stroke="blue"
                                    y={400}
                                    data={chartData}
                                    gap={20}
                                    style={{ marginTop: "20em" }}
                                    travellerWidth={50}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                        : <ResponsiveContainer width={"100%"} height="100%">
                            <BarChart
                                data={chartData}
                                margin={{ top: 15, right: 30, left: 20, bottom: 35 }}
                            >
                                <CartesianGrid strokeDasharray="1 1" />
                                <XAxis
                                    angle={45}
                                    textAnchor="start"
                                    dataKey="dayMonth"
                                    stroke="white"
                                />
                                <YAxis
                                    unit=" %"
                                    type="number"
                                    domain={["dataMin - 50", "dataMax + 50"]}
                                    stroke="rgba(255,255,255,1)"
                                />
                                <Tooltip cursor={{ fill: 'transparent' }}
                                    wrapperStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                                    itemStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                                    contentStyle={{ background: "white" }}
                                />
                                {/* <Legend verticalAlign="top"  height={36} /> */}
                                <Legend
                                    verticalAlign="top"
                                    formatter={() =>
                                        renderColorfulLegendText(
                                            "Average Humidity - " + startYear + endYear,
                                            "white"
                                        )
                                    }
                                    height={50}
                                />

                                <Bar
                                    name="Humidity"
                                    fillOpacity={0.7}
                                    type="monotone"
                                    dataKey="Humidity"
                                    stroke="rgba(255,255,255,0.5)"
                                    fill="#82ca9d"
                                />

                                <Brush
                                    dataKey="dayMonth"
                                    height={20}
                                    stroke="blue"
                                    y={400}
                                    data={chartData}
                                    gap={20}
                                    style={{ marginTop: "20em" }}
                                    travellerWidth={50}
                                />
                            </BarChart>
                        </ResponsiveContainer>}
                </>
            }
        </>
    )
};

export default HumidityChart;