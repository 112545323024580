import React, { useContext, useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ControlCameraIcon from "@material-ui/icons/ControlCamera";

import CloseIcon from "@material-ui/icons/Close";

import { DashboardContainer, ModalCancel } from "../../styles/util/UtilStyle";
import { connect } from "react-redux";

import GeoMap from "../geoMap/GeoMap";
import {
  BackgroundMap,
  MapControlCard,
  MapControlChart,
  MapControlSelectInner,
  MapControlChartRender,
  MapControlDate,
  MapControlEachInput,
  MapControlSelect,
  MapControlDateInner,
  MapControlDateCard,
  MapBody,
  DatesChartModal,
  ClickModalButton,
  ModalDates,
} from "../../styles/dashboard/DashboardHomeStyle";
import {
  Backdrop,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";

// import HorizontalScroll from 'react-scroll-horizontal'

import {
  Area,
  AreaChart,
  Brush,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DatePicker, Space } from "antd";
import moment from "moment";

import LoadingOverlay from "react-loading-overlay";
import { AppContext } from "../../appRouter";
import MapDates from "../geoMap/MapDates";
import { getFisMeanNYearsData } from "../../adapters/sentinelRequests";
import { GetUserSubscriptionPlan } from "../../redux/actions/subscriptionAction";

const {
  getNoOfDaysDiff,
  removeFromDate,
  getPastNYears,
  getOnlyDayAndMonth,
} = require("../util/Util");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    outline: "none",
  },
}));

const DashboardHome = (props) => {
  const classes = useStyles();

  const {
    selectedRealCoords,
    startDate,
    endDate,
    index,
    cloudCover,
    handleCloudCover,
    handleStartDate,
    handleEndDate,
    handleIndex,
  } = useContext(AppContext);

  const [dates, setDates] = useState([]);
  const [changeDates, setChangeDates] = useState(true);
  const [dateError, setDateError] = useState(false);
  const [services, setServices] = useState([]);
  // const [indexing, setIndexing] = useState(false);
  const [chartLoading, setChartLoading] = useState(true);
  const [chartLoadingText, setChartLoadingText] = useState(
    "Please wait while we load the chart..."
  );

  const [chartData, setChartData] = useState([]);
  const [chartFragment, setChartFragment] = useState({});

  // const onChange = (dates, dateStrings) => {
  //   if (dateStrings[0].length > 0 && dateStrings[1].length > 0) {
  //     setChangeDates(true);

  //     handleStartDate(dateStrings[0]);
  //     handleEndDate(dateStrings[1]);
  //   }
  // };

  const pickStartDate = (dates, dateStrings) => {
    // --- Check if the date range has elapsed 365 days or not ---
    if (getNoOfDaysDiff(endDate, dateStrings) > 365) {
      return setDateError(true);
    }

    setDateError(false);
    setChangeDates(true);
    return handleStartDate(dateStrings);
  };

  const pickEndDate = (dates, dateStrings) => {
    // --- Check if the date range has elapsed 365 days or not ---
    if (getNoOfDaysDiff(dateStrings, startDate) > 365) {
      return setDateError(true);
    }
    setDateError(false);
    setChangeDates(true);
    return handleEndDate(dateStrings);
  };

  function disabledStartDate(current) {
    const from = moment(removeFromDate(365, endDate));
    const to = moment(endDate);
    return !(from.isSameOrBefore(current) && to.isAfter(current));
  }

  function disabledEndDate(current) {
    const from = moment(startDate);
    const to = moment(new Date());
    return !(from.isSameOrBefore(current) && to.isAfter(current));
  }

  const getTeamId =
    props.user_details?.owned_team?.id || props.user_details?.user_team;
  // useEffect(() => {
  //   const getSubscriptionDetails = async () => {
  //     if (!getTeamId) return;
  //     let res = await props.GetUserSubscriptionPlan(
  //       props.user_details?.owned_team?.current_subscription
  //     );
  //     console.log(res.user_subscription);
  //     setServices(res.user_subscription);
  //     res?.user_subscription?.forEach((element) => {
  //       if (element.includes("Index")){
  //         setIndexing(true)
  //       }
  //     })
  //     // if (!res.success) {
  //     //   toast.error('Error fetching user subscription plans');
  //     // }
  //   };
  //   getSubscriptionDetails();
  // }, []);

  useEffect(() => {
    setChartLoading(true);

    // check if the coords is not empty
    if (
      selectedRealCoords?.length > 0 &&
      index?.length &&
      cloudCover !== null &&
      startDate?.length > 0 &&
      endDate?.length > 0
    ) {
      // query the FIS for the chart data
      setChartLoadingText("Please wait while we load the chart...");

      let geometry = `POLYGON ((${selectedRealCoords
        .map((coord) => coord.join(" "))
        .join(", ")}))`;

      getFisMeanNYearsData(
        getPastNYears(startDate, endDate, 5),
        index,
        cloudCover,
        geometry,
        setChartLoading,
        setChartData,
        setChartLoadingText,
        setChartFragment
      );
    } else {
      setChartLoading(true);
      setChartLoadingText("Please select an index and/or a valid date range.");
    }
  }, [index, startDate, endDate, cloudCover, selectedRealCoords]);

  const [indexList, setIndexList] = useState([
    { id: 1, name: "Choose Index" },
    { id: 2, name: "NDMI" },
    { id: 3, name: "NDWI" },
  ]);

  const allIndexList = [
    { id: 1, name: "Choose Index" },
    { id: 2, name: "NDMI" },
    { id: 3, name: "NDWI" },
    { id: 11, name: "NDVI" },
    { id: 22, name: "LAI" },
    { id: 33, name: "SAVI" },
  ];

  const handleIndexType = (e) => {
    if (e === "Vegetative") {
      setIndexList([
        { id: 1, name: "Choose Index" },
        { id: 11, name: "NDVI" },
        { id: 22, name: "LAI" },
        { id: 33, name: "SAVI" },
      ]);
    } else {
      setIndexList([
        { id: 1, name: "Choose Index" },
        { id: 14, name: "NDMI" },
        { id: 12, name: "NDWI" },
      ]);
    }
  };

  const [openModal, setModal] = useState(false);

  // --- Get the weather data here ---

  const handleOpenModal = () => {
    if (selectedRealCoords?.length === 0) {
      return toast.warning("Please ensure a field has been selected first.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setModal(true);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardContainer>
        <MapBody>
          <BackgroundMap style={{ height: "80vh" }}>
            <GeoMap
              setDates={setDates}
              height={"80vh"}
              isMapLoading={props?.isMapLoading}
              setIsMapLoading={props?.setIsMapLoading}
              setCentroid={props?.setCentroid}
              centroid={props?.centroid}
              allFields={props?.allFields}
              setAllFields={props?.setAllFields}
              setChartLoading={setChartLoading}
              changeDates={changeDates}
              // indexing={indexing}
            />
          </BackgroundMap>
          {/* {indexing ?  */}
              <MapControlCard style={{ height: "10vh" }}>
                <Grid container spacing={0}>
                  <Grid item lg={12} md={12} xs={12} sm={12}>
                    <MapControlDate>
                      <Grid container spacing={0}>
                        <Grid item lg={1} md={1} xs={2} sm={2}>
                          <MapControlEachInput
                            style={{ marginTop: "0.5em", marginLeft: "1em" }}
                          >
                            <FormControl
                              variant="outlined"
                              style={{
                                width: "100%",
                                color: "white",
                                border: "1px solid rgba(255,255,255,0.3)",
                                borderRadius: "0.3em",
                              }}
                            >
                              <InputLabel
                                htmlFor="outlined-age-native-simple"
                                style={{ color: "white" }}
                              >
                                Index
                              </InputLabel>
                              <Select
                                native
                                defaultValue={index}
                                name="index"
                                label="Index"
                                onChange={(e) => handleIndex(e.target.value)}
                                style={{
                                  border:
                                    "1px solid rgba(255,255,255,1) !important",
                                  color: "white",
                                  height: "3em",
                                }}
                              >
                                {allIndexList?.map((eachItem) => (
                                  <option
                                    key={eachItem?.name}
                                    defaultValue={eachItem?.name}
                                    style={{ color: "rgba(1, 28, 20, 1)" }}
                                  >
                                    {eachItem?.name}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          </MapControlEachInput>
                        </Grid>

                        <Grid item lg={11} md={11} xs={10} sm={10}>
                          <MapControlDateCard>
                            <MapControlDateInner>
                              <MapDates
                                // startDate={startDate}
                                handleStartDate={handleStartDate}
                                handleEndDate={handleEndDate}
                                handleCloudCover={handleCloudCover}
                                dates={dates}
                                setChangeDates={setChangeDates}
                                selDateType={"full-screen"}
                              />
                            </MapControlDateInner>
                          </MapControlDateCard>
                        </Grid>
                      </Grid>
                    </MapControlDate>
                  </Grid>
                </Grid>
              </MapControlCard>
          {/* //   : 
          //   <MapControlCard style={{ height: "10vh" }}>
          //     <p style={{color: 'white', textAlign: 'center', marginTop: '5px', fontSize:'16px'}}>Upgrade your subscription plan to activate Field Indexing</p>
          //   </MapControlCard>
          // } */}
        </MapBody>
      </DashboardContainer>

      {/* --- Field Infomation Modal Here ---- */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={() => setModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
        style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          zIndex: "100000000",
        }}
      >
        <DatesChartModal>
          <ModalCancel>
            <CloseIcon onClick={() => setModal(false)} />
          </ModalCancel>

          <div>
            {/* <h6 style={{ color: 'white', textAlign: 'center', marginTop: '1em', marginBottom: '1.5em' }}>Configure This Field!</h6> */}
            <Grid container spacing={0}>
              <Grid item lg={12} md={12} xs={12} sm={12}>
                <MapControlSelect>
                  <MapControlSelectInner>
                    <Grid container spacing={2}>
                      <Grid item lg={3} md={4} xs={6} sm={6}>
                        <MapControlEachInput>
                          <FormControl
                            variant="outlined"
                            style={{
                              width: "100%",
                              color: "white",
                              border: "1px solid rgba(255,255,255,0.3)",
                              borderRadius: "0.3em",
                            }}
                          >
                            <InputLabel
                              htmlFor="outlined-age-native-simple"
                              style={{ color: "white" }}
                            >
                              Index Type
                            </InputLabel>
                            <Select
                              native
                              defaultValue={"Moisture"}
                              label="Index Type"
                              onChange={(e) => handleIndexType(e.target.value)}
                              style={{
                                border: "none !important",
                                color: "white",
                                height: "3em",
                              }}
                            >
                              {/* <option aria-label="None" value="" /> */}
                              <option
                                defaultValue={"Moisture"}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                Moisture
                              </option>
                              <option
                                defaultValue={"Vegetative"}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                Vegetative
                              </option>
                            </Select>
                          </FormControl>
                        </MapControlEachInput>
                      </Grid>

                      <Grid item lg={3} md={4} xs={6} sm={6}>
                        <MapControlEachInput>
                          <FormControl
                            variant="outlined"
                            style={{
                              width: "100%",
                              color: "white",
                              border: "1px solid rgba(255,255,255,0.3)",
                              borderRadius: "0.3em",
                            }}
                          >
                            <InputLabel
                              htmlFor="outlined-age-native-simple"
                              style={{ color: "white" }}
                            >
                              Index
                            </InputLabel>
                            <Select
                              native
                              name="index"
                              label="Index"
                              onChange={(e) => handleIndex(e.target.value)}
                              defaultValue={index}
                              style={{
                                border:
                                  "1px solid rgba(255,255,255,1) !important",
                                color: "white",
                                height: "3em",
                              }}
                            >
                              {indexList?.map((eachItem) => (
                                <option
                                  key={eachItem?.name}
                                  defaultValue={eachItem?.name}
                                  style={{ color: "rgba(1, 28, 20, 1)" }}
                                >
                                  {eachItem?.name}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </MapControlEachInput>
                      </Grid>

                      <Grid item lg={3} md={4} xs={6} sm={6}>
                        <Space direction="vertical">
                          <DatePicker
                            onChange={pickStartDate}
                            style={{
                              // border: "none",
                              color: "white !important",
                              height: "3.5em",
                              background: "rgba(1, 28, 20, 1)",
                            }}
                            defaultValue={moment(startDate, "YYYY/MM/DD")}
                            disabledDate={disabledStartDate}
                          />
                        </Space>
                      </Grid>
                      <Grid item lg={3} md={4} xs={6} sm={6}>
                        <Space direction="vertical">
                          <DatePicker
                            onChange={pickEndDate}
                            style={{
                              // border: "none",
                              color: "white !important",
                              height: "3.5em",
                              background: "rgba(1, 28, 20, 1)",
                            }}
                            defaultValue={moment(endDate, "YYYY/MM/DD")}
                            disabledDate={disabledEndDate}
                          />
                        </Space>
                      </Grid>
                      <Grid item lg={12} md={12} xs={12} sm={12}>
                        {dateError ? (
                          <p
                            style={{
                              color: "rgba(200,0,0,1)",
                              textAlign: "center",
                            }}
                          >
                            The date range exceeds a year.
                          </p>
                        ) : (
                          <p style={{ color: "white", textAlign: "center" }}>
                            Only a year Date Range is accepted.
                          </p>
                        )}
                      </Grid>
                    </Grid>
                  </MapControlSelectInner>
                </MapControlSelect>
              </Grid>

              <Grid item lg={12} md={12} xs={12} sm={12}>
                <LoadingOverlay
                  active={chartLoading}
                  spinner
                  text={chartLoadingText}
                >
                  <MapControlSelect>
                    <MapControlSelectInner>
                      <FormControl component="fieldset">
                        <FormLabel
                          component="legend"
                          style={{ color: "white" }}
                        >
                          Pick A Year Range
                        </FormLabel>
                        {chartData.length && (
                          <RadioGroup
                            row
                            aria-label="Year"
                            value={chartFragment?.name}
                            name="row-radio-buttons-group"
                          >
                            {chartData.map((item, pos) => (
                              <FormControlLabel
                                value={item?.name}
                                style={{ color: item?.color }}
                                onClick={() => setChartFragment(chartData[pos])}
                                key={pos}
                                control={<Radio />}
                                label={`${item?.name}`}
                              />
                            ))}
                          </RadioGroup>
                        )}
                      </FormControl>
                    </MapControlSelectInner>
                  </MapControlSelect>

                  <MapControlChart style={{ marginTop: "2em" }}>
                    <MapControlChartRender>
                      <ResponsiveContainer width={"100%"} height="100%">
                        <AreaChart
                          data={chartFragment.data}
                          margin={{ top: 25, right: 30, bottom: 35 }}
                          // layout="vertical"
                        >
                          <CartesianGrid strokeDasharray="0.5 0.5" />
                          <XAxis
                            tickFormatter={getOnlyDayAndMonth}
                            angle={45}
                            textAnchor="start"
                            dataKey="date"
                            stroke="rgba(255,255,255,1)"
                            type="category"
                            allowDuplicatedCategory={false}
                          />
                          <YAxis
                            type="number"
                            stroke="rgba(255,255,255,1)"
                            dataKey="mean"
                          />
                          <Tooltip
                            wrapperStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                            itemStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                          />
                          <Legend verticalAlign="top" height={50} />

                          <Area
                            fillOpacity={0}
                            type="monotone"
                            dataKey="mean"
                            name={"Mean"}
                            stroke={chartFragment.color}
                            fill={chartFragment.color}
                          />

                          <Brush
                            dataKey="dayMonth"
                            height={20}
                            stroke="blue"
                            // x={0}
                            // y={40}
                            y={400}
                            data={chartData}
                            gap={20}
                            style={{ marginTop: "20em" }}
                            travellerWidth={50}
                          />
                          {/* <Area
                            name="2021-"
                            fillOpacity={0.1}
                            type="monotone"
                            dataKey="2021-"
                            stroke="rgba(255,255,255,1)"
                            fill="#2F7A64"
                          />
                          <Area
                            name="2020"
                            fillOpacity={0.1}
                            type="monotone"
                            dataKey="2020-"
                            stroke="#6CADEA"
                            fill="#6CADEA"
                          />
                          <Area
                            name="2019-"
                            fillOpacity={0.1}
                            type="monotone"
                            dataKey="2019-"
                            stroke="red"
                            fill="red"
                          /> */}
                          {/* <Area
                            name="Min"
                            fillOpacity={0.1}
                            type="monotone"
                            dataKey="min"
                            stroke="#6CADEA"
                            fill="#6CADEA"
                          />
                          <Area
                            name="Max"
                            fillOpacity={0.1}
                            type="monotone"
                            dataKey="max"
                            stroke="rgba(255,255,255,1)"
                            fill="rgba(255,255,255,1)"
                          /> */}
                          <text
                            x="35"
                            y="0"
                            fill="white"
                            dominantBaseline="hanging"
                            fontSize="16"
                            fontWeight="bold"
                          >
                            Mean Index Value Chart
                          </text>
                        </AreaChart>
                      </ResponsiveContainer>
                    </MapControlChartRender>
                  </MapControlChart>
                </LoadingOverlay>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sm={12}
                style={{ display: "none" }}
              >
                <ModalDates>
                  <MapControlDateInner>
                    <MapDates
                      // startDate={startDate}
                      handleStartDate={handleStartDate}
                      handleEndDate={handleEndDate}
                      handleCloudCover={handleCloudCover}
                      dates={dates}
                      setChangeDates={setChangeDates}
                      selDateType={"full-screen"}
                    />
                  </MapControlDateInner>
                </ModalDates>
              </Grid>
            </Grid>
          </div>
        </DatesChartModal>
      </Modal>

      <ClickModalButton onClick={handleOpenModal}>
        <ControlCameraIcon
          style={{ cursor: "pointer", marginLeft: "auto", marginRight: "auto" }}
        />
      </ClickModalButton>
      {/* <DashboardDrawer */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user_details: state.authReducer.user_details,
    one_account_subscription: state.adminOtherReducer.one_account_subscription,
  };
};

export default connect(mapStateToProps, { GetUserSubscriptionPlan })(
  DashboardHome
);
// export default DashboardHome;
