import React from 'react';

// import RedoIcon from '@material-ui/icons/Redo';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import MapIcon from '@material-ui/icons/Map';
import SatelliteIcon from '@material-ui/icons/Satellite';

import { useHistory } from 'react-router-dom';
import { SwitchBox } from '../../styles/satelliteField/SatelliteFieldStyle';

const SwitchButton = (props) => {

    let history = useHistory();

    const handleRedir = () => {
        history.push(props?.redr)
    }

    return (
        <>
            <SwitchBox onClick={handleRedir}>
                {props?.isDefault ?
                    <VerticalSplitIcon style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    :
                    <>
                        {props?.redrType === 'dashboard' ?
                            <MapIcon style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                            :
                            <SatelliteIcon style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        }
                    </>
                }
            </SwitchBox>
        </>
    )
};

export default SwitchButton;