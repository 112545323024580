import React, { useState, useEffect } from 'react';

import { Fade, Grid, Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FarmCropRotation, ShowMoreTable, ShowMoreTd, ShowMoreTh, ShowMoreTr } from '../../styles/farmManagement/FarmSummaryStyle';
import { CropPlanAddBtn, CropPlanInputText, CropPlanModal, CropPlanModalInner, CropPlanModalTitle, CropPlanSelect } from '../../styles/cropPlan/CropPlanStyles';

import { getSeasonsByItsYear, rotateSeason } from '../../redux/actions/cropPlanDevelopment';
import { useSelector } from 'react-redux';
import { range } from 'lodash';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        outline: 'none'
    }
}));


const ShowCropsModal = (props) => {

    const classes = useStyles();
    const {
        access_token: access_key,
    } = useSelector((state) => state.authReducer);

    const [selSeasonYear, setSelSeasonYear] = useState(props?.seasonsList[0]);
    const [seasonData, setSeasonData] = useState([]);
    const [selNewSeasonYear, setSelNewSeasonYear] = useState("");
    const [btnState, setBtnState] = useState({ text: 'Add Season', isDisabled: false });

    useEffect(() => {
        setSelSeasonYear(props?.seasonsList[0]);
    }, [props?.seasonsList]);

    useEffect(() => {

        setSeasonData([]);
        const getResponse = async () => {
            let { isError, data } = await getSeasonsByItsYear(access_key, props?.fieldId, selSeasonYear);

            if (isError) {

                setSeasonData([]);
            } else {
                setSeasonData(data.data)

            }
        }

        if (props?.open && (selSeasonYear !== undefined)) {
            setSeasonData([]);
            getResponse();
        }
        else {
            setSeasonData([]);
            setSelSeasonYear(undefined)
        }

    }, [props?.fieldId, access_key, selSeasonYear, props?.open]);

    const handleGetSeasonData = async (seasonYear) => {
        if (seasonYear) {
            setSelSeasonYear(seasonYear)
        } else {
            setSeasonData([]);
        }
    }

    const seasonDates = range(2021, 2040);

    const handleAddSeason = async () => {

        let update = {
            crop_season: selNewSeasonYear
        }

        console.log(props?.selRotationId, update)
        setBtnState({ text: 'Please wait!', isDisabled: true });

        let { isError } = await rotateSeason(update, access_key, props?.selRotationId);

        if (isError) {

            toast.error('Sorry, an error occured and we could not rotate the season', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBtnState({ text: 'Add Season', isDisabled: false });

        } else {

            toast(`Season ${props?.selSeason} successfully rotated`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBtnState({ text: 'Add Season', isDisabled: false });
            // close the modal and refresh
            props?.setRenderData(20);
            props?.handleClose();
        }
    }

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props?.open}
                onClose={props?.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
                style={{ border: 'none !important' }}
            >
                <Fade in={props?.open}>
                    {/* {props?.rotationData?.crops ? */}
                    <>
                        <CropPlanModal>
                            <CropPlanModalInner>
                                <Grid container spacing={1}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <CropPlanModalTitle>VIEW SEASONS FOR THIS ROTATION</CropPlanModalTitle>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6} style={{ float: 'right' }}>
                                        <CloseIcon onClick={props?.handleClose} style={{ float: 'right', cursor: 'pointer', color: 'rgba(200,0,0,1)' }} />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <CropPlanInputText>Choose Season</CropPlanInputText>
                                        {selSeasonYear === undefined && <div style={{ color: 'rgba(200,0,0,0.7)', fontSize: '0.8em', marginBottom: '-1em', marginTop: '0.5em' }}>No season added/chosen yet</div>}
                                        <CropPlanSelect name="crop" type='select' onClick={(e) => handleGetSeasonData(e.target.value)}>
                                            <option key={'Choose'} value={'None'} selected>Choose a season</option>
                                            {props?.seasonsList.map((eachItem, pos) => (
                                                <option key={pos} value={eachItem}>{eachItem}</option>
                                            ))}
                                        </CropPlanSelect>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <CropPlanInputText>Crops in this Season</CropPlanInputText>
                                        {seasonData?.length ?
                                            <FarmCropRotation style={{ overflowY: 'scroll', width: '100%', background: 'white', maxHeight: '20em' }}>
                                                <ShowMoreTable>
                                                    <thead>
                                                        <ShowMoreTr>
                                                            <ShowMoreTh>Crop Name</ShowMoreTh>
                                                            <ShowMoreTh>Season</ShowMoreTh>
                                                            {/* <ShowMoreTh>Season</ShowMoreTh> */}
                                                            <ShowMoreTh>Sowing Date</ShowMoreTh>
                                                            <ShowMoreTh>Harvest Date</ShowMoreTh>
                                                        </ShowMoreTr>
                                                    </thead>
                                                    <tbody>
                                                        {seasonData.map((eachCrop, pos) => (
                                                            <ShowMoreTr key={pos}>
                                                                <ShowMoreTd>{eachCrop?.crop?.name}</ShowMoreTd>
                                                                <ShowMoreTd>{eachCrop?.season}</ShowMoreTd>
                                                                {/* <ShowMoreTd>{props?.rotationData?.season}</ShowMoreTd> */}
                                                                <ShowMoreTd>{eachCrop?.sowing_date}</ShowMoreTd>
                                                                <ShowMoreTd>{eachCrop?.harvest_date}</ShowMoreTd>
                                                            </ShowMoreTr>
                                                        ))}
                                                    </tbody>
                                                </ShowMoreTable>
                                            </FarmCropRotation>
                                            :
                                            <FarmCropRotation style={{ width: '100%' }}>
                                                <p style={{ color: 'rgba(200,0,0,0.9)' }}>{selSeasonYear !== undefined && <span>No Crops Added Yet!</span>}</p>
                                            </FarmCropRotation>
                                        }
                                    </Grid>

                                    <Grid container spacing={2} style={{ marginBottom: '1em' }}>
                                        <Grid item lg={8} md={12} sm={12} xs={12}>
                                            <CropPlanInputText>Add A New Season To This Rotation</CropPlanInputText>
                                            <CropPlanSelect name="crop" defaultValue={seasonDates[0]} onChange={(e) => setSelNewSeasonYear(e.target.value)}>
                                                {seasonDates.map((eachSeason, pos) => (
                                                    <option key={pos} defaultValue={eachSeason} style={{ color: 'rgba(1, 28, 20, 1)' }}>{eachSeason}</option>
                                                ))}
                                            </CropPlanSelect>

                                        </Grid>
                                        <Grid item lg={4} md={12} sm={12} xs={12}>
                                            <CropPlanInputText>.</CropPlanInputText>
                                            <CropPlanAddBtn style={{ marginTop: '1em' }} type='submit' onClick={handleAddSeason} disabled={btnState?.disabled}>{btnState?.text}</CropPlanAddBtn>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CropPlanModalInner>
                        </CropPlanModal>
                    </>
                </Fade>

            </Modal>
        </>
    )
};

export default ShowCropsModal;