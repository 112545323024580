import { getOnlyDayAndMonth, wmsKey } from "../../components/util/Util";
import { baseUrl } from "../../config";
import { fetchApi } from "../util/fetchApi";


export const getIndexValAndValueChange = async (date, index, geometry, cloudCover, dataToUpdate) => {

    let APIUrl = `${baseUrl}/farm/value?LAYER=${index}&CRS=CRS:84&TIME=${date}&MAXCC=${cloudCover}&WIDTH=512&HEIGHT=512&GEOMETRY=${geometry}`;

    let requestHeaders = {
        "Content-Type": "application/json"
    }

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "GET", APIUrl, requestHeaders);

        if (isError) {
            return { errorMessage, isError };

        } else {
            return { data: data }
        }

    }


    const response = await getResponse();
    return response;
};


export const getFisMeanNYearsData = async (dateRangeList, index, cloudCover, geometry, setFetching, setChartData, setChartLoadingText, setChartFragment) => {

    setFetching(true);
    let requestHeaders = {
        "Content-Type": "application/json"
    }

    let nYearsData = [];

    const getResponse = async (url, results) => {
        const { isError, data, } = await fetchApi({}, "GET", url, requestHeaders);
        if (isError) {
            return results;
        } else {

            if (Object.keys(data).length > 0) {
                data["C0"].map((eachData) => {
                    // test and handle exceptions if any of the data is NaN
                    try {
                        // let d = eachData.date;
                        results.push({
                            date: eachData?.date,
                            dayMonth: getOnlyDayAndMonth(eachData?.date),
                            mean: eachData?.basicStats?.mean?.toFixed(3),
                            // min: eachData?.basicStats?.min?.toFixed(3),
                            // max: eachData?.basicStats?.max?.toFixed(3),
                        });
                    } catch (e) {
                        results.push({
                            date: eachData?.date,
                            dayMonth: getOnlyDayAndMonth(eachData?.date),
                            mean: null,
                            min: null,
                            max: null,
                        });
                    }
                    return 0;
                });
                // .reverse()
            }
            return results;
        }
    }

    let colorsList = ["red", "blue", "yellow", "white", "#6CADEA"];
    // --- Hit the Endpoint in N number of times where N is the length of the dateRangeList
    for (let i = 0; i < dateRangeList.length; i++) {
        let results = []
        let url = `https://services.sentinel-hub.com/ogc/fis/${wmsKey}?LAYER=${index}&CRS=CRS:84&TIME=${dateRangeList[i][0]}/${dateRangeList[i][1]}&MAXCC=${cloudCover}&WIDTH=512&HEIGHT=512&GEOMETRY=${geometry}`;
        await getResponse(url, results);
        nYearsData.push({
            name: (dateRangeList[i][0].substr(0, 4) + "/" + dateRangeList[i][1].substr(0, 4)),
            data: results, color: colorsList[i]
        })
    }
    if (nYearsData?.length === 0) {
        setChartLoadingText(
            "No chart date for the index selected within the date range and cloud cover."
        );
    } else {
        setChartLoadingText("");
    }

    setFetching(false);
    setChartData(nYearsData);
    // --- For default setting ---
    setChartFragment(nYearsData[0]);
    return nYearsData;
};