import React from 'react'
import ResetPassword from '../../components/auth/ResetPassword';

const ResetPasswordPage = () => {
    return (
        <div>
            <ResetPassword />
        </div>
    )
}

export default ResetPasswordPage
