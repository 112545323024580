import constants from "../constants";
import axios from "axios";
import { baseUrl } from "../../config";

export const AdminActionLogin = (logindetails) => (dispatch) => {
    return axios
        .post(`${baseUrl}/auth/login/`, logindetails, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(res => {
            console.log('admin', res);
            if (res?.data?.data?.role === 'admin') {
                dispatch({ type: constants.ADMIN_USER, payload: res?.data?.data });
                dispatch({ type: constants.ADMIN_TOKEN, payload: res?.data?.data?.token });
            } else {
                dispatch({ type: constants.ADMIN_USER, payload: null });
                dispatch({ type: constants.ADMIN_TOKEN, payload: null });
            }
            return {
                success: true,
                role: res?.data?.data?.role,
                message: 'Login Successfully! Redirecting to dashboard...',
            };
        })
        .catch(err => {
            console.log('rrr', err?.response);
            return {
                success: false,
                message: err?.response?.data?.message || 'Something went wrong',
            };
        });
}

export const AdminActionLogout = () => dispatch => {
    dispatch({ type: constants.ADMIN_USER, payload: null });
    dispatch({ type: constants.ADMIN_TOKEN, payload: null });
    return { success: true, message: 'Logout Successfully' };
};


// System Settings
export const ConfigureSystemSettings = (details) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.post(`${baseUrl}/system_setup/system_configuration/`, details, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch(GetSystemConfiguredRecords());
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.[0] }
    })
}

export const UpdateSystemSettings = (details) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.patch(`${baseUrl}/system_setup/system_configuration/${details.id}/`, details, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        
        dispatch(GetSystemConfiguredRecords());
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const DeleteSystemSettings = (id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.delete(`${baseUrl}/system_setup/system_configuration/${id}/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        
        dispatch(GetSystemConfiguredRecords());
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const GetSystemConfiguredRecords = () => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/system_setup/system_configuration/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        
        dispatch({ type: constants.SYSTEM_CONFIG_RECORD, payload: res?.data });
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

// Subscription Actions
export const ClearSubValues = (formMode) => (dispatch) => {
    if (formMode === 'CREATE_MODE') {
        dispatch({type: constants.GET_ONE_SUBSCRIPTION, payload: null});
    }
    dispatch({type: constants.GET_ONE_SUBSCRIPTION, payload: null});
    return { success: true };
}
export const ConfigureSubscriptions = (details) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.post(`${baseUrl}/system_setup/subscription_plan/`, details, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch(GetConfiguredSubscriptionsRecords());
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.[0] }
    })
}

export const UpdateSubscription = (details) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.patch(`${baseUrl}/system_setup/subscription_plan/${details?.id}/`, details, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        
        dispatch(GetConfiguredSubscriptionsRecords());
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const DeleteSubscription = (sub_id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.delete(`${baseUrl}/system_setup/subscription_plan/${sub_id}/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {

        dispatch(GetConfiguredSubscriptionsRecords());
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const GetConfiguredSubscriptionsRecords = () => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/system_setup/subscription_plan/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        console.log('get', res);
        dispatch({ type: constants.ALL_SUBSCRIPTION_RECORD, payload: res?.data });
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const GetOneSubscription = (id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/system_setup/subscription_plan/${id}/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        
        dispatch({ type: constants.GET_ONE_SUBSCRIPTION, payload: res?.data });
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

// Service Configuration Actions
export const ConfigureOneServices = (details) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.post(`${baseUrl}/system_setup/service/`, details, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        
        dispatch(GetConfiguredServices());
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.[0] }
    })
}

export const UpdateServices = (details) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.patch(`${baseUrl}/system_setup/service/${details.id}/`, details, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        
        dispatch(GetConfiguredServices());
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const GetConfiguredServices = () => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/system_setup/service/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        
        dispatch({ type: constants.ALL_SERVICES_RECORD, payload: res?.data });
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const GetOneConfiguredServices = (id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/system_setup/service/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        
        dispatch({ type: constants.GET_ONE_SERVICE, payload: res?.data });
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const DeleteConfiguredService = (id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.delete(`${baseUrl}/system_setup/service/${id}/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch(GetConfiguredServices());
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}
