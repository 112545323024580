/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import { Card, Spin, Row, Col, Input, Button, Table, Tag } from "antd";
import { UserProfileContainer } from "./UserManagement.styled";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getTeamMembers,
  getAUserProfile,
  // updateUserProfile,
} from "../../redux/actions/userManagement";
import {
  updateUserPassword,
  updateUserProfile,
  LogoutUser,
} from "../../redux/actions/authentication";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GetOneAccountSubscriptions } from "../../redux/actions/adminOtherServices";

const UserAccount = (props) => {
  let {
    getTeamMembers,
    getAUserProfile,
    updateUserProfile,
    updateUserPassword,
    GetOneAccountSubscriptions,
    LogoutUser,
    one_account_subscription,
    // ownTeam_id,
    user_details,
    team_members = [],
  } = props;
  const [loading, setloading] = useState(false);
  const [fetching, setfetching] = useState(false);
  const [newData, setNewData] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [processing, setProcessing] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [user, setUser] = useState({});
  const [newpwd, setNewPwd] = useState({
    new_password1: "",
    new_password2: "",
  });
  const history = useHistory();
  const { user_details: userdetails } = useSelector(
    (state) => state.authReducer
  );
  const column = [
    {
      title: "Full Name",
      dataIndex: "name",
    },
    {
      title: "Email Address",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (_text, record) => (
        <>
          {record.role === null ? (
            <Tag color="#f38c75">Not Found</Tag>
          ) : (
            record.role
          )}
        </>
      ),
    },
  ];

  const onChangePwd = (e) => {
    setNewPwd({ ...newpwd, [e.target.name]: e.target.value });
  };

  const UpdateProfile = async (data) => {
    setUpdating(true);
    const updateData = {
      ...data,
      email: user?.email,
      ...user?.owned_team,
    };
    const res = await updateUserProfile(updateData, user?.id);
    if (res.success) {
      toast("Account Details Updated successfully.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        setUpdating(false);
      }, 400);
    } else {
      toast.error("An unexpected error occurred. Kindly try again, please.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const UpdateMyPassword = async (e) => {
    e.preventDefault();
    if (newpwd.new_password1 !== newpwd.new_password2) {
      toast.error("Password does not match", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setProcessing(true);
    const res = await updateUserPassword(newpwd);
    if (res.success) {
      toast(`${res.message} Please login again`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/auth/login");
      setTimeout(() => {
        LogoutUser();
        setProcessing(false);
      }, 400);
    } else {
      setProcessing(false);
      toast.error(`${res.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      setfetching(true);
      const res = await getAUserProfile(userdetails?.id);
      if (res.success) {
        setUser(user_details);
        setfetching(false);
      }
    };
    fetchProfile();
  }, []);

  const getTeamId = user_details?.owned_team?.id || user_details?.user_team;

  useEffect(() => {
    const getSubscriptionDetails = async () => {
      if (!getTeamId) return;
      await GetOneAccountSubscriptions(
        user_details?.owned_team?.current_subscription
      );
      // if (!res.success) {
      //   toast.error('Error fetching user subscription plans');
      // }
    };
    getSubscriptionDetails();
  }, []);

  console.log("one_account_subscription", one_account_subscription);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (getTeamId === undefined) {
        return;
      }
      setloading(true);
      const res = await getTeamMembers(getTeamId);
      if (res.success) {
        setloading(false);
        const t = team_members.map((res) => {
          return {
            ...res,
            id: res.id,
            name: `${res.member?.first_name} ${res.member?.last_name}`,
            email: res.member?.email,
            role: res.role?.name,
          };
        });
        setNewData(t);
      }
    };
    fetchTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout pageTitle="My Account">
      <UserProfileContainer
        data-aos="fade-up"
        data-aos-delay="2s"
        data-aos-duration="1s"
      >
        <div className="mb-4">
          <h3>My Account</h3>
        </div>
        <Row gutter={10}>
          <Col xs={24} xl={12} lg={12}>
            <Card bordered={false} className="card-shadow">
              <h4>Basic Details</h4>
              <Spin spinning={fetching}>
                <form onSubmit={handleSubmit(UpdateProfile)}>
                  <Row gutter={12}>
                    <Col xs={24} xl={12} lg={12}>
                      <div className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          defaultValue={user.first_name}
                          name="first_name"
                          {...register("first_name", { required: true })}
                          className="form-control"
                        />
                        {errors.first_name && (
                          <small className="error">
                            This field cannot be empty
                          </small>
                        )}
                      </div>
                    </Col>
                    <Col xs={24} xl={12} lg={12}>
                      <div className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          defaultValue={user.last_name}
                          name="last_name"
                          {...register("last_name", { required: true })}
                          className="form-control"
                        />
                        {errors.last_name && (
                          <small className="error">
                            This field cannot be empty
                          </small>
                        )}
                      </div>
                    </Col>
                    <Col xs={24} xl={12} lg={12}>
                      <div className="form-group">
                        <label htmlFor="first_name">Email Address</label>
                        <input
                          type="email"
                          defaultValue={user.email}
                          disabled
                          {...register("email")}
                          className="form-control"
                        />
                      </div>
                    </Col>
                    <Col xs={24} xl={12} lg={12}>
                      <div className="form-group">
                        <label htmlFor="last_name">Owned Name</label>
                        <input
                          defaultValue={user?.owned_team?.name}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row justify="end">
                    <Col xs={24} style={{ textAlign: "right" }}>
                      <Button
                        loading={updating}
                        onClick={handleSubmit(UpdateProfile)}
                        type="primary"
                      >
                        {updating ? "Updating..." : "Save Changes"}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </Spin>
            </Card>

            <Card bordered={false} className="card-shadow mt-4">
              <h4>Update Password</h4>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(UpdateMyPassword)}
              >
                <Row gutter={20}>
                  <Col xs={24} xl={12} lg={12}>
                    <div className="form-group">
                      <label htmlFor="password1">New Password</label>
                      <Input.Password
                        placeholder="Password"
                        name={"new_password1"}
                        minLength={8}
                        onChange={onChangePwd}
                      />
                      <small className="text-danger">
                        {errors.password1 && (
                          <span className="error">
                            Password must have at least 8 characters
                          </span>
                        )}
                      </small>
                    </div>
                  </Col>
                  <Col xs={24} xl={12} lg={12}>
                    <div className="form-group">
                      <label htmlFor="password2">Confirm New Password</label>
                      <Input.Password
                        placeholder="Password"
                        name={"new_password2"}
                        minLength={8}
                        onChange={onChangePwd}
                      />
                      <small className="text-danger">
                        {errors.password2 && (
                          <span className="error">
                            Password must have at least 8 characters
                          </span>
                        )}
                      </small>
                    </div>
                  </Col>
                </Row>
                <Row justify="end">
                  <Col xs={24} style={{ textAlign: "right" }}>
                    <Button
                      loading={processing}
                      onClick={UpdateMyPassword}
                      type="danger"
                    >
                      {processing ? "Processing..." : "Change Password"}
                    </Button>
                  </Col>
                </Row>
              </form>
            </Card>
          </Col>
          <Col xs={24} xl={12} lg={12}>
            <Card bordered={false} className="card-shadow">
              <h4>My Team Members</h4>
              <Row>
                <Col xs={24}>
                  <Table
                    rowKey="id"
                    loading={loading}
                    dataSource={newData}
                    columns={column}
                    scroll={{ x: 500 }}
                  />
                </Col>
              </Row>
            </Card>
            {user_details?.current_subscription === null &&
            user_details?.owned_team === null &&
            user_details?.user_team !== null ? null : (
              <Card bordered={false} className="card-shadow mt-4">
                <h4>My Current Subscription</h4>
                <Row>
                  <Col xs={24}>
                    <p>
                      Your are currently on:{" "}
                      <strong>
                        {
                          user_details?.current_subscription
                            ?.current_subscription
                        }
                      </strong>
                    </p>
                    {/*<p>Expires on: 25th Jan, 2021</p>*/}
                    <p>
                      Status:{" "}
                      {user_details?.current_subscription
                        ?.subscription_approved ? (
                        <>
                          <Tag color="green">Active</Tag>
                        </>
                      ) : (
                        <>
                          <Tag color="red">Expired</Tag>
                        </>
                      )}
                    </p>
                    <p>
                      Approved Status:{" "}
                      {user_details?.current_subscription?.admin_approved ? (
                        <>
                          <Tag color="green">Active</Tag>
                        </>
                      ) : (
                        <>
                          <Tag color="red">Expired</Tag>
                        </>
                      )}
                    </p>
                    {/*<p><Tag color='gray'>No Information Available</Tag></p>*/}
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
        </Row>
      </UserProfileContainer>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    team_roles: state.userManagementReducer.team_roles,
    team_members: state.userManagementReducer.team_members,
    // ownTeam_id: state.authReducer.user_details?.owned_team.id,
    user_details: state.authReducer.user_details,
    one_account_subscription: state.adminOtherReducer.one_account_subscription,
  };
};

export default connect(mapStateToProps, {
  getTeamMembers,
  getAUserProfile,
  updateUserProfile,
  updateUserPassword,
  LogoutUser,
  GetOneAccountSubscriptions,
})(UserAccount);
