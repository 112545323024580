import React, { useState } from "react";
import { Button, Col, DatePicker, Modal, Row, Select } from "antd";
import moment from "moment";
import FarmCord from "../../assets/img/farmtest.png";
import { toast } from "react-toastify";
import { FarmConfigureContainer } from "./FarmActivitiy.styled";
import { useForm, Controller } from "react-hook-form";
const { Option } = Select;

const UpdateFarmTaskModal = (props) => {
  const {
    formData,
    team_members,
    updateFarmActivities,
    handleCancel,
    selectedFieldId,
  } = props;
  const [updatevalue, setUpdateValue] = useState({
    field: 0,
    name: "",
    type: "planning",
    description: "",
    planned_cost: 0,
    actual_cost: 0,
    start_date: null,
    end_date: null,
    assignees: [],
  });
  const [loadingupdate, setLoadingUpdate] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const closeModal = () => {
    props.handleCancel();
    setUpdateValue({
      field: 0,
      name: "",
      type: "planning",
      description: "",
      planned_cost: 0,
      actual_cost: 0,
      start_date: null,
      end_date: null,
      assignees: [],
    });
    reset();
  };

  const UpdateFarmActivity = async (data) => {
    setLoadingUpdate(true);
    const farmData = {
      ...updatevalue,
      ...data,
      field: selectedFieldId,
      start_date: moment(data.start_date).format("YYYY-MM-DD"),
      end_date: moment(data.end_date).format("YYYY-MM-DD"),
    };
    console.log("farm data", data);
    console.log("farm 2", farmData);
    const res = await updateFarmActivities(farmData, formData?.id);
    if (res.success) {
      toast.success(`Farm Task Updated Successfully`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      closeModal();
      setLoadingUpdate(false);
    } else {
      toast.error(`${res.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setTimeout(() => {
      setLoadingUpdate(false);
    }, 400);
  };

  const disableEndDate = (current) => {
    return current && current < moment(updatevalue?.start_date).startOf("day");
  };

  const children = team_members?.map((user) => {
    return (
      <Option key={user.member.pk} value={user.member.pk}>
        {user.member.email}
      </Option>
    );
  });

  return (
    <>
      <Modal
        title={`Update this Task: ${formData?.name}?`}
        visible={props.isModalVisible}
        onOk={handleCancel}
        onCancel={closeModal}
        closable={false}
        okText="Close Task"
        maskClosable={false}
        cancelText="Cancel"
        footer={null}
      >
        <FarmConfigureContainer>
          <div className="taskbody">
            <form onSubmit={handleSubmit(UpdateFarmActivity)}>
              <div className="form-group">
                <Row gutter={24} align="middle" justify="space-between">
                  <Col xs={24} xl={6} lg={6}>
                    <label>Task Name</label>
                  </Col>
                  <Col xs={24} xl={16} lg={16}>
                    <input
                      size="large"
                      name="name"
                      className="form-control"
                      defaultValue={formData?.name}
                      {...register("name", { required: true })}
                    />
                    {errors.name && <small>Task name is required</small>}
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row gutter={24} align="middle" justify="space-between">
                  <Col xs={24} xl={6} lg={6}>
                    <label>Assignee</label>
                  </Col>
                  <Col xs={24} xl={16} lg={16}>
                    <Controller
                      control={control}
                      name="assignees"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          mode="multiple"
                          allowClear
                          name="assignees"
                          value={value}
                          style={{ width: "100%" }}
                          defaultValue={formData?.assignees}
                          onChange={onChange}
                          size="large"
                          showArrow={true}
                          maxTagCount={2}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {children}
                        </Select>
                      )}
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row gutter={24} align="middle" justify="space-between">
                  <Col xs={24} xl={6} lg={6}>
                    <label>Task Description</label>
                  </Col>
                  <Col xs={24} xl={16} lg={16}>
                    <textarea
                      className="form-control"
                      name="description"
                      defaultValue={formData.description}
                      {...register("description")}
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row gutter={24} align="middle" justify="space-between">
                  <Col xs={24} xl={6} lg={6}>
                    <label>Start Date</label>
                  </Col>
                  <Col xs={24} xl={16} lg={16}>
                    <Controller
                      control={control}
                      name="start_date"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DatePicker
                          size="large"
                          name="start_date"
                          value={value}
                          defaultValue={moment(formData?.start_date)}
                          onChange={onChange}
                          disabledDate={disableEndDate}
                          disabled={!formData?.start_date}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row gutter={24} align="middle" justify="space-between">
                  <Col xs={24} xl={6} lg={6}>
                    <label>End Date</label>
                  </Col>
                  <Col xs={24} xl={16} lg={16}>
                    <Controller
                      control={control}
                      name="end_date"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DatePicker
                          size="large"
                          name="end_date"
                          value={value}
                          defaultValue={moment(formData?.end_date)}
                          onChange={onChange}
                          disabledDate={disableEndDate}
                          disabled={!formData?.start_date}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row gutter={24} align="middle" justify="space-between">
                  <Col xs={24} xl={24} lg={24}>
                    <img src={FarmCord} alt="cord" />
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row gutter={48} align="middle" justify="space-around">
                  <Col xs={12} xl={12} lg={12}>
                    <Button
                      onClick={closeModal}
                      block
                      type="default"
                      className="cancelbtn"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xs={12} xl={12} lg={12}>
                    <Button
                      loading={loadingupdate}
                      block
                      type="primary"
                      onClick={handleSubmit(UpdateFarmActivity)}
                    >
                      {loadingupdate ? "Processing..." : "Update Task"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </FarmConfigureContainer>
      </Modal>
    </>
  );
};

export default UpdateFarmTaskModal;
