import { fetchApi } from "../util/fetchApi";


const getIndexData = async (url) => {

    const APIUrl = url;

    let requestHeaders = {
        "Content-Type": "application/json"
    }

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "GET", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {

            return { data: data }
        }

    }


    const response = await getResponse();
    return response;
};

export default getIndexData;