import React, { useContext, useState, useEffect } from "react";

import { FormControl, Grid, Select } from "@material-ui/core";
import { DashboardContainer } from "../../styles/util/UtilStyle";
import {
  SatelliteBottom,
  SatelliteContainer,
  SplitViewScreen,
} from "../../styles/satelliteField/SatelliteFieldStyle";
import CloudControl from "./CloudControl";
import { DatePicker, Space } from "antd";
import moment from "moment";
import SingleView from "./SingleView";
import MapDates from "../geoMap/MapDates";
import { InputLabel } from "@material-ui/core";
import { backDate } from "../util/Util";
import { DisableMobileView } from "../util/DisableMobileView";
import { AppContext } from "../../appRouter";

const { RangePicker } = DatePicker;

const SplitView = (props) => {
  const {
    selSplitDate1,
    selSplitDate2,
    selFieldDetails,
    handleSelSplitDate1,
    handleSelSplitDate2,
  } = useContext(AppContext);
  const [cloudCover1, setCloudCover1] = useState(props?.cloudCover);
  const [cloudCover2, setCloudCover2] = useState(20);

  const [refresh1, setRefresh1] = useState(false);
  const [refresh2, setRefresh2] = useState(false);

  const [startDate1, setStartDate1] = useState(backDate(3));
  const [endDate1, setEndDate1] = useState(backDate(0));

  const [startDate2, setStartDate2] = useState(backDate(3));
  const [endDate2, setEndDate2] = useState(backDate(0));

  const [dates1, setDates1] = useState([]);
  const [dates2, setDates2] = useState([]);

  const [changeDates1, setChangeDates1] = useState(true);
  const [changeDates2, setChangeDates2] = useState(true);

  const [layer1, setLayer1] = useState(null);
  const [layer2, setLayer2] = useState(null);

  const [isCloudPresent1, setIsCloudPresent1] = useState(true);
  const [isCloudPresent2, setIsCloudPresent2] = useState(true);

  const handleCloud1 = (e) => {
    setCloudCover1(e);
    setRefresh1(true);
  };

  const handleCloud2 = (e) => {
    setCloudCover2(e);
    setRefresh2(true);
  };

  const onChange1 = (dates, dateStrings) => {
    console.log("From: ", dates[0], ", to: ", dates[1]);
    if (dateStrings[0].length > 0 && dateStrings[1].length > 0) {
      setStartDate1(dateStrings[0]);
      setEndDate1(dateStrings[1]);
      setRefresh1(true);
      setChangeDates1(true);
    }
  };

  const onChange2 = (dates, dateStrings) => {
    console.log("From: ", dates[0], ", to: ", dates[1]);
    if (dateStrings[0].length > 0 && dateStrings[1].length > 0) {
      setStartDate2(dateStrings[0]);
      setEndDate2(dateStrings[1]);
      setRefresh2(true);
      setChangeDates2(true);
    }
  };

  const handleIndex1 = (e) => {
    setLayer1(e);
    setRefresh1(true);
  };

  const handleIndex2 = (e) => {
    setLayer2(e);
    setRefresh2(true);
  };

  useEffect(() => {
    handleSelSplitDate1(dates1[0]);
    handleSelSplitDate2(dates2[0]);
    // eslint-disable-next-line
  }, [dates1, dates2]);
  return (
    <>
      <DashboardContainer style={{ background: "white" }}>
        <SatelliteContainer>
          <SplitViewScreen>
            <Grid container spacing={0}>
              <Grid
                item
                lg={6}
                xs={12}
                md={6}
                sm={6}
                style={{ borderRight: "1px solid white" }}
              >
                <SingleView
                  selectedCentroid={props?.selectedCentroid}
                  selectedCoords={props?.selectedCoords}
                  selectedRealCoords={props?.selectedRealCoords}
                  cloudCover={cloudCover1}
                  startDate={startDate1}
                  endDate={endDate1}
                  refresh={refresh1}
                  setDates={setDates1}
                  changeDates={changeDates1}
                  layer={layer1}
                  handleCloud={handleCloud1}
                  isCloudPresent={isCloudPresent1}
                  redrType={"dashboard"}
                  selectedDate={selSplitDate1}
                  selFieldDetails={selFieldDetails}
                />
              </Grid>

              <Grid item lg={6} xs={12} md={6} sm={6}>
                <SingleView
                  selectedCentroid={props?.selectedCentroid}
                  selectedCoords={props?.selectedCoords}
                  selectedRealCoords={props?.selectedRealCoords}
                  cloudCover={cloudCover2}
                  startDate={startDate2}
                  endDate={endDate2}
                  refresh={refresh2}
                  setDates={setDates2}
                  changeDates={changeDates2}
                  layer={layer2}
                  handleCloud={handleCloud2}
                  isCloudPresent={isCloudPresent2}
                  redrType={"satellite"}
                  selectedDate={selSplitDate2}
                  selFieldDetails={selFieldDetails}
                />
              </Grid>

              <Grid item lg={12} xs={12} md={12} sm={12}>
                <SatelliteBottom>
                  <Grid container spacing={0}>
                    <Grid item lg={6} xs={12} md={6} sm={6}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginTop: "0.5em",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "95%",
                        }}
                      >
                        <Grid
                          item
                          lg={6}
                          xs={12}
                          md={12}
                          sm={6}
                          style={{ display: "none" }}
                        >
                          {/* --- Import the Cloud Cover controller */}
                          <CloudControl
                            cloudCover={cloudCover1}
                            handleCloud={handleCloud1}
                            // setIsMapLoading={props?.setIsMapLoading}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xs={6} sm={6}>
                          {/* <MapControlEachInput> */}
                          <FormControl
                            variant="outlined"
                            style={{
                              width: "100%",
                              color: "white",
                              border: "1px solid rgba(255,255,255,0.3)",
                              borderRadius: "0.3em",
                            }}
                          >
                            <InputLabel
                              htmlFor="outlined-age-native-simple"
                              style={{ color: "white" }}
                            >
                              Index
                            </InputLabel>
                            <Select
                              native
                              defaultValue={null}
                              name="index"
                              label="Index"
                              onChange={(e) => handleIndex1(e.target.value)}
                              style={{
                                border:
                                  "1px solid rgba(255,255,255,1) !important",
                                color: "white",
                                height: "3em",
                              }}
                            >
                              {/* <option aria-label="None" value="" /> */}
                              <option
                                defaultValue={null}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                Choose Index
                              </option>
                              <option
                                defaultValue={"NDVI"}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                NDVI
                              </option>
                              <option
                                defaultValue={"NDMI"}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                NDMI
                              </option>
                              <option
                                defaultValue={"NDWI"}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                NDWI
                              </option>
                              <option
                                defaultValue={"LAI"}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                LAI
                              </option>
                              <option
                                defaultValue={"SAVI"}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                SAVI
                              </option>
                            </Select>
                          </FormControl>
                          {/* </MapControlEachInput> */}
                        </Grid>
                        <Grid item lg={6} xs={6} md={6} sm={6}>
                          <FormControl
                            variant="outlined"
                            style={{
                              width: "100%",
                              color: "white",
                              border: "1px solid rgba(255,255,255,0.3)",
                              borderRadius: "0.3em",
                            }}
                          >
                            <Space direction="vertical" size={12}>
                              <RangePicker
                                style={{
                                  border: "none",
                                  color: "white !important",
                                  height: "3.5em",
                                  background: "rgba(1, 28, 20, 1)",
                                }}
                                ranges={{
                                  Today: [moment(), moment()],
                                  "This Month": [
                                    moment().startOf("month"),
                                    moment().endOf("month"),
                                  ],
                                }}
                                defaultValue={[
                                  moment(startDate1, "YYYY/MM/DD"),
                                  moment(endDate1, "YYYY/MM/DD"),
                                ]}
                                onChange={onChange1}
                              />
                            </Space>
                          </FormControl>
                        </Grid>

                        <Grid item lg={12} xs={12} md={12} sm={12}>
                          <MapDates
                            setChangeDates={setChangeDates1}
                            startDate={startDate1}
                            handleStartDate={setStartDate1}
                            handleEndDate={setEndDate1}
                            handleCloudCover={handleCloud1}
                            dates={dates1}
                            setIsCloudPresent={setIsCloudPresent1}
                            selDateType={"left-screen"}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} xs={12} md={6} sm={6}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginTop: "0.5em",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "95%",
                        }}
                      >
                        <Grid
                          item
                          lg={6}
                          xs={12}
                          md={6}
                          sm={6}
                          style={{ display: "none" }}
                        >
                          {/* --- Import the Cloud Cover controller */}
                          <CloudControl
                            cloudCover={cloudCover2}
                            handleCloud={handleCloud2}
                            // setIsMapLoading={props?.setIsMapLoading}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xs={6} sm={6}>
                          {/* <MapControlEachInput> */}
                          <FormControl
                            variant="outlined"
                            style={{
                              width: "100%",
                              color: "white",
                              border: "1px solid rgba(255,255,255,0.3)",
                              borderRadius: "0.3em",
                            }}
                          >
                            <InputLabel
                              htmlFor="outlined-age-native-simple"
                              style={{ color: "white" }}
                            >
                              Index
                            </InputLabel>
                            <Select
                              native
                              defaultValue={null}
                              name="index"
                              label="Index"
                              onChange={(e) => handleIndex2(e.target.value)}
                              style={{
                                border:
                                  "1px solid rgba(255,255,255,1) !important",
                                color: "white",
                                height: "3em",
                              }}
                            >
                              {/* <option aria-label="None" value="" /> */}
                              <option
                                defaultValue={null}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                Choose Index
                              </option>
                              <option
                                defaultValue={"NDVI"}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                NDVI
                              </option>
                              <option
                                defaultValue={"NDMI"}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                NDMI
                              </option>
                              <option
                                defaultValue={"LAI"}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                LAI
                              </option>
                              <option
                                defaultValue={"SAVI"}
                                style={{ color: "rgba(1, 28, 20, 1)" }}
                              >
                                SAVI
                              </option>
                            </Select>
                          </FormControl>
                          {/* </MapControlEachInput> */}
                        </Grid>
                        <Grid item lg={6} xs={6} md={6} sm={6}>
                          <FormControl
                            variant="outlined"
                            style={{
                              width: "100%",
                              color: "white",
                              border: "1px solid rgba(255,255,255,0.3)",
                              borderRadius: "0.3em",
                            }}
                          >
                            <Space direction="vertical" size={12}>
                              <RangePicker
                                style={{
                                  border: "none",
                                  color: "white !important",
                                  height: "3.5em",
                                  background: "rgba(1, 28, 20, 1)",
                                }}
                                ranges={{
                                  Today: [moment(), moment()],
                                  "This Month": [
                                    moment().startOf("month"),
                                    moment().endOf("month"),
                                  ],
                                }}
                                defaultValue={[
                                  moment(startDate2, "YYYY/MM/DD"),
                                  moment(endDate2, "YYYY/MM/DD"),
                                ]}
                                onChange={onChange2}
                              />
                            </Space>
                          </FormControl>
                        </Grid>

                        <Grid item lg={12} xs={12} md={12} sm={12}>
                          <MapDates
                            handleStartDate={setStartDate2}
                            handleEndDate={setEndDate2}
                            dates={dates2}
                            setChangeDates={setChangeDates2}
                            handleCloudCover={handleCloud2}
                            setIsCloudPresent={setIsCloudPresent2}
                            selDateType={"right-screen"}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </SatelliteBottom>
              </Grid>
            </Grid>
          </SplitViewScreen>

          {/* --- To disable View for mobile ---- */}
          <DisableMobileView />
        </SatelliteContainer>
      </DashboardContainer>
    </>
  );
};

export default SplitView;
