import React, { useEffect } from 'react';
import DashboardLayout from "../Layout/DashboardLayout";
import { SubscriptionContainer } from "./Subscription.styled";
import { Col, Row } from "antd";
import SubscriptionCards from "./SubscriptionCards";
import { connect } from "react-redux";
import {
    CreateAccountPayment,
    GetAllUSubscriptionPlan,
    GetOneUSubscriptionPlan
} from "../../redux/actions/subscriptionAction";
import { toast } from "react-toastify";

const UserSubscriptionPlans = (props) => {
    const {
        GetAllUSubscriptionPlan,
        all_sub_plans,
        payment_resp,
        GetOneUSubscriptionPlan,
        CreateAccountPayment,
        single_sub,
    } = props;

    useEffect(() => {
        const getPlans = async () => {
            const res = await GetAllUSubscriptionPlan();
            if (!res.success) {
                toast.error(res.message);
            }
        };
        getPlans();
    }, [GetAllUSubscriptionPlan]);

    return (
        <DashboardLayout pageTitle="Subscription">
            <SubscriptionContainer data-aos="zoom-in">
                <div className="subhead">
                    <h3>Our Pricing & Plans</h3>
                    <h4>Here are the list of available subscriptions</h4>
                </div>

                <section className="subcard">
                    <Row gutter={19} justify="space-around">
                        {
                            all_sub_plans?.length > 0 ? all_sub_plans?.map((plan) => {
                                return (
                                    <Col xs={24} xl={6} lg={6} key={plan.id}>
                                        <SubscriptionCards
                                            plan={plan}
                                            payment_resp={payment_resp}
                                            CreateAccountPayment={CreateAccountPayment}
                                            GetOneUSubscriptionPlan={GetOneUSubscriptionPlan}
                                            single_sub={single_sub}
                                        />
                                    </Col>
                                )
                            }) : (
                                    <>
                                        <div>No Plan Configured</div>
                                    </>
                                )
                        }
                    </Row>
                </section>
            </SubscriptionContainer>
        </DashboardLayout>
    )
}

const mapStateToProps = (state) => {
    return {
        all_sub_plans: state.subscriptionReducer.all_sub_plans,
        payment_resp: state.subscriptionReducer.payment_resp,
        single_sub: state.subscriptionReducer.single_sub,
    }
}

export default connect(mapStateToProps, {
    CreateAccountPayment,
    GetAllUSubscriptionPlan,
    GetOneUSubscriptionPlan,
})(UserSubscriptionPlans);
