import React, { useState, useEffect } from 'react';
import DefaultLayout from '../Layout/DefaultLayout';
import { ManageUserContainer } from './AdminStyle.styled';
import {Table, Tag} from 'antd';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllAccountSubscriptions } from "../../../redux/actions/adminOtherServices";
import {formatDateObjectHandler, GetFromNowDate} from "../../../utils";

const AdminSubscriptionRecord = props => {
    const { all_account_subscription, GetAllAccountSubscriptions } = props;
    const [loading, setloading] = useState(false);

    useEffect(() => {
        const getAllRecords = async () => {
            setloading(true);
            const res = await GetAllAccountSubscriptions();
            setloading(false);
            if (!res.success) {
                setloading(false);
                toast.error(res.message, {
                    pauseOnHover: true,
                    closeOnClick: true,
                });
            }
        };
        getAllRecords();
    }, [GetAllAccountSubscriptions]);

    const subCol = [
        {
            title: 'User Limit',
            dataIndex: 'no_users_limit',
        },
        {
            title: 'Farm Area Limit',
            dataIndex: 'farm_area_limit',
        },
        {
            title: 'Start date',
            dataIndex: 'start_date',
            render: (_text, record) => (
                <>
                    {formatDateObjectHandler(record.start_date, 'DD-MM-YYYY')} <br/>
                    <small style={{ color: 'green' }}>
                        ({GetFromNowDate(record.start_date, 'YYYY-MM-DDTHH:mm:ss', 'fromNow')})
                    </small>
                </>
            )
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            render: (_text, record) => (
                <>
                    {formatDateObjectHandler(record.end_date, 'DD-MM-YYYY')} <br/>
                    <small style={{ color: 'blue' }}>
                        ({GetFromNowDate(record.end_date, 'YYYY-MM-DDTHH:mm:ss', 'fromNow')})
                    </small>
                </>
            )
        },
        {
            title: 'Is Active?',
            dataIndex: 'is_active',
            align: 'center',
            render: (_text, record) => <>{
                record.is_active === null ?
                    <Tag color="gray">Unknown</Tag> :
                    record.is_active === false ?
                        <Tag color="red">No</Tag> :
                        <Tag color="green">Yes</Tag>
            }</>
        },
        {
            title: 'Plan',
            dataIndex: 'plan',
        },
        {
            title: 'Payment',
            dataIndex: 'payment',
            align: 'center',
        },
        {
            title: 'Date Created',
            dataIndex: 'date_created',
            align: 'center',
            render: (_text, record) => (
                <>
                    {formatDateObjectHandler(record.date_created, 'DD-MM-YYYY')} <br/>
                    <small style={{ color: 'green' }}>
                        ({GetFromNowDate(record.date_created, 'YYYY-MM-DDTHH:mm:ss', 'fromNow')})
                    </small>
                </>
            )
        },
    ];

    return (
        <DefaultLayout browserTitle="Account Subscription">
            <ManageUserContainer data-aos="fade-right">
                <h3>Subscription Records</h3>
                <Table loading={loading} style={{ overflowX: 'auto' }} rowKey="id" columns={subCol} dataSource={all_account_subscription} />
            </ManageUserContainer>
        </DefaultLayout>
    );
};

const mapStateToProps = state => {
    return {
        all_account_subscription: state.adminOtherReducer.all_account_subscription,
    };
};

export default connect(mapStateToProps, {
    GetAllAccountSubscriptions,
})(AdminSubscriptionRecord);
