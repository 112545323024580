import styled from "styled-components";

export const WeatherContainer = styled.div`
  height: fit-content;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // background:rgba(31, 40, 71, 0.0005);
  /* margin-top: 1.5em; */
  display: flex;
  justify-content: space-between;
  /* border: 0.5px solid rgba(0, 106, 75, 1); */
  // font-family: Avenir Next;
  // font-style: normal;
`;

export const WeatherLeftContainer = styled.section`
  width: 20%;
  background: rgba(0, 106, 75, 1);
  // background:rgba(255,255,255,1);
  display: none;
  @media screen and (max-width: 769px) {
    width: 15%;
  }
`;

export const WeatherRightContainer = styled.section`
  width: 100%;
  // margin-left:2em;
  // margin-right:2em;
  /* width: 100%; */
  /* margin-left: 5em; */
  /* margin-right: 2em; */
  @media screen and (max-width: 769px) {
    width: 100%;
    // margin-left: 1em;
    // margin-right: 1em;

    // margin-right: 2.5%;
  }
`;

export const EachCard = styled.div`
  height: 10em;
  width: 100%;
  // background: rgba(31, 40, 71, 0.1);
  background: rgba(255, 255, 255, 0.8);
  border: 0.1px solid rgba(255, 255, 255, 1);
  //   box-shadow: 0px 1px 5px rgba(255, 255, 255, 0.1),
  //     0px 1px 20px rgba(0, 0, 0, 0.05), 0px -2px 20px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  margin-bottom: -0.5em;
`;

export const WeatherTitle = styled.h4`
  color: rgba(5, 5, 5, 0.9);
  margin-top: 1.5em;
  font-weight: bold;
  margin-bottom: 1em;
  @media screen and (max-width: 769px) {
    font-size: 1.2em;
  }
`;

export const ForeCastCard = styled.div`
  height: fit-content;
  width: 100%;
  // background: rgba(31, 40, 71, 0.1);
  background: rgba(255, 255, 255, 0.8);
  border: 0.1px solid rgba(255, 255, 255, 1);
  border-radius: 2px;
  margin-bottom: -0.5em;
  display: flex;
  justify-content: space-between;
  font-family: Avenir Next;
  font-style: normal;
  @media screen and (max-width: 769px) {
    display: block;
    height: fit-content;
  }
`;

export const ForeCastLeft = styled.section`
  width: 58%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
`;

export const ForeCastRight = styled.section`
  width: 38%;
  height: 6em;
  height: fit-content;
  @media screen and (max-width: 769px) {
    width: 100%;
    margin-top: 1em;
  }
`;

export const ForeCastLeftInnerLeft = styled.div`
  width: 50%;
  display: flex;
  @media screen and (max-width: 1025px) {
    width: 55%;
  }
  @media screen and (max-width: 769px) {
    width: 45%;
  }
`;

export const ForeCastLeftInnerRight = styled.div`
  width: 40%;
  display: flex;
  @media screen and (max-width: 769px) {
    width: 50%;
    margin-right: 1%;
  }
`;

export const ForeCastLeftInnerLeftBox = styled.div`
  width: 10em;
  @media screen and (max-width: 769px) {
    width: 50%;
  }
`;

// export const ForeCastLeftInnerRightBox
export const ForeCastLeftInnerDate = styled.h5`
  margin-top: 1em;
  margin-left: 1.5em;
  color: rgba(5, 5, 5, 0.9);
  font-size: 1em;
  font-weight: bold;
  font-family: Avenir Next;
  font-style: normal;
  line-height: 25px;
  color: #011c14;
  @media screen and (max-width: 769px) {
    font-size: 0.9em;
    margin-left: 1em;
  }
`;

export const ForeCastLeftInnerImg = styled.img`
  width: 5em;
  height: 5em;
  border-radius: 50px;
  margin-top: 0.5em;
  margin-left: 1.5em;
  @media screen and (max-width: 1025px) {
    width: 4em;
    height: 4em;
  }
  @media screen and (max-width: 769px) {
    width: 3em;
    height: 3em;
    margin-left: 1em;
  }
`;

export const ForeCastLeftInnerMiddleBox = styled.div`
  width: 8em;
  margin-left: 1em;
  @media screen and (max-width: 769px) {
    width: 50%;
    margin-left: 0.5em;
  }
`;

export const ForeCastLeftInnerDayType = styled.h5`
  margin-top: 2em;
  text-align: center;
  color: rgba(5, 5, 5, 0.9);
  font-size: 1em;
  font-weight: normal;
  @media screen and (max-width: 769px) {
    font-size: 0.9em;
  }
`;

export const ForeCastLeftInnerDayNo = styled.h5`
  margin-top: 0em;
  text-align: center;
  color: rgba(5, 5, 5, 1);
  font-size: 4em;
  position: relative;
  @media screen and (max-width: 1025px) {
    font-size: 3em;
  }
  @media screen and (max-width: 769px) {
    font-size: 2em;
  }
  .deg {
    position: absolute;
    width: 12px;
    height: 12px;
    background: #fff;
    right: 10px;
    top: 0;
    border: 2px solid #000;
    border-radius: 50px;
  }
`;

export const ForeCastLeftInnerRightLeftBox = styled.div`
  width: 50%;
  margin-top: 1.3em;
`;

export const ForeCastLeftInnerRightRightBox = styled.div`
  width: 50%;
  margin-top: 1.3em;
  text-align: right;
  margin-right: 1em;
`;

export const ForeCastType = styled.h5`
  color: rgba(5, 5, 5, 0.7);
  font-size: 1em;
  line-height: 1.5em;
  @media screen and (max-width: 1025px) {
    font-size: 0.95em;
  }
  @media screen and (max-width: 769px) {
    font-size: 0.9em;
  }
`;

export const ForeCastVal = styled.h5`
  color: rgba(5, 5, 5, 1);
  // font-weight: bold;
  font-size: 1em;
  line-height: 1.5em;
  @media screen and (max-width: 1025px) {
    font-size: 0.95em;
  }
  @media screen and (max-width: 769px) {
    font-size: 0.9em;
  }
`;

export const ForeCastText = styled.p`
  color: rgba(0, 106, 75, 1);
  margin-top: 1em;
  font-size: 0.8em;
  font-weight: 400;
  line-height: 2em;
  padding-right: 20px;
  @media screen and (max-width: 769px) {
    margin-left: 1em;
  }
`;

export const ForeCastTopCard = styled.div`
  height: 4em;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  // background: rgba(31, 40, 71, 0.1);
  background: rgba(255, 255, 255, 0.8);
  /* border: 0.1px solid rgba(255, 255, 255, 1); */
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  transition: ease all 0.5s;
  // margin-bottom: -0.5em;
  // margin-top: 1em;
  /* border-bottom: 2px solid rgba(5, 5, 5, 0.1); */
`;

export const ForeCastTopCardText = styled.h6`
  /* margin-top: 1.5em; */
  color: rgba(0, 106, 75, 1);
  margin-bottom: 0;
  font-weight: bold;
  /* margin-left: 1em; */
  // font-weight:bold
`;

export const ForeCastBodyCard = styled.div`
  height: fit-content;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // background: rgba(31, 40, 71, 0.1);
  background: rgba(255, 255, 255, 0.8);
  border: 0.1px solid rgba(255, 255, 255, 1);
  border-radius: 3px;
  margin-bottom: 1.5em;
  @media screen and (max-width: 769px) {
    // width: 95%;
  }
`;

export const ForeCastBody2Card = styled.div`
  height: 6em;
  width: 100%;
  // background: rgba(31, 40, 71, 0.1);
  background: rgba(255, 255, 255, 0.8);
  border: 0.1px solid rgba(255, 255, 255, 1);
  border-radius: 2px;
  margin-top: 0em;

  // overflow-x:
`;

export const EachForeCast2Inner = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 5em;
  text-align: center;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 769px) {
    width: 95%;
  }
`;

export const EachForeCast2InnerCard = styled.div`
  width: 2.5em;
  height: 5em;
  text-align: center;
`;

export const EachForesCast2InnerIcon = styled.img`
  width: 1.5em;
  height: 1.5em;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  // background: rgba(0, 106, 75, 1);
`;

export const EachForesCast2InnerText = styled.h6`
  color: rgba(5, 5, 5, 0.9);
  margin-left: auto;
  margin-right: auto;
  font-size: 0.9em;
  margin-top: 0.5em;
  // font-weight: bold;
`;

export const TableItemTitle = styled.h3`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: rgba(1, 28, 20, 0.78);
`;

export const TableItemValue = styled.h3`
  // font-family: Avenir Next;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9em;
  line-height: 25px;
  // color: #011C14;
  color: rgba(5, 5, 5, 0.8);
`;

export const ForeCastIcon = styled.img`
  width: 4em;
  height: 4em;
  border-radius: 50px;
  margin-top: 0.5em;
  @media screen and (max-width: 1025px) {
    width: 4em;
    height: 4em;
  }
  @media screen and (max-width: 769px) {
    width: 3em;
    height: 3em;
    margin-left: 1em;
  }
`;

// --- history part ---

export const HistoryChartCard = styled.div`
  height: fit-content;
  height: 30em;
  // margin-bottom:1em;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  background: rgba(1, 28, 20, 1);
  border: 0.1px solid rgba(5, 5, 5, 0.1);
  border-radius: 2px;
  margin-top: 0em;
`;

export const HistoryCalendarCard = styled.div`
  height: 6em;
  height: fit-content;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border: 0.1px solid rgba(255, 255, 255, 1);
  border-radius: 7px;
  margin-bottom: -0.5em;
  margin-top: 2em;
`;

export const HistoryCalendarCardInner = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const HistoryCalendarBox = styled.div`
  width: 100%;
  // margin-top:1em;
  @media screen and (max-width: 769px) {
    margin-bottom: -0.5em;
  }
`;

export const HistoryCalendarPickDate = styled.input`
  width: 100%;
  border: 1px solid rgba(5, 5, 5, 0.3);
  border: 1px solid rgba(0, 106, 75, 1);
  color: rgba(5, 5, 5, 0.5);
  height: 2.5em;
  border-radius: 0.3em;
  padding: 0 20px;
  &:hover {
    border: 1px solid rgba(0, 106, 75, 1);
  }
`;

export const HistoryCalendarTitle = styled.h6`
  color: rgba(5, 5, 5, 0.7);
  @media screen and (max-width: 768px) {
    font-size: 1em;
  }
`;

export const HistoryBtn = styled.button`
  height: 2.5em;
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  border: none;
  width: 100%;
  margin-top: 1.9em;
  transition: ease all 0.5s;
  margin-bottom: 1em;
  cursor: pointer;
  background: rgba(0, 106, 75, 1);
  outline: none;
  border-radius: 0.3em;
  transition: 0.7s background, 0.7s border, 0.7s color;
  &:hover {
    background: white;
    border: 1px solid rgba(0, 106, 75, 1);
    color: rgba(0, 106, 75, 1);
  }
`;

export const HistoryDownloadBox = styled.div`
  width: 100%;
  // margin-bottom: 5.5em;
  // margin-top:-2em;
  //  background:red;
  cursor: pointer;
  height: 4em;
`;

// weather and history error handler

// export const WeatherErrorCard = styled.section``;
