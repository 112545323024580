import { Grid } from "@material-ui/core";
import SkeletonCard from "../../adapters/util/SkeletonCard";
import {
  FarmInfoCard,
  FarmInfoWeather,
  FarmSideBarIcon,
  FarmSideBarThirdCardDate,
  FarmSideBarThirdCardDay,
  FarmSideBarThirdCardInner,
  FarmSideBarThirdCardSeason,
  FarmSideBarThirdCardWeatherIcon,
  FarmSideBarThirdCardWeatherType,
  FarmSideBarThirdCardWeatherVal,
} from "../../styles/farmManagement/FarmSummaryStyle";

import windIcon from "../../assets/icon/weather/Vector.png";
import preIcon from "../../assets/icon/weather/Vector2.png";
import { formatDate } from "../util/Util";

const TodayWeather = (props) => {
  return (
    <>
      <FarmInfoCard
        style={{
          marginTop: "0em",
          height: "21.5em",
          marginBottom: "2em",
          background: "transparent",
        }}
      >
        <FarmInfoWeather style={{ marginTop: "0em" }}>
          {/* <FarmSummaryTitle>WEATHER TODAY</FarmSummaryTitle> */}
          {props?.stillFetching1 ? (
            <SkeletonCard height={"21.5em"} />
          ) : (
            <FarmSideBarThirdCardInner>
              <Grid container spacing={4} key={1}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={4}
                  sm={4}
                  key={"seasonDate"}
                  style={{ textAlign: "center" }}
                >
                  <FarmSideBarThirdCardSeason>
                    {props?.weatherData?.indicator[0]}
                  </FarmSideBarThirdCardSeason>
                  <FarmSideBarThirdCardDate>
                    {formatDate(props?.weatherData?.date[0])}
                  </FarmSideBarThirdCardDate>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={4}
                  sm={4}
                  key={"seasonImg"}
                  style={{ textAlign: "center" }}
                >
                  <FarmSideBarIcon
                    src={props?.weatherData?.weatherUrlIcon[0]}
                    alt=""
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={4}
                  sm={4}
                  key={"seasonDay"}
                  style={{ textAlign: "center" }}
                >
                  <FarmSideBarThirdCardDay>
                    {props?.weatherData?.date[0]?.substr(8, 9)}
                  </FarmSideBarThirdCardDay>
                </Grid>
              </Grid>

              <Grid container spacing={4} key={2} style={{ marginTop: "2em" }}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={4}
                  sm={4}
                  key={"weatherTypes1"}
                  style={{ textAlign: "center" }}
                >
                  <FarmSideBarThirdCardWeatherType>
                    Precipitation
                  </FarmSideBarThirdCardWeatherType>
                  <FarmSideBarThirdCardWeatherIcon src={preIcon} />
                  <FarmSideBarThirdCardWeatherVal>
                    {props?.weatherData?.precipitation[0] + " mm"}
                  </FarmSideBarThirdCardWeatherVal>
                </Grid>

                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={4}
                  sm={4}
                  key={"weatherTypes2"}
                  style={{ textAlign: "center" }}
                >
                  <FarmSideBarThirdCardWeatherType>
                    Wind
                  </FarmSideBarThirdCardWeatherType>
                  <FarmSideBarThirdCardWeatherIcon src={windIcon} />
                  <FarmSideBarThirdCardWeatherVal>
                    {props?.weatherData?.windSpeed[0] + " m/s"}
                  </FarmSideBarThirdCardWeatherVal>
                </Grid>

                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={4}
                  sm={4}
                  key={"weatherTypes3"}
                  style={{ textAlign: "center" }}
                >
                  <FarmSideBarThirdCardWeatherType>
                    Humidity
                  </FarmSideBarThirdCardWeatherType>
                  <FarmSideBarThirdCardWeatherIcon src={windIcon} />
                  <FarmSideBarThirdCardWeatherVal>
                    {props?.weatherData?.humidity[0] + " %"}
                  </FarmSideBarThirdCardWeatherVal>
                </Grid>
              </Grid>
            </FarmSideBarThirdCardInner>
          )}
        </FarmInfoWeather>
      </FarmInfoCard>
    </>
  );
};

export default TodayWeather;
