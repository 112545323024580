import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../buttons/button';

// import the package for Drag & Drop here
import { DropzoneArea } from 'material-ui-dropzone';

import { createField } from '../../redux/actions/fieldManagement';
import './dashBoard.css';
import { AppContext } from '../../appRouter';

const AddFieldForm = (props) => {

    const { handleSelectedCentroid, handleSelectedCoords } = useContext(AppContext);

    // const { access_token: access_key, user_details: { owned_team: { id } } } = useSelector(state => state.authReducer);
    const { access_token: access_key, user_details: { owned_team, user_team } } = useSelector(state => state.authReducer);
    const id = owned_team !== null ? owned_team?.id : user_team !== null ? user_team : null

    const [fieldData, setFieldData] = useState("");
    const [upload1, setUpload1] = useState({ text: 'Upload', status: true });

    const handleSubmitField = async (e) => {

        // if (fieldData[0]?.type.substr(0, 11) === "application") {
        setUpload1({ text: 'Please wait...', status: false });
        toast.info('Please wait...while we process your file', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        let getResp = await createField(fieldData[0], { access_key, id });
        let { isError, data, errorMessage } = getResp;

        if (isError) {

            setUpload1({ text: 'Upload', status: true })

            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {

            setUpload1({ text: 'Upload', status: true });

            // update the base polygon shape
            handleSelectedCentroid(data.centroid);
            handleSelectedCoords(data.coordinates);

            props?.setFormBoxes({ open: false });

            // history.go(0);

            toast('Successfully uploaded field file', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        // } else {
        //     toast.warning('Please, select a valid geojson, kml or shape file to upload', {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        // }

    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="row">
                <div className="col-md-12 mb-12">
                    <div className="add_field_type_cards text-center d-flex align-items-center justify-content-center">
                        <div className="px-4">
                            <div>
                                <DropzoneArea
                                    dropzoneText='Drag-and-drop here or select a json file with the field contours for the upload to start'
                                    onChange={(files) => setFieldData(files)}
                                    dropzoneClass='dragAndDrop'
                                    dropzoneParagraphClass="dragAndDropText"
                                    filesLimit={1}
                                // acceptedFiles={['application/geo+json']}
                                />
                                {/* <img src={uploadIcon} alt="..." /> */}
                                <div className="addField_sub_card_headers my-2">Upload Fields</div>
                            </div>
                            <div className="mt-2 mb-3">
                                <p className="addField_sub_card_p">Drag-and-drop here or select files with the field contours for the upload to start</p>
                            </div>

                            <div className="position-relative">
                                <Button onClick={handleSubmitField} disabled={!upload1?.status}>{upload1?.text}</Button>
                                <small className="file_support_text">Supported formats are: .shp, .kml, .json</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddFieldForm;