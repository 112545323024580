import React, { useState } from 'react';
import { PaymentContainer, SubscriptionCardContainer } from "./Subscription.styled";
import { Button, Col, Modal, Row, Tag } from "antd";
import { CurrencySign, findAllTotal, formatAmount } from "../../utils";
import { Check } from "@material-ui/icons";
import { PaystackButton } from 'react-paystack'
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import successMark from '../../assets/img/successMark.png';
import { useHistory } from "react-router-dom";

const SubscriptionCards = (props) => {
    const { plan, GetOneUSubscriptionPlan, single_sub } = props;
    const [paymentmodal, setpaymentmodal] = useState(false);
    const [getready, setready] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [paymentcompleted, setpaymentcompleted] = useState(false);
    const [requestloading, setrequestloading] = useState(false);
    const [stackrecord, setstackrecord] = useState({});
    const [paystackres, setpaystackres] = useState({});
    const [totalAll, settotalAll] = useState('0');
    const [checkfarm, setcheckfarm] = useState(false);
    const [checkuser, setcheckuser] = useState(false);
    const { user_details: userdetails } = useSelector(
        (state) => state.authReducer
    );
    const history = useHistory();
    // let getrecord = {}
    let checkField = [];

    const StartModal = async (record) => {
        setready(true);
        const res = await GetOneUSubscriptionPlan(record.id);
        if (res.success) {
            setready(false);
            setpaymentmodal(true);
            // getrecord = single_sub;
            checkField = res?.services_only?.map((ser) => ser.service);
            setcheckuser(checkField?.includes('users'));
            setcheckfarm(checkField?.includes('farm_area'));
        }
    };

    const componentProps = {
        email: userdetails?.email,
        name: userdetails?.first_name + '  ' + userdetails?.last_name,
        amount: totalAll * 100,
        publicKey: 'pk_test_34b61dc3713d63a5e388ca5a3f03c0418a81b2fc',
        metadata: {
            name: userdetails?.first_name + '  ' + userdetails?.last_name,
            custom_fields: [
                {
                    value: "makurdi",
                    first_name: userdetails?.first_name,
                    last_name: userdetails?.last_name,
                    display_name: `Subscription Payment for ${single_sub?.name}`,
                    variable_name: "donation_for",
                    no_of_users: parseInt(stackrecord?.no_of_user),
                    plan_id: single_sub?.id,
                    farm_area: parseInt(stackrecord?.no_of_farm),
                    amount: totalAll,
                }
            ]
        },
        text: "Continue Payment",
        onSuccess: (reference) => {
            setpaystackres(reference);
            setpaymentcompleted(true);
            setTimeout(() => {
                history.push('/dashboard');
            }, 8000);
            toast.success(`Transaction Successful: ${reference?.reference} Thanks for doing business with us! Come back soon!!.`);
        },
        onError: (err) => {
            toast.error(`${err?.response?.data?.message || 'Something went wrong'}`);
        },
        // onClose: () =>  toast.info("Wait! Don't leave :("),
    };

    const { handleSubmit, register, formState: { errors }, reset } = useForm({
        defaultValues: {
            no_of_user: '0',
            no_of_farm: '0'
        }
    });

    const getTotalPrice = plan?.services.map((ser) => ser.amount_per_unit);

    const getFarmAmt = single_sub?.services?.filter((ser) => ser.service === 'farm_area')?.[0]?.amount_per_unit || '0';
    const getUser = single_sub?.services?.filter((ser) => ser.service === 'users')?.[0]?.amount_per_unit || '0';
    const getOthersServ = single_sub?.services?.filter((ser) => ser.service !== 'farm_area' && ser.service !== 'users');
    const getOthers = findAllTotal(getOthersServ?.map((other) => other.amount_per_unit));

    const confirmValues = (data) => {
        const userunit = (parseFloat(getUser) * parseFloat(data.no_of_user));
        const checkUserNAN = isNaN(userunit) ? 0 : userunit;
        const farmunit = (parseFloat(getFarmAmt) * parseFloat(data.no_of_farm));
        const checkFarmNAN = isNaN(farmunit) ? 0 : farmunit
        const getAll = (checkUserNAN + checkFarmNAN + parseFloat(getOthers));
        if (isNaN(getAll)) {
            settotalAll('0');
            return;
        }
        settotalAll(getAll);
        setstackrecord(data);
        setrequestloading(true);
        setTimeout(() => {
            setrequestloading(false);
            setConfirm(true);
        }, 800);
    }

    const closePayment = () => {
        setpaymentmodal(false);
        setrequestloading(false);
        setConfirm(false);
        settotalAll('0');
        reset();
    }

    const resetAllValues = () => {
        setrequestloading(false);
        setConfirm(false);
        settotalAll('0');
        reset();
    }

    return (
        <SubscriptionCardContainer>
            <div>
                <div className="head">
                    <h3>{plan?.name}</h3>
                    <h2><span className="head__sup">{CurrencySign}</span>{formatAmount(findAllTotal(getTotalPrice))}</h2>
                    <p>per {plan?.unit} Hectare <span className="toCap">{plan?.duration}</span></p>
                </div>
                <div className="features">
                    <ul>
                        {plan?.services?.length > 0 ? plan?.services?.map((ser) => {
                            return (
                                <li key={ser.id} className="toCap"><Check /> {ser?.service?.replace('_', ' ')}</li>
                            )
                        }) : (
                                <li><Tag style={{ textAlign: 'center' }} color="gray">FREE VERSION</Tag></li>
                            )}
                    </ul>
                    <ul>
                        <li><Check /> {plan?.min_users_in_team_allocated} Min User </li>
                        <li><Check /> {plan?.max_users_in_team_allocated} Max User </li>
                        <li><Check /> {plan?.min_farm_area_allocated} Min Farm Allocated </li>
                        <li><Check /> {plan?.max_farm_area_allocated} Max Farm Allocated </li>
                    </ul>
                </div>

                <Button loading={getready} className="subbtn" onClick={() => StartModal(plan)}>Get Started</Button>

                <Modal
                    title={`Payment Details for ${single_sub?.name}`}
                    visible={paymentmodal}
                    footer={null}
                    maskClosable={false}
                    onCancel={closePayment}

                    // --- I added this so that the modal can overlay properly on mobile views
                    zIndex={"10000"}
                    style={{ marginTop: '-4em' }}
                >
                    {
                        !paymentcompleted ? (
                            <>
                                <div className="paymentfirst">
                                    <h3>Please, Complete your order </h3>
                                    <form>
                                        {
                                            single_sub?.services?.map((res) => {
                                                return (
                                                    <div key={res?.id} className="item">
                                                        <Row justify="space-between">
                                                            <Col>
                                                                <span className="toCap">{res?.service?.replace('_', ' ')}</span>
                                                            </Col>
                                                            <Col>{CurrencySign}{formatAmount(res?.amount_per_unit)} / Unit</Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                        {checkuser && (
                                            <>
                                                <div className="item">
                                                    <Row justify="space-between">
                                                        <Col>
                                                            <span>Min User Per Hectare</span>
                                                        </Col>
                                                        <Col>{single_sub?.min_users_in_team_allocated}</Col>
                                                    </Row>
                                                </div>
                                                <div className="item">
                                                    <Row justify="space-between">
                                                        <Col>
                                                            <span>Max. Users Per Hectare</span>
                                                        </Col>
                                                        <Col>{single_sub?.max_users_in_team_allocated}</Col>
                                                    </Row>
                                                </div>
                                            </>
                                        )}
                                        {checkfarm && (
                                            <>
                                                <div className="item">
                                                    <Row justify="space-between">
                                                        <Col>
                                                            <span>Min Farm Allocated Per Hectare</span>
                                                        </Col>
                                                        <Col>{single_sub?.min_users_in_team_allocated}</Col>
                                                    </Row>
                                                </div>
                                                <div className="item">
                                                    <Row justify="space-between">
                                                        <Col>
                                                            <span>Max. Farm Allocated Per Hectare</span>
                                                        </Col>
                                                        <Col>{single_sub?.max_users_in_team_allocated}</Col>
                                                    </Row>
                                                </div>
                                            </>
                                        )}
                                        <div className="item">
                                            <Row gutter={28} justify="space-between">
                                                {checkuser && (
                                                    <Col xs={12} xl={12} lg={12}>
                                                        <label>Number of Users (Between {single_sub?.min_users_in_team_allocated} - {single_sub?.max_users_in_team_allocated})</label>
                                                        <input
                                                            className="ant-input"
                                                            name="no_of_user"
                                                            placeholder="Enter unit of users"
                                                            required
                                                            type="number"
                                                            min={single_sub?.min_users_in_team_allocated}
                                                            max={single_sub?.max_users_in_team_allocated}
                                                            {...register('no_of_user',
                                                                {
                                                                    required: true,
                                                                    min: single_sub?.min_users_in_team_allocated,
                                                                    max: single_sub?.max_users_in_team_allocated
                                                                })
                                                            }
                                                            onBlur={handleSubmit(confirmValues)}
                                                        />
                                                        {errors.no_of_user && errors.no_of_user === 'required' && <small role="alert" className="error">Required</small>}
                                                        {errors.no_of_user && errors.no_of_user.type === 'min' && <small role="alert" className="error">Lower than range</small>}
                                                        {errors.no_of_user && errors.no_of_user.type === 'max' && <small role="alert" className="error">Value out of range</small>}
                                                    </Col>
                                                )}
                                                {checkfarm && (
                                                    <Col xs={12} xl={12} lg={12}>
                                                        <label>Number of Farms (Between {single_sub?.min_farm_area_allocated} - {single_sub?.max_farm_area_allocated})</label>
                                                        <input
                                                            className="ant-input"
                                                            name="no_of_farm"
                                                            placeholder="Enter unit of farms"
                                                            required
                                                            type="number"
                                                            min={single_sub?.min_farm_area_allocated}
                                                            max={single_sub?.max_farm_area_allocated}
                                                            {...register('no_of_farm',
                                                                {
                                                                    required: true,
                                                                    min: single_sub?.min_farm_area_allocated,
                                                                    max: single_sub?.max_farm_area_allocated
                                                                })
                                                            }
                                                            onBlur={handleSubmit(confirmValues)}
                                                        />
                                                        {errors.no_of_farm && errors.no_of_farm === 'required' && <small role="alert" className="error">Required</small>}
                                                        {errors.no_of_farm && errors.no_of_farm.type === 'min' && <small role="alert" className="error">Lower than range</small>}
                                                        {errors.no_of_farm && errors.no_of_farm.type === 'max' && <small role="alert" className="error">Value out of range</small>}
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                        <div className="item total">
                                            <Row justify="space-between">
                                                <Col>
                                                    <strong>Total Amount</strong>
                                                </Col>
                                                <Col><strong>{CurrencySign} {formatAmount(totalAll)}</strong></Col>
                                            </Row>
                                        </div>
                                    </form>
                                    <Row gutter={28} justify="end">
                                        {!confirm ?
                                            (
                                                <Col xs={24} xl={8} lg={8}>
                                                    <Button loading={requestloading} onClick={handleSubmit(confirmValues)}>Confirm Request</Button>
                                                </Col>
                                            )
                                            :
                                            (
                                                <Col xs={24} xl={10} lg={10}>
                                                    <PaystackButton {...componentProps} data-aos="fade-up" />
                                                    <small>N:B You are about to subscribe</small>
                                                </Col>
                                            )
                                        }
                                        {totalAll !== '0' && (
                                            <Col xs={24} xl={6} lg={6}>
                                                <Button className="btn-cancel" block onClick={resetAllValues}>Reset</Button>
                                            </Col>
                                        )}
                                        <Col xs={24} xl={6} lg={6}>
                                            <Button className="btn-cancel" block onClick={closePayment}>Cancel</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        ) : (
                                <>
                                    <PaymentContainer data-aos="zoom-in">
                                        <h2>Transaction Successful</h2>
                                        <img src={successMark} alt="success" />
                                        <div className="afterpayment">
                                            <Row gutter={28} align="middle" justify="space-between">
                                                <Col xs={12} xl={12} lg={12}>
                                                    <span>Transaction ID:</span>
                                                </Col>
                                                <Col xs={12} xl={12} lg={12}>
                                                    {paystackres?.reference || '0'}
                                                </Col>
                                            </Row>
                                            <Row gutter={28} align="middle" justify="space-between">
                                                <Col xs={12} xl={12} lg={12}>
                                                    <span>Transaction Status:</span>
                                                </Col>
                                                <Col xs={12} xl={12} lg={12}>
                                                    <Tag color="green">{paystackres?.status || 'Unknown'}</Tag>
                                                </Col>
                                            </Row>

                                            <div style={{ textAlign: 'center', paddingTop: '2rem', fontStyle: 'italic' }}>
                                                <h3>
                                                    <strong>Thank you!!!</strong>
                                                </h3>
                                                <h6 style={{ fontStyle: 'normal', fontWeight: 'lighter' }}>We will redirect you in 8sec or you can refresh to start exploring.</h6>
                                            </div>
                                        </div>
                                    </PaymentContainer>
                                </>
                            )
                    }
                </Modal>
            </div>
        </SubscriptionCardContainer>
    )
}

export default SubscriptionCards;
