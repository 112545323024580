import React, { useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Fade,
    Grid,
    makeStyles,
    Modal
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';

import { deleteACrop, deleteASeason, deleteRotation } from '../../redux/actions/cropPlanDevelopment';
import { useSelector } from 'react-redux';

import { CropPlanButtonBox, CropPlanDelBtn, CropPlanModal, CropPlanModalInner, CropPlanModalTitle } from '../../styles/cropPlan/CropPlanStyles';

const useStyles = makeStyles({
    root: {
        // background: '#eff9f6',
        borderRadius: 3,
        cursor: 'pointer',
        // border: 0,
        color: 'rgba(1, 28, 20, 0.9)',
        // padding: '0 30px',
        // height: '20em',
        border: '1px solid rgba(1, 28, 20, 0.2)',
        // boxShadow: '0 3px 5px 2px rgba(5,5,5, .3)',
        '&::-webkit-scrollbar': {
            width: '6px',
            background: 'red',
            color: 'red'
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        outline: 'none'
    }
});

export const DeleteModal = (props) => {
    const classes = useStyles();
    const [btnState, setBtnState] = useState({ text: 'Yes, Delete', isDisabled: false });

    // --- get the access key of the user here ---
    const {
        access_token: access_key,
    } = useSelector((state) => state.authReducer);

    // handler to delete any of the selected crops
    const handleDelCrop = async () => {

        setBtnState({ text: 'Please wait!', isDisabled: true });

        if (props?.deleteType === 'Crop') {

            let { isError } = await deleteACrop(access_key, props?.selectedCrop);

            if (isError) {

                toast.error('Sorry, an error occurred', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setBtnState({ text: 'Yes, Delete', isDisabled: false });

            } else {

                toast('Crop successfully deleted!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setBtnState({ text: 'Yes, Delete', isDisabled: false });
                // close the modal and refresh
                props?.setRenderData(1);
                props?.setOpen(false);
            }

        } else if (props?.deleteType === 'Season') {

            setBtnState({ text: 'Please wait!', isDisabled: true });


            let { isError } = await deleteASeason(props?.fieldData?.id, props?.seasonCropId, access_key);

            if (isError) {

                toast.error('Sorry, an error occurred', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setBtnState({ text: 'Yes, Delete', isDisabled: false });

            } else {

                toast('Season successfully deleted!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setBtnState({ text: 'Yes, Delete', isDisabled: false });
                // close the modal and refresh
                props?.setRenderData(2);
                props?.setOpen(false);
            }
        } else if (props?.deleteType === 'Rotation') {

            setBtnState({ text: 'Please wait!', isDisabled: true });

            let { isError } = await deleteRotation(access_key, props?.selectedRotation[0]?.id);

            if (isError) {

                toast.error('Sorry, an error occurred', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setBtnState({ text: 'Yes, Delete', isDisabled: false });

            } else {

                toast('Rotation successfully deleted!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setBtnState({ text: 'Yes, Delete', isDisabled: false });
                // close the modal and refresh
                props?.setRenderData(3);
                props?.setOpen(false);

            }
        };

    }

    return (
        <>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props?.open}
                onClose={props?.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
                style={{ border: 'none !important' }}
            >
                <Fade in={props?.open}>
                    <CropPlanModal>
                        <CropPlanModalInner>
                            <Grid container spacing={1}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <CropPlanModalTitle>Are You Sure?</CropPlanModalTitle>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6} style={{ float: 'right' }}>
                                    <CloseIcon onClick={props?.handleClose} style={{ float: 'right', cursor: 'pointer', color: 'rgba(200,0,0,1)' }} />
                                </Grid>
                            </Grid>
                            <CropPlanButtonBox style={{ marginTop: '0em' }}>
                                <Grid container spacing={3}>

                                    <Grid item lg={2} md={2} sm={6} xs={2}>
                                    </Grid>

                                    <Grid item lg={2} md={2} sm={6} xs={2}>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={6} xs={4}>
                                        {/* <CropPlanDelBtn onClick={props?.handleClose}>Cancel<CloseIcon /></CropPlanDelBtn> */}
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={6} xs={4}>
                                        <CropPlanDelBtn onClick={handleDelCrop} disabled={btnState?.isDisabled}>{btnState?.text}</CropPlanDelBtn>
                                    </Grid>
                                </Grid>
                            </CropPlanButtonBox>
                        </CropPlanModalInner>
                    </CropPlanModal>
                </Fade>
            </Modal>
        </>
    )
};
