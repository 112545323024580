import React, {useState} from 'react';
import {Button, Col, Divider, Modal, Row, Select} from "antd";
import {Controller, useForm} from "react-hook-form";
import {toast} from "react-toastify";

const { Option } = Select;

const ManageSubscriptionModal = (props) => {
    const {
        visible,
        formMode,
        allServices,
        one_sub_record,
        ConfigureSubscriptions,
        UpdateSubscription,
        closeModal
    } = props;
    const [loading, setloading] = useState(false);

    const closeService = () => {
        closeModal();
        reset();
    };

    const checkMode = formMode === 'CREATE_MODE';

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const performSubConfiguration = async (data) => {
        const {
            amount,
            max_farm_area_allocated,
            max_users_in_team_allocated,
            min_farm_area_allocated,
            min_users_in_team_allocated,
            subscription_value_per_unit,
            unit,
        } = data;
        const formattedRequest = {
            ...data,
            amount: parseFloat(amount),
            unit: parseInt(unit),
            max_farm_area_allocated: parseInt(max_farm_area_allocated),
            max_users_in_team_allocated: parseInt(max_users_in_team_allocated),
            min_farm_area_allocated: parseInt(min_farm_area_allocated),
            min_users_in_team_allocated: parseInt(min_users_in_team_allocated),
            subscription_value_per_unit: parseInt(subscription_value_per_unit),
        };
        if (formMode === 'CREATE_MODE') {
            setloading(true);
            const res = await ConfigureSubscriptions(formattedRequest);
            if (res.success) {
                toast.success("Subscription Created Successfully", {
                    pauseOnHover: true,
                });
                setloading(false);
                closeService();
                requestAnimationFrame(() => {
                    reset();
                    setloading(false);
                });
            } else if (res.message === undefined) {
                toast.error("Network Error", {
                    pauseOnHover: true,
                });
                setloading(false);
            } else {
                toast.error(res.message, {
                    pauseOnHover: true,
                });
                setloading(false);
            }
        } else if (formMode === 'UPDATE_MODE') {
            setloading(true);
            const updateObj = {
                ...data,
                id: one_sub_record.id,
                amount: parseFloat(amount),
                unit: parseInt(unit),
                max_farm_area_allocated: parseInt(max_farm_area_allocated),
                max_users_in_team_allocated: parseInt(max_users_in_team_allocated),
                min_farm_area_allocated: parseInt(min_farm_area_allocated),
                min_users_in_team_allocated: parseInt(min_users_in_team_allocated),
                subscription_value_per_unit: parseInt(subscription_value_per_unit),
            }
            const res = await UpdateSubscription(updateObj);
            if (res.success) {
                closeService();
                setloading(false);
                toast.success("Subscription Updated Successfully", {
                    pauseOnHover: true,
                });
            } else if (res.message === undefined) {
                toast.error("Network Error", {
                    pauseOnHover: true,
                });
                setloading(false);
            } else {
                toast.error(res.message, {
                    pauseOnHover: true,
                });
                setloading(false);
            }
        }
    };

    const children = allServices?.map((obj) => {
        return (
            <Option key={obj.id} value={obj.id}>
                {obj.name}
            </Option>
        );
    });


    return (
        <>
            <Modal
                visible={visible}
                title={`${checkMode ? 'Create New Subscription' : `Update Subscription: ${one_sub_record?.name}`}`}
                footer={null}
                onCancel={closeService}
                maskClosable={false}
                width={'600px'}
            >
                <form onSubmit={handleSubmit(performSubConfiguration)} autoComplete="off">
                    <div className="form-group">
                        <Row gutter={28}>
                            <Col xs={24} xl={24} lg={24}>
                                <label>Services</label>
                                <Controller
                                    name="services"
                                    control={control}
                                    // rules={{ required: true }}
                                    render={({ field: { onChange, value} }) => (
                                        <Select
                                            key="defaultValue"
                                            mode="multiple"
                                            allowClear
                                            name="services"
                                            placeholder="Select services"
                                            value={value}
                                            style={{ width: "100%" }}
                                            defaultValue={one_sub_record?.services?.map((res => res.name))}
                                            onChange={onChange}
                                            size="large"
                                            showArrow={true}
                                            maxTagCount={3}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children
                                                    .toLowerCase()
                                                    .localeCompare(
                                                        optionB.children.toLowerCase()
                                                    )
                                            }
                                        >
                                            {children}
                                        </Select>
                                    )}
                                />
                                {/*{errors.services && <span className="error">Services is required</span>}*/}
                            </Col>
                        </Row>
                    </div>
                    {/*<div className="form-group">
                        <Row gutter={28}>
                            <Col xs={24} xl={12} lg={12}>
                                <label>Amount</label>
                                <Row gutter={7}>
                                    <Col xs={8} xl={8} lg={8}>
                                        <Select size="large" defaultValue="NGN" style={{ width: '100%' }} disabled>
                                            <Option value="NGN">NGN</Option>
                                        </Select>
                                    </Col>
                                    <Col xs={16} xl={16} lg={16}>
                                        <input
                                            {...register("amount", { required: true })}
                                            className="ant-input ant-input-lg"
                                            name="amount"
                                            defaultValue={one_sub_record?.amount}
                                        />
                                    {errors.amount && (
                                        <span className="error">Amount is required</span>
                                    )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} xl={12} lg={12}>
                                <label>Unit</label>
                                <input
                                    {...register("unit", { required: true })}
                                    className="ant-input ant-input-lg"
                                    name="unit"
                                    defaultValue={one_sub_record?.unit}
                                />
                                {errors.unit && <span className="error">Unit is required</span>}
                            </Col>
                        </Row>
                    </div>*/}
                    <div className="form-group">
                        <Row gutter={28}>
                           {/* <Col xs={24} xl={12} lg={12}>
                                <label>Subscription Value Per Unit</label>
                                <input
                                    {...register("subscription_value_per_unit", { required: true })}
                                    className="ant-input ant-input-lg"
                                    name="subscription_value_per_unit"
                                    defaultValue={one_sub_record?.subscription_value_per_unit}
                                />
                                {errors.subscription_value_per_unit && (
                                    <span className="error">Sub value per unit is required</span>
                                )}
                            </Col>*/}
                            <Col xs={24} xl={12} lg={12}>
                                <label>Name</label>
                                <input
                                    {...register("name", { required: true })}
                                    className="ant-input ant-input-lg"
                                    name="name"
                                    defaultValue={one_sub_record?.name}
                                />
                                {errors.name && <span className="error">Name is required</span>}
                            </Col>
                            <Col xs={24} xl={12} lg={12}>
                                <label htmlFor="duration">Duration</label>
                                <Controller
                                    name="duration"
                                    control={control}
                                    rules={{ required: formMode === 'CREATE_MODE' }}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            allowClear
                                            name="duration"
                                            placeholder="Select Duration"
                                            onChange={onChange}
                                            size="large"
                                            style={{ width: '100%' }}
                                            value={value}
                                            defaultValue={one_sub_record?.duration}
                                        >
                                            <Option value="daily">Daily</Option>
                                            <Option value="weekly">Weekly</Option>
                                            <Option value="monthly">Monthly</Option>
                                            <Option value="quarterly">Quarterly</Option>
                                            <Option value="yearly">Yearly</Option>
                                        </Select>
                                    )}
                                />
                                {errors.duration && (
                                    <span className="error">Duration is required</span>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row gutter={28}>
                            <Col xs={24} xl={12} lg={12}>
                                <label>Min Farm Area Allocated</label>
                                <input
                                    {...register("min_farm_area_allocated", { required: true })}
                                    className="ant-input ant-input-lg"
                                    name="min_farm_area_allocated"
                                    defaultValue={one_sub_record?.min_farm_area_allocated}
                                    // value={}
                                />
                                {errors.min_farm_area_allocated && (
                                    <span className="error">Field is required</span>
                                )}
                            </Col>
                            <Col xs={24} xl={12} lg={12}>
                                <label>Max Farm Area Allocated</label>
                                <input
                                    {...register("max_farm_area_allocated", { required: true })}
                                    className="ant-input ant-input-lg"
                                    name="max_farm_area_allocated"
                                    defaultValue={one_sub_record?.max_farm_area_allocated}
                                />
                                {errors.max_farm_area_allocated && (
                                    <span className="error">Field is required</span>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row gutter={28}>
                            <Col xs={24} xl={12} lg={12}>
                                <label>Min Users In Team Allocated</label>
                                <input
                                    {...register("min_users_in_team_allocated", { required: true })}
                                    className="ant-input ant-input-lg"
                                    name="min_users_in_team_allocated"
                                    defaultValue={one_sub_record?.min_users_in_team_allocated}
                                />
                                {errors.min_users_in_team_allocated && (
                                    <span className="error">Field is required</span>
                                )}
                            </Col>
                            <Col xs={24} xl={12} lg={12}>
                                <label>Max Users In Team Allocated</label>
                                <input
                                    {...register("max_users_in_team_allocated", { required: true })}
                                    className="ant-input ant-input-lg"
                                    name="max_users_in_team_allocated"
                                    defaultValue={one_sub_record?.max_users_in_team_allocated}
                                />
                                {errors.max_users_in_team_allocated && (
                                    <span className="error">Field is required</span>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <Divider />
                    <div className="form-group">
                        <Row style={{ marginTop: "2rem" }}>
                            <Col xs={24} style={{ textAlign: "right" }}>
                                <Button
                                    loading={loading}
                                    type="primary"
                                    size="large"
                                    onClick={handleSubmit(performSubConfiguration)}
                                    style={{ marginRight: '10px' }}
                                >
                                    {formMode === 'CREATE_MODE' ? 'Create' : 'Update'} Subscription
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </form>
            </Modal>
        </>
    )
};

export default ManageSubscriptionModal;
