import React from 'react';
import Register from '../../components/auth/Register';

const RegisterPage = () => {
    return (
        <>
            <Register />
        </>
    )
};

export default RegisterPage