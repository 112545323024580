import React, { useState } from "react";
import { Modal, Input } from "antd";
import { toast } from "react-toastify";
import { CloseTask } from "./FarmActivitiy.styled";

const CloseFarmActivities = (props) => {
  const [confirmloading, setconfirmloading] = useState(false);
  const [comment, setcomment] = useState({
    status: "close",
    comment: "",
  });

  const onChangeText = (e) => {
    e.preventDefault();
    setcomment({ ...comment, [e.target.name]: e.target.value });
  };

  const CloseFarmTask = async () => {
    setconfirmloading(true);
    // const data = {}
    const res = await props.closeFarmActivities(comment, props?.farmRecordId.id);
    if (res.success) {
      toast("Farm Activity Closed successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setcomment({
          status: 'close',
          comment: '',
      });
      props.handleCancel();
      setconfirmloading(false);
    } else {
      setconfirmloading(false);
      toast.error(`${res.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Modal
        title={`Want to Close this Task ${props?.farmRecordId?.name}?`}
        visible={props.isModalVisible}
        onOk={CloseFarmTask}
        onCancel={props.handleCancel}
        closable={false}
        okText="Close Task"
        maskClosable={false}
        okButtonProps={{
          loading: confirmloading,
          danger: true,
        }}
        cancelText="Cancel"
      >
        <CloseTask>
          <form>
            <Input.TextArea
              showCount
              name="comment"
              maxLength={120}
              placeholder="State your reason"
              onChange={onChangeText}
              value={comment.comment}
            />
          </form>
        </CloseTask>
      </Modal>
    </>
  );
};

export default CloseFarmActivities;
