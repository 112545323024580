import styled from "styled-components";

export const ManageUser = styled.div`
  position: relative;
  h3 {
    color: #011c14;
    font-size: 1.82rem;
  }
  .action {
    display: flex;
    svg {
      font-size: 1rem;
    }
    > div {
      &:first-child {
        cursor: pointer;
      }
    }
  }
  .ant-btn-primary {
    color: #fff;
    background: var(--primary-green-color);
    border-color: var(--primary-green-color);
    height: 40px;
    padding: 0 2rem;
    border-radius: 5px;
  }
  .rolebutton {
    margin-right: 3px;
    background: transparent;
    border-color: transparent;
    border: none;
    font-size: 0.9rem;
    outline: none;
    &:disabled {
      background: transparent;
      border-color: transparent;
    }
    svg {
      font-size: 1rem;
    }
  }
  .table {
    border: 1px solid rgb(0, 0, 0, 0.06);
    thead th {
      border-bottom: 0;
      border-top: 0;
      background: #eef9f6;
    }
  }
  /* .card-shadow {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
  } */
`;

export const ListContainer = styled.div`
  transition: ease all 0.5s;
  ul {
    list-style: none;
    padding-top: 4rem;
    padding-left: 0;
  }
  li {
    text-transform: capitalize;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:first-child {
      color: #05674a;
      font-weight: bold;
    }
    > div {
      display: flex;
    }
    &:hover {
      .list_ops {
        opacity: 1;
        transition: ease all 0.5s;
        transform: translateX(0px);
      }
    }
  }
  .list_ops {
    transition: ease all 0.5s;
    transform: translateX(-10px);
    opacity: 0;
    button {
      margin-right: 3px;
      background: transparent;
      border-color: transparent;
      border: none;
      font-size: 0.9rem;
      outline: none;
      svg {
        font-size: 1rem;
      }
    }
  }
  .loading {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
  }
  .ant-btn-primary {
    background: #05674a;
    border-color: #05674a;
    border-radius: 5px;
    height: 45px;
    font-weight: bold;
    transition: ease all 0.5s;
  }
  .rolelink {
    color: #011c14;
    font-weight: bolder;
    margin-bottom: 15px;
  }
  .roleDescription {
    font-size: 0.81rem;
  }
  .privContainer {
    border-radius: 4px;
    overflow: hidden;
  }
`;

export const PrivilegeContainer = styled.div`
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #c2f1cc;
  height: auto;
  .header {
    background: #c2f1cc;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 15px 15px 40px;
    color: #29a845;
  }
  .list {
    margin: 10px 0 0 0;
    ul {
      list-style: none;
      width: 100%;
      padding: 0;
      margin: 0;
      /* padding-left: 0; */
    }
    li {
      text-transform: capitalize;
      padding: 10px 40px;
      border-bottom: 1px solid #c2f1cc;
      width: 100%;
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

export const NotPrivilegeContainer = styled.div`
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #ffbaba;
  height: auto;
  .header {
    background: #ffbaba;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 15px 15px 40px;
    color: #f14a4a;
  }
  .list {
    margin: 10px 0;
    ul {
      list-style: none;
      width: 100%;
      padding: 0;
      margin: 0;
      /* padding-left: 0; */
    }
    li {
      text-transform: capitalize;
      padding: 10px 40px;
      border-bottom: 1px solid #ffbaba;
      width: 100%;
      &:last-child {
        border-bottom: none;
      }
    }
    .fullaccess {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 10px 4rem;
      h3 {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        font-size: 11px;
        margin-bottom: 0;
        text-align: center;
      }
    }
  }
`;

export const CreateCustomModal = styled.div`
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
  }
  .ant-modal-title {
    text-align: center;
  }
  .ant-transfer {
    //justify-content: space-between;
  }
  .ant-transfer-list {
    width: 250px;
  }
  .ant-transfer-operation {
    .ant-btn-primary {
      height: 25px;
    }
  }
  label {
    font-weight: bold;
    font-size: 0.9rem;
  }
  .form-control {
    background-color: #fafafa;
    border: 1px solid #f1f1f1;
  }
  textarea.form-control {
    height: 100px;
    resize: none;
  }
  .check {
    display: flex;
    align-items: center;
  }
  h4 {
    font-size: 0.9rem;
    font-weight: bold;
  }
  span {
    padding-left: 5px;
    font-weight: lighter;
  }
  .ant-btn-default {
    display: flex;
    height: 45px;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bolder;
    background: #ddd;
    border-color: #ddd;
    justify-content: center;
    color: #000;
    border-radius: 4px;
  }
  .ant-btn-primary {
    background: #05674a;
    border-color: #05674a;
    font-weight: bold;
    color: #fff;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 1.1rem;
  }
`;

export const UserProfileContainer = styled.div`
  position: relative;
  transition: ease all 0.5s;
  padding-top: 5rem;
  h4 {
    font-size: 1rem;
    position: relative;
    margin-bottom: 1.5rem;
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.05);
      height: 1px;
      bottom: -10px;
      transition: ease all 0.5s;
    }
  }
  .ant-input, .ant-input[disabled] {
    height: 38px;
    border-radius: 4px;
  }
  .ant-btn-primary {
    background: var(--primary-green-color);
    border-color: var(--primary-green-color);
    height: 40px;
    border-radius: 5px;
    padding: 0 1.3rem;
  }
  .ant-btn-danger {
    height: 40px;
    border-radius: 5px;
    padding: 0 1.3rem;
  }
  label {
    margin-bottom: 0;
    display: flex;
  }
`;
