import constants from "../constants";

const subscriptionReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.ALL_SUBSCRIPTIONS:
            return {
                ...state,
                all_sub_plans: action.payload
            }
        case constants.CREATE_PAYMENT:
            return {
                ...state,
                payment_resp: action.payload,
            }
        case constants.GET_SINGLE_SUBSCRIPTIONS:
            return {
                ...state,
                single_sub: action.payload,
            }
        case constants.GET_USER_SUBSCRIPTIONS:
            return {
                ...state,
                user_sub: action.payload,
            }
        default:
            return state;
    }
}

export default subscriptionReducer;
