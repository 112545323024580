import { combineReducers } from 'redux';
import authReducer from './authentication';
import userManagementReducer from './userManagement';
import fieldManagementReducer from './fieldManagement';
import FarmReducer from './farmReducer';
import adminReducer from "./adminReducer";
import adminOtherReducer from "./adminOtherReducer";
import subscriptionReducer from "./subscriptionReducer";

export default combineReducers({
    authReducer,
    userManagementReducer,
    fieldManagementReducer,
    FarmReducer,
    adminReducer,
    adminOtherReducer,
    subscriptionReducer,
});