import React, { useState } from 'react';
import AuthPlainLayout from './Layout/AuthPlainLayout';
import { AuthContainer } from './Layout/AdminLayout.styled';
import { Input, Button } from 'antd';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { AdminActionLogin } from '../../redux/actions/adminActions';

const AdminLogin = props => {
    const [loading, setloading] = useState(false);
    // const { AdminActionLogin } = props;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const history = useHistory();

    const performAdminLogin = () => {
        setloading(true);
        setTimeout(() => {
            toast.success('E shock you! Just Testing Design', {
                pauseOnHover: false
            })
            history.push('/admin/dashboard');
            setloading(false);
        }, 1000);
    }

    // const performAdminLogin = async (data, e) => {
    //     setloading(true);
    //     e.preventDefault();
    //     const res = await AdminActionLogin(data);
    //     if (res.success && res.role === 'admin') {
    //         setloading(false);
    //         toast.success(res.message, {
    //             pauseOnHover: false,
    //         });
    //         setTimeout(() => {
    //             history.push('/admin/dashboard');
    //             setloading(false);
    //         }, 1000);
    //     } else if (res.success && res.role !== 'admin') {
    //         toast.warning('Account does not have admin role', {
    //             position: 'top-right',
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //         setloading(false);
    //     } else {
    //         toast.error(res.message, {
    //             position: 'top-right',
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //         setloading(false);
    //     }
    // };

    return (
        <>
            <AuthPlainLayout pageTitle="Admin Login" title="Welcome Admin!" subtitle="Enter your login details">
                <AuthContainer data-aos="fade-down" data-aos-duration="2000">
                    <div className="login">
                        <form className="login__form" autoComplete="off" onSubmit={handleSubmit(performAdminLogin)}>
                            <div className="form-group">
                                <label>Email</label>
                                <Input
                                    {...register('email', { required: true })}
                                    size="large"
                                    style={{ height: '46px' }}
                                />
                                {errors.email && <span className="error">Email Address is required</span>}
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <Input.Password
                                    {...register('password', { required: true })}
                                    size="large"
                                    style={{ height: 'auto' }}
                                />
                                {errors.password && <span className="error">Password is required</span>}
                            </div>
                            <div className="form-group">
                                <Button
                                    onClick={handleSubmit(performAdminLogin)}
                                    loading={loading}
                                    type="primary"
                                    size="large"
                                    block
                                >
                                    {loading ? 'Authenticating...' : 'Sign In'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </AuthContainer>
            </AuthPlainLayout>
        </>
    );
};

const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps, { AdminActionLogin })(AdminLogin);
