import axios from "axios";

import { baseUrl } from "../../config";
import { errorMessage } from "../../utils";
import constants from "../constants";

export const loginUser = (userDetails) => (dispatch, getState) => {
  return axios
    .post(`${baseUrl}/auth/login/`, userDetails, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      dispatch({ type: constants.ACCESS_TOKEN, payload: res.data.key });
      dispatch({ type: constants.USER_DEATIALS, payload: res.data.user });
      return { success: true, response: res.data.user, is_staff: res.data?.user?.is_staff };
    })
    .catch((err) => {
      dispatch({
        type: constants.AUTH_ERROR,
        payload: errorMessage(err.response?.data || 'Something went wrong'),
      });
      return { success: false, message: errorMessage(err.response?.data) };
    });
};

export const signUpUser = (userDetails) => (dispatch, getState) => {
  return axios
    .post(`${baseUrl}/auth/registration/`, userDetails, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      dispatch({ type: constants.ACCESS_TOKEN, payload: res.data.key });
      dispatch({ type: constants.USER_DEATIALS, payload: res.data.user });
      return { success: true, message:res?.data };
    })
    .catch((err) => {
      console.log('inner error',err.response);
      dispatch({
        type: constants.AUTH_ERROR,
        payload: errorMessage(err.response.data),
      });
      return { success: false, message: errorMessage(err.response.data)};
    });
};

export const oAuth2 = (userDetails) => (dispatch, getState) => {
  return axios
    .post(`${baseUrl}/accounts/google/login/`, userDetails, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      dispatch({ type: constants.ACCESS_TOKEN, payload: res.data.key });
      dispatch({ type: constants.USER_DEATIALS, payload: res.data.user });
      return { success: true, message: "message" };
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: constants.AUTH_ERROR,
        payload: errorMessage(err.response.data),
      });
      return { success: false, message: "message" };
    });
};

export const ResetEmailPassword = (data) => (dispatch) => {
  return axios
    .post(`${baseUrl}/auth/password_reset/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log("reset", res);
      dispatch({ type: constants.RESET_PASSWORD_EMAIL, payload: res.data });
      return { success: true, message: res.data?.detail };
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: constants.AUTH_ERROR,
        payload: errorMessage(err.response?.data),
      });
      return { success: false, message: err.response?.data.email?.[0] || 'Something went wrong' };
    });
};

export const ValidateEmailToken = (data) => (dispatch) => {
  return axios
    .post(`${baseUrl}/auth/password_reset/validate_token/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return { success: true, message: res?.data?.detail };
    })
    .catch((err) => {
      console.log("token erro", err.response);
      return { success: false, message: err?.response?.data.status };
    });
};

export const ResetNewPassword = (data) => (dispatch) => {
  return axios
    .post(`${baseUrl}/auth/password_reset/confirm/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log("reset", res);
      // dispatch({ type: constants.RESET_PASSWORD_EMAIL, payload: res.data });
      return { success: true, message: res.data?.detail };
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: constants.AUTH_ERROR,
        payload: errorMessage(err.response?.data),
      });
      return {
        success: false,
        message: {
          ...err?.response,
          token: err.response?.data?.token?.[0],
          password: `${err.response?.data?.password?.[0]} & ${err.response?.data?.password?.[1]}`,
        },
      };
    });
};

export const LogoutUser = () => (dispatch) => {
  return axios
    .post(`${baseUrl}/auth/logout/`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log("logout", res);
      dispatch({ type: constants.ACCESS_TOKEN, payload: res.data.key });
      dispatch({ type: constants.USER_DEATIALS, payload: res.data.user });
      return { success: true, message: res.data?.detail };
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: constants.AUTH_ERROR,
        payload: errorMessage(err.response?.data),
      });
      return { success: false, message: "message" };
    });
};

export const updateUserProfile = (data, id) => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  // let owonerId = getState().authReducer.user_details.owned_team.id;
  return axios
    .put(`${baseUrl}/accounts/${id}/profile/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      console.log("update", res.data);
      if (res.status === "200" || res.status === 200) {
        // dispatch(getTeamMembers(owonerId));
        dispatch({ type: constants.USER_DEATIALS, payload: res.data });
        return { success: true, message: "message" };
      }
    })
    .catch((err) => {
      console.log("login err", err.response);
      return { success: false, message: err.response?.data.detail };
    });
};

export const updateUserPassword = (data) => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  return axios
    .post(`${baseUrl}/auth/password/change/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      // dispatch(LogoutUser());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("pwd", err.response);
      return {
        success: false,
        message:
          err.response?.data?.new_password2[0] ||
          " || An unexpected error occurred. Kindly try again, please.",
      };
    });
};
