import React from "react";

import { Grid } from "@material-ui/core";

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";

import {
  Footer,
  FooterBigTitle,
  FooterButton,
  FooterInner,
  FooterInput,
  FooterLowerSection,
  FooterRule,
  FooterText,
  FooterTitle,
  FooterUpperSection,
  SocialIconsBlack,
  FooterSectionSocial,
  FooterTextArea,
} from "../../styles/home/FooterStyles";

import logo from "../../assets/img/home/main/logo.png";
import { HeaderLogo, HeaderItemBlock, HeaderLogoText } from "../../styles/home/HeaderStyles";

const HomeFooter = () => {
  return (
    <>
      <Footer>
        <FooterLowerSection>
          {/* <FooterRule /> */}
          <FooterInner>
            <Grid container spacing={2}>
              <Grid item lg={4} xs={6} md={4} sm={6}>
                <FooterTitle>
                  <HeaderItemBlock>
                    <HeaderLogo src={logo} />
                    <HeaderLogoText style={{color: 'white'}}>AGROPREVER</HeaderLogoText>
                  </HeaderItemBlock>
                </FooterTitle>
                <FooterSectionSocial>
                  <SocialIconsBlack>
                    <FacebookIcon
                      style={{
                        fontSize: "1.3em",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </SocialIconsBlack>
                  <SocialIconsBlack>
                    <InstagramIcon
                      style={{
                        fontSize: "1.3em",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </SocialIconsBlack>
                  <SocialIconsBlack>
                    <TwitterIcon
                      style={{
                        fontSize: "1.3em",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </SocialIconsBlack>
                </FooterSectionSocial>
              </Grid>
              {/* <Grid item lg={2} xs={6} md={2} sm={6}>
                <FooterTitle>Industries</FooterTitle>
                <FooterText>Oil and Gas</FooterText>
                <FooterText>Forestry</FooterText>
                <FooterText>Agriculture</FooterText>
              </Grid> */}
              <Grid item lg={2} xs={6} md={2} sm={4}>
                <FooterTitle>All Products</FooterTitle>
                <FooterText>Farm management</FooterText>
                <FooterText>Satelite Imagery</FooterText>
                <FooterText>Crop Monitoring</FooterText>
                <FooterText>Crop Plan Rotation</FooterText>
                <FooterText>Weather Monitoring</FooterText>
              </Grid>

              <Grid item lg={2} xs={6} md={2} sm={4}>
                <FooterTitle>Help & Support</FooterTitle>
                <FooterText>Contact Us</FooterText>
                <FooterText>Partnership</FooterText>
              </Grid>

              <Grid item lg={2} xs={6} md={2} sm={4}>
                <FooterTitle>Legal</FooterTitle>
                <FooterText>Terms & Conditions</FooterText>
                <FooterText>Privacy Policy</FooterText>
                <FooterText>Licenses (EULA)</FooterText>
              </Grid>
            </Grid>
          </FooterInner>
        </FooterLowerSection>
      </Footer>
    </>
  );
};

export default HomeFooter;
