import React from 'react';
import ConfigureFarmActivity from '../../components/farmActivity/ConfigureFarmActivity';

const ConfigureFarm = () => {
    return (
        <div>
            <ConfigureFarmActivity />
        </div>
    )
}

export default ConfigureFarm;
