import React, { useState } from "react";
import { connect } from "react-redux";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import DashboardHome from "../../components/dashboardHome/DashboardHome";

import {
  getField,
  // getLatestField
} from "../../redux/actions/fieldManagement";
// import { AppContext } from "../../appRouter";
import Header from "../../components/util/Header";

const DashboardHomePage = (props) => {
  // const { access_token: access_key } = useSelector(
  //   (state) => state.authReducer
  // );
  // const { handleSelectedCentroid, handleSelectedCoords, handleSelectedRealCoords, handleSelectedFieldId } =
  //   useContext(AppContext);
  const [allFields, setAllFields] = useState([]);
  const [isMapLoading, setIsMapLoading] = useState(true);

  // useEffect(() => {
  //   async function fetchData() {
  //     let { isError, data } = await getLatestField(access_key);
  //     if (isError) {
  //       setIsMapLoading(false);
  //     } else {
  //       setIsMapLoading(false);
  //       let shapeCentroid = data[0]?.centroid;
  //       let shapeCoords = data[0]?.coordinates;
  //       handleSelectedCentroid(shapeCentroid);
  //       handleSelectedCoords(shapeCoords);
  //       handleSelectedRealCoords(shapeCoords);
  //       handleSelectedFieldId(data[0]?.id);
  //     }
  //   }
  //   fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <DashboardLayout pageTitle="Dashboard" allFields={allFields} isHome={true}>
      <Header
        setPath={props?.setPath}
        setCentroid={props?.setCentroid}
        allFields={props?.allFields}
      />
      <DashboardHome
        isMapLoading={isMapLoading}
        allFields={allFields}
        setAllFields={setAllFields}
        setIsMapLoading={setIsMapLoading}
      />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    all_field: state.fieldManagementReducer.fields,
  };
};

export default connect(mapStateToProps, { getField })(DashboardHomePage);
