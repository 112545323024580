import React, { useState } from "react";

import { BasemapLayer } from "react-esri-leaflet";
import { MapContainer, WMSTileLayer } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import L from "leaflet";

import LoadingOverlay from "react-loading-overlay";

import { useEffect } from "react";
import SwitchButton from "../satelliteField/SwitchButton";
import Legend from "./Legend";
import RangeControl from "./RangeControl";
import GetMapInfo from "./GetMapInfo";

import { cloudLimit, wmsKey } from "../util/Util";
import { useContext } from "react";
import { AppContext } from "../../appRouter";

const RenderMap = (props) => {
  let basePolygon = React.useRef();

  // --- Get the field Id here ---
  const { selectedFieldId, selectedDate } = useContext(AppContext);

  const [geometry, setGeometry] = useState("");
  const [isMapLoading, setIsMapLoading] = useState(true);

  const [tooltipData, setTooltipData] = useState({
    position: [],
    text: "Click any point to read value",
  });
  const [cloudCover, setCloudCover] = useState(props?.cloudCover);
  const [curLayer, setCurLayer] = useState(props?.selectedLayer);

  useEffect(() => {
    if (props?.selectedCoords.length > 0) {
      let ge = `POLYGON ((${props?.selectedRealCoords
        .map((coord) => coord.join(" "))
        .join(", ")}))`;
      setGeometry(ge);
    }
    setIsMapLoading(true);
  }, [props?.selectedCoords, props?.selectedRealCoords]);

  const [layerKey, setLayerKey] = useState(true);

  useEffect(() => {
    // if (props?.refresh) {
    setIsMapLoading(props?.refresh);
    setCloudCover(props?.cloudCover);
    setCurLayer(props?.selectedLayer);
    let key =
      props?.cloudCover +
      props?.startDate +
      props?.endDate +
      props?.selectedCentroid +
      props?.selectedLayer;
    setLayerKey(key);
    // }
  }, [
    props?.cloudCover,
    props?.refresh,
    props?.startDate,
    props?.endDate,
    props?.selectedCentroid,
    props?.selectedLayer,
  ]);

  const [map, setMap] = useState(null);

  return (
    <>
      <LoadingOverlay
        active={isMapLoading}
        spinner
        text="Please wait while we are loading the map"
      >
        <MapContainer
          attributionControl={false}
          whenCreated={setMap}
          scrollWheelZoom={false}
          center={[props?.selectedCentroid[1], props?.selectedCentroid[0]]}
          zoom={10}
          zoomControl={true}
          style={{
            height: "85vh",
            width: "100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          <BasemapLayer name="ImageryFirefly" />
          {props?.selectedLayer &&
            props?.cloudCover <= cloudLimit &&
            selectedFieldId && selectedDate.length && (
              <WMSTileLayer
                // version="1.3.0"
                key={layerKey}
                layers={props?.selectedLayer}
                format="image/png"
                crs={L.CRS.EPSG4326}
                attribution='&copy; <a href="http://www.sentinel-hub.com/" target="_blank">Sentinel Hub</a>'
                url={`https://services.sentinel-hub.com/ogc/wms/${wmsKey}?REQUEST=GetMap`}
                geometry={geometry}
                maxcc={props?.cloudCover}
                minZoom="0"
                maxZoom="500"
                zoom={12}
                preset={props?.selectedLayer}
                width="512"
                height="512"
                time={`${selectedDate}`}
                transparent="true"
              />
            )}

          <GetMapInfo
            basePolygon={basePolygon}
            setIsMapLoading={setIsMapLoading}
            setTooltipData={setTooltipData}
            tooltipData={tooltipData}
            selectedCoords={props?.selectedCoords}
            cloudCover={cloudCover}
            setCurLayer={setCurLayer}
            curLayer={curLayer}
            time={`${selectedDate}`}
            showLocateAddr
          />

          <SwitchButton redr="/dashboard/split-view/" isDefault />

          <Legend map={map} layer={curLayer} />

          <RangeControl
            setIsMapLoading={props?.setIsMapLoading}
            startDate={selectedDate}
          />
        </MapContainer>
      </LoadingOverlay>
    </>
  );
};

const MapEngine = (props) => {
  return (
    <>
      <RenderMap
        selectedCoords={props?.selectedCoords}
        selectedRealCoords={props.selectedRealCoords}
        cloudCover={props?.cloudCover}
        selectedCentroid={props?.selectedCentroid}
        startDate={props?.startDate}
        endDate={props?.endDate}
        refresh={props?.refresh}
        setIsMapLoading={props?.setIsMapLoading}
        selectedLayer={props?.selectedLayer}
      />
    </>
  );
};
export default MapEngine;