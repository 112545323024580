import React, {useEffect, useState} from "react";
import {Modal, Button, Transfer} from "antd";
import { useForm } from "react-hook-form";
import { CreateCustomModal } from "../UserManagement.styled";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateCustomRoleModal = (props) => {
  const {
    // teamOwner,
    ownTeam_id,
    all_permissions,
    formData,
    updateRole,
    getTeamRoles,
  } = props;

  const [permissionObject, setPermissionObject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [targetKeys, setTargetKeys] = useState(formData?.privileges);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleClose = () => {
    props.handleUpdateCancel();
  };

  // useEffect(() => {
  //   setTargetKeys(formData?.privileges);
  // }, [formData?.privileges, targetKeys])

  useEffect(() => {
    const getValue = () => {
      if (all_permissions) {
        const convertPermission = all_permissions.map((res, index) => {
          return {
            key: res,
            permission: res,
          }
        })
        setPermissionObject(convertPermission);
      }
    };
    getValue();
  }, [all_permissions]);

  const fetchTeamRoles = async () => {
    setLoading(true);
    const res = await getTeamRoles(ownTeam_id);
    if (res.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };


  const UpdateARole = async (data) => {
    if (!ownTeam_id) {
      toast.error('Account does not have a team, please subscribe to a team to continue')
      return;
    }
    setLoading(true);
    const roleData = {
      team: ownTeam_id,
      name: data.name,
      description: data.description,
      privileges: targetKeys,
    };

    const res = await updateRole(roleData, formData.id);
    if (res.success) {
      toast("Role Updated successfully.");
      handleClose();
      setLoading(false);
      await fetchTeamRoles();
      reset();
    } else {
      setLoading(false);
      toast.error("An unexpected error occurred. Kindly try again, please.");
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        title="Update Custom Role"
        visible={props.isModalVisible}
        onCancel={props.handleCancel}
        footer={null}
        closable={false}
        width="600px"
      >
        <CreateCustomModal>
          <div className="container">
            <form onSubmit={handleSubmit(UpdateARole)}>
              <div className="row">
                {/* <input name="id" value={formData.id} onChange={handleChange} {...register('id')} hidden readOnly/> */}
                {/* <input name="team" value={formData.id} onChange={handleChange} {...register('team')} hidden readOnly/> */}
                <div className="col-md-3">
                  <label htmlFor="name">Role Name</label>
                </div>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    name="name"
                    {...register("name")}
                    defaultValue={formData?.name}
                  // onChange={handleChange}
                  />
                  {errors.name && (
                    <span className="error">Role Name is Required</span>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  <label htmlFor="description">Role Description</label>
                </div>
                <div className="col-md-9">
                  <textarea
                    className="form-control"
                    {...register("description")}
                    defaultValue={formData?.description}
                  // onChange={handleChange}
                  />
                  {/* {errors.description && (
                    <span className="error">Role Name is Required</span>
                  )} */}
                </div>
              </div>
              {/*<div className="row mt-4">
                <div className="col-md-12">
                  <h4 className="pb-1">Add Permissions</h4>
                  {
                    all_permissions?.map((permssion, index) => {
                      return (
                        <div key={index}>
                          <label htmlFor={permssion?.split(".")[1]} className="check">
                            <input
                              value={permssion}
                              name={permssion?.split(".")[1]}
                              type="checkbox"
                              id={permssion?.split(".")[1]}
                              // {...register(permssion?.split(".")[1])}
                              className="role-check"
                              onChange={(e) => onGetPrivValue(e)}
                              checked={all_permissions.includes(formData.privileges)}
                            />
                            <span>
                              <strong>Can</strong> {permssion?.split(".")[1].replace(/_/g, " ")}
                            </span>
                          </label>
                        </div>
                      )
                    })
                  }
                </div>
              </div>*/}
              <div className="row mt-3" style={{ marginTop: '1rem' }}>
                <label className="ml-3">Select Permission</label>
                <div className="col-md-12">
                  <Transfer
                      dataSource={permissionObject}
                      titles={['To Add', 'To Remove']}
                      render={item => item.permission?.split(".")[1].replace(/_/g, " ")}
                      targetKeys={targetKeys}
                      selectedKeys={selectedKeys}
                      list-style="{
                        width: '40%',
                        height: '550px',
                        justifyContent: 'space-between',
                      }"
                      onChange={onChange}
                      onSelectChange={onSelectChange}
                  />
                </div>
              </div>
              <div className="row justify-content-between mt-4">
                <div className="col-md-4">
                  <Button type="default" block onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
                <div className="col-md-6">
                  <Button
                    type="primary"
                    block
                    disabled={loading}
                    onClick={handleSubmit(UpdateARole)}
                  >
                    {loading ? "Updating..." : "Update Role"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </CreateCustomModal>
      </Modal>
    </div>
  );
};

export default UpdateCustomRoleModal;
