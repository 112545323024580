import React, { useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import {
  CropModalTextArea,
  CropPlanAddBtn,
  CropPlanButtonBox,
  CropPlanInput,
  CropPlanInputError,
  CropPlanInputText,
  CropPlanModal,
  CropPlanModalInner,
  CropPlanModalTitle,
  CropPlanCard,
  CropPlanCardHeader,
  CropPlanCardInner,
  CropPlanCardSearch,
  CropPlanTable,
} from "../../../../../styles/cropPlan/CropPlanStyles";
import { useForm } from "react-hook-form";
import { addACrop } from "../../../../../redux/actions/cropPlanDevelopment";
import { getCropList } from "../../../../../redux/actions/fieldManagement";
import { Table } from "antd";
const useStyles = makeStyles({
  root: {
    // background: '#eff9f6',
    borderRadius: 3,
    cursor: "pointer",
    // border: 0,
    color: "rgba(1, 28, 20, 0.9)",
    // padding: '0 30px',
    // height: '20em',
    border: "1px solid rgba(1, 28, 20, 0.2)",
    // boxShadow: '0 3px 5px 2px rgba(5,5,5, .3)',
    "&::-webkit-scrollbar": {
      width: "6px",
      background: "red",
      color: "red",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    outline: "none",
  },
});

const AddNewCrop = () => {
  const classes = useStyles();
  // --- get the access key of the user here ---
  const { access_token: access_key } = useSelector(
    (state) => state.authReducer
  );

  const [btnState, setBtnState] = useState({
    text: "Add Crop",
    isDisabled: false,
  });
  const [cropListCopy, setCropListCopy] = useState([]);
  const [cropList, setCropList] = useState([]);
  const [fetchCrops, setFetchCrops] = useState(true);
  const [selectedCrop, setSelectedCrop] = useState(0);
  const [deleteType, setDeleteType] = useState("Crop");
  // make request to get the list of the crops here
  useEffect(() => {
    async function fetchData() {
      setFetchCrops(true);

      let { isError, data } = await getCropList(access_key);

      if (isError) {
        setCropList([]);
        setCropListCopy([]);
        setFetchCrops(false);
      } else {
        setCropList(data);
        setCropListCopy(data);
        setFetchCrops(false);
      }
    }
    fetchData();
  }, [access_key]);
  const searchCrops = (e) => {
    let searchItem = e.target.value;
    let res = [];
    cropListCopy.find(function (post, index) {
      if (
        post?.name.includes(searchItem) ||
        post?.description.includes(searchItem)
      ) {
        res.push(post);
        return setCropList(res);
      }
      return 0;
    });
  };
  const tableState1 = {
    bordered: false,
    loading: fetchCrops,
    size: "default",
    scroll: true,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCrop(selectedRowKeys[0]);
      setDeleteType("Crop");
    },
  };
  const columns = [
    {
      title: <strong>Crop</strong>,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <strong>Description</strong>,
      dataIndex: "description",
      key: "description",
    },
  ];
  // handler to add a new crop
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (dataObject) => {
    setBtnState({ text: "Please wait!", isDisabled: true });

    let { isError } = await addACrop(access_key, dataObject);

    if (isError) {
      toast.error("Sorry, an error occured.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setBtnState({ text: "Add Crop", isDisabled: false });
    } else {
      toast("Crop successfully added", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setBtnState({ text: "Add Crop", isDisabled: false });
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* <CropPlanModal>
        <CropPlanModalInner> */}
      <Grid container spacing={1}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <CropPlanModalTitle>ADD A NEW CROP</CropPlanModalTitle>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CropPlanInputText>Crop Name</CropPlanInputText>
                <CropPlanInput
                  type="text"
                  placeholder="Crop Name"
                  defaultValue=""
                  {...register("name", { required: true })}
                />
                <CropPlanInputError>
                  {errors.name?.type === "required" && "Crop Name is required"}
                </CropPlanInputError>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "-1em" }}
              >
                <CropPlanInputText>Description</CropPlanInputText>
                <CropModalTextArea
                  type="text"
                  placeholder="Describe the crop here..."
                  defaultValue=""
                  {...register("description", { required: true })}
                />
                <CropPlanInputError>
                  {errors.description?.type === "required" &&
                    "Crop Description is required"}
                </CropPlanInputError>
              </Grid>
            </Grid>

            <CropPlanButtonBox style={{ marginTop: "0em" }}>
              <Grid container spacing={3}>
                <Grid item lg={2} md={2} sm={6} xs={2}></Grid>

                <Grid item lg={2} md={2} sm={6} xs={2}></Grid>

                <Grid item lg={4} md={4} sm={6} xs={4}>
                  {/* <CropPlanDelBtn onClick={props?.handleClose}>Cancel<CloseIcon /></CropPlanDelBtn> */}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <CropPlanAddBtn type="submit" disabled={btnState?.isDisabled}>
                    {btnState?.text}
                  </CropPlanAddBtn>
                </Grid>
              </Grid>
            </CropPlanButtonBox>
          </form>
        </Grid>

        {/* </CropPlanModalInner>
      </CropPlanModal> */}
        <Grid item lg={6} md={6} sm={12} xs={12} key="cropsDatabase">
          <CropPlanModalTitle>Crops Database</CropPlanModalTitle>
          <CropPlanCard>
            <CropPlanCardInner>
              <CropPlanCardSearch
                type="search"
                placeholder="Search any Crop by Name or Description"
                onInput={searchCrops}
              />
              <CropPlanTable>
                <Table
                  {...tableState1}
                  rowSelection={{
                    type: "radio",
                    ...rowSelection,
                  }}
                  dataSource={cropList}
                  columns={columns}
                  rowKey="id"
                />
              </CropPlanTable>
            </CropPlanCardInner>
          </CropPlanCard>
        </Grid>
      </Grid>
      {/* </Fade> */}
      {/* </Modal> */}
    </>
  );
};

export default AddNewCrop;
