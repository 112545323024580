import React, { useState, useEffect } from 'react';
import DefaultLayout from '../Layout/DefaultLayout';
import { TrackingContainer } from './AdminStyle.styled';
import { Table } from 'antd';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import {GetAllTrackingRecord} from "../../../redux/actions/adminOtherServices";
import {formatDateObjectHandler, GetFromNowDate} from "../../../utils";
// import {formatAmount} from "../../../utils/Helpers";
// import { useHistory } from 'react-router-dom';
// import { generatePath } from 'react-router';
// import { TrackingMenu } from './TrackingMenu';

const AdminTrackRecords = props => {
    const { GetAllTrackingRecord, allTracking } = props;
    const [loadingdata, setloadingdata] = useState(false);
    // const history = useHistory();

    // const getOneOrder = record => {
    //     const path = generatePath('/admin/tracking/:orderId', {
    //         orderId: record.id,
    //     });
    //     history.push(`${path}`);
    // };

    useEffect(() => {
        const getAllTrackedOrders = async () => {
            setloadingdata(true);
            const res = await GetAllTrackingRecord();
            if (res.success) {
                setloadingdata(false);
            } else {
                setloadingdata(false);
                toast.error(res.message, {
                    pauseOnHover: true,
                });
            }
        };
        getAllTrackedOrders();
    }, [GetAllTrackingRecord]);

    const formatNewRecord = allTracking.map((rec) => {
        return {
            ...rec,
            team_member: rec?.team_member?.member?.email,
            first_name: rec?.team_member?.member?.first_name,
            last_name: rec?.team_member?.member?.last_name,
            service: rec?.service.name,
            service_name: rec?.service?.service,
            date_created: rec?.date_created,
        }
    })

    const trackingColumn = [
        {
            title: 'Team Member',
            dataIndex: 'team_member',
            width: '250px',
            render: (_text, record) =>(
                <>
                    <div>Name: {record.first_name + ' ' + record.last_name}</div>
                    <span><a href={`mailto:${record.team_member}`}>{record.team_member}</a></span>
                </>
            )
        },
        {
            title: 'Service',
            dataIndex: 'service',
            align: 'center',
        },
        {
            title: 'Service Name',
            dataIndex: 'service_name',
            align: 'center',
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
        },
        {
            title: 'Date Created',
            dataIndex: 'date_created',
            align: 'center',
            render: (_text, record) => (
                <>
                    {formatDateObjectHandler(record.date_created, 'DD-MM-YYYY')} <br/>
                    <small style={{ color: 'green' }}>{GetFromNowDate(record.date_created, 'YYYY-MM-DDTHH:mm:ss', 'fromNow')}</small>
                </>
            )
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     align: 'center',
        //     render: (_text, record) => <TrackingMenu record={record} getOneOrder={getOneOrder} />,
        // },
    ];

    return (
        <DefaultLayout browserTitle="Tracking">
            <TrackingContainer data-aos="fade-right">
                <h3>Tracking</h3>
                <Table
                    loading={loadingdata}
                    rowKey="id"
                    bordered
                    style={{ overflowX: 'auto' }}
                    columns={trackingColumn}
                    dataSource={formatNewRecord}
                />
            </TrackingContainer>
        </DefaultLayout>
    );
};

const mapStateToProps = state => {
    return {
        allTracking: state.adminOtherReducer.allTracking,
    };
};

export default connect(mapStateToProps, { GetAllTrackingRecord })(AdminTrackRecords);
