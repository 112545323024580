import React from 'react';
import AdminDashboard from "../../components/admin/components/AdminDashboard";

const AdminDashboardPage = () => {
    return (
        <>
           <AdminDashboard />
        </>
    );
};

export default AdminDashboardPage;
