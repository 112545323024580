import axios from "axios"
import { fetchApi } from "../../adapters/util/fetchApi"
import { baseUrl } from "../../config"
import constants from "../constants"


export const createField = async (fieldFile, { access_key, id }) => {
    const formData = new FormData();
    let fieldName = fieldFile?.name?.split(".")[0];

    formData.append('upload', fieldFile);
    formData.append('team', id);
    formData.append('group_name', 'All');
    formData.append('name', fieldName);

    let requestHeaders = {
        "Content-Type": "multipart/form-data",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/create/`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi(formData, "POST", APIUrl, requestHeaders);

        if (isError) {
            console.log('error', errorMessage);
            return { errorMessage, isError };

        } else {
            // dispatch(getField());
            console.log('success', data);
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}



export const updateField = async (fieldData, access_key) => {

    // let dataToPost = { ...fieldDetails, team: id 

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/${fieldData?.id}/update/`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi(fieldData, "PATCH", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            // dispatch(getField());
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}


export const deleteField = async (fieldId, access_key) => {

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/${fieldId}/delete/`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "DELETE", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            // dispatch(getField());
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}

export const getUserFields = async (access_key) => {

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "GET", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}


export const getLatestField = async (access_key) => {

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/latest`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "GET", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}

export const getNvdiShape = async () => {
    // access_key
    let requestHeaders = {
        "Content-Type": "application/json",
        // "Authorization": `Token ${access_key}`
    }

    let dataObject = {
        shape: [
            [3.861677757042263, 8.28751894279872],
            [3.808452071066049, 8.436789361828778],
            [3.743125675341754, 8.327449844830408],
            [3.861677757042263, 8.28751894279872]
        ]
    }

    let APIUrl = `${baseUrl}/farm/ndvi/`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi(dataObject, "GET", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            // dispatch(getField());
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}


export const getUserFarmSummaries = async (access_key) => {

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/monitoring/`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "GET", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}

export const getSpecialFieldDetails = async (fieldId, access_key) => {

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/weather/field-info/?id=${fieldId}`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "GET", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}


export const getFieldReport = async (date, access_key, fieldId) => {

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/report/?start_date=${date?.start_date}&end_date=${date?.end_date}&field_id=${fieldId}`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "GET", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}


export const getCropList = async (access_key) => {

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/crop/list/`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "GET", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}

export const getField = () => (dispatch, getState) => {
    let access_key = getState().authReducer.access_token
    return axios.get(`${baseUrl}/field/`, {
        "headers": {
            "Content-Type": "application/json",
            Authorization: `Token ${access_key}`
        }
    })
        .then((res) => {
            dispatch({ type: constants.ALL_FIELDS, payload: res.data })
            return { success: true, message: "message" }
        })
        .catch((err) => {
            return { success: false, message: "message" }
        })
}

export const createCrop = (cropDetails) => (dispatch, getState) => {
    let access_key = getState().authReducer.access_token
    let id = getState().authReducer.user_details.owned_team.id || getState().authReducer.user_details.user_team
    let dataToPost = { ...cropDetails, team: id }
    return axios.post(`${baseUrl}/crop/define/`, dataToPost, {
        "headers": {
            "Content-Type": "application/json",
            Authorization: `Token ${access_key}`
        }
    })
        .then((res) => {
            dispatch(getCrop())
            return { success: true, message: "message" }
        })
        .catch((err) => {
            return { success: false, message: "message" }
        })
}

export const getCrop = () => (dispatch, getState) => {
    let access_key = getState().authReducer.access_token
    return axios.get(`${baseUrl}/crop/field/7/all/`, {
        "headers": {
            "Content-Type": "application/json",
            Authorization: `Token ${access_key}`
        }
    })
        .then((res) => {
            dispatch({ type: constants.ALL_CROPS, payload: res.data })
            return { success: true, message: "message" }
        })
        .catch((err) => {
            return { success: false, message: "message" }
        })
}

export const createCropRotation = (cropDetails) => (dispatch, getState) => {
    let access_key = getState().authReducer.access_token
    let id = getState().authReducer.user_details.owned_team.id || getState().authReducer.user_details.user_team
    let dataToPost = { ...cropDetails, team: id }
    return axios.post(`${baseUrl}/crop_rotation/define/`, dataToPost, {
        "headers": {
            "Content-Type": "application/json",
            Authorization: `Token ${access_key}`
        }
    })
        .then((res) => {
            dispatch(getCrop())
            return { success: true, message: "message" }
        })
        .catch((err) => {
            return { success: false, message: "message" }
        })
}

export const getCropRotation = () => (dispatch, getState) => {
    let access_key = getState().authReducer.access_token
    return axios.get(`${baseUrl}/crop_rotation/field/7/all/`, {
        "headers": {
            "Content-Type": "application/json",
            Authorization: `Token ${access_key}`
        }
    })
        .then((res) => {
            dispatch({ type: constants.ALL_CROPPLAN, payload: res.data })
            return { success: true, message: "message" }
        })
        .catch((err) => {
            return { success: false, message: "message" }
        })
}
