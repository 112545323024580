import { fetchApi } from "../../adapters/util/fetchApi"
import { baseUrl } from "../../config"

export const addACrop = async (access_key, dataObject) => {

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/crop/define/`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi(dataObject, "POST", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            // dispatch(getField());
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}

export const deleteACrop = async (access_key, cropId) => {

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/crop/${cropId}`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "DELETE", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            // dispatch(getField());
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}


export const getSeasonsByFieldId = async (access_key, fieldId, seasonYear) => {

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    // let APIUrl = `${baseUrl}/field/${fieldId}/retrieve/`;
    let APIUrl = `${baseUrl}/field/season/${seasonYear}/${fieldId}/all/`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "GET", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError2: isError };

        } else {
            return { seasonData: data }
        }

    }

    const response = await getResponse();
    return response;

}

export const addASeason = async (params, access_key) => {

    // let dataToPost = { ...fieldDetails, team: id 

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/season/define/`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi(params, "POST", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            // dispatch(getField());
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}


export const deleteASeason = async (fieldId, seasonCropId, access_key) => {

    // let dataToPost = { ...fieldDetails, team: id 

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key} `
    }

    let APIUrl = `${baseUrl}/field/season/${seasonCropId} `;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "DELETE", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            // dispatch(getField());
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}

export const createRotation = async (params, access_key) => {

    // let dataToPost = { ...fieldDetails, team: id 

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/rotation/define/`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi(params, "POST", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            // dispatch(getField());
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}


export const getRotationsByFieldId = async (access_key, fieldId) => {

    // let dataToPost = { ...fieldDetails, team: id 

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/rotation/?field=${fieldId}`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "GET", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}

export const rotateSeason = async (params, access_key, rotationId) => {

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    // let APIUrl = `${baseUrl}/field/rotation/${rotationId}/`;
    let APIUrl = `${baseUrl}/field/rotation/${rotationId}/update`;

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi(params, "PATCH", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            // dispatch(getField());
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}


export const getSeasonsByItsYear = async (access_key, fieldId, seasonYear) => {

    // let dataToPost = { ...fieldDetails, team: id 

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/season/?season=${seasonYear}&field=${fieldId}`;
    // let APIUrl = `${baseUrl}/field/season/?season=2021&field=1`;

    console.log('reeived', APIUrl);
    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "GET", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            // dispatch(getField());
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}


export const deleteRotation = async (access_key, rotationId) => {

    // let dataToPost = { ...fieldDetails, team: id 

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Token ${access_key}`
    }

    let APIUrl = `${baseUrl}/field/rotation/${rotationId}/`;


    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "DELETE", APIUrl, requestHeaders);

        if (isError) {

            return { errorMessage, isError };

        } else {
            // dispatch(getField());
            return { data: data }
        }

    }

    const response = await getResponse();
    return response;

}