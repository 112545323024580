import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from "antd";
import { CreateCustomModal } from "../UserManagement.styled";

const InviteSomeoneModal = (props) => {
  const [loading, setLoading] = useState(false);

  const { getTeamRoles, AssignARole, ownTeam_id } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const getRoles = getTeamRoles || [];

  const handleClose = () => {
    reset();
    props.handleCancel();
  };

  const PerformInvitation = async (data) => {
    setLoading(true);
    const roleData = {
      ...data,
      role: parseInt(data.role),
      team: ownTeam_id,
    };
    console.log(roleData);
    const res = await AssignARole(roleData);
    if (res.success) {
      toast("Role Assigned successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      props.handleOk();
      setLoading(false);
      reset();
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(`${res.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        title="Invite A Member"
        visible={props.isModalVisible}
        onOk={PerformInvitation}
        onCancel={props.handleCancel}
        footer={null}
        closable={false}

         // --- I added this so that the modal can overlay properly on mobile views
         zIndex={"10000"}
      >
        <CreateCustomModal>
          <div className="container">
          <div className="row form-group">
              <div className="col-4">
                <label className="new_team_label">First Name</label>
              </div>
              <div className="col-8">
                <input
                  {...register("first_name", { required: true })}
                  type="text"
                  className="form-control"
                />
                {errors.email && (
                  <span className="error">First Name is Required</span>
                )}
              </div>
            </div>
            <div className="row form-group">
              <div className="col-4">
                <label className="new_team_label">Last Name</label>
              </div>
              <div className="col-8">
                <input
                  {...register("last_name", { required: true })}
                  type="text"
                  className="form-control"
                />
                {errors.email && (
                  <span className="error">Last Name is Required</span>
                )}
              </div>
            </div>
            <div className="row form-group">
              <div className="col-4">
                <label className="new_team_label">Email</label>
              </div>
              <div className="col-8">
                <input
                  {...register("email", { required: true })}
                  type="text"
                  className="form-control"
                />
                {errors.email && (
                  <span className="error">Email Address is Required</span>
                )}
              </div>
            </div>
            <div className="row form-group">
              <div className="col-4">
                <label className="new_team_label">Choose Role</label>
              </div>
              <div className="col-8">
                <select
                  {...register("role", { required: true })}
                  className="form-control"
                >
                  {getRoles.length > 0
                    ? getRoles.map((role) => {
                        return (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
                {errors.role && <span className="error">Role is Required</span>}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="modal-foote row px-3">
              <div className="col-4">
                <Button block type="default" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
              <div className="col-8">
                <Button
                  block
                  onClick={handleSubmit(PerformInvitation)}
                  type="primary"
                  disabled={loading}
                  className="btn px-1 auth_btn"
                >
                  {loading ? "Inviting..." : "Invite Team Member"}
                </Button>
              </div>
            </div>
          </div>
        </CreateCustomModal>
      </Modal>
    </div>
  );
};

export default InviteSomeoneModal;
