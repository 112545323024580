import styled, { keyframes } from 'styled-components';

import homeBgOverlay from '../../assets/img/home/homeBackdrop.gif';

// --- Utils ---

export const carouselDot = styled.div`
  width: 4em;
  height: 4em;
  background: red;
`;

export const shakeAmination = keyframes`
0% { transform: translate(1px, 1px) rotate(0deg); }
10% { transform: translate(-1px, -2px) rotate(-1deg); }
20% { transform: translate(-3px, 0px) rotate(1deg); }
30% { transform: translate(3px, 2px) rotate(0deg); }
40% { transform: translate(1px, -1px) rotate(1deg); }
50% { transform: translate(-1px, 2px) rotate(-1deg); }
60% { transform: translate(-3px, 1px) rotate(0deg); }
70% { transform: translate(3px, 1px) rotate(-1deg); }
80% { transform: translate(-1px, -1px) rotate(1deg); }
90% { transform: translate(1px, 2px) rotate(0deg); }
100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

// ----
export const MainBody = styled.main`
  height: fit-content;
  width: 100%;
  // position: absolute;
  // margin-bottom: 5em;
`;

export const BodyFirstSection = styled.section`
  margin-top: -6em;
  padding: 0 3%;
  height: fit-content;
  width: 100%;
  background: #011811;
  text-align: left;
`;

export const FirstSectionTitle = styled.h3`
  margin-top: 40vh;
  font-weight: 700;
  font-size: 48.83px;
  line-height: 63px;
  color: #FAFFFD;
  @media screen and (max-width: 769px) {
    margin-top: 35vh;
    font-size: 30px;
  }
`;

export const FirstSectionText = styled.p`
  font-weight: 400;
  line-height: 21px;
  color: #fff;
  font-size: 16px;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
`;

export const FirstSectionButton = styled.button`
  margin-left: auto;
  margin-right: auto;
  width: 12em;
  text-align: center;
  height: 3em;
  background: #ffffff;
  color:  #102E31;;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 4px 7px -1px rgba(0, 0, 0, 0.11),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  border: none;
  transition: 0.5s color, 0.5s background;
  &:hover {
    background: transparent;
    color: white;
    border: 1px solid white;
  }
`;

export const FirstSectionGroup = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-top: 5em;
  margin-bottom: 1em;
  @media screen and (max-width: 769px) {
    margin-bottom: 2em;
  }
`;

export const SecondSection = styled.section`
  width: 100%;
  height: fit-content;
  background: #000F0B;
  padding: 1% 3%;
`;
export const SecondSectionTitle = styled.h3`
  font-weight: 700;
  font-size: 39.06px;
  line-height: 51px;
  color: #FAFFFD;
  @media screen and (max-width: 769px) {
    font-size: 30px;
  }
`;

export const SecondSectionText = styled.p`
  font-weight: 400;
  line-height: 21px;
  color: #fff;
  font-size: 16px;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
`;

export const SecondSectionButton = styled.button`
  margin-left: auto;
  margin-right: auto;
  width: 12em;
  text-align: center;
  height: 3em;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 4px 7px -1px rgba(0, 0, 0, 0.11),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  border-color: #fff;
  transition: 0.5s color, 0.5s background;
  background: transparent;
  &:hover {
    background: white;
    color: #000F0B;
    border: 1px solid white;
  }
`;
export const ThirdSection = styled.section`
  width: 100%;
  background: linear-gradient(88.57deg, #011811 20.69%, #253631 74.52%);
  padding: 1% 3%;
`;

export const ThirdSectionInner = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 2em;
  height: fit-content;
  // margin-bottom: 2em;
`;

export const ThirdSectionBottomSvg = styled.svg`
<!-- margin-bottom:-8em; -->
<!-- margin-bottom:-8em; -->
<!-- background:white; -->
`;

export const ThirdSectionCard = styled.div`
  width: 100%;
  margin: 3% 0;
`;

export const ThirdSectionCardInner = styled.div`
  margin-top: 0.5em;
  margin-left: 1em;
  margin-right: 1em;
`;

export const ThirdWhiteCardImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.3em;
`;

export const TryItCard = styled.div`
  width: 50%;
  height: 3em;
  background: #C4FCEC;;
  box-sizing: border-box;
  border-radius: 0px 8px;
  text-align: center;
  padding: 0.8em 0.1em;
  color: #0A4C3E;
  cursor: pointer;
  font-weight: 700;
  bottom: 10px;
  position: absolute;
`;

export const FourthSection = styled.section`
  width: 100%;
  height: fit-content;
  background: linear-gradient(88.57deg, #000F0B 20.69%, #0B1916 74.52%);
  padding: 3%;
`;

export const CompaniesSection = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
`;

export const CompanyLogo = styled.img`
  // width:10em;
  // height:3em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
`;

export const FourthSectionCard = styled.div`
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  margin-top: 1em;
`;

export const FourthSectionCount = styled.h3`
  color: #FAFFFD;;
  font-size: 48px;
  line-height: 1em;
  font-weight: bold;
`;

export const FourthSectionName = styled.h6`color:#C4FCEC;`;

export const FourthSectionText = styled.p`color: #C4FCEC;`;

export const FifthSection = styled.div`
  width: 100%;
  height: fit-content;
  background-color:  #000F0B; 
  padding: 3em 3%;
  box-shadow: inset 2000px 0 0 0 rgba(0, 15, 11, 0.9);
  // height: 500px;
  @media screen and (max-width: 769px) {
    // margin-bottom: 2em;
  }
`;

export const FifthSectionTitle = styled.h3`
  text-align: center;
  color: #C4FCEC;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
`;

// export const FifthSectionText = styled.p`
//   font-family: Avenir;
//   font-style: normal;
//   font-weight: normal;
//   line-height: 2em;
//   text-align: center;
//   letter-spacing: -0.034em;
//   color: #011c14;
// `;

export const FifthSectionImage = styled.img`
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2em;
`;

// ---- Sixth Section ---
export const SixthSection = styled.section`
  background-color:  #000F0B; 
  padding: 3% 15%;
`;

export const SixthSectionButton = styled.button`
margin-left: auto;
margin-right: auto;
width: 12em;
text-align: center;
height: 3em;
font-weight: 700;
font-size: 16px;
box-shadow: 0px 4px 7px -1px rgba(0, 0, 0, 0.11),
  0px 2px 4px -1px rgba(0, 0, 0, 0.07);
border-radius: 8px;
border: 1px solid white;
transition: 0.5s color, 0.5s background;
background: white;
color: #0A4C3E;
&:hover {
  background: white;
  color: #000F0B;
  border: 1px solid white;
}
`;

export const SixthSvg = styled.svg`
  <!--: -15em;
  -->: -10em;
  color: linear-gradient(135deg, #05674a 0%, #141727 100%);
`;

export const SixthPath = styled.path`
  // fill: linear-gradient(135deg, #05674A 0%, #141727 100%);
`;

export const SixthSectionNews = styled.section`
background: linear-gradient(88.57deg, #0A4C3E 20.69%, #05674A 74.52%);
border-radius: 60px;
padding: 3%;
// transform: matrix(-1, 0, 0, 1, 0, 0);
// margin: 4%;
`

export const SixthSectionFormdiv = styled.div`
background: #FAFFFD;
border-radius: 16px;
margin: 40px 0px;
display: flex;
`;
export const SixthSectionForm = styled.input`
background: #FAFFFD;
border-radius: 16px;
border-color: transparent;
width: 70%;
padding: 3%;
color: #0A4C3E;
&:focus:{
color: #0A4C3E;
}
`;
export const SixthSectionButtonNews = styled.button`
background:#0A4C3E;
border: 1px solid #0A4C3E;
box-sizing: border-box;
border-radius: 8px;
width: 30%;
height: inherit;
`;