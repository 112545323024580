import constants from "../constants";
import axios from "axios";
import { baseUrl } from "../../config";

export const AllSystemPayments = () => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/accounts/payment/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch({ type: constants.ALL_PAYMENT_RECORD, payload: res?.data?.results });
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const GetAllAccountUsers = (details) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/accounts/all-users/`, {
        params: {
            ...details,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch({ type: constants.ALL_ACCOUNT_USERS, payload: res?.data });
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const GetOnlyOneAccountUsers = (id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/accounts/${id}/profile/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch({ type: constants.ONE_ACCOUNT_USER, payload: res?.data });
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const AdminDeleteOneUser = (id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.delete(`${baseUrl}/accounts/user/delete/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch(GetAllAccountUsers());
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const GetAllAccountTeams = (search) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/accounts/team/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        params: {
            ...search,
        },
    }).then((res) => {
        dispatch({ type: constants.ALL_ACCOUNT_TEAMS, payload: res?.data });
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const ApproveTeamAccount = (details, id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.patch(`${baseUrl}/accounts/team/${id}/`, details,{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch(GetAllAccountTeams());
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const GetAllTrackingRecord = () => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/tracking/tracking/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch({ type: constants.ALL_TRACKING_RECORDS, payload: res?.data?.results });
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const GetAllAccountSubscriptions = () => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/accounts/subscriptions/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch({ type: constants.ALL_ACCOUNT_SUBSCRIPTIONS, payload: res?.data?.results });
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}

export const GetOneAccountSubscriptions = (id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/accounts/subscriptions/${id}/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch({ type: constants.ONE_ACCOUNT_SUBSCRIPTIONS, payload: res?.data?.results });
        return { success: true, message: res?.data?.details }
    }).catch((err) => {
        console.log('err', err?.response);
        return { success: false, message: err?.response?.data?.detail }
    })
}