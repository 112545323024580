import styled from 'styled-components';

export const SatelliteContainer = styled.main`
  // z-index: 10000000000000;
  // bottom: 0;
  // width: 100%;
  // width:103%;
  height: fit-content;
  // position: fixed;
  // margin-left: -2em;
  // position:absolute;
  margin-top: -2em;
  background: white;
`;

export const SplitViewScreen = styled.div`
display:initial;
@media screen and (max-width:769px){
  display:none;
}`;

export const SatelliteBottom = styled.section`
  width: 100%;
  height: 25vh;
  // position: fixed;
  // width: 97%;
  background: rgba(1, 28, 20, 1);
`;

export const CloudRange = styled.input`
  // transform: rotate(270deg);
  // right:8.3em;
  // position: relative;
  // margin-top:10.5em;
  margin-top: 1em;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
`;

export const SatelliteRange = styled.div`
  // height: 22em;
  // width: 3.5em;
  width: 100%;
  height: 3.5em;
  background: white;
  border-radius: 0.5em;
  // position: fixed;
  // margin-top: -0.2em;
  // margin-top: 15em;
  // bottom: 0em;
  z-index: 1000;
  background: rgba(1, 28, 20, 1);
  // left: 0;
  // margin-left: 6em;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  @media screen and (max-width: 769px) {
    // margin-right: 4em;
    // margin-top:20 15em;
  }
  // display:none;
`;

export const SwitchBox = styled.div`
  width: 3.5em;
  height: 3.5em;
  background: white;
  margin-left: 2em;
  border-radius: 0.5em;
  z-index: 1000;
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  color: rgba(1, 28, 20, 1);
  transition: color 0.5s, background 0.5s;
  &:hover {
    background: rgba(1, 28, 20, 1);
    color: white;
  }
  background: white;
  position: absolute;
  padding: 0.5em 0.5em;
  bottom: 10em;
  left: 0;
  margin-left: 2em;
  @media screen and (max-width:768px){
    bottom:8em;
  }
`;

export const DownloadBox = styled.div`
  width: 3.5em;
  height: 3.5em;
  background: rgba(1, 28, 20, 0.9);
  position: absolute;
  margin-left: 2em;
  // bottom:0;
  // margin-top: 60vh;
  border-radius: 0.5em;
  z-index: 1000;
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  color: white;
  transition: color 0.5s, background 0.5s;
  &:hover {
    background: white;
    color: rgba(1, 28, 20, 1);
  }
  position: absolute;
  padding: 0.5em 0.5em;
  bottom: 15em;
  left: 0;
  margin-left: 2em;
`;

export const NextBox = styled.div`
  width: 8em;
  height: 4em;
  background: white;
  position: fixed;
  margin-right: 2em;
  // bottom:0;
  border-radius: 0.5em;
  z-index: 10000000;
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  color: white;
  transition: color 0.5s, background 0.5s;
  &:hover {
    background: white;
    color: rgba(1, 28, 20, 1);
  }
  background: rgba(1, 28, 20, 1);
  position: absolute;
  padding: 0.5em 0.5em;
  bottom: 8em;
  right: 0;
  margin-right: 4em;
  @media screen and (max-width:769px){
    bottom:8em;
  margin-right: 2em;
  }
`;

export const PreviousBtn = styled.div`
  width: 8em;
  height: 4em;
  background: white;
  position: fixed;
  // bottom:0;
  border-radius: 0.5em;
  z-index: 10000000;
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  color: white;
  transition: color 0.5s, background 0.5s;
  &:hover {
    background: white;
    color: rgba(1, 28, 20, 1);
  }
  background: rgba(1, 28, 20, 1);
  position: absolute;
  padding: 0.5em 0.5em;
  bottom: 8em;
  left: 0;
  margin-left: 4em;
  @media screen and (max-width:769px){
    bottom:8em;
  margin-left: 2em;
  }
`;
