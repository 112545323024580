import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { getField } from "../../redux/actions/fieldManagement";
import HistoryChart from "../../components/weather/HistoryChart";
import {
  getHistoryChart,
  getRainfallData,
} from "../../redux/actions/weatherManagement";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import { backDate } from "../../components/util/Util";

const HistoryChartPage = (props) => {
  const [stillFetching, setStillFetching] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [rainfallData, setRainfallData] = useState([]);

  let queryCentroid = props?.location?.pathname.substr(27).split(",");
  // stripe off into two separate points
  let lat = queryCentroid[1]?.substr(0, 5);
  let long = queryCentroid[0]?.substr(0, 5);

  let queryCoord = lat + "," + long;

  const [centroid, setCentroid] = useState(queryCoord);

  let today = backDate(0);
  let from = backDate(1);

  const [fromDate, setFromDate] = useState(from);
  const [toDate, setToDate] = useState(today);

  useEffect(() => {
    async function fetchData() {
      let { isError, data } = await getHistoryChart(centroid, fromDate, toDate);
      if (isError) {
        toast.error(
          "It looks like we could not provide the weather data at the moment. Please kindly try again or ensure you select the right field or date range.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        setChartData(data);
        setStillFetching(false);
      }
    }
    fetchData();
  }, [fromDate, toDate, centroid]);

  // -- Query the endpoint to get the rainfall data
  useEffect(() => {
    async function fetchData() {
      let { isError, data } = await getRainfallData(centroid, fromDate, toDate);
      if (isError) {
        console.log("error");
      } else {
        setRainfallData(data);
      }
    }
    fetchData();
  }, [fromDate, toDate, centroid]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <DashboardLayout pageTitle="Weather Forecast">
        <HistoryChart
          from={fromDate}
          to={toDate}
          stillFetching={stillFetching}
          setFromDate={setFromDate}
          setToDate={setToDate}
          setCentroid={setCentroid}
          setStillFetching={setStillFetching}
          chartData={chartData}
          rainfallData={rainfallData}
          urlCoords={queryCentroid}
          setChartData={setChartData}
          centroid={centroid}
        />
      </DashboardLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    all_field: state.fieldManagementReducer.fields,
  };
};
export default connect(mapStateToProps, { getField })(HistoryChartPage);
