import styled from "styled-components";

export const FarmActivitiesHeader = styled.div`
  position: relative;
  transition: ease all 0.5s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 1.5rem;
    margin: 2.3rem 0;
    transition: ease all 0.5s;
  }
  button {
    
  }
`;

export const FarmConfigureContainer = styled.div`
  position: relative;
  /* border: 1px solid #000; */
  transition: ease all 0.5s;
  .taskhead {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-bottom: 1px solid #eee;
    padding: 1rem;
    transition: ease all 0.5s;
    font-weight: bold;
  }
  .ant-card-body {
    padding: 0;
  }
  .taskbody {
    transition: ease all 0.5s;
    padding: 2rem;
    .ant-picker-input > input {
      color: #000 !important;
    }
    form {
      position: relative;
      transition: ease all 0.5s;
      label {
        font-size: 0.9rem;
        font-weight: 500;
      }
      .form-group {
        margin-bottom: 2rem;
      }
      .ant-input,
      .ant-picker {
        height: 50px;
        background: #fafafa;
        border-radius: 5px;
        border: 1px solid #f1f1f1;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: #fafafa;
        border: none;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
      textarea.ant-input {
        height: 75px;
        resize: none;
      }
      .ant-picker {
        width: 100%;
      }
      .cancelbtn,
      .ant-btn-primary {
        border-radius: 5px;
        height: 56px;
        font-size: 1.2rem;
        font-weight: bold;
        transition: ease all 0.5s;
        &:hover {
          transition: ease all 0.5s;
          transform: translateY(-5px);
        }
      }
      .cancelbtn {
        background: #f2f2f2;
        border: 1px solid #e0e0e0;
        &:hover {
          color: #000000;
        }
      }
      .ant-btn-primary {
        background: var(--primary-green-color);
        border-color: var(--primary-green-color);
      }
    }
    textarea {
      height: 100px;
      resize: none;
    }
  }
`;

export const FarmDetailContainer = styled.div`
  position: relative;
  transition: ease all 0.5s;
  .example {
    margin: 20px auto;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 22%;
    justify-content: center;
    align-items: center;
    transition: ease all 0.5s;
    svg {
      transition: ease all 0.5s;
      transform: rotate(0deg);
    }
    .ant-btn-dashed {
      margin-top: 4rem;
      &:hover {
        svg {
          transition: ease all 0.5s;
          transform: rotate(180deg);
        }
      }
    }
  }

  .ant-btn-dashed {
    margin-bottom: 1rem;
    background: #ddd;
    svg {
      transition: ease all 0.5s;
      transform: rotate(0deg);
    }
    &:hover {
      color: var(--primary-green-color);
      /* background: var(--primary-green-color); */
      border-color: var(--primary-green-color);
        svg {
          transition: ease all 0.5s;
          transform: rotate(180deg);
        }
      }
  }

  h4 {
    font-size: 1rem;
    position: relative;
    margin-bottom: 15px;
    &:after {
      position: absolute;
      content: "";
      bottom: -9px;
      background: #eef9f6;
      height: 1px;
      width: 100%;
      left: 0;
      right: 0;
    }
  }
  .details {
    position: relative;
    transition: ease alll 0.5s;
    margin-top: 1rem;
    > div {
      display: flex;
      margin-bottom: 1rem;
      flex: 1;
      align-items: flex-start;
      p {
        color: #555;
        margin-right: 2rem;
        flex: 0 0 26%;
      }
      span {
        line-height: 20px;
        font-size: 1rem;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
    .closed-reason {
      display: flex;
      flex-direction: column;
      border: 1px dashed #ddd;
      background: #eee;
      font-size: 1rem;
      margin: 0 10px 20px;
      border-radius: 7px;
      span {
        padding: 0 1rem 1rem;
        font-size: 0.9rem;
      }
      p {
        padding: 10px 1rem;
        margin-bottom: 0;
      }
    }
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 45px;
    border-radius: 3px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    font-weight: bold;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector::after,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 40px;
  }
  .ant-btn-primary {
    height: 45px;
    border-radius: 3px;
    font-size: 0.9rem;
    font-weight: bold;
    transition: ease all 0.5s;
    background: var(--primary-green-color);
    border-color: var(--primary-green-color);
    &:hover {
      transition: ease all 0.5s;
      transform: translateX(-5px);
    }
  }
`;

export const AllFarmTable = styled.div`
  position: relative;
  transition: ease all 0.5s;
  .downbtn {
    background: var(--primary-green-color);
    border-color: var(--primary-green-color);
    border-radius: 5px;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .ant-card-body {
    padding: 10px;
  }
  .btn-primary {
    background: var(--primary-green-color);
    border-color: var(--primary-green-color);
  }
  .ant-btn-default {
    height: 41px;
    border-radius: 6px;
    &:hover {
      /* background: var(--primary-green-color); */
      border-color: var(--primary-green-color);
      color: var(--primary-green-color);
    }
  }
`;

export const CloseTask = styled.div`
  transition: ease all 0.5s;
  form {
    textarea.ant-input {
      height: 100px;
      resize: none;
    }
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector {
      height: 100px;
      border-radius: 3px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      position: relative;
      background-color: #f1f1f1;
      border: 1px solid #f1f1f1;
      border-radius: 2px;
      // font-weight: bold;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector::after,
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-item,
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-placeholder {
      line-height: 40px;
    }
  }
  .ant-modal-footer {
    color: #000;
    .ant-btn-primary {
      background: var(--primary-green-color);
      border-color: var(--primary-green-color);
    }
  }
`;

export const MyFarmTaskContainer = styled.div`
  transition: ease all 0.5s;
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background-color: var(--primary-green-color);
    border-radius: 3px;
  }
`;
