// Operations to display all fields and edit any field info here ----
import React, { useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import CloseIcon from '@material-ui/icons/Close';

import {
    EditModalBox,
    EditModalBoxLeft,
    EditModalBoxRight,
    EditModalBoxText,
    EditModalInput,
    EditModalInputText,
    EditModalInner,
    EditModalButton,
    EditAddSeasonBox,
    EditAddSeasonIcon,
} from "../../styles/util/UtilStyle";
import { Divider, Grid } from "@material-ui/core";
import { deleteField, updateField } from "../../redux/actions/fieldManagement";
import { useSelector } from "react-redux";

import { CropPlanButtonBox, CropPlanDelBtn, CropPlanModal, CropPlanModalInner, CropPlanModalTitle } from "../../styles/cropPlan/CropPlanStyles";

import GetMap from "../geoMap/GetMap";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        outline: "none",
    },
}));

const FieldsMap = (polygonPath, centroid, zoomVal) => {
    return (
        <>
            <GetMap polygonPath={polygonPath} centroid={centroid} zoomVal={zoomVal} />
        </>
    );
};

// --- modal to delete a field
// --- used style of the crop plan delete modal ---
export const DeleteFieldModal = (props) => {

    const classes = useStyles();
    const {
        access_token: access_key
    } = useSelector((state) => state.authReducer);
    const history = useHistory();

    const [btnState, setBtnState] = useState({ text: 'Yes, Delete Field', isDisabled: false });

    const handleDelField = async () => {
        setBtnState({ text: 'Please wait!', isDisabled: true });

        let { isError } = await deleteField(props?.fieldData?.id, access_key);

        if (isError) {

            toast.error('Sorry, an error occurred', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBtnState({ text: 'Yes, Delete Field', isDisabled: false });

        } else {

            toast(`${props?.fieldData?.name} Field successfully deleted!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBtnState({ text: 'Yes, Delete', isDisabled: false });

            props?.handleClose();
            props?.handleCloseEdit();
            // close modal and refresh the page after a second.
            // perform data table refresh for farm summary and page refresh for dashboard 
            if (props?.setRenderData) {
                // trigger refresh on the data table
                props?.setRenderData((props?.fieldData?.id - 1));
            } else {
                setTimeout(() => {
                    history.go(0);
                }, 1000);
            }


        }
    }

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props?.open}
                onClose={props?.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
                style={{ border: 'none !important' }}
            >
                <Fade in={props?.open}>
                    <CropPlanModal>
                        <CropPlanModalInner>
                            <Grid container spacing={1}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <CropPlanModalTitle>Are You Sure?</CropPlanModalTitle>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6} style={{ float: 'right' }}>
                                    <CloseIcon onClick={props?.handleClose} style={{ float: 'right', cursor: 'pointer', color: 'rgba(200,0,0,1)' }} />
                                </Grid>
                            </Grid>
                            <CropPlanButtonBox style={{ marginTop: '0em' }}>
                                <Grid container spacing={3}>

                                    <Grid item lg={2} md={2} sm={6} xs={2}>
                                    </Grid>

                                    <Grid item lg={2} md={2} sm={6} xs={2}>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={6} xs={4}>
                                        {/* <CropPlanDelBtn onClick={props?.handleClose}>Cancel<CloseIcon /></CropPlanDelBtn> */}
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={6} xs={4}>
                                        <CropPlanDelBtn onClick={handleDelField} disabled={btnState?.isDisabled}>{btnState?.text}</CropPlanDelBtn>
                                    </Grid>
                                </Grid>
                            </CropPlanButtonBox>
                        </CropPlanModalInner>
                    </CropPlanModal>
                </Fade>
            </Modal>
        </>
    )
}


// modal to edit any of the fetched fields
export const EditModal = (props) => {

    const classes = useStyles();
    const [deleteOpen, setDeleteOpen] = useState(false);

    const [btnState, setBtnState] = useState({ text: 'Save', isDisabled: false });

    const [fieldData, setFieldData] = useState({
        name: props?.fieldData?.name,
        group_name: props?.fieldData?.group_name,
        coordinates: props?.fieldData?.coordinates,
        centroid: props?.fieldData?.centroid,
        size: props?.fieldData?.size,
        id: props?.fieldData?.id
    });

    useEffect(() => {
        setFieldData({
            name: props?.fieldData?.name,
            group_name: props?.fieldData?.group_name,
            coordinates: props?.fieldData?.coordinates,
            centroid: props?.fieldData?.centroid,
            size: props?.fieldData?.size,
            id: props?.fieldData?.id
        })
    }, [props?.fieldData]);

    const handleChange = (event) => {
        setFieldData({ ...fieldData, [event.target.name]: event.target.value });
    };

    const {
        access_token: access_key
    } = useSelector((state) => state.authReducer);

    const handleSave = async () => {

        setBtnState({ text: 'Please wait...', isDisabled: true })

        let { isError } = await updateField(fieldData, access_key);

        if (isError) {
            toast.error("An error occurred while updating the field", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setBtnState({ text: 'Save', isDisabled: false });
        } else {
            toast("Field successfully updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setBtnState({ text: 'Save', isDisabled: false });
        }

        props?.handleCloseEdit();
        // close modal and refresh the page after a second.
        // perform data table refresh for farm summary and page refresh for dashboard 
        if (props?.setRenderData) {
            // trigger refresh on the data table
            props?.setRenderData((props?.fieldData?.id + 1));
        }
    };


    const handleCloseDelete = () => {
        setDeleteOpen(false);
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props?.open}
                onClose={props?.handleCloseEdit}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
            >
                <Fade in={props?.open}>
                    <EditModalBox>
                        <Grid container spacing={0}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <EditModalBoxLeft>
                                    {FieldsMap(
                                        props?.fieldData?.coordinates,
                                        props?.fieldData?.centroid,
                                        11
                                    )}
                                </EditModalBoxLeft>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <EditModalBoxRight>
                                    <EditModalInner>
                                        <EditModalBoxText>Edit Field</EditModalBoxText>
                                        <Divider
                                            style={{
                                                background: "rgba(255,255,255,0.5)",
                                                marginTop: "1em",
                                                marginBottom: "0.5em",
                                            }}
                                        />
                                        <Grid container spacing={2}>
                                            <Grid item lg={12} md={12} xs={12} sm={12}>
                                                <EditModalInputText>Field Name</EditModalInputText>
                                                <EditModalInput
                                                    name="name"
                                                    value={fieldData.name}
                                                    onChange={handleChange}
                                                    placeholder="Field Name"
                                                />
                                            </Grid>

                                            <Grid item lg={6} md={6} xs={6} sm={6}>
                                                <EditModalInputText>Group Name</EditModalInputText>
                                                <EditModalInput
                                                    name="group_name"
                                                    value={fieldData.group_name}
                                                    onChange={handleChange}
                                                    placeholder="Group Name"
                                                />
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6} sm={6}>
                                                <EditModalInputText>Field Area Size</EditModalInputText>
                                                <EditModalInput
                                                    name="size"
                                                    value={fieldData.size}
                                                    onChange={handleChange}
                                                    placeholder="Size"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item lg={6} md={6} xs={6} sm={6}>
                                                <EditAddSeasonBox>
                                                    <Link to={`/dashboard/crop-plan/config/farm/${fieldData?.id}`}>
                                                        <EditAddSeasonIcon style={{ textDecoration: 'underline' }}>
                                                            Configure Field Crop
                      </EditAddSeasonIcon>
                                                    </Link>
                                                </EditAddSeasonBox>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6} sm={6}>
                                                <EditAddSeasonBox>
                                                    <Link to={`/dashboard/farmsummary/info/${fieldData?.id}`}>
                                                        <EditAddSeasonIcon style={{ textDecoration: 'underline', float: 'right' }}>
                                                            View Field Info
                      </EditAddSeasonIcon>
                                                    </Link>
                                                </EditAddSeasonBox>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6} sm={6}>
                                                <EditModalButton style={{ background: 'rgba(200,0,0,0.9)' }} onClick={() => setDeleteOpen(true)}>
                                                    Delete Field
                      </EditModalButton>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6} sm={6}>
                                                <EditModalButton onClick={handleSave} disabled={btnState?.isDisabled}>{btnState?.text}</EditModalButton>
                                            </Grid>
                                        </Grid>
                                    </EditModalInner>
                                </EditModalBoxRight>
                            </Grid>
                        </Grid>


                    </EditModalBox>
                </Fade>
            </Modal>

            {/* --- Import the Delete field here --- */}
            <DeleteFieldModal
                open={deleteOpen}
                fieldData={fieldData}
                handleClose={handleCloseDelete}
                setRenderData={props?.setRenderData}
                handleCloseEdit={props?.handleCloseEdit}
            />
        </>
    );
};