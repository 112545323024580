import React from "react";
import AllFarmActivityTable from "../../components/farmActivity/AllFarmActivityTable";

const AllFarmActivityPage = () => {
  return (
    <>
      <AllFarmActivityTable />
    </>
  );
};

export default AllFarmActivityPage;
