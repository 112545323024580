import React, { useState } from "react";
import { Modal, Row, Col, Select } from "antd";
import { toast } from "react-toastify";
import { CloseTask } from "./FarmActivitiy.styled";
import {useForm, Controller} from "react-hook-form";
const { Option } = Select;

const ReAssignFarmActivities = (props) => {
  const { taskrecord, ReAssignFarmTask, team_members } = props;
  const [reloading, setreloading] = useState(false);

  const children = team_members?.map((user) => {
    return (
        <Option key={user.member.pk} value={user.member.email}>
          {user.member.email}
        </Option>
    );
  });
  const {
    // register,
    handleSubmit,
    reset,
    control,
  } = useForm();

  const ReassignFarmTask = async (data) => {
    const newObjList = data?.users.map((str, index) => ({ email: str, id: index + 1 }));
    const newAssigneeIds = [];

    team_members.map((user) => {
      // eslint-disable-next-line array-callback-return
      return newObjList.map((newObjEmail) => {
        if (user.member.email === newObjEmail.email) {
          return newAssigneeIds.push(user.member.pk);
        }
      })
    });

    const newUsers = {
      users: newAssigneeIds,
    };
    console.log('::::: id 22 :::::', newUsers);
    setreloading(true);
    const res = await ReAssignFarmTask(newUsers, taskrecord?.id);
    if (res.success) {
      toast("Farm Activity Re-Assigned successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      reset();
      props.handleCancel();
      setreloading(false);
    } else {
      setreloading(false);
      toast.error(`${res.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Modal
        title={`Want to Re-Assign this Task: ${taskrecord?.name}?`}
        visible={props.isModalVisible}
        onOk={handleSubmit(ReassignFarmTask)}
        onCancel={props.handleCancel}
        closable={false}
        okText="Re-Assign Task"
        maskClosable={false}
        okButtonProps={{
          loading: reloading,
          type: "primary",
        }}
        cancelText="Cancel"
      >
        <CloseTask>
          <form onSubmit={handleSubmit(ReassignFarmTask)}>
            <div className="form-group">
              <Row gutter={24} align="middle" justify="space-between">
                <label>Select Assignee</label>
                <Col xs={24} xl={24} lg={24}>
                  <Controller
                      control={control}
                      name="users"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Select
                              mode="multiple"
                              allowClear
                              name="users"
                              value={value}
                              style={{ width: "100%" }}
                              defaultValue={taskrecord?.assignees}
                              onChange={onChange}
                              size="large"
                              showArrow={true}
                              maxTagCount={5}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                  option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                  optionA.children
                                      .toLowerCase()
                                      .localeCompare(optionB.children.toLowerCase())
                              }
                          >
                            {children}
                          </Select>
                      )}
                  />
                </Col>
              </Row>
            </div>
          </form>
        </CloseTask>
      </Modal>
    </>
  );
};

export default ReAssignFarmActivities;
