import React, { useEffect, useState } from 'react';

import { BasemapLayer } from 'react-esri-leaflet';

import { MapContainer, Polygon, useMap } from 'react-leaflet';

const BoundMap = (props) => {

    const map = useMap();

    setTimeout(() => {
        try {
            map.fitBounds(props?.polygon?.current?._bounds)
        } catch (e) {
            console.log("Map could not be bound")
        }
    }, 3000);

    return null;
}

const GetMap = (props) => {

    const polygon = React.useRef();

    const purpleOptions = { color: 'white' };

    const [polygonPath, setPolygonPath] = useState([]);

    useEffect(() => {

        let swapPath = [];

        props?.polygonPath.map(eachPath => {
            if (eachPath?.length >= 2) {
                let p = [eachPath[1], eachPath[0]];
                swapPath.push(p);
            }

            return 0;
        })
        setPolygonPath(swapPath);

    }, [props?.centroid, props?.polygonPath]);
    // [8.6775, 9.0778]
    // check if the centroid is not empty or not
    let c = props?.centroid?.length >= 2 ? [props?.centroid[1], props?.centroid[0]] : [8.6775, 9.0788];

    return (
        <>
            <MapContainer attributionControl={false} center={c} zoom={props?.zoomVal} zoomControl={false} style={{ height: "100%" }}>
                <Polygon whenCre ref={polygon} pathOptions={purpleOptions} positions={polygonPath} />
                <BasemapLayer name="Imagery" />
                <BoundMap polygon={polygon} />
            </MapContainer>
        </>
    )
};

export default GetMap;