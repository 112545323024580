import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import {
  getTeamMembers,
  getTeamRoles,
  AssignARole,
  UpdateReassignRole,
  removeMember,
} from "../../redux/actions/userManagement";
import { ManageUser } from "./UserManagement.styled";
import InviteSomeoneModal from "./roles/InviteSomeoneModal";
// import Button from "../../components/buttons/button";
import { Edit, Delete } from "@material-ui/icons";
import { Popconfirm, Button, Spin, Tag, Tooltip } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReAssignRoleModal from "./roles/ReAssignRoleModal";

const UserManagement = (props) => {
  let {
    AssignARole,
    UpdateReassignRole,
    getTeamMembers,
    team_roles,
    user_details,
    removeMember,
    team_members = [],
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [allFields /*setAllFields*/] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [memberData, setMemberData] = useState(null);
  const [resetKey, setResetKey] = useState(0);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleUpdateCancel = () => {
    setUpdateModalVisible(false);
  };

  const handleCancel = () => {
    setResetKey(resetKey + 1);
    setIsModalVisible(false);
  };

  const cancelDelete = () => {
    setCurrentIndex(0);
    setVisible(false);
  };

  const showPopconfirm = (x) => {
    setCurrentIndex(x.id);
    setVisible(true);
  };

  const getTeamId = user_details?.owned_team?.id;

  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (getTeamId === undefined) {
        return;
      }
      await getTeamMembers(getTeamId);
    };
    fetchTeamMembers();
  }, [getTeamMembers, getTeamId]);

  const unassignRole = async (id) => {
    setConfirmLoading(true);
    console.log("del id", id);
    const res = await removeMember(id);
    console.log(res, "delter");
    if (res.success) {
      toast("Member Removed successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setVisible(false);
      setConfirmLoading(false);
    } else {
      setVisible(false);
      setConfirmLoading(false);
      toast.error(`${res.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const editMemberRole = (record) => {
    setUpdateModalVisible(true);
    setMemberData(record);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardLayout pageTitle="Manage User" allFields={allFields}>
        <ManageUser
          data-aos="fade-up"
          data-aos-delay="2s"
          data-aos-duration="1s"
        >
          <div className="row">
            <div className="col-md-12 h-100 p-4">
              <div className="mb-4">
                <h3>User Management</h3>
              </div>
              <div className="card border-0 w-100 p-4 card-shadow ">
                <div className="row pb-5">
                  <div className="col-md-6 d-flex align-items-center">
                    <h6>
                      <b>Team Members ({team_members?.length})</b>
                    </h6>
                  </div>
                  <div className="col-md-6" data-aos="slide-left" data-aos-delay="4s" data-aos-duration="2s">
                    <div className="row justify-content-end">
                      <div className="col-md-4 mb-md-0 mb-2">
                        <Link to="/dashboard/manage-roles" className="link_style">
                          <Button type="primary">Manage Roles</Button>
                        </Link>
                      </div>
                      <div className="col-md-4">
                        <Button type="primary" onClick={showModal}>
                          Invite Member
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Full Name</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {team_members ? (
                        team_members.map((x, i) => (
                          <tr key={i}>
                            <td>{`${x.member.first_name} ${x.member.last_name}`}</td>
                            <td>{x.member.email}</td>
                            <td style={{ textTransform: "capitalize" }}>
                              {x.role === null ? (
                                <Tag color="#f38c75">Not Found</Tag>
                              ) : (
                                x.role.name
                              )}
                            </td>
                            <td className="action">
                              {x.role?.name === "Owner" ||
                              x.role?.name === "owner" ? (
                                <div>
                                  <Tooltip
                                    placement="top"
                                    title="You cannot perform update on your role"
                                  >
                                    <Tag color="#f38c75">Not Allowed</Tag>
                                  </Tooltip>
                                </div>
                              ) : (
                                <>
                                  <div onClick={() => editMemberRole(x)}>
                                    <Edit />
                                  </div>
                                  <Popconfirm
                                    title="Are you sure you want to delete?"
                                    visible={
                                      currentIndex === x.id ? visible : false
                                    }
                                    onConfirm={() => unassignRole(x.id)}
                                    onCancel={cancelDelete}
                                    okText="Yes"
                                    okButtonProps={{
                                      loading: confirmLoading,
                                      danger: true,
                                    }}
                                    cancelText="No"
                                  >
                                    <button
                                      className="rolebutton"
                                      disabled={
                                        x.role?.name === "owner" ||
                                        x.role?.name === "Owner"
                                      }
                                      onClick={() => showPopconfirm(x)}
                                    >
                                      <Delete />
                                    </button>
                                  </Popconfirm>
                                </>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div>
                          <Spin />
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </ManageUser>
      </DashboardLayout>

      <InviteSomeoneModal
          key={resetKey}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        getTeamRoles={team_roles}
        team_members={team_members}
        ownTeam_id={getTeamId}
        AssignARole={AssignARole}
      />

      <ReAssignRoleModal
        isModalVisible={updateModalVisible}
        ownTeam_id={getTeamId}
        handleOk={handleOk}
        getTeamRoles={team_roles}
        team_members={team_members}
        handleCancel={handleUpdateCancel}
        UpdateReassignRole={UpdateReassignRole}
        formData={memberData}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    team_roles: state.userManagementReducer.team_roles,
    team_members: state.userManagementReducer.team_members,
    user_details: state.authReducer.user_details,
  };
};

export default connect(mapStateToProps, {
  AssignARole,
  UpdateReassignRole,
  getTeamMembers,
  getTeamRoles,
  removeMember,
})(UserManagement);
