import React, {useEffect, useState} from "react";
import { NotificationHolder } from "../../AdminStyle.styled";
import {Row, Col, Button, Divider, Table, Modal, Tag} from "antd";
import { connect } from "react-redux";
import {
  ConfigureSubscriptions,
  GetConfiguredSubscriptionsRecords,
  UpdateSubscription,
  ClearSubValues,
  DeleteSubscription,
  GetConfiguredServices, GetOneSubscription,
} from "../../../../../redux/actions/adminActions";
import { toast } from "react-toastify";
import ManageSubscriptionModal from "./ManageSubscriptionModal";
import {CurrencySign, formatAmount, formatDateObjectHandler, GetFromNowDate} from "../../../../../utils";
import {SubscriptionMenu} from "../../menus/SubscriptionMenu";

const SubscriptionConfiguration = (props) => {
  const {
    allServices,
    one_sub_record,
    ConfigureSubscriptions,
    GetOneSubscription,
    GetConfiguredSubscriptionsRecords,
    allSubscriptions,
    DeleteSubscription,
    ClearSubValues,
    UpdateSubscription,
    GetConfiguredServices
  } = props;
  const [visible, setvisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [deleteloading, setdeleteloading] = useState(false);
  const [subServices, setSubServices] = useState({});
  const [formMode, setFormMode] = useState('CREATE_MODE');
  const [resetKey, setResetKey] = useState(0);

  const editRecord = async (record) => {
    // console.log('recc', record);
    setloading(true);
    const res = await GetOneSubscription(record.id);
    if (res.success) {
      setvisible(true);
      setloading(false);
    } else {
      setloading(false);
      toast.error('Error Fetching Record');
    }
    setFormMode('UPDATE_MODE');
  }

  const CreateSubscription = async () => {
    const res = await ClearSubValues('CREATE_MODE');
    if (res.success) {
      setvisible(true);
      setFormMode('CREATE_MODE');
    }
    // setSubObj(null);
  }

  const closeModal = () => {
    setvisible(false);
    setFormMode('CREATE_MODE');
    // setSubObj(null);
    setResetKey(resetKey + 1);
  }

  // View Added Services
  const viewSubRecord = (record) => {
    setModalVisible(true);
    setSubServices(record)
  }

  const closeSubModal = () => {
    setModalVisible(false);
  }

  const confirmSubscriptionDelete = record => {
    Modal.confirm({
      title: 'Do you want to delete this subscription?',
      content: 'Click yes to proceed',
      okText: 'Delete',
      loading: deleteloading,
      okType: 'danger',
      async onOk() {
        setdeleteloading(true);
        const res = await DeleteSubscription(record.id);
        if (res.success) {
          setdeleteloading(false);
          toast.success(res.message, {
            pauseOnHover: false,
          });
        } else {
          toast.info(res.message, {
            pauseOnHover: true,
          });
        }
      },
      onCancel() {
        setdeleteloading(false);
        return false;
      },
    });
  };

  useEffect(() => {
    const getSubRecords = async () => {
      setloading(true);
      const res = await GetConfiguredSubscriptionsRecords();
      setloading(false);
      if (!res.success) {
        setloading(false);
        toast.error(`Error`)
      }
    };
    getSubRecords();
  }, [GetConfiguredSubscriptionsRecords]);

  useEffect(() => {
    const getServiceRecords = async () => {
      const res = await GetConfiguredServices();
      if (!res.success) {
        toast.error(`Error`)
      }
    };
    getServiceRecords();
  }, [GetConfiguredServices]);

  const recordColumn = [
    {
      title: 'Services',
      dataIndex: 'services',
      render: (_text, record) => <>{
        <Tag onClick={() => viewSubRecord(record)} style={{ cursor: 'pointer' }}>View Services</Tag>
      }</>
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => <><span className="toCap">{text}</span></>
    },
    {
      title: 'Min User',
      dataIndex: 'min_users_in_team_allocated',
    },
    {
      title: 'Max User',
      dataIndex: 'max_users_in_team_allocated',
    },
    {
      title: 'Min Farm',
      dataIndex: 'min_farm_area_allocated'
    },
    {
      title: 'Max Farm',
      dataIndex: 'max_farm_area_allocated'
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      render: (text) => <><span className="toCap">{text}</span></>
    },
    {
      title: 'Date Updated',
      dataIndex: 'date_updated',
      render: (_text, record) => (
          <>
            <p className="dateTable">{formatDateObjectHandler(record.date_updated, 'DD-MM-YYYY')}</p>
            <small style={{ color: 'green' }}>
              ({GetFromNowDate(record.date_updated, 'YYYY-MM-DDTHH:mm:ss', 'fromNow')})
            </small>
          </>
      )
    },
    // {
    //   title: 'action',
    //   dataIndex: 'action',
    //   align: 'center',
    //   render: (_text, record) => <>
    //     <button style={{ border: 'none', background: 'transparent', outline: 'none' }} onClick={() => editRecord(record)}><Tag color="cyan">Edit</Tag></button>
    //   </>
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (_text, record) =>(
          <>
            <SubscriptionMenu record={record} editRecord={editRecord} confirmSubscriptionDelete={confirmSubscriptionDelete} />
          </>
      )
    }
  ]

  const seColumn = [
    {
      title: 'Service',
      dataIndex: 'service',
      render: (text) => <><span className="toCap">{text.replace('_', ' ')}</span></>
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: <>Amount Per Hectare ({CurrencySign})</>,
      dataIndex: 'amount_per_unit',
      render: (_text, record) => <>{formatAmount(record.amount_per_unit)}</>
    },
    {
      title: 'Notification',
      dataIndex: 'notification',
      align: 'center',
      render: (_text, record) => <>{
        record.notification === null ?
            <Tag color="gray">Unknown</Tag> :
            record.notification === false ?
                <Tag color="red">Not Active</Tag> :
                <Tag color="green">Active</Tag>
      }</>
    }
  ]

  return (
      <NotificationHolder>
        <Row>
          <Col xs={24}>
            <Col xs={24}>
              <div style={{ textAlign: 'right', marginBottom: '15px' }}>
                <Button size="large" onClick={CreateSubscription} type="primary"> Create Subscription</Button>
              </div>
            </Col>
          </Col>
        </Row>


        <Divider />
        <Table rowKey="id" loading={loading} columns={recordColumn} dataSource={allSubscriptions} />

        <Modal
            title={`Services for ${subServices?.name}`}
            visible={modalVisible}
            width="650px"
            footer={null}
            onCancel={closeSubModal}
        >
          <Table rowKey="id" style={{ overflowX: 'auto' }} columns={seColumn} dataSource={subServices?.services} />
        </Modal>

        <ManageSubscriptionModal
            key={resetKey}
            visible={visible}
            ConfigureSubscriptions={ConfigureSubscriptions}
            UpdateSubscription={UpdateSubscription}
            allServices={allServices}
            closeModal={closeModal}
            one_sub_record={one_sub_record}
            formMode={formMode}
        />
      </NotificationHolder>
  );
};

const mapStateToProps = (state) => {
  return {
    allSubscriptions: state.adminReducer.allSubscriptions,
    allServices: state.adminReducer.allServices,
    one_sub_record: state.adminReducer.one_sub_record,
  };
};

export default connect(mapStateToProps, {
  ConfigureSubscriptions,
  UpdateSubscription,
  GetConfiguredSubscriptionsRecords,
  GetConfiguredServices,
  DeleteSubscription,
  GetOneSubscription,
  ClearSubValues,
})(SubscriptionConfiguration);
