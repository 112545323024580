import React, {useContext, useEffect, useState} from "react";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import {
  FarmActivitiesHeader,
  FarmConfigureContainer,
} from "./FarmActivitiy.styled";
import { Row, Col, Card, Input, Button, Select, DatePicker } from "antd";
import { connect } from "react-redux";
import { getTeamMembers } from "../../redux/actions/userManagement";
import {assignFarmActivities, getFieldMonitorData} from "../../redux/actions/farmActions";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import moment from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {AppContext} from "../../appRouter";
import {useForm, Controller} from "react-hook-form";
import {IndexMapRender} from "../farmManagement/IndexMapRender";
const { Option } = Select;

const ConfigureFarmActivity = (props) => {
  const { handleSelectedFieldId } = useContext(AppContext);
  const selectedFieldId = sessionStorage.getItem("selectedFieldId");

  // ---- Call the global Context ----
  const {
    getTeamMembers,
    team_members = [],
    user_details,
    assignFarmActivities,
    getFieldMonitorData,
    user_field,
  } = props;
  // console.log(user_field[0].cord)
  const [loading, setloading] = useState(false);
  const [farmvalue, setfarmvalue] = useState({
    field: 0,
    name: "",
    type: "planning",
    description: "",
    planned_cost: 0,
    actual_cost: 0,
    start_date: moment(),
    end_date: null,
    assignees: [],
  });

  const { handleSubmit, register, formState: { errors }, control, reset } = useForm({
    defaultValues: {
      type: "planning",
      planned_cost: 0,
      actual_cost: 0,
      assignees: [],
      field: parseInt(selectedFieldId),
      start_date: null,
      end_date: null,
    }
  })

  const history = useHistory();

  // in case of refresh of the page
  if (selectedFieldId) {
    sessionStorage.setItem("selectedFieldId", selectedFieldId);
  }
  if (history.location.pathname.substr(33)) {
    handleSelectedFieldId(history.location.pathname.substr(33));
  }

  const goBack = () => {
    history.push('/dashboard/farmactivity/all-activities');
  };

    // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    let team_id = user_details?.owned_team === null ? user_details?.user_team : user_details?.owned_team.id
    const fetchAllUsers = async () => {
      await getTeamMembers(team_id);
    };
    fetchAllUsers();
  }, [user_details?.owned_team === null ? user_details?.user_team : user_details?.owned_team.id, getTeamMembers]);


  const AssignFarmActivity = async (data) => {
    setfarmvalue(data);
    setloading(true);
    const farmData = {
      ...data,
      start_date: moment(data.start_date).format("YYYY-MM-DD"),
      end_date: moment(data.end_date).format("YYYY-MM-DD"),
    };
    const res = await assignFarmActivities(farmData);
    if (res.success) {
      toast.success(`Farm Activity Assigned Successfully`);
      reset();
      setloading(false);
      // --- Redirect to the all farm task page ---
      setTimeout(() => {
        history.push("/dashboard/farmactivity/my-tasks");
      }, 1000);

    } else {
      toast.error(`An unexpected error occurred. Kindly try again, please.`);
    }
    setTimeout(() => {
      setloading(false);
    }, 400);
  };

  const disableEndDate = (current) => {
    return current && current < moment(farmvalue?.start_date).startOf("day");
  };

  const returnTasks = () => {
    history.push("/dashboard");
  };

  const children = team_members?.map((user) => {
    return (
      <Option key={user.member.pk} value={user.member.pk}>
        {user.member.email}
      </Option>
    );
  });

  useEffect(() => {
    const getSatelliteImage = async () => {
      const res = await getFieldMonitorData();
      if (!res.success) {
        toast.error(res.message);
      }
    }
    getSatelliteImage()
  }, [getFieldMonitorData])

  return (
    <>
      <DashboardLayout pageTitle="Configure Farm Activities">
        <FarmActivitiesHeader>
          <h3>Assign Farm Activity</h3>
        </FarmActivitiesHeader>

        <FarmConfigureContainer
          data-aos="fade-right"
          data-aos-delay="2s"
          data-aos-duration="1s"
        >
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              xl={{ span: 12, offset: 6 }}
              lg={{ span: 12, offset: 6 }}
            >
              {selectedFieldId === null ? (
                <>
                  <div style={{ marginTop: "3rem" }}>
                    <div className="example">
                      <h5>No Field Selected Yet!</h5>
                      <Button type="dashed" onClick={returnTasks}>
                        <ArrowRightAltIcon /> Return To Dashboard/Field
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Card className="card-shadow">
                    <div className="taskhead">New Task</div>
                    <div className="taskbody">
                      <form onSubmit={AssignFarmActivity}>
                        <div className="form-group">
                          <Row
                            gutter={24}
                            align="middle"
                            justify="space-between"
                          >
                            <Col xs={24} xl={6} lg={6}>
                              <label>Task Name</label>
                            </Col>
                            <Col xs={24} xl={16} lg={16}>
                              <input
                                className="ant-input ant-input-lg"
                                name="name"
                                { ...register('name', { required: true }) }
                              />
                              {errors.name && (<small className="error">Task name is required</small>)}
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group">
                          <Row
                            gutter={24}
                            align="middle"
                            justify="space-between"
                          >
                            <Col xs={24} xl={6} lg={6}>
                              <label>Assignee</label>
                            </Col>
                            <Col xs={24} xl={16} lg={16}>
                              <Controller
                                  control={control}
                                  name="assignees"
                                  render={({ field: { onChange, value } }) => (
                                      <Select
                                          mode="multiple"
                                          allowClear
                                          name="assignees"
                                          value={value}
                                          style={{ width: "100%" }}
                                          defaultValue={[]}
                                          onChange={onChange}
                                          size="large"
                                          showArrow={true}
                                          maxTagCount={3}
                                          optionFilterProp="children"
                                          filterOption={(input, option) =>
                                              option.children
                                                  .toLowerCase()
                                                  .indexOf(input.toLowerCase()) >= 0
                                          }
                                          filterSort={(optionA, optionB) =>
                                              optionA.children
                                                  .toLowerCase()
                                                  .localeCompare(
                                                      optionB.children.toLowerCase()
                                                  )
                                          }
                                      >
                                        {children}
                                      </Select>
                                  )}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group">
                          <Row
                            gutter={24}
                            align="middle"
                            justify="space-between"
                          >
                            <Col xs={24} xl={6} lg={6}>
                              <label>Task Description</label>
                            </Col>
                            <Col xs={24} xl={16} lg={16}>
                              <Controller
                                  control={control}
                                  name="description"
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                      <Input.TextArea
                                          size="large"
                                          name="description"
                                          value={value}
                                          onChange={onChange}
                                      />
                                  )}
                              />
                              {errors.description && (<small className="error">Description is required</small>)}
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group">
                          <Row
                            gutter={24}
                            align="middle"
                            justify="space-between"
                          >
                            <Col xs={24} xl={6} lg={6}>
                              <label>Start Date</label>
                            </Col>
                            <Col xs={24} xl={16} lg={16}>
                              <Controller
                                  control={control}
                                  name="start_date"
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                      <DatePicker
                                          size="large"
                                          name="start_date"
                                          value={value}
                                          defaultPickerValue={moment()}
                                          onChange={onChange}
                                      />
                                  )}
                              />
                              {errors.start_date && (<small className="error">Start Date is required</small>)}
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group">
                          <Row
                            gutter={24}
                            align="middle"
                            justify="space-between"
                          >
                            <Col xs={24} xl={6} lg={6}>
                              <label>End Date</label>
                            </Col>
                            <Col xs={24} xl={16} lg={16}>
                              <Controller
                                  control={control}
                                  name="end_date"
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                      <DatePicker
                                          size="large"
                                          name="end_date"
                                          value={value}
                                          onChange={onChange}
                                          defaultPickerValue={moment()}
                                          disabledDate={disableEndDate}
                                          // disabled={!farmvalue?.start_date}
                                      />
                                  )}
                              />
                              {errors.end_date && (<small className="error">End Date is required</small>)}
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group">
                          <Row
                            gutter={24}
                            align="middle"
                            justify="space-between"
                          >
                            <Col xs={24} xl={24} lg={24}>
                              <IndexMapRender
                                  cloudCover={""}
                                  dateRange={""}
                                  selectedCentroid={JSON.parse(sessionStorage.getItem('selectedCentroid'))}
                                  selectedCoords={JSON.parse(sessionStorage.getItem('selectedRealCoords'))}
                                  index={'TRUE-COLOR'}
                                  height={'8em'}
                                  zoomVal={9}
                              />
                              {/*<img src={FarmCord} alt="cord" />*/}
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group">
                          <Row
                            gutter={48}
                            align="middle"
                            justify="space-around"
                          >
                            <Col xs={12} xl={12} lg={12}>
                              <Button
                                block
                                type="default"
                                className="cancelbtn"
                                onClick={goBack}
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col xs={12} xl={12} lg={12}>
                              <Button
                                loading={loading}
                                block
                                type="primary"
                                onClick={handleSubmit(AssignFarmActivity)}
                              >
                                {loading ? "Processing..." : "Add Task"}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </form>
                    </div>
                  </Card>
                </>
              )}
            </Col>
          </Row>
        </FarmConfigureContainer>
      </DashboardLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // all_users: state.userManagementReducer.all_users,
    user_details: state.authReducer.user_details,
    team_members: state.userManagementReducer.team_members,
    user_field: state.FarmReducer.user_field,
  };
};

export default connect(mapStateToProps, {
  assignFarmActivities,
  getTeamMembers,
  getFieldMonitorData,
})(ConfigureFarmActivity);
