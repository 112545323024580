import {
  ArrowDropDownCircleOutlined,
  ArrowDropUpOutlined,
} from "@material-ui/icons";
import { useEffect, useState } from "react";

import { LegendCard } from "../../styles/util/UtilStyle";
import { wmsKey } from "../util/Util";

function Legend({ map, layer }) {
  const [legendImg, setLegendImg] = useState(``);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (map && layer) {
      setLegendImg(
        `https://services.sentinel-hub.com/ogc/wms/${wmsKey}?service=WMS&request=GetLegendGraphic&layer=${layer}&height=300&width=200`
      );
    }
  }, [map, layer]);

  const handleOpenLegend = () => {
    setOpen(!open);
  };
  return (
    <>
      <LegendCard>
        <div onClick={handleOpenLegend}>
          Click me
          {!open ? (
            <ArrowDropDownCircleOutlined
              style={{ float: "right", cursor: "pointer" }}
            />
          ) : (
            <ArrowDropUpOutlined
              style={{ float: "right", cursor: "pointer" }}
            />
          )}
        </div>
        {open ? (
          <>
            {layer !== null ? (
              <img src={legendImg} alt="Legend Not Found" />
            ) : (
              <p>Please select an Index</p>
            )}
          </>
        ) : (
          <p>Maximize!</p>
        )}
      </LegendCard>
    </>
  );
}

export default Legend;
