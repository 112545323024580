export const content = () => {
  let first = `    
██████╗░███████╗██╗░░░██╗███████╗██╗░░░░░░█████╗░██████╗░███████╗██████╗░    ██████╗░██╗░░░██╗  
██╔══██╗██╔════╝██║░░░██║██╔════╝██║░░░░░██╔══██╗██╔══██╗██╔════╝██╔══██╗    ██╔══██╗╚██╗░██╔╝  
██║░░██║█████╗░░╚██╗░██╔╝█████╗░░██║░░░░░██║░░██║██████╔╝█████╗░░██║░░██║    ██████╦╝░╚████╔╝░  
██║░░██║██╔══╝░░░╚████╔╝░██╔══╝░░██║░░░░░██║░░██║██╔═══╝░██╔══╝░░██║░░██║    ██╔══██╗░░╚██╔╝░░  
██████╔╝███████╗░░╚██╔╝░░███████╗███████╗╚█████╔╝██║░░░░░███████╗██████╔╝    ██████╦╝░░░██║░░░  
╚═════╝░╚══════╝░░░╚═╝░░░╚══════╝╚══════╝░╚════╝░╚═╝░░░░░╚══════╝╚═════╝░    ╚═════╝░░░░╚═╝░░░  

░█████╗░░█████╗░░██████╗░███████╗░██████╗░░█████╗░██████╗░░█████╗░░██████╗░███████╗░█████╗░███████╗██████╗░██╗░█████╗░░█████╗░
██╔══██╗██╔══██╗██╔════╝░██╔════╝██╔════╝░██╔══██╗██╔══██╗██╔══██╗██╔════╝░██╔════╝██╔══██╗██╔════╝██╔══██╗██║██╔══██╗██╔══██╗
██║░░╚═╝██║░░██║██║░░██╗░█████╗░░██║░░██╗░███████║██████╔╝███████║██║░░██╗░█████╗░░███████║█████╗░░██████╔╝██║██║░░╚═╝███████║
██║░░██╗██║░░██║██║░░╚██╗██╔══╝░░██║░░╚██╗██╔══██║██╔══██╗██╔══██║██║░░╚██╗██╔══╝░░██╔══██║██╔══╝░░██╔══██╗██║██║░░██╗██╔══██║
╚█████╔╝╚█████╔╝╚██████╔╝███████╗╚██████╔╝██║░░██║██║░░██║██║░░██║╚██████╔╝███████╗██║░░██║██║░░░░░██║░░██║██║╚█████╔╝██║░░██║
░╚════╝░░╚════╝░░╚═════╝░╚══════╝░╚═════╝░╚═╝░░╚═╝╚═╝░░╚═╝╚═╝░░╚═╝░╚═════╝░╚══════╝╚═╝░░╚═╝╚═╝░░░░░╚═╝░░╚═╝╚═╝░╚════╝░╚═╝░░╚═╝`;

  let second = `
𝗧𝗛𝗘 𝗘𝗡𝗚𝗜𝗡𝗘𝗘𝗥𝗜𝗡𝗚 𝗧𝗘𝗔𝗠

𝑭𝑹𝑶𝑵𝑻𝑬𝑵𝑫
1. Steven Omole-Adebomi
2. Falua Temitope

𝑩𝑨𝑪𝑲𝑬𝑵𝑫
1. Saliu Moshood
2. Daranijo Ifeoluwa Timothy
3. Femi Oluwafemi

PRODUCT MANAGERS
1. Peter Onu Oche
--------------------------

THE CODEGARAGE AFRICA TEAM

--------------------------

`;

  console.log(
    `%c ${first}`,
    'background: white; color: rgba(5, 103, 74, 1);'
  );
  console.log(
    `%c ${second}`,
    'background: white; color: rgba(5, 103, 74, 1); font-size:1.5em'
  );

  return false;
};
