import React, { useState } from "react";
import { Row, Col, Input, Button, Checkbox } from "antd";
import { Link, useHistory } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import { signUpUser, oAuth2 } from "../../redux/actions/authentication";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";

const Register = (props) => {
  let { signUpUser, oAuth2 } = props;
  let history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [errorLog, setErrorLog] = useState({
    first_name: [],
    last_name: [],
    email: [],
    password1: [],
    password2: [],
  });

  const [loading, setLoading] = useState(false);

  const PerformSignup = async (data) => {
    setLoading(true);
    setErrorLog({
      first_name: [],
      last_name: [],
      email: [],
      password1: [],
      password2: [],
    });

    const objData = {
      ...data,
      password2: data.password1,
    };

    const res = await signUpUser(objData);
    if (res.success) {
      reset();
      toast.success(
        "Account created. Please, wait while we redirect you to dashboard",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setTimeout(() => {
        history.push("/dashboard");
      }, 3000);
    } else {
      setLoading(false);
      setErrorLog(res?.message);
      return toast.error("Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AuthLayout
        fadeName="fade-left"
        googleLabel="Sign Up"
        pageTitle="Register"
        oAuth2={oAuth2}
      >
        <form onSubmit={handleSubmit(PerformSignup)}>
          <Row gutter={8}>
            <Col xs={24} xl={12} lg={12}>
              <div className="form-group">
                <Input
                  placeholder="First Name"
                  name={"first_name"}
                  {...register("first_name", {
                    required: true,
                    minLength: {
                      value: 3,
                    },
                  })}
                />
                <small className="text-danger">
                  {errors.first_name && (
                    <span className="error">
                      Too short, must be at least 3 characters
                    </span>
                  )}
                </small>
                <small className="text-danger">
                  {errorLog.first_name && (
                    <span className="error">{errorLog.first_name[0]}</span>
                  )}
                </small>
                <small className="text-danger">
                  {errorLog.first_name && (
                    <>
                      {errorLog.first_name.map((error, pos) => (
                        <p className="error" key={pos}>
                          {error}
                        </p>
                      ))}
                    </>
                  )}
                </small>
              </div>
            </Col>
            <Col xs={24} xl={12} lg={12}>
              <div className="form-group">
                <Input
                  placeholder="Last Name"
                  name={"last_name"}
                  {...register("last_name", {
                    required: true,
                    minLength: {
                      value: 3,
                    },
                  })}
                />
                <small className="text-danger">
                  {errors.last_name && (
                    <span className="error">
                      Too short, must be at least 3 characters
                    </span>
                  )}
                </small>
                <small className="text-danger">
                  {errorLog.last_name && (
                    <>
                      {errorLog.last_name.map((error, pos) => (
                        <p className="error" key={pos}>
                          {error}
                        </p>
                      ))}
                    </>
                  )}
                </small>
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={24} lg={24}>
              <div className="form-group">
                <Input
                  placeholder="Email Address"
                  name="email"
                  {...register("email", {
                    required: true,
                    pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  })}
                />
                <small className="text-danger">
                  {errors.email && (
                    <span className="error">
                      Please, enter a valid email address
                    </span>
                  )}
                </small>
                <small className="text-danger">
                  {errorLog.email && (
                    <>
                      {errorLog.email.map((error, pos) => (
                        <p className="error" key={pos}>
                          {error}
                        </p>
                      ))}
                    </>
                  )}
                </small>
              </div>
            </Col>
            <Col xs={24} xl={24} lg={24}>
              <div className="form-group">
                <Input.Password
                  placeholder="Password"
                  name={"password1"}
                  {...register("password1", {
                    required: true,
                    minLength: {
                      value: 8,
                    },
                  })}
                />
                <small className="text-danger">
                  {errors.password1 && (
                    <span className="error">
                      Password must have at least 8 characters
                    </span>
                  )}
                </small>
                <small className="text-danger">
                  {errorLog.password1 && (
                    <>
                      {errorLog.password1.map((error, pos) => (
                        <p className="error" key={pos}>
                          {error}
                        </p>
                      ))}
                    </>
                  )}
                </small>
              </div>
            </Col>
            <Col xs={24} xl={24} lg={24}>
              <Checkbox>
                <p style={{ fontSize: "0.8rem" }}>
                  I agree to the <a href="/termandcondition">Terms of Use</a>{" "}
                  and <a href="/privacypolicy">Privacy Policy</a> , to the
                  processing of my personal data, and to receive emails
                </p>
              </Checkbox>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={24} lg={24}>
              <Button
                onClick={handleSubmit(PerformSignup)}
                loading={loading}
                block
                type="primary"
              >
                {loading ? "Creating Account..." : "Sign Up"}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={24} xl={24} lg={24}>
              <p className="register">
                Don't have account? <Link to="/auth/login">Sign in</Link>
              </p>
            </Col>
          </Row>
        </form>
      </AuthLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_error: state.authReducer.auth_error,
  };
};

export default connect(mapStateToProps, { signUpUser, oAuth2 })(Register);
