import React from 'react';
import BrokenLink from '../../components/errorHandling/BrokenLink';

const BrokenLinkPage = (props) => {
    return (
        <>
            <BrokenLink customText={props?.customText} customSubText={props?.customSubText} />
        </>
    )
};

export default BrokenLinkPage;