import React, {useEffect, useState} from 'react';
import DefaultLayout from "../Layout/DefaultLayout";
import {connect} from "react-redux";
import {GetOnlyOneAccountUsers} from "../../../redux/actions/adminOtherServices";
import {useHistory, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import { ArrowBack } from '@material-ui/icons';
import {UserDetailContainer} from "./AdminStyle.styled";
import {Row, Col, Table, Tag} from "antd";
// import {UserMenu} from "./UserMenu";
import {getTeamMembers} from "../../../redux/actions/userManagement";

const AdminManageUserDetails = (props) => {
    const { GetOnlyOneAccountUsers, user_details, team_members, getTeamMembers } = props;
    const [loading, setloading] = useState(false);
    const { id } = useParams();

    const history = useHistory();

    const gotoOrders = () => {
        history.push('/admin/manage-users');
    };

    const userTeamCol = [
        {
            title: "Full Name",
            dataIndex: "name",
        },
        {
            title: "Email Address",
            dataIndex: "email",
        },
        {
            title: "Role",
            dataIndex: "role",
            render: (_text, record) => (
                <>
                    {record.role === null ? (
                        <Tag color="#f38c75">Not Found</Tag>
                    ) : (
                        record.role
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        const getOneRecord = async () => {
            if (id === undefined) {
                return;
            }
            const res = await GetOnlyOneAccountUsers(id);
            if (!res.success) {
                toast.error(res.message);
            }
        };
        getOneRecord();
    }, [GetOnlyOneAccountUsers, id]);

    const getTeamId = user_details?.owned_team?.id;

    const formatData = team_members?.map((team) => {
        return {
            ...team,
            name: team.member?.first_name + ' ' + team.member?.last_name,
            admin_approved: team.admin_approved,
            role: team.role?.name,
            id: team.id,
            email: team.member?.email,
        }
    })

    useEffect(() => {
        const fetchTeamMembers = async () => {
            if (getTeamId === undefined) {
                return;
            }
            setloading(true);
            const res = await getTeamMembers(getTeamId);
            setloading(false);
            if (!res.success) {
                toast.error('Error Fetching Team Members');
            }
        };
        fetchTeamMembers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <DefaultLayout browserTitle="Manage User">
                <UserDetailContainer>
                    <h3>User Details</h3>
                    <div className="details">
                        <div onClick={gotoOrders} className="details__top">
                            <p> <ArrowBack /> Go Back</p>
                        </div>
                        <div className="details__inner">
                            <Row>
                                <Col xs={8} xl={12} lg={12}>
                                    <span>Full Name:</span>
                                </Col>
                                <Col xs={16} xl={12} lg={12}>
                                    <span style={{ textTransform: 'capitalize' }}>{user_details?.first_name} {user_details?.last_name}</span>
                                </Col>
                            </Row>
                        </div>
                        <div className="details__inner">
                            <Row>
                                <Col xs={8} xl={12} lg={12}>
                                    <span>User Type:</span>
                                </Col>
                                <Col xs={16} xl={12} lg={12}>
                                    <span>{user_details?.is_staff ? (<Tag color="green">Admin</Tag>) : (<Tag color="blue">Portfolio User</Tag>)}</span>
                                </Col>
                            </Row>
                        </div>
                        <div className="details__inner">
                            <Row>
                                <Col xs={8} xl={12} lg={12}>
                                    <span>Email:</span>
                                </Col>
                                <Col xs={16} xl={12} lg={12}>
                                    <span>{user_details?.email}</span>
                                </Col>
                            </Row>
                        </div>
                        <div className="details__inner">
                            <Row>
                                <Col xs={8} xl={12} lg={12}>
                                    <span>Team Name:</span>
                                </Col>
                                <Col xs={16} xl={12} lg={12}>
                                    <span style={{ textTransform: 'capitalize' }}>{user_details?.owned_team?.name}</span>
                                </Col>
                            </Row>
                        </div>
                        <div className="details__top">
                            <span>Owned Team</span>
                        </div>
                    </div>
                    <div className="progress_action">
                        <div style={{ marginTop: '10px' }}>
                            <Table loading={loading} columns={userTeamCol} dataSource={formatData} />
                        </div>
                    </div>
                </UserDetailContainer>
        </DefaultLayout>
    )
}

const mapStateToProps = state => {
    return {
        user_details: state.adminOtherReducer.user_details,
        team_members: state.userManagementReducer.team_members,
    }
}
export default connect(mapStateToProps, { getTeamMembers, GetOnlyOneAccountUsers })(AdminManageUserDetails);