import React, { useState } from 'react';

import { useForm } from "react-hook-form";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Fade,
    Grid,
    makeStyles,
    Modal
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';

import { addASeason } from '../../redux/actions/cropPlanDevelopment';
import { useSelector } from 'react-redux';

import { CropPlanAddBtn, CropPlanButtonBox, CropPlanInput, CropPlanInputError, CropPlanInputText, CropPlanModal, CropPlanModalInner, CropPlanModalTitle, CropPlanSelect } from '../../styles/cropPlan/CropPlanStyles';

const useStyles = makeStyles({
    root: {
        // background: '#eff9f6',
        borderRadius: 3,
        cursor: 'pointer',
        // border: 0,
        color: 'rgba(1, 28, 20, 0.9)',
        // padding: '0 30px',
        // height: '20em',
        border: '1px solid rgba(1, 28, 20, 0.2)',
        // boxShadow: '0 3px 5px 2px rgba(5,5,5, .3)',
        '&::-webkit-scrollbar': {
            width: '6px',
            background: 'red',
            color: 'red'
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        outline: 'none'
    }
});

export const AddNewSeason = (props) => {
    const classes = useStyles();
    // --- get the access key of the user here ---
    const {
        access_token: access_key,
    } = useSelector((state) => state.authReducer);

    const [btnState, setBtnState] = useState({ text: 'Add Crop', isDisabled: false });
    const [minDate, setMinDate] = useState("");
    const [maxDate, setMaxDate] = useState("");
    // handler to add a new crop
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (dataObject) => {

        let params =
        {
            "crop": dataObject?.crop,
            "sowing_date": dataObject?.sowing_date,
            "harvest_date": dataObject?.harvest_date,
            "season": props?.seasonYear,
            "field": props?.fieldId
        }

        setBtnState({ text: 'Please wait!', isDisabled: true });

        let { isError } = await addASeason(params, access_key);

        if (isError) {

            toast.error('Sorry, an error occured and we could not add a new seasons', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBtnState({ text: 'Add Crop', isDisabled: false });

        } else {

            toast('Season successfully added', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBtnState({ text: 'Add Crop', isDisabled: false });
            // close the modal and refresh
            props?.setRenderData(13);
            props?.setOpen(false);
        }
    }

    return (
        <>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props?.open}
                onClose={props?.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
                style={{ border: 'none !important' }}
            >
                <Fade in={props?.open}>
                    <CropPlanModal>
                        <CropPlanModalInner>
                            <Grid container spacing={1}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <CropPlanModalTitle>ADD A CROP TO SEASON: 2021</CropPlanModalTitle>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6} style={{ float: 'right' }}>
                                    <CloseIcon onClick={props?.handleClose} style={{ float: 'right', cursor: 'pointer', color: 'rgba(200,0,0,1)' }} />
                                </Grid>
                            </Grid>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={1}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <CropPlanInputText>Choose Crop</CropPlanInputText>
                                        <CropPlanSelect name="crop"  {...register("crop", { required: true })}>
                                            {/* <option value="">Select Crop</option> */}
                                            {props?.cropList?.map((eachCrop) => (
                                                <option
                                                    key={eachCrop?.id}
                                                    name="crop"
                                                    value={eachCrop?.id}
                                                >
                                                    {eachCrop?.name}
                                                </option>
                                            ))}
                                        </CropPlanSelect>
                                        <CropPlanInputError>{errors.crop?.type === 'required' && "Crop Name is required"}</CropPlanInputError>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12} style={{ marginTop: '-1em' }}>
                                        <CropPlanInputText>Sowing Date</CropPlanInputText>

                                        <CropPlanInput
                                            type="date"
                                            placeholder="Sowing Date"
                                            max={maxDate}
                                            onInput={(e) => setMinDate(e.target.value)}
                                            defaultValue="" {...register("sowing_date", { required: true })}
                                        />
                                        <CropPlanInputError>{errors.sowing_date?.type === 'required' && "Sowing Date is required"}</CropPlanInputError>

                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12} style={{ marginTop: '-1em' }}>
                                        <CropPlanInputText>Harvest Date</CropPlanInputText>

                                        <CropPlanInput
                                            type="date"
                                            placeholder="Sowing Date"
                                            min={minDate}
                                            onInput={(e) => setMaxDate(e.target.value)}
                                            defaultValue="" {...register("harvest_date", { required: true })}
                                        />
                                        <CropPlanInputError>{errors.harvest_date?.type === 'required' && "Harvest Date is required"}</CropPlanInputError>

                                    </Grid>
                                </Grid>

                                <CropPlanButtonBox style={{ marginTop: '0em' }}>
                                    <Grid container spacing={3}>

                                        <Grid item lg={2} md={2} sm={6} xs={2}>
                                        </Grid>

                                        <Grid item lg={2} md={2} sm={6} xs={2}>
                                        </Grid>

                                        <Grid item lg={4} md={4} sm={6} xs={4}>
                                            {/* <CropPlanDelBtn onClick={props?.handleClose}>Cancel<CloseIcon /></CropPlanDelBtn> */}
                                        </Grid>

                                        <Grid item lg={4} md={4} sm={6} xs={4}>
                                            <CropPlanAddBtn type='submit' disabled={btnState?.isDisabled}>{btnState?.text}</CropPlanAddBtn>
                                        </Grid>
                                    </Grid>
                                </CropPlanButtonBox>
                            </form>
                        </CropPlanModalInner>
                    </CropPlanModal>
                </Fade>
            </Modal>
        </>
    )
};