import { toast } from "react-toastify";
import { fetchApi } from "../../adapters/util/fetchApi";
import { getOnlyDayAndMonth, getPastNYears } from "../../components/util/Util";
import { baseUrl } from "../../config";

export const getForeCast = async (centroid) => {
  let requestHeaders = {
    "Content-Type": "application/json",
    // "Authorization": `Token ${access_key}`
  };

  let APIUrl = `${baseUrl}/weather/forecast/?location=${centroid}`;

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      return { errorMessage, isError };
    } else {
      // dispatch(getField());
      return { data: data };
    }
  };

  const response = await getResponse();
  return response;
};


export const getHistoryChart = async (centroid, from, to) => {
  let requestHeaders = {
    "Content-Type": "application/json",
    // "Authorization": `Token ${access_key}`
  };

  let APIUrl = `${baseUrl}/weather/weather/history/?LOCATION=${centroid}&startdate=${from}&enddate=${to}`;

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      return { errorMessage, isError };
    } else {
      if (data.data === "Invalid Date") {
        toast.error("Invalid Date", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return { data: [] };
      }

      return { data: data.data };
    }
  };

  const response = await getResponse();
  return response;
};

export const getRainfallData = async (centroid, from, to) => {
  let requestHeaders = {
    "Content-Type": "application/json",
    // "Authorization": `Token ${access_key}`
  };

  let APIUrl = `${baseUrl}/weather/average/rainfall/?location=${centroid}&startdate=${from}&enddate=${to}`;

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      return { errorMessage, isError };
    } else {
      // dispatch(getField());
      return { data: data };
    }
  };

  const response = await getResponse();
  return response;
};

export const downloadHistoryChart = async (centroid, from, to) => {
  let requestHeaders = {
    "Content-Type": "application/json",
    // "Authorization": `Token ${access_key}`
  };

  let APIUrl = `${baseUrl}/weather/weather/history/download/?location=${centroid}&startdate=${from}&enddate=${to}`;

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      return { errorMessage, isError };
    } else {
      return { data: data };
    }
  };

  const response = await getResponse();
  return response;
};



export const get5YearsHistoryChart = async (centroid, startDate, endDate) => {
  let requestHeaders = {
    "Content-Type": "application/json",
    // "Authorization": `Token ${access_key}`
  };

  let nYearsData = [];

  // --- Get the list of the past 5 years between the startdate and enddate
  let dateRangeList = getPastNYears(startDate, endDate, 5);

  const getResponse = async (from, to) => {

    let APIUrl = `${baseUrl}/weather/weather/history/?LOCATION=${centroid}&startdate=${from}&enddate=${to}`;

    const { isError, data } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      return [];
    } else {
      if (data.data === "Invalid Date") {
        toast.error("Invalid Date", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return []
      }
      // return data.data;
      let results = []
      data.data.map((item) => {
        let d = {
          Humidity: item.Humidity,
          areaName: item.areaName,
          cloud_over: item.cloud_over,
          country: item.country,
          date: item.date,
          indicator: item.indicator,
          max_temperature: item.max_temperature,
          min_temperature: item.min_temperature,
          precipitation: item.precipitation,
          windspeed: item.windspeed,
          dayMonth: getOnlyDayAndMonth(item.date)
        }
        results.push(d);
        return results
      })

      return results;
    }
  };

  let colorsList = ["red", "blue", "yellow", "white", "#6CADEA"];
  // --- Hit the Endpoint in N number of times where N is the length of the dateRangeList
  for (let i = 0; i < dateRangeList.length; i++) {
    let results = await getResponse(dateRangeList[i][0], dateRangeList[i][1]);

    nYearsData.push({
      year: (dateRangeList[i][0].substr(0, 4) + "/" + dateRangeList[i][1].substr(0, 4)),
      data: results, color: colorsList[i]
    })
  }


  if (nYearsData?.length === 0) {
    return { isError: true };
  } else {
    return { data: nYearsData };
  }

};

export const get5YrsAvgHistory = async (centroid, startDate, endDate) => {
  let requestHeaders = {
    "Content-Type": "application/json",
    // "Authorization": `Token ${access_key}`
  };

  let avgHistory = [];

  // --- Get the list of the past 5 years between the startdate and enddate
  let dateRangeList = getPastNYears(startDate, endDate, 5);

  const getResponse = async (from, to, position) => {

    let APIUrl = `${baseUrl}/weather/weather/history/?LOCATION=${centroid}&startdate=${from}&enddate=${to}`;

    const { isError, data } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      return [];
    } else {
      if (data.data === "Invalid Date") {
        toast.error("Invalid Date", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return []
      }

      let results = [];
      let prevAvgHistory = 0;

      data.data.map((item, pos) => {

        // --- declare the variables here to avoid redundate running of the typecast algorithm
        let Humidity = isNaN(item.Humidity) ? 0 : Number.parseFloat(item.Humidity);
        let cloud_cover = isNaN(item.cloud_over) ? 0 : Number.parseFloat(item.cloud_over);
        let max_temperature = isNaN(item.max_temperature) ? 0 : Number.parseFloat(item.max_temperature);
        let min_temperature = isNaN(item.min_temperature) ? 0 : Number.parseFloat(item.min_temperature);
        let precipitation = isNaN(item.precipitation) ? 0 : Number.parseFloat(item.precipitation);
        let windspeed = isNaN(item.windspeed) ? 0 : Number.parseFloat(item.windspeed);
        let dayMonth = getOnlyDayAndMonth(item.date);

        let d = { Humidity, cloud_cover, max_temperature, min_temperature, precipitation, windspeed, dayMonth };

        if (avgHistory.length > 0) {
          prevAvgHistory = avgHistory[pos];
          if (position !== 4) {
            d = {
              Humidity: (Humidity + prevAvgHistory.Humidity),
              cloud_over: (cloud_cover + prevAvgHistory.cloud_cover),
              max_temperature: (max_temperature + prevAvgHistory.max_temperature),
              min_temperature: (min_temperature + prevAvgHistory.min_temperature),
              precipitation: (precipitation + prevAvgHistory.precipitation),
              windspeed: (windspeed + prevAvgHistory.windspeed),
              dayMonth: dayMonth
            }
          } else {
            d = {
              Humidity: (Humidity + prevAvgHistory.Humidity) / 5,
              cloud_over: (cloud_cover + prevAvgHistory.cloud_cover) / 5,
              max_temperature: (max_temperature + prevAvgHistory.max_temperature) / 5,
              min_temperature: (min_temperature + prevAvgHistory.min_temperature) / 5,
              precipitation: (precipitation + prevAvgHistory.precipitation) / 5,
              windspeed: (windspeed + prevAvgHistory.windspeed) / 5,
              dayMonth: dayMonth
            }
          }
        }

        results.push(d);
        return results
      })

      return results;
    }
  };

  // --- Hit the Endpoint in N number of times where N is the length of the dateRangeList
  for (let i = 0; i < dateRangeList.length; i++) {
    avgHistory = await getResponse(dateRangeList[i][0], dateRangeList[i][1], i);
  }

  if (avgHistory?.length === 0) {
    return { isError: true };
  } else {
    return { data: avgHistory };
  }

};