import React, { useEffect } from 'react';
// import PlainHeader from './PlainHeader';
import { LayoutContainer } from './AdminLayout.styled';
import { Helmet } from 'react-helmet';
import AOS from 'aos';

const AuthPlainLayout = ({ title, subtitle, pageTitle, children }) => {
    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (
        <>
            <Helmet>
                <title>{pageTitle} | Agroprever</title>
            </Helmet>
            {/*<PlainHeader />*/}
            <main>
                <LayoutContainer>
                    <div className="header-title" data-aos="fade-down" data-aos-delay="500" data-aos-duration="1000">
                        <h3>{title}</h3>
                        <h4>{subtitle}</h4>
                    </div>
                    {children}
                </LayoutContainer>
            </main>
        </>
    );
};

export default AuthPlainLayout;
