import { bbox, bboxPolygon, buffer, point } from "@turf/turf";
import { wmsKey } from "../../components/util/Util";
import { fetchApi } from "../util/fetchApi";


const getAvailableDates = async (startDate, endDate, centroid) => {

    let lat = centroid[1];
    let lng = centroid[0];

    let hoverPoint = point([lng, lat]);

    let buffered = buffer(hoverPoint, 0.2)

    let pointBBox = bbox(buffered);

    let pointPolygon = bboxPolygon(pointBBox);

    // let geometry = `POLYGON ((${pointPolygon.geometry.coordinates[0].map(coord => coord.join(' ')).join(', ')}))`;

    // console.log('original geometry', geometry);
    const APIUrl = `https://services.sentinel-hub.com/ogc/wfs/${wmsKey}?REQUEST=GetFeature&TYPENAMES=DSS2&OUTPUTFORMAT=application/json&BBOX=${pointPolygon?.bbox}&TIME=${startDate}/${endDate}&SRSNAME=EPSG:4326`;

    let requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": "Bearer df9e133c-8188-49ec-b8fe-f00d74bbb4b1"
    }

    const getResponse = async () => {
        const { isError, data, errorMessage } = await fetchApi({}, "GET", APIUrl, requestHeaders);

        if (isError) {
            return { errorMessage, isError };

        } else {
            return { data: data }
        }

    }


    const response = await getResponse();
    return response;
};

export default getAvailableDates;