import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Input, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import { AppContext } from "../../appRouter";
import { connect } from "react-redux";
import { loginUser, oAuth2 } from "../../redux/actions/authentication";
import { toast } from "react-toastify";

const Login = (props) => {

  const { handleIsLoggedIn,
    clearData,
  } = useContext(AppContext);

  let history = useHistory();
  let { loginUser, oAuth2 } = props;
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.id]: e.target.value });
  };

  // --- clear all storages set already here ---
  useEffect(() => {
    clearData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let res = await loginUser(userDetails);
    if (res.success) {
      toast("Successfully Logged In..redirecting to dashboard", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      handleIsLoggedIn(true);
      if (res.is_staff) {
        setTimeout(() => {
          history.push("/admin/dashboard");
        }, 1000);
      } else {
        setTimeout(() => {
          history.push("/dashboard");
        }, 1000);
      }
    } else {
      // this backend team confused the auth endpoints
      if (
        res.message.non_field_errors[0] ===
        "Unable to log in with provided credentials."
      ) {
        toast.error("Unable to log in with provided credentials.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An unexpected error occurred. Kindly try again, please.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      handleIsLoggedIn(false);
      setLoading(false);
    }
  };

  return (
    <>
      {/*<ToastContainer*/}
      {/*  position="top-right"*/}
      {/*  autoClose={5000}*/}
      {/*  hideProgressBar={false}*/}
      {/*  newestOnTop*/}
      {/*  closeOnClick*/}
      {/*  rtl={false}*/}
      {/*  pauseOnFocusLoss*/}
      {/*  draggable*/}
      {/*  pauseOnHover*/}
      {/*/>*/}
      <AuthLayout
        fadeName="fade-right"
        pageTitle="Login"
        // googleLabel="Sign In"
        // oAuth2={oAuth2}
      >
        <form onSubmit={handleSubmit} autoComplete='off'>
          <Row gutter={24}>
            <Col xs={24} xl={24} lg={24}>
              <div className="form-group">
                <label>Email Address</label>
                <Input
                  id={"email"}
                  onChange={handleChange}
                  required={true}
                  minLength={0}
                />
              </div>
            </Col>
            <Col xs={24} xl={24} lg={24}>
              <div className="form-group">
                <label>Password</label>
                <Input.Password
                  id={"password"}
                  value={userDetails.password}
                  onChange={handleChange}
                  required={true}
                />
              </div>

              <p className="p-pwd">
                <Link to="/forgot-password">Forgot Password?</Link>
              </p>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={24} lg={24}>
              <Button loading={loading} block onClick={handleSubmit} type="primary">
                {loading ? "Authenticating..." : "LOGIN"}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={24} xl={24} lg={24}>
              {/* <p className="register">
                Don't have account? <Link to="#">Get Started</Link>
              </p> */}
            </Col>
          </Row>
        </form>
      </AuthLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_error: state.authReducer.auth_error,
  };
};

export default connect(mapStateToProps, { loginUser, oAuth2 })(Login);
