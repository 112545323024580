// Operations to display all fields and edit any field info here ----
import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import EditIcon from "@material-ui/icons/Edit";
// import DeleteIcon from "@material-ui/icons/Delete";

// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { Cancel } from "@material-ui/icons";

import {
  ModalCancel,
  ModalContainer,
  FieldModalTitle,
  FieldModalSearchInput,
  FieldModalCard,
  FieldEachModalCard,
  FieldModalCardLeft,
  FieldModalCardRight,
  FieldCardName,
  FieldCardText,
  FieldCardType,
  FieldModalContainer,
  FieldModalInnerCard,
  FieldModalCardInner,
  FieldModalCardImg,
  FieldButton,
  FieldToggleBox,
  FieldToggleBoxItem,
} from "../../styles/util/UtilStyle";

import SkeletonCard from "../../adapters/util/SkeletonCard";
import { AppContext } from "../../appRouter";
import { EditModal } from "./EditFieldModal";
import GetMap from "../geoMap/GetMap";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    outline: "none",
  },
}));

const FieldsMap = (polygonPath, centroid, zoomVal) => {
  return (
    <>
      <GetMap polygonPath={polygonPath} centroid={centroid} zoomVal={zoomVal} />
    </>
  );
};

// modal to fetch all fields of the user
const FieldsModal = (props) => {
  const classes = useStyles();

  const {
    handleSelectedCentroid,
    handleSelectedCoords,
    handleSelectedRealCoords,
    handleSelectedFieldId,
    handleSelFieldDetails,
  } = useContext(AppContext);

  const handleSelectField = (coords, centroid, id, details) => {
    handleSelectedCoords(coords);
    handleSelectedCentroid(centroid);
    handleSelectedRealCoords(coords);
    handleSelectedFieldId(id);
    handleSelFieldDetails(details);
  };

  // const [dropDown, setDropDown] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [fieldData, setFieldData] = useState({});

  const [allFields, setAllFields] = useState([]);

  const handleEditModal = (selField) => {
    setEditOpen(true);
    setFieldData({
      coordinates: selField?.coordinates,
      centroid: selField?.centroid,
      size: selField?.size,
      id: selField?.id,
      name: selField?.name,
      group_name: selField.group_name,
    });

    if (props?.open) {
      props?.close(false);
    }
  };

  useEffect(() => {
    setAllFields(props?.fieldsArray);
  }, [props?.fieldsArray]);

  // --- search for field with this handler ---
  const handleSearchField = (e) => {
    let res = [];
    let searchItem = e.target.value;
    props?.fieldsArray.find(function (post, index) {
      if (post?.name.includes(searchItem)) {
        res.push(post);
        return setAllFields(res);
      }
      return 0;
    });
  };

  const handleCloseEdit = () => {
    setEditOpen(false);
  };

  return (
    <>
      {/* Start of the toastify component */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* --- End of the Toastify Component --- */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props?.open}
        onClose={props?.close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
        style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          zIndex: "100000000",
        }}
      >
        <Fade in={props?.open}>
          <ModalContainer>
            <ModalCancel>
              <Cancel onClick={() => props?.setFormBoxes({ open2: false })} />
            </ModalCancel>

            <FieldModalContainer>
              <FieldModalTitle>Field List</FieldModalTitle>

              {props?.isFetching ? (
                <SkeletonCard width="100%" height="90vh" />
              ) : (
                <>
                  <FieldModalCard>
                    <FieldModalInnerCard>
                      <FieldModalSearchInput
                        placeholder="Search fields by their name here..."
                        onInput={handleSearchField}
                      />
                    </FieldModalInnerCard>
                  </FieldModalCard>

                  {allFields?.map((eachField) => (
                    <FieldEachModalCard
                      key={eachField.id}
                      onClick={() =>
                        handleSelectField(
                          eachField?.coordinates,
                          eachField?.centroid,
                          eachField?.id,
                          eachField
                        )
                      }
                    >
                      <FieldModalInnerCard>
                        <FieldModalCardLeft>
                          <FieldModalCardImg>
                            {FieldsMap(
                              eachField.coordinates,
                              eachField.centroid,
                              9
                            )}
                          </FieldModalCardImg>
                        </FieldModalCardLeft>
                        <FieldModalCardRight>
                          <FieldModalCardInner>
                            <FieldCardName>
                              {eachField.group_name}
                              <FieldToggleBox>
                                <FieldToggleBoxItem>
                                  <EditIcon
                                    onClick={() => handleEditModal(eachField)}
                                    // onClick={() =>
                                    //   setDropDown({ [eachField.id]: true })
                                    // }
                                    style={{
                                      float: "right",
                                      marginTop: "0.1em",
                                      // background: 'rgba(5,5,5,1)',
                                      borderRadius: "50%",
                                    }}
                                  />
                                </FieldToggleBoxItem>
                              </FieldToggleBox>
                              {/* {dropDown?.[eachField.id] && (
                                  <FieldToggleBox>
                                    <FieldToggleBoxItem
                                      onClick={() => handleEditModal(eachField)}
                                    >
                                      <EditIcon />
                                    </FieldToggleBoxItem>
                                    <FieldToggleBoxItem>
                                      <DeleteIcon />
                                    </FieldToggleBoxItem>
                                  </FieldToggleBox>
                                )} */}
                            </FieldCardName>
                            <FieldCardText>{eachField.name}</FieldCardText>
                            <FieldCardType>
                              {"[ Lat: " +
                                (eachField?.centroid?.length >= 2
                                  ? eachField?.centroid[0]
                                      .toString()
                                      .substring(0, 5)
                                  : null) +
                                "   Long: " +
                                (eachField?.centroid?.length >= 2
                                  ? eachField?.centroid[1]
                                      .toString()
                                      .substring(0, 5)
                                  : null) +
                                " ]"}
                            </FieldCardType>
                            <Link
                              to={
                                "/dashboard/weather/forecast/" +
                                (eachField?.centroid?.length >= 2
                                  ? eachField?.centroid[0]
                                      .toString()
                                      .substring(0, 5)
                                  : "") +
                                "," +
                                (eachField?.centroid?.length >= 2
                                  ? eachField?.centroid[1]
                                      .toString()
                                      ?.substring(0, 5)
                                  : "")
                              }
                            >
                              <FieldButton>
                                Click to View Weather Info
                              </FieldButton>
                            </Link>
                          </FieldModalCardInner>
                        </FieldModalCardRight>
                      </FieldModalInnerCard>
                    </FieldEachModalCard>
                  ))}
                </>
              )}
            </FieldModalContainer>
          </ModalContainer>
        </Fade>
      </Modal>
      <EditModal
        open={editOpen}
        fieldData={fieldData}
        handleCloseEdit={handleCloseEdit}
      />
    </>
  );
};

export default FieldsModal;
