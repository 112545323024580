/* eslint-disable import/no-anonymous-default-export */
// import { constant } from "lodash";
import constants from "../constants";

export default (state = {}, action) => {
  switch (action.type) {
    case constants.ACCESS_TOKEN:
      return {
        ...state,
        access_token: action.payload,
      };
    case constants.AUTH_ERROR:
      return {
        ...state,
        auth_error: action.payload,
      };
    case constants.RESET_PASSWORD_EMAIL:
      return {
        ...state,
        reset_email: action.payload,
      };
    case constants.USER_DEATIALS:
      return {
        ...state,
        user_details: action.payload,
      };
    default:
      return state;
  }
};
