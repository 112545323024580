import styled from 'styled-components';

export const Footer = styled.footer`
  width: 100%;
  bottom: 0;
  background-color: #102E31;
opacity: 0.89;
`;
export const FooterInner = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 3em;
  @media screen and (max-width: 769px) {
    width: 90%;
  }
`;

export const FooterUpperSection = styled.section`
  width: 100%;
  padding: 0.01em 0em;
  height: fit-content;
  background: rgba(5, 103, 74, 0.05);
`;

export const FooterLowerSection = styled.section`
  width: 100%;
  height: fit-content;
  // background: white;
  padding: 0.01em 0em;
`;

export const FooterText = styled.p`
  display: flex;
  align-items: center;
  color: white;
`;

export const FooterTitle = styled.h6`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 2em;
  display: flex;
  align-items: center;
  color: rgba(5, 103, 74, 1);
`;

export const FooterBigTitle = styled.h3`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 2em;
  letter-spacing: -0.03333em;
  color: #252f40;
`;

export const FooterInput = styled.input`
  background: #ffffff;
  border: 1px solid #d2d6da;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 3em;
  padding: 0em 1em;
  outline: none;
`;

export const FooterTextArea = styled.textarea`
  background: #ffffff;
  border: 1px solid #d2d6da;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 10em;
  padding: 1em 1em;
  outline: none;
`;

export const FooterButton = styled.button`
  background: linear-gradient(
    180deg,
    #18a379 0%,
    rgba(14, 133, 98, 0.995104) 34.38%,
    rgba(7, 111, 80, 0.991256) 55.21%,
    rgba(5, 103, 74, 0.99) 100%,
    #05674a 100%
  );
  border-radius: 4px;
  height: 3em;
  width: 100%;
  border: none;
  color: white;
  transition: 0.5s color, 0.5s background, 0.5s border;
  &:hover {
    background: white;
    color: #18a379;
    border: 1px solid rgba(5, 103, 74, 0.5);
  }
`;

export const FooterRule = styled.hr`
  color: red;
  margin-top: 4em;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

export const SocialIconsBlack = styled.div`
  width: 2em;
  height: 2em;
  color: white;
  margin-left: 1em;
  margin-right: 0.5em;
  display: flex;
  align-self: center;
  align-items: center;
  cursor: pointer;
`;

export const FooterSectionSocial = styled.div`
  //   text-align: center;
  width: fit-content;
  display: flex;
  margin-top: 1em;
`;
