import styled from 'styled-components';

export const LayoutHolder = styled.div`
    position: relative;
    //background: #777;
`;

export const AgentHeaderLayout = styled.header`
    position: fixed;
    width: 100%;
    transition: ease all 0.6s;
    background: var(--white-color);
    display: flex;
    align-items: center;
    height: 55px;
    padding: 5px 6rem;
    z-index: 1;
    .user_notification {
        display: flex;
        justify-content: flex-end;
        transition: ease all 0.5s;
        flex: 1;
        position: relative;
        cursor: pointer;
        svg {
            font-size: 2rem;
        }
        .count {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50px;
            background: #f04040;
            color: var(--white-color);
            right: -4px;
            top: -4px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                color: var(--white-color);
                font-size: 0.9rem;
                font-weight: bold;
            }
        }
    }
    @media (max-width: 449px) {
        padding: 5px 2rem;
        .opendrawer {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
`;

export const AgentSidebarLayout = styled.aside``;

export const AgentMainContainer = styled.main`
    position: relative;
    transition: ease all 0.6s;
    background: #e5e5e5;
    padding: 5rem 2rem 1rem 16rem;
    height: auto;
    width: auto;
    margin-bottom: 15px;
    @media (max-width: 449px) {
        padding: 5rem 2rem 1rem 2rem;
        transition: ease all 0.6s;
    }
`;

export const FooterContainer = styled.footer`
    position: relative;
    bottom: 0;
    z-index: 1;
    background: var(--white-color);
    padding: 10px;
    transition: ease all 0.5s;
    width: 100%;
    display: flex;
    justify-content: center;
    span {
        text-align: center;
    }
`;

export const PlainHeaderContainer = styled.header`
    position: relative;
    transition: ease all 0.5s;
    display: flex;
    align-items: center;
    padding: 2rem 2rem;
    .logo-div {
        margin-left: 3rem;
        transition: ease all 0.5s;
        img {
            width: 50%;
            object-fit: contain;
        }
    }
    @media (max-width: 449px) {
        padding: 20px 1rem;
        .logo-div {
            margin-left: 10px;
            transition: ease all 0.5s;
            img {
                width: 63%;
                object-fit: contain;
            }
        }
    }
`;

export const MainContainer = styled.main`
    transition: ease all 0.5s;
    position: relative;
`;

export const LayoutContainer = styled.div`
    margin: 13rem 2rem 1rem;
    transition: ease all 0.5s;
    .header-title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h3 {
            font-size: 1.82rem;
            font-family: 'Avenir Heavy', sans-serif;
            margin-bottom: 0;
        }
        h4 {
            font-size: 1.1rem;
            font-family: 'Avenir light', sans-serif;
        }
    }
    .footer-title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 3rem 0;
        span {
            font-size: 1.1rem;
            font-family: 'Avenir Light', sans-serif;
            margin-bottom: 10px;
            a {
                color: var(--green-color);
                font-family: 'Avenir Heavy', sans-serif;
            }
        }
    }
    .pt-4 {
        padding: 1rem 0;
    }
    .container {
        width: 40%;
        margin: 3rem auto;
        transition: ease all 0.5s;
        &__inner {
            background: var(--gray-color);
            padding: 2rem;
            cursor: pointer;
            transition: ease all 0.5s;
            border-radius: 4px;
            margin-bottom: 15px;
            h3 {
                font-weight: bold;
                font-family: 'Avenir Heavy', sans-serif;
            }
            &.active {
                background: var(--green-color);
                transition: ease all 0.5s;
            }
            &:hover {
                background: var(--green-color);
                transition: ease all 0.5s;
                color: var(--white-color);
                h3 {
                    color: var(--white-color);
                }
                svg {
                    fill: var(--white-color);
                    stroke: var(--white-color);
                }
            }
        }
        &.login {
            width: 28%;
        }
    }
    @media (max-width: 768px) {
        .header-title {
            h3 {
                font-size: 1.42rem;
            }
            h4 {
                font-size: 1rem;
            }
        }
        .container {
            width: 90%;
            margin: 3rem auto;
        }
    }
    @media (min-width: 1124px) {
        .header-title {
            h3 {
                font-size: 1.42rem;
            }
            h4 {
                font-size: 1rem;
            }
        }
        .container {
            width: 65%;
            margin: 3rem auto;
        }
    }
`;

export const AuthContainer = styled.div`
    // display: flex;
    align-items: center;
    padding: 5px 0 10px;
    transition: ease all 0.5s;
    h2 {
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 2.4rem;
    }
    .login {
        width: 30%;
        margin: 3rem auto;
        padding: 15px;
        box-shadow: 0 2px 13px rgba(0, 0, 0, 0.09);
        transition: ease all 0.6s;
        border-radius: 8px;
        overflow: hidden;
        &.register {
            width: 50%;
        }
        &__form {
            // padding: 4rem;
            position: relative;
            transition: ease all 0.6s;
            h3 {
                font-size: 2rem;
                margin-bottom: 1.5rem;
                color: car(--background-color);
            }
            h4 {
                font-weight: lighter;
                font-size: 1.5rem;
            }
            label {
                margin-bottom: 0;
                font-size: 0.8rem;
            }
            .form-group {
                margin-bottom: 2rem;
            }
            .ant-input {
                /* height: 46px; */
                border-radius: 2px;
            }
            .ant-input-affix-wrapper {
                /* height: 46px; */
                overflow: hidden;
            }
            .ant-input-affix-wrapper-lg {
                padding: 10.5px 11px;
            }
        }
        &__pwd {
            text-align: center;
        }
        .ant-btn-default {
            background: #f2f2f2;
            border-radius: 2px;
            border: none;
            span {
                padding-left: 2rem;
            }
            &:hover {
                color: var(--black-color);
                span {
                    color: var(--black-color);
                    font-weight: bold;
                }
            }
        }
        &.verify {
            transition: ease all 0.5s;
            form {
                padding: 1rem 0 2rem;
                .ant-input {
                    height: 55px;
                    display: flex;
                    font-size: 2rem;
                    align-items: center;
                    border: none;
                    background: var(--gray-color);
                    justify-content: center;
                    text-align: center;
                    &:focus {
                        border-color: var(--btn-text-color);
                        box-shadow: none;
                    }
                }
            }
        }
    }
      .ant-btn-primary {
        background: var(--primary-green-color);
        border-color: var(--primary-green-color);
      }
    @media (max-width: 768px) {
        .login {
            width: 99%;
        }
    }
`;

