import constants from "../constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
    switch (action.type) {
        case constants.ALL_PAYMENT_RECORD:
            return {
                ...state,
                allPayments: action.payload,
            }
        case constants.ALL_ACCOUNT_USERS:
            return {
                ...state,
                allUsers: action.payload,
            }
        case constants.ONE_ACCOUNT_USER:
            return {
                ...state,
                user_details: action.payload,
            }
        case constants.ALL_ACCOUNT_TEAMS:
            return {
                ...state,
                allTeams: action.payload,
            }
        case constants.ALL_TRACKING_RECORDS:
            return {
                ...state,
                allTracking: action.payload,
            }
        case constants.ALL_ACCOUNT_SUBSCRIPTIONS:
            return {
                ...state,
                all_account_subscription: action.payload,
            }
        case constants.ONE_ACCOUNT_SUBSCRIPTIONS:
            return {
                ...state,
                one_account_subscription: action.payload,
            };
        default:
            return state;
    }
}