import React, { useEffect, useState } from 'react';
import { ServiceSection } from '../../AdminStyle.styled';
import {Row, Col, Button, Table, Modal, Tag} from 'antd';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
    ConfigureOneServices,
    DeleteConfiguredService,
    GetConfiguredServices, GetOneConfiguredServices,
    UpdateServices
} from "../../../../../redux/actions/adminActions";
// import CloseIcon from "@material-ui/icons/Close";
import {Delete, Edit, Save} from "@material-ui/icons";
import CreateServiceModal from "./CreateServiceModal";
import {CurrencySign, formatAmount} from "../../../../../utils";

const ServiceConfiguration = props => {
    const {
        ConfigureOneServices,
        // one_service,
        GetConfiguredServices,
        // GetOneConfiguredServices,
        DeleteConfiguredService,
        allServices,
        UpdateServices
    } = props;

    const [deleteloading, setdeleteloading] = useState(false);
    const [loading, setloading] = useState(false);
    const [visible, setvisible] = useState(false);
    const [formMode, setFormMode] = useState('CREATE_MODE');
    const [serviceRecord, setServiceRecord] = useState(null);

    const editRecord = async (record) => {
        // setloading(true);
        setServiceRecord(record);
        setvisible(true);
        //     setloading(false);
            setFormMode('UPDATE_MODE');
        // const res = await GetOneConfiguredServices(record.id);
        // if (res.success) {
        //     setvisible(true);
        //     setloading(false);
        //     setFormMode('UPDATE_MODE');
        //     console.log('reee', one_service);
        // } else {
        //     setloading(false);
        //     toast.error("Error Fetching Record");
        // }
    }

    const deleteRecord = (record) => {
        Modal.confirm({
            title: 'Do you want to delete this record?',
            content: 'Click yes to proceed',
            okText: 'Delete',
            loading: deleteloading,
            okType: 'danger',
            async onOk() {
                setdeleteloading(true);
                const res = await DeleteConfiguredService(record.id);
                if (res.success) {
                    setdeleteloading(false);
                    toast.success(res.message, {
                        pauseOnHover: false,
                    });
                } else {
                    toast.info(res.message, {
                        pauseOnHover: true,
                    });
                }
            },
            onCancel() {
                setdeleteloading(false);
                return false;
            },
        });
    };

    const CreateService = () => {
        setvisible(true);
        setFormMode('CREATE_MODE');
        setServiceRecord(null);
    }

    const closeModal = () => {
        setvisible(false);
        setFormMode('CREATE_MODE');
        setServiceRecord(null);
    }

    const recordColumn = [
        {
            title: 'Service',
            dataIndex: 'service',
            render: (text) => <><span style={{ textTransform: 'capitalize' }}>{text?.replace('_', ' ')}</span></>
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: <>Amount Per Hectare ({CurrencySign})</>,
            dataIndex: 'amount_per_unit',
            render: (_text, record) => <>{formatAmount(record?.amount_per_unit)}</>
        },
        {
            title: 'Notification',
            dataIndex: 'notification',
            render: (_text, record) => <>{
                record.notification === null ?
                    <Tag color="gray">Unknown</Tag> :
                    record.notification === false ?
                        <Tag color="red">Not Active</Tag> :
                        <Tag color="green">Active</Tag>
            }</>
        },
        {
            title: 'Date Updated',
            dataIndex: 'date_updated',
            render: (_text, record) => <>{record?.date_updated.split('T')?.[0]}</>
        },
        {
            title: 'action',
            dataIndex: 'action',
            align: 'center',
            render: (_text, record) => <>
                <div className="actiontable">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <button
                        className="eid"
                        onClick={() => editRecord(record)}>
                        <Edit />
                    </button>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <button
                        className="del"
                        onClick={() => deleteRecord(record)}
                    >
                        <Delete />
                    </button>
                </div>
            </>
        }

    ]

    useEffect(() => {
        const getServiceRecords = async () => {
            setloading(true);
            const res = await GetConfiguredServices();
            setloading(false);
            if (!res.success) {
                setloading(false);
                toast.error(`Error`)
            }
        };
        getServiceRecords();
    }, [GetConfiguredServices]);

    return (
        <ServiceSection>
            <Row>
                <Col xs={24}>
                    <div style={{ textAlign: 'right', marginBottom: '15px' }}>
                        <Button onClick={CreateService} icon={<Save />} type="primary"> Create Service</Button>
                    </div>
                </Col>
            </Row>

            <Table rowKey="id" style={{ overflowX: 'auto' }} loading={loading} columns={recordColumn} dataSource={allServices} />

            <CreateServiceModal
                visible={visible}
                closeModal={closeModal}
                one_service={serviceRecord}
                ConfigureOneServices={ConfigureOneServices}
                UpdateServices={UpdateServices}
                formMode={formMode}
            />
        </ServiceSection>
    );
};

const mapStateToProps = state => {
    return {
        allServices: state.adminReducer.allServices,
        one_service: state.adminReducer.one_service,
    };
};

export default connect(mapStateToProps, {
    ConfigureOneServices,
    UpdateServices,
    GetConfiguredServices,
    DeleteConfiguredService,
    GetOneConfiguredServices,
})(ServiceConfiguration);
