import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { /*useHistory,*/ useParams } from "react-router-dom";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import {
  FarmDetailContainer,
  FarmActivitiesHeader,
} from "./FarmActivitiy.styled";
import { getOneFarmActivities } from "../../redux/actions/farmActions";
import { Row, Col, Spin, Button, Card, Table, Tag } from "antd";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import "react-nice-dates/build/style.css";

// const { Option } = Select;

const FarmActivitiesDetails = (props) => {
  const { getOneFarmActivities, single_activities } = props;
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  // const createTask = () => {
  //   history.push("/farmactivity/configure");
  // };

  useEffect(() => {
    const getActivityDetails = async () => {
      setloading(true);
      const res = await getOneFarmActivities(id);
      if (res.success) {
        setloading(false);
      } else {
        toast.error(`No Record of the Farm Activities with ${id}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    getActivityDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assignCol = [
    {
      title: "Email Address",
      dataIndex: "email",
    },
  ];

  const allAssignees = single_activities?.assignees || [];

  const returnTasks = () => {
    history.goBack();
  };

  return (
    <DashboardLayout pageTitle="Task Details">
      <FarmDetailContainer
        data-aos="fade-up"
        data-aos-delay="2s"
        data-aos-duration="1s"
      >
        {loading ? (
          <div className="example">
            <Spin />
            <Button type="dashed" onClick={returnTasks}>
              <ArrowRightAltIcon /> Return To Tasks
            </Button>
          </div>
        ) : (
          <>
            <FarmActivitiesHeader>
              <h3>Farm Activities Details</h3>
            </FarmActivitiesHeader>
            {/*<Row gutter={4} align="middle">
              <Col xs={24} xl={4} lg={6}>
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  defaultValue="2021"
                >
                  <Option value={"2021"}>2021</Option>
                </Select>
              </Col>
              <Col xs={24} xl={4} lg={6}>
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  defaultValue="Group"
                >
                  <Option value={"Group"}>Group</Option>
                </Select>
              </Col>
              <Col xs={24} xl={4} lg={6}>
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  defaultValue="Crop"
                >
                  <Option value={"Crop"}>Crop</Option>
                </Select>
              </Col>
              <Col xs={24} xl={4} lg={6}>
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  defaultValue="Selected"
                >
                  <Option value={"Selected"}>Selected</Option>
                </Select>
              </Col>
              <Col xs={24} xl={4} lg={6}>
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  defaultValue="Scheduled"
                >
                  <Option value={"Scheduled"}>Scheduled</Option>
                </Select>
              </Col>
              <Col xs={24} xl={4} lg={6}>
                <Button onClick={createTask} block size="large" type="primary">
                  Add Activity
                </Button>
              </Col>
            </Row>*/}
            <div style={{ marginTop: "2rem" }} />
            <Row>
              <Col xs={24}>
                <Button type="dashed" onClick={returnTasks}>
                  <ArrowRightAltIcon /> Task List
                </Button>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24} xl={12} lg={8}>
                <Card className="card-shadow">
                  <h4>Task Details</h4>
                  <div className="details">
                    <div>
                      <p>Task Status:</p>
                      <span>
                        <Tag color="green">{single_activities?.status}</Tag>
                      </span>
                    </div>
                    {single_activities?.status === "closed" ||
                    single_activities?.status === "Closed" ? (
                      <>
                        <div className="closed-reason">
                          <p>Reason</p>
                          <span>{single_activities?.comment}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div>
                      <p>Task Name:</p>
                      <span>{single_activities?.name}</span>
                    </div>
                    <div>
                      <p>Description:</p>
                      <span>{single_activities?.description}</span>
                    </div>
                    <div>
                      <p>Task Date:</p>
                      <span>
                        Start: {single_activities?.start_date} --- End:{" "}
                        {single_activities?.end_date}{" "}
                      </span>
                    </div>
                  </div>
                </Card>
              </Col>

              <Col xs={24} xl={12} lg={12}>
                <Card className="card-shadow">
                  <h4>All Assignees</h4>
                  <Table
                    rowKey="id"
                    columns={assignCol}
                    dataSource={allAssignees}
                  />
                </Card>
              </Col>
            </Row>
          </>
        )}
      </FarmDetailContainer>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    single_activities: state.FarmReducer.single_activities,
  };
};

export default connect(mapStateToProps, { getOneFarmActivities })(
  FarmActivitiesDetails
);
