import React, { useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { AppContext } from '../../appRouter';
import DashboardLayout from '../../components/Layout/DashboardLayout';

import SatelliteView from '../../components/satelliteField/SatelliteView';

const SatelliteViewPage = (props) => {

    const { selectedCentroid, selectedCoords, selectedRealCoords } = useContext(AppContext);

    let history = useHistory();

    useEffect(() => {
        // check if a shape/field has been selected or not
        if (selectedCoords?.length === 0) {
            history.push('/dashboard/');
        }
    });
    return (
        <>

            {/* No need for broken link page here. But instead toasify straightaway */}
            <DashboardLayout pageTitle="Satellite View" isHome={true}>

                <SatelliteView
                    selectedCentroid={selectedCentroid}
                    selectedCoords={selectedCoords}
                    selectedRealCoords={selectedRealCoords}
                    height={'100vh'}
                />

            </DashboardLayout>
        </>
    )
};

export default SatelliteViewPage;