import styled from 'styled-components';

export const SubscriptionContainer = styled.div`
  position: relative;
  transition: ease all 0.5s;
  margin: 4rem 3rem;
  .subhead {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
    h3 {
      font-size: 2.3rem;
      font-weight: bolder;
      margin-bottom: 0;
      color: var(--primary-green-color);
    }
    h4 {
      font-size: 1.2rem;
      font-weight: lighter;
    }
  }
  .subcard {
    padding: 20px 0;
    //background: var(--white-color);
  }
  @media(max-width: 768px) {
    margin: 7rem 0;
    .subhead {
      margin-top: 1rem;
      h3 {
        font-size: 2rem;
      }
      h4 {
        font-size: 0.81rem;
      }
    }
  }
`;

export const SubscriptionCardContainer = styled.div`
  padding: 15px;
  background: var(--white-color);
  border-radius: 4px;
  text-align: center;
  height: auto;
  box-shadow: 0 3px 10px rgba(0,0, 0,0.04);
  transition: ease all 0.5s;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
  .head {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    h3 {
      padding: 15px 0;
      font-size: 1rem;
      text-transform: uppercase;
    }
    &__sup {
      vertical-align: super;
      font-size: 20px;
    }
    h2 {
      font-weight: bold;
    }
  }
  .subbtn {
    display: flex;
    align-items: center;
    border-radius: 50px;
    height: 40px;
    margin: 1rem auto;
    padding: 10px 4rem;
    justify-content: center;
    transition: ease all 0.5s;
    background: var(--primary-green-color);
    border-color: var(--primary-green-color);
    color: var(--white-color);
  }
  .paymentfirst {
    h3 {
      font-size: 1rem;
    }
  }
  .features {
    padding: 10px 0;
    h4 {
      font-size: 0.9rem;
      font-weight: bold;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0;
      padding: 0;
    }
    li {
      display: flex;
      align-items: center;
      padding: 1rem 0;
      svg {
        margin-right: 5px;
        font-size: 14px;
        stroke: var(--primary-green-color);
        fill: var(--primary-green-color);
      }
      span {
        padding: 10px 15px; 
      }
    }
  }
  &:hover {
    color: var(--white-color);
    background: var(--primary-green-color);
    transition: ease all 0.5s;
    transform: scale(1,1);
    .head {
      h3 {
        color: var(--white-color);
      }
      &__sup {
        color: var(--white-color);;
      }
      h2 {
        color: var(--white-color);
      }
    }
    .features {
      h4 {
        color: var(--white-color);
      }
      li {
        svg {
          margin-right: 5px;
          font-size: 14px;
          stroke: var(--white-color);
          fill: var(--white-color);
        }
        span {
          color: var(--white-color);
        }
      }
    }
    .subbtn {
      background: var(--white-color);
      border-color: var(--white-color);
      color: var(--primary-green-color);
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
    }
  }
  @media(max-width: 768px) {
    
  }
`;

export const PaymentContainer = styled.div`
    position: relative;
    transition: ease all 0.5s;
    padding-top: 2rem;
    h2 {
        display: flex;
        justify-content: center;
        font-size: 2rem;
        font-weight: bold;
    }
    .footerlogo {
        display: flex;
        padding: 15px 0;
        height: 100px;
        overflow: hidden;
        justify-content: flex-end;
    }
    .btn {
        display: flex;
        margin-left: 10px;
        height: 40px;
        align-items: center;
        color: var(--white-color);
    }
    .btn-payment {
        background: var(--green-color);
        border-color: var(--green-color);
        &:hover {
            background: rgb(50, 100, 90);
            border-color: rgb(50, 100, 90);
        }
    }
    .btn-cancel {
        background: var(--white-color);
        border-color: var(--black-color);
        color: var(--black-color);
    }
    .beforecheckout {
        padding: 2rem;
        p {
            display: flex;
            font-style: italic;
            font-size: 0.9rem;
            padding-top: 15px;
            color: rgb(68, 10, 30);
            justify-content: flex-end;
            animation: blink 3s;
        }
    }
    .initiatepayment {
        width: 70%;
        margin: auto;
        h3,
        h4 {
            text-align: center;
        }
        h3 {
            font-size: 1.5rem;
        }
        img {
            width: 30%;
            height: 100%;
            margin: 3rem auto;
            display: flex;
        }
    }
    img {
        width: 100px;
        display: flex;
        object-fit: contain;
        margin: auto;
    }
    .afterpayment {
        margin-top: 2rem;
        padding: 3rem 5rem;
        .ant-result-extra {
            display: flex;
            justify-content: center;
        }
        .ant-row {
            margin-bottom: 1rem;
            padding: 1rem 0;
            border-bottom: 1px solid #dddddd;
        }
        span {
            font-size: 1rem;
        }
    }
`;