import React from 'react';

import CropPlan from '../../components/cropPlan/CropPlan';


const CropPlanPage = (props) => {

    return (
        <>
            {/* {gotError ? */}
            {/* <BrokenLinkPage customText="Looks like something went wrong!" customSubText="The Farm summary info could not be fetched at the moment." /> */}
            {/* : */}
            <CropPlan />
            {/* } */}
        </>
    )
};

export default CropPlanPage;