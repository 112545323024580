import React, { useMemo } from 'react';

import "leaflet/dist/leaflet.css";
import L from 'leaflet';

import { bbox, bboxPolygon, point, buffer } from '@turf/turf';

import { Polygon, Tooltip, useMap } from 'react-leaflet';

import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';

import getIndexData from '../../adapters/map/getIndexData';

import { cloudLimit, wmsKey } from '../util/Util';

const purpleOptions = { color: 'rgba(255,255,255,0.7)' }

const provider = new OpenStreetMapProvider();

const searchControl = new GeoSearchControl({
    provider: provider,
    style: 'default',
    autoComplete: true,
    autoCompleteDelay: 250,
    showMarker: true,
    showPopup: true,
    marker: {
        // optional: L.Marker    - default L.Icon.Default
        // icon: new L.Icon.Default(),
        icon: new L.Icon.Default(),
        draggable: false,
    },
    maxMarkers: 1,
    retainZoomLevel: false,
    animateZoom: true,
    autoClose: false,
    searchLabel: 'Enter address to locate',
    keepResult: false,
    updateMap: true,
});

const GetMapInfo = (props) => {
    const map = useMap();
    // check for a change in the layer controls
    map.on('baselayerchange', function (e) {
        props?.setCurLayer(e?.name)
    });

    // prop to receive to determine to add locate address marker or not
    if (props?.showLocateAddr) {
        map.addControl(searchControl);
    }

    setTimeout(() => {

        try {
            map.fitBounds(props?.basePolygon?.current?._bounds);
            props?.setIsMapLoading(false);
        } catch (e) {
            console.log('could not bound map')
        }
    }, 3000);

    const eventHandlers = useMemo(
        () => ({
            click: (e) => {
                // const context = props?.basePolygon?.current?.getContext('2d');

                // the selected layer
                // let indexLayer = Object.keys(map?._layers)[1];
                // let indexName = map?._layers?.[indexLayer]?.options?.layers;

                let lat = Number.parseFloat(e.latlng.lat.toFixed(3));
                let lng = Number.parseFloat(e.latlng.lng.toFixed(3));

                let hoverPoint = point([lng, lat]);

                let buffered = buffer(hoverPoint, 0.2);

                let pointBBox = bbox(buffered);

                let pointPolygon = bboxPolygon(pointBBox);

                let geometry = `POLYGON ((${pointPolygon.geometry.coordinates[0].map(coord => coord.join(' ')).join(', ')}))`;

                let layer = "";
                if (props?.curLayer === "NDMI") {
                    layer = "NDMI"
                } else {
                    layer = props?.curLayer;
                }

                if (layer && (props?.cloudCover <= cloudLimit)) {

                    let fisUrl = `https://services.sentinel-hub.com/ogc/fis/${wmsKey}?LAYER=${layer}I&CRS=CRS:84&TIME=${props?.time}&MAXCC=${props?.cloudCover}&WIDTH=512&HEIGHT=512&GEOMETRY=${geometry}`;

                    // console.log(fisUrl);
                    // console.log('the fisUrl', fisUrl);
                    props?.setTooltipData({ position: e.latlng, text: 'Please wait...' });

                    let getResp = getIndexData(fisUrl);

                    getResp.then(({ isError, data }) => {

                        if (isError) {
                            props?.setTooltipData({ position: e.latlng, text: 'Not Found' });
                        } else {

                            let totalMean = 0;
                            let indexVal = 0;
                            if (Object.keys(data).length > 0) {
                                data['C0'].map(eachData => {
                                    console.log(eachData.basicStats)
                                    totalMean += eachData.basicStats.mean;
                                    return 0;
                                });
                                indexVal = (totalMean / (data['C0'].length)).toFixed(3);
                                console.log("indexVal", indexVal)
                            }

                            props?.setTooltipData({ position: e.latlng, text: 'Click any point to read value' });
                            map.openPopup(`${props?.curLayer} : ${indexVal}`, e.latlng, { className: 'popupLeaflet' });
                        }

                    })
                }
            }
        }), [map, props])

    return <>

        <Polygon
            ref={props?.basePolygon}
            pathOptions={purpleOptions} positions={props?.selectedCoords}
            eventHandlers={eventHandlers}
        >
            <Tooltip direction="top" className="tooltip" sticky position={props?.tooltipData?.position}>{props?.tooltipData?.text}</Tooltip>
        </Polygon>
    </>
}

export default GetMapInfo;