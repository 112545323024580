import axios from "axios";
import { baseUrl } from "../../config";
import constants from "../constants";

export const getMyRole = () => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  return axios
    .post(`${baseUrl}/roles/my-roles/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.MY_ROLES, payload: res.data });
      return { success: true, message: "message" };
    })
    .catch((err) => {
      return { success: false, message: "message" };
    });
};

export const getAllPermissions = () => (dispatch) => {
  return axios.get(`${baseUrl}/roles/permissions/`, {
    headers: {
      "Content-Type": "application/json",
    },
  })
  .then((res) => {
    dispatch({ type: constants.GET_ALL_PERMISSION, payload: res.data });
    return { success: true, message: 'Successful' };
  }).catch((err) => {
    return { success: false, message: "Error Fetching Permission" };
  })
};

export const createRole = (roleDetails) => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  // let id = getState().authReducer.user_details.owned_team.id;
  return axios
    .post(`${baseUrl}/roles/create/`, roleDetails, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      if (res.status === "201" || res.status === 201) {
        // dispatch(getTeamRoles(id));
        return { success: true, message: "message" };
      }
    })
    .catch((err) => {
      return { success: false, message: "message" };
    });
};

export const updateRole = (data, id) => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  let ownerId = getState().authReducer.user_details.owned_team.id;
  return axios
    .put(`${baseUrl}/roles/${id}/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      dispatch(getTeamRoles(ownerId));
      // dispatch({ type: constants.TEAM_ROLES, payload: res.data });
      return { success: true, message: "message" };
    })
    .catch((err) => {
      console.log("login err", err.response);
      return { success: false, message: err.response?.data.message };
    });
};

export const getTeamRoles = (id) => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/roles/team/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.TEAM_ROLES, payload: res.data });
      return { success: true, message: "message" };
    })
    .catch((err) => {
      return { success: false, message: "message" };
    });
};

export const getTeamMembers = (id) => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/accounts/team/${id}/members/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.TEAM_MEMBERS, payload: res.data });
      return { success: true, message: "message" };
    })
    .catch((err) => {
      return { success: false, message: err.response?.data.message };
    });
};

export const deleteARole = (id) => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  return axios
    .delete(`${baseUrl}/roles/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      //   dispatch(getTeamRoles(id));
      if (res.status === 204 && res.data === "") {
        dispatch(getTeamRoles(id));
        console.log("action res", res);
        dispatch({ type: constants.TEAM_ROLES, payload: res.data });
        return { success: true, message: "message" };
      }
    })
    .catch((err) => {
      return { success: false, message: err.response?.data.message };
    });
};

export const AssignARole = (data) => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  let ownerId = getState().authReducer.user_details.owned_team.id;
  return axios
    .post(`${baseUrl}/roles/assign/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      console.log("succ", res);
      if (res.status === "201" || res.status === 201) {
        dispatch(getTeamMembers(ownerId));
        // dispatch({ type: constants.TEAM_MEMBERS, payload: res.data });
        // dispatch({ type: constants.TEAM_ROLES, payload: res.data });
        return { success: true, message: "message" };
      }
    })
    .catch((err) => {
      console.log("login err", err.response);
      return { success: false, message: err.response?.data[0] };
    });
};

export const UpdateReassignRole = (data, id) => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  let owonerId = getState().authReducer.user_details.owned_team.id;
  return axios
    .patch(`${baseUrl}/roles/reassign/${id}/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      if (res.status === "200" || res.status === 200) {
        dispatch(getTeamMembers(owonerId));
        // dispatch({ type: constants.TEAM_MEMBERS, payload: res.data });
        // dispatch({ type: constants.TEAM_ROLES, payload: res.data });
        return { success: true, message: "message" };
      }
    })
    .catch((err) => {
      console.log("login err", err.response);
      return { success: false, message: err.response?.data.detail };
    });
};

export const removeMember = (id) => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  let ownerId = getState().authReducer.user_details.owned_team.id;
  return axios
    .delete(`${baseUrl}/roles/remove-member/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      getTeamMembers(ownerId);
      // console.log("succ", res);
      if (res.status === 204 && res.data === "") {
        dispatch(getTeamMembers(ownerId));
        return { success: true, message: "message" };
      }
    })
    .catch((err) => {
      console.log("login err", err.response);
      return { success: false, message: err.response?.data.detail };
    });
};

export const getAUserProfile = (id) => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/accounts/${id}/profile/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.USER_DEATIALS, payload: res.data });
      return { success: true, message: "message" };
    })
    .catch((err) => {
      return { success: false, message: "message" };
    });
};

export const GetAllUsers = () => (dispatch, getState) => {
  let access_key = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/accounts/all-users/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${access_key}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.ALL_USERS, payload: res.data });
      return { success: true, message: "Succesfull" };
    })
    .catch((err) => {
      return { success: false, message: err?.response?.data.detail };
    });
};
