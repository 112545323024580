/* eslint-disable import/no-anonymous-default-export */
import constants from "../constants";

export default (state = {}, action) => {
  switch (action.type) {
    case constants.ALL_FIELDS:
      return {
        ...state,
        fields: action.payload,
      };
    case constants.ALL_CROPS:
      return {
        ...state,
        crops: action.payload,
      };
    case constants.ALL_CROPPLAN:
      return {
        ...state,
        crop_plan: action.payload,
      };
    default:
      return state;
  }
};
