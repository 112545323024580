import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FarmSummaryInfo from '../../components/farmManagement/FarmSummaryInfo';
import { getSpecialFieldDetails } from '../../redux/actions/fieldManagement';
import { BrokenLinkPage } from '../errorHandling';

const FarmSummaryInfoPage = (props) => {

    const [stillFetching1, setStillFetching1] = useState(true);

    const [gotError, setGotError] = useState(false);
    const [coords, setCoords] = useState([]);
    const [cropSeasons, setCropSeasons] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [fieldData, setFieldData] = useState({});
    const [activities, setActivities] = useState([]);
    const [weatherData, setWeatherData] = useState({});
    const [centroid, setCentroid] = useState([]);

    let id = props?.location?.pathname.substr(28,).split(",");
    const { access_token: access_key } = useSelector(state => state.authReducer);


    useEffect(() => {

        setStillFetching1(true);
        async function fetchData() {

            let weather = {
                date: [],
                weatherUrlIcon: [],
                maxTemp: [],
                minTemp: [],
                indicator: [],
                windSpeed: [],
                humidity: [],
                cloud_over: [],
                precipitation: [],
            }
            let { isError, data } = await getSpecialFieldDetails(id, access_key);

            if (isError) {
                setStillFetching1(true);
                setGotError(true);
                toast.error('It looks like we could not provide the farm summary list at the moment. Please kindly go back to select a field and try again here.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                setActivities(data?.activity);
                setCoords(data?.field?.coordinates);
                setCentroid(data?.field?.centroid);
                setCropSeasons(data?.field?.crop_season);
                setChartData(data?.weather);
                setFieldData(data?.field);
                data?.weather.map((row) => {
                    weather?.date?.push(row?.date);
                    weather?.maxTemp?.push(row?.max_temperature);
                    weather?.minTemp?.push(row?.min_temperature);
                    weather?.indicator?.push(row?.indicator);
                    weather?.windSpeed?.push(row?.windspeed);
                    weather?.humidity?.push(row?.Humidity);
                    weather?.cloud_over?.push(row?.cloud_over);
                    weather?.precipitation?.push(row?.precipitation);
                    weather?.weatherUrlIcon?.push(row?.weatherUrlIcon);
                    return 0;
                }).reverse();

                setWeatherData(weather)
                setStillFetching1(false);
                setGotError(false);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {gotError ?
                <BrokenLinkPage customText="Looks like something went wrong!" customSubText="The Farm summary info could not be fetched at the moment." />
                :
                <FarmSummaryInfo activityData={activities} fieldData={fieldData} chartData={chartData} weatherData={weatherData} stillFetching1={stillFetching1} centroid={centroid} coords={coords} fieldId={id} cropSeasons={cropSeasons} />
            }
        </>
    )
};

export default FarmSummaryInfoPage;