import React from 'react';

import { Fade, Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { FarmCropRotation, ShowMoreBody, ShowMoreCloseBtn, ShowMoreTable, ShowMoreTd, ShowMoreTh, ShowMoreTitle, ShowMoreTr } from '../../styles/farmManagement/FarmSummaryStyle';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        outline: 'none'
    }
}));


const ShowMoreModal = (props) => {
    const classes = useStyles();
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props?.open}
                onClose={props?.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
                style={{ border: 'none !important' }}
            >
                <Fade in={props?.open}>
                    <ShowMoreBody>
                        <ShowMoreTitle>YOUR CROP INFORMATION</ShowMoreTitle>
                        <FarmCropRotation>
                            <ShowMoreTable>
                                <thead>
                                    <ShowMoreTr>
                                        <ShowMoreTh>Crop Name</ShowMoreTh>
                                        <ShowMoreTh>Variety</ShowMoreTh>
                                        <ShowMoreTh>Season</ShowMoreTh>
                                        <ShowMoreTh>Sowing Date</ShowMoreTh>
                                        <ShowMoreTh>Harvest Date</ShowMoreTh>
                                    </ShowMoreTr>
                                </thead>
                                <tbody>
                                    <ShowMoreTr>
                                        <ShowMoreTd>{props?.cropInfo?.crop?.name}</ShowMoreTd>
                                        <ShowMoreTd>{props?.cropInfo?.crop?.description}</ShowMoreTd>
                                        <ShowMoreTd>{props?.cropInfo?.season}</ShowMoreTd>
                                        <ShowMoreTd>{props?.cropInfo?.sowing_date}</ShowMoreTd>
                                        <ShowMoreTd>{props?.cropInfo?.harvest_date}</ShowMoreTd>
                                    </ShowMoreTr>
                                </tbody>
                            </ShowMoreTable>
                        </FarmCropRotation>
                        <ShowMoreCloseBtn onClick={props?.handleClose}>Close</ShowMoreCloseBtn>

                    </ShowMoreBody>
                </Fade>

            </Modal>
        </>
    )
};

export default ShowMoreModal;