import React, { useState, useEffect } from 'react';
import DefaultLayout from '../Layout/DefaultLayout';
import { ManageUserContainer } from './AdminStyle.styled';
import {Modal, Pagination, Table, Tag} from 'antd';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {ApproveTeamAccount, GetAllAccountTeams} from "../../../redux/actions/adminOtherServices";
import {TeamMenu} from "./menus/TeamMenu";


const AdminTeamRecords = props => {
    const { allTeams, allTeamsObject, GetAllAccountTeams, ApproveTeamAccount } = props;
    const [loading, setloading] = useState(false);
    const [apploading, setapploadng] = useState(false);

    const approveTeam = (record) => {
        const approveObject = {
            // owner: {
            //     email: record.email,
            //     first_name: record.owner?.split(' ')[0],
            //     last_name: record.owner?.split(' ')[1],
            // },
            // name: record.name,
            admin_approved: true,
            // subscription_approved: true,
            // current_subscription: 0
        };
        Modal.confirm({
            title: 'Do you want to approve this team?',
            content: 'Click yes to proceed',
            okText: 'Yes',
            loading: apploading,
            okType: 'primary',
            async onOk() {
                setapploadng(true);
                const res = await ApproveTeamAccount(approveObject, record.id);
                if (res.success) {
                    setapploadng(false);
                    toast.success(res.message || 'Team Approved Successfully', {
                        pauseOnHover: false,
                    });
                } else {
                    toast.error(res.message || 'Error Occurred', {
                        pauseOnHover: true,
                    });
                }
            },
            onCancel() {
                setapploadng(false);
                return false;
            },
        });
    };

    const revokeApproval = (record) => {
        const approveObject = {
            admin_approved: false,
        };
        Modal.confirm({
            title: 'Do you want to revoke this team?',
            content: 'Click yes to proceed',
            okText: 'Yes',
            loading: apploading,
            okType: 'danger',
            async onOk() {
                setapploadng(true);
                const res = await ApproveTeamAccount(approveObject, record.id);
                if (res.success) {
                    setapploadng(false);
                    toast.success(res.message, {
                        pauseOnHover: false,
                    });
                } else {
                    toast.error(res.message || 'Error Occurred', {
                        pauseOnHover: true,
                    });
                }
            },
            onCancel() {
                setapploadng(false);
                return false;
            },
        });
    };

    useEffect(() => {
        const getAllRecords = async () => {
            const searchObject = {
                size: 20,
                page: 1
            };
            setloading(true);
            const res = await GetAllAccountTeams(searchObject);
            setloading(false);
            if (!res.success) {
                setloading(false);
                toast.error(res.message, {
                    pauseOnHover: true,
                    closeOnClick: true,
                });
            }
        };
        getAllRecords();
    }, [GetAllAccountTeams]);

    const formatData = allTeams?.map((team) => {
        return {
            ...team,
            name: team.name,
            email: team.owner.email,
            owner: team.owner?.first_name + ' ' + team.owner?.last_name,
            admin_approved: team.admin_approved,
            subscription_approved: team.subscription_approved,
        }
        // return {
        //     "owner": {
        //         "email": "user@example.com",
        //         "first_name": "string",
        //         "last_name": "string"
        //     },
        //     "name": "string",
        //     "admin_approved": true,
        //     "subscription_approved": true,
        //     "current_subscription": 0
        // }
    })

    const teamCol = [
        {
            title: 'Team Name',
            dataIndex: 'name',
            render: (text) => <><span style={{ textTransform: 'capitalize' }}>{text}</span></>
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            record: (_text, record) => <><span style={{ textTransform: 'capitalize' }}>{record?.owner?.first_name} {record?.owner?.last_name}</span></>
        },
        {
            title: 'Approved?',
            dataIndex: 'admin_approved',
            align: 'center',
            render: (_text, record) => <>{
                record.admin_approved === null ?
                    <Tag color="gray">Unknown</Tag> :
                    record.admin_approved === false ?
                        <Tag color="red">No</Tag> :
                        <Tag color="green">Yes</Tag>
            }</>
        },
        {
            title: 'Subscription Approved?',
            dataIndex: 'subscription_approved',
            align: 'center',
            render: (_text, record) => <>{
                record.subscription_approved === null ?
                    <Tag color="gray">Unknown</Tag> :
                    record.subscription_approved === false ?
                        <Tag color="red">No</Tag> :
                        <Tag color="green">Yes</Tag>
            }</>
        },
        {
            title: 'Current Subscription',
            dataIndex: 'current_subscription',
            align: 'center',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (_text, record) => (
                <>
                    <TeamMenu record={record} approveTeam={approveTeam} revokeApproval={revokeApproval} />
                </>
            ),
        },
    ];

    const onChangeSize = async (current, pageSize) => {
        const searchObject = {
            size: pageSize,
            page: current
        };
        await getAllRecords(searchObject);
    };

    const getAllRecords = async (searchObject) => {
        setloading(true);
        const res = await GetAllAccountTeams(searchObject);
        setloading(false);
        if (!res.success) {
            setloading(false);
            toast.error(res.message, {
                pauseOnHover: true,
                closeOnClick: true,
            });
        }
    };

    return (
        <DefaultLayout browserTitle="Manage Users">
            <ManageUserContainer data-aos="fade-right">
                <h3>Team Management</h3>
                <Table
                    style={{ overflowX: 'auto' }}
                    loading={loading}
                    pagination={false}
                    rowKey="id"
                    columns={teamCol}
                    dataSource={formatData}
                />
                <div style={{ marginTop: '1rem', textAlign: 'right' }}>
                    <Pagination
                        defaultPageSize={20}
                        responsive={true}
                        defaultCurrent={1}
                        pageSize={20}
                        total={allTeamsObject?.count}
                        onChange={onChangeSize}
                    />
                </div>
            </ManageUserContainer>
        </DefaultLayout>
    );
};

const mapStateToProps = state => {
    return {
        allTeams: state.adminOtherReducer.allTeams?.results,
        allTeamsObject: state.adminOtherReducer.allTeams,
    };
};

export default connect(mapStateToProps, {
    GetAllAccountTeams,
    ApproveTeamAccount,
})(AdminTeamRecords);
