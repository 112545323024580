/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import { Link } from "react-router-dom";
import backIcon from "../../assets/img/backIcon.svg";
import peopleIcon from "../../assets/img/peopleIcon.svg";
import { Button, Popconfirm, Spin, Card } from "antd";
import { ListContainer, PrivilegeContainer } from "./UserManagement.styled";
import CreateCustomRoleModal from "./roles/CreateCustomRoleModal";
import UpdateCustomRole from "./roles/UpdateCustomRole";
import { connect } from "react-redux";
import {
  getTeamRoles,
  createRole,
  deleteARole,
  updateRole,
  getAllPermissions,
} from "../../redux/actions/userManagement";
import { Edit, Delete } from "@material-ui/icons";

const ManageRole = (props) => {
  let {
    team_roles = [],
    all_permissions = [],
    user_details,
    getTeamRoles,
    createRole,
    updateRole,
    deleteARole,
    team_members,
    getAllPermissions,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [privilege, setPrivilege] = useState([]);
  const [roleDetails, setRoleDetails] = useState();
  const [editData, setEditData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [addedPrivilege, setAddedPrivileges] = useState([]);
  const [resetEdit, setResetEdit] = useState(0);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const getTeamId = user_details?.owned_team?.id;

  const handleUpdateCancel = () => {
    setUpdateModalVisible(false);
    setResetEdit(resetEdit + 1);
    // setAddedPrivileges([]);
    setEditData(null);
  };

  const UpdateRole = (record) => {
    // console.log("edit", record);
    setUpdateModalVisible(true);
    setAddedPrivileges(record?.privileges);
    setEditData(record);
    // console.log("edit added", addedPrivilege);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const cancelDelete = () => {
    setCurrentIndex(0);
    setVisible(false);
  };

  const showPopconfirm = (x) => {
    setCurrentIndex(x.id);
    setVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getPriviledges = () => {
    const filterPriv = team_roles?.map((priv) => priv.privileges)[0] || [];
    setPrivilege(filterPriv);
  };

  const fetchTeamRoles = async () => {
    if (getTeamId === undefined) {
      return;
    }
    const res = await getTeamRoles(getTeamId);
    if (res.success) {
      return team_roles?.map((priv) => priv.privileges)[0];
    } else {
      // setLoading(false);
    }
  };

  const getAllRolePermission = useCallback(async () => {
    await getAllPermissions();
  }, [getAllPermissions]);

  useEffect(() => {
    getAllRolePermission();
  }, [getAllRolePermission]);

  useEffect(() => {
    getPriviledges();
  }, []);

  useEffect(() => {
    fetchTeamRoles();
  }, []);

  const dropData = (
    <ul>
      {privilege?.map((x, i) => (
        <li key={i}>
          <strong>Can</strong> {x.split(".")[1].replace(/_/g, " ")}
        </li>
      ))}
    </ul>
  );

  const getPrivilegeOnRole = (name) => {
    const details = team_roles?.filter((priv) => priv.name === name)[0] || [];
    setRoleDetails(details);
    setPrivilege(details.privileges);
  };

  useEffect(() => {
    getPrivilegeOnRole("Owner");
  }, []);

  const DeleteRole = async (id) => {
    if (getTeamId === undefined) {
      return;
    }
    setConfirmLoading(true);
    const res = await deleteARole(id);
    if (res.success) {
      fetchTeamRoles(getTeamId);
      setVisible(false);
      setConfirmLoading(false);
    } else {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <DashboardLayout pageTitle="Manage Role">
        <div
          className="m-0"
          data-aos="fade-up"
          data-aos-delay="2s"
          data-aos-duration="1s"
        >
          <div className="userManagementBody">
            {user_details?.owned_team === null &&
            user_details?.user_team !== null ? (
              <div className="mt-4 mb-4">
                <h3>Manage Role</h3>
              </div>
            ) : null}
            <div className="row">
              <Link
                to="/dashboard/manage-user"
                className="col-3 my-3 d-flex align-items-center"
              >
                <img src={backIcon} alt="..." className="mr-2" />
                <span className="text-dark">Back</span>
              </Link>
            </div>
            <Card bordered={false} className="card-shadow">
              <div className="row">
                <div className="col-md-3 py-4 pr-0 border-right">
                  <aside>
                    <div className="role_aside_header px-4">Default Roles</div>
                    <ListContainer>
                      <ul>
                        {team_roles ? (
                          team_roles.map((x, i) => (
                            <li
                              key={i}
                              className="px-4 border-bottom roleLink mb-4 pb-2"
                              onClick={() => getPrivilegeOnRole(x.name)}
                            >
                              <div>{x.name}</div>
                              <div className="list_ops">
                                <button onClick={() => UpdateRole(x)}>
                                  <Edit />
                                </button>
                                <Popconfirm
                                  title="Are you sure you want to delete?"
                                  visible={
                                    currentIndex === x.id ? visible : false
                                  }
                                  onConfirm={() => DeleteRole(x.id)}
                                  onCancel={cancelDelete}
                                  okText="Yes"
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    danger: true,
                                  }}
                                  cancelText="No"
                                >
                                  <button onClick={() => showPopconfirm(x)}>
                                    <Delete />
                                  </button>
                                </Popconfirm>
                              </div>
                            </li>
                          ))
                        ) : (
                          <div className="loading">
                            <Spin />
                          </div>
                        )}
                      </ul>
                      <div className="px-4 pb-2">
                        <div className="rolelink">Custom Roles</div>
                        <div>
                          <p className="roleDescription">
                            You can create your own custom roles, and choose
                            exactly what team members can see and do.
                          </p>
                        </div>
                        <div>
                          <Button type="primary" onClick={showModal}>
                            Create a custom role
                          </Button>
                        </div>
                      </div>
                    </ListContainer>
                  </aside>
                </div>
                <div className="col-md-8 py-4">
                  <div className="row m-0">
                    <div className="col-md-12 mb-4">
                      <h6>
                        <strong style={{ textTransform: "capitalize" }}>
                          {roleDetails?.name}
                        </strong>
                      </h6>
                    </div>
                  </div>
                  <div className="row m-0">
                    <div className="col-md-12">
                      <p className="roleDescription">
                        This role grants users the permissions to manage
                        everything on the dashboard
                      </p>
                    </div>
                  </div>
                  <div className="row m-0">
                    <div className="border-top w-100 my-2" />
                    <div className="col-md-12 d-flex align-items-center ">
                      <img src={peopleIcon} alt="..." />
                      <span className="text-secondary ml-2">
                        Team Members with this role
                      </span>
                    </div>
                    <div className="border-bottom w-100 mt-2 mb-4" />
                  </div>
                  <div className="row m-0">
                    {dropData.props.children === null && (
                      <>
                        <div className="col-md-6">
                          <PrivilegeContainer>
                            <div className="header">
                              What this role can access
                            </div>
                            <div className="list">
                              Select A Role to view permission
                            </div>
                          </PrivilegeContainer>
                          {/* <ThemedDropdown name="What this role can access" dropItems={dropData} /> */}
                        </div>
                      </>
                    )}
                    {dropData.props.children !== null && (
                      <>
                        <div className="col-md-6">
                          <PrivilegeContainer>
                            <div className="header">
                              What this role can access
                            </div>
                            <div className="list">{dropData}</div>
                          </PrivilegeContainer>
                          {/* <ThemedDropdown name="What this role can access" dropItems={dropData} /> */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </DashboardLayout>

      <CreateCustomRoleModal
        teamOwner={getTeamId}
        ownTeam_id={getTeamId}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        getTeamRoles={getTeamRoles}
        createRole={createRole}
        team_roles={team_roles}
      />

      <UpdateCustomRole
        key={resetEdit}
        teamOwner={getTeamId}
        ownTeam_id={getTeamId}
        isModalVisible={updateModalVisible}
        handleOk={handleOk}
        handleUpdateCancel={handleUpdateCancel}
        getTeamRoles={getTeamRoles}
        updateRole={updateRole}
        team_roles={team_roles}
        team_members={team_members}
        formData={editData}
        addedPrivilege={addedPrivilege}
        all_permissions={all_permissions}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    team_members: state.userManagementReducer.team_members,
    team_roles: state.userManagementReducer.team_roles,
    user_details: state.authReducer.user_details,
    all_permissions: state.userManagementReducer.all_permissions,
  };
};

export default connect(mapStateToProps, {
  getTeamRoles,
  createRole,
  updateRole,
  deleteARole,
  getAllPermissions,
})(ManageRole);
