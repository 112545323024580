import { Area, AreaChart, Brush, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { convertDate, renderColorfulLegendText } from "../../util/Util";

const WindChart = ({ chartData, type, startYear, endYear }) => {

    return (
        <>
            {type === "5 Years" ?
                <ResponsiveContainer width={"100%"} height="100%">
                    <AreaChart
                        margin={{ top: 20, right: 30, bottom: 35 }}
                    >
                        <XAxis
                            angle={45}
                            textAnchor="start"
                            dataKey="dayMonth"
                            stroke="rgba(255,255,255,1)"
                            type="category"
                            allowDuplicatedCategory={false}
                        />

                        <YAxis
                            unit=" m/s"
                            type="number"
                            domain={["0", "dataMax + 100"]}
                            stroke="rgba(255,255,255,1)"
                        />
                        <CartesianGrid strokeDasharray="0.5 0.5" />
                        <Tooltip cursor={{ fill: 'transparent' }}
                            wrapperStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                            itemStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                        />
                        {/* <Legend verticalAlign="top" height={36} /> */}
                        <Legend
                            verticalAlign="top"
                            height={50}
                        />

                        <Brush
                            dataKey="dayMonth"
                            height={20}
                            stroke="blue"
                            // x={0}
                            y={400}
                            data={chartData}
                            gap={20}
                            travellerWidth={50}
                        />

                        {chartData.map((eachSegment, key) => (
                            <Area
                                name={`Year ${eachSegment?.year}`}
                                key={key}
                                fillOpacity={0.7}
                                data={eachSegment.data}
                                type="monotone"
                                dataKey="windspeed"
                                stroke={eachSegment?.color}
                                fill={eachSegment?.color}
                            />
                        ))}
                    </AreaChart>
                </ResponsiveContainer>
                :
                <>
                    {type === "None" ?
                        <ResponsiveContainer width={"100%"} height="100%">
                            <AreaChart
                                data={chartData}
                                margin={{ top: 15, right: 30, left: 20, bottom: 35 }}
                            >
                                <XAxis
                                    tickFormatter={convertDate}
                                    angle={45}
                                    textAnchor="start"
                                    dataKey="date"
                                    stroke="rgba(255,255,255,1)"
                                />
                                <YAxis
                                    unit=" m/s"
                                    type="number"
                                    domain={["0", "dataMax + 100"]}
                                    stroke="rgba(255,255,255,1)"
                                />
                                <CartesianGrid strokeDasharray="0.5 0.5" />
                                <Tooltip cursor={{ fill: 'transparent' }}
                                    wrapperStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                                    itemStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                                />
                                {/* <Legend verticalAlign="top" height={36} /> */}
                                <Legend
                                    verticalAlign="top"
                                    formatter={() =>
                                        renderColorfulLegendText(
                                            "Wind Speed - " + startYear + endYear,
                                            "white"
                                        )
                                    }
                                    height={50}
                                />

                                <Brush
                                    dataKey="date"
                                    height={20}
                                    stroke="blue"
                                    // x={0}
                                    y={400}
                                    data={chartData}
                                    gap={20}
                                    travellerWidth={50}
                                />

                                <Area
                                    name="Wind Speed"
                                    fillOpacity={0.7}
                                    type="monotone"
                                    dataKey="windspeed"
                                    stroke="rgba(255,255,255,0.5)"
                                    fill="rgba(0, 106, 75, 0.7)"
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                        : <ResponsiveContainer width={"100%"} height="100%">
                            <AreaChart
                                data={chartData}
                                margin={{ top: 15, right: 30, left: 20, bottom: 35 }}
                            >
                                <XAxis
                                    angle={45}
                                    textAnchor="start"
                                    dataKey="dayMonth"
                                    stroke="rgba(255,255,255,1)"
                                />
                                <YAxis
                                    unit=" m/s"
                                    type="number"
                                    domain={["0", "dataMax + 100"]}
                                    stroke="rgba(255,255,255,1)"
                                />
                                <CartesianGrid strokeDasharray="0.5 0.5" />
                                <Tooltip cursor={{ fill: 'transparent' }}
                                    wrapperStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                                    itemStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                                />
                                {/* <Legend verticalAlign="top" height={36} /> */}
                                <Legend
                                    verticalAlign="top"
                                    formatter={() =>
                                        renderColorfulLegendText(
                                            "Average Wind Speed - " + startYear + endYear,
                                            "white"
                                        )
                                    }
                                    height={50}
                                />

                                <Brush
                                    dataKey="dayMonth"
                                    height={20}
                                    stroke="blue"
                                    // x={0}
                                    y={400}
                                    data={chartData}
                                    gap={20}
                                    travellerWidth={50}
                                />

                                <Area
                                    name="Wind Speed"
                                    fillOpacity={0.7}
                                    type="monotone"
                                    dataKey="windspeed"
                                    stroke="rgba(255,255,255,0.5)"
                                    fill="rgba(0, 106, 75, 0.7)"
                                />
                            </AreaChart>
                        </ResponsiveContainer>}
                </>
            }
        </>
    )
};

export default WindChart;