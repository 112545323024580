import React, { useContext } from "react";
import { AppContext } from "../../appRouter";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CloudIcon from "@material-ui/icons/Cloud";
import EventIcon from "@material-ui/icons/Event";

import {
  CoverDetectRange,
  CoverFound,
  CoverNotFound,
  GeoMapButtonBox,
  GeoMapRange,
} from "../../styles/util/UtilStyle";
import { formatDate } from "../util/Util";

const handleChangeCloud = (e, handleCloudCover, setIsMapLoading) => {
  handleCloudCover(e.target.value);
  setIsMapLoading(true);
  setTimeout(() => {
    setIsMapLoading(false);
  }, 3000);
};

function RangeControl(props) {
  const {
    cloudCover,
    handleCloudCover,
    isCloudPresent,
    selFieldDetails,
    selectedDate,
  } = useContext(AppContext);

  return (
    <>
      <GeoMapButtonBox>
        <GeoMapRange
          defaultValue={cloudCover}
          type="range"
          min={0}
          max={100}
          onChange={(e) =>
            handleChangeCloud(e, handleCloudCover, props?.setIsMapLoading)
          }
          cloudCover={cloudCover}
        />
      </GeoMapButtonBox>

      {!isCloudPresent ? (
        <CoverNotFound>
          <NotInterestedIcon
            style={{ marginLeft: "auto", marginRight: "auto" }}
          />
        </CoverNotFound>
      ) : (
        <CoverFound>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontSize: "0.9em" }}>
              Clouds <CloudIcon style={{ fontSize: "1em" }} />
            </span>
            <span style={{ fontSize: "0.9em" }}>
              {selFieldDetails?.size
                ? selFieldDetails?.size + " ha / " + cloudCover + " %"
                : "No Field Selected"}
            </span>
          </div>
          <div>
            <CoverDetectRange type="range" disabled value={cloudCover} />
          </div>
          <div>
            {selectedDate ? (
              <>
                <EventIcon style={{ fontSize: "1em" }} />{" "}
                {formatDate(selectedDate)}
              </>
            ) : (
              "No date selected"
            )}
          </div>
        </CoverFound>
      )}
    </>
  );
}

export default RangeControl;
