import constants from "../constants";
import axios from "axios";
import { baseUrl } from "../../config";

export const GetAllUSubscriptionPlan = () => (dispatch) => {
    return axios.get(`${baseUrl}/system_setup/subscription_plan_users/`, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((res) => {
        dispatch({ type: constants.ALL_SUBSCRIPTIONS, payload: res?.data })
        return { success: true, message: '' }
    }).catch((err) => {
        return { success: false, message: err?.response?.data?.details }
    });
}


export const GetOneUSubscriptionPlan = (id) => (dispatch) => {
    return axios.get(`${baseUrl}/system_setup/subscription_plan_users/${id}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((res) => {
        console.log('fom', res?.data?.services);
        dispatch({ type: constants.GET_SINGLE_SUBSCRIPTIONS, payload: res?.data })
        return { success: true, services_only: res?.data?.services }
    }).catch((err) => {
        return { success: false, message: err?.response?.data?.details }
    });
}

export const GetUserSubscriptionPlan = (id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.get(`${baseUrl}/accounts/user-subscription/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch({ type: constants.GET_USER_SUBSCRIPTIONS, payload: res?.data })
        return { success: true, user_subscription: res?.data?.services }
    }).catch((err) => {
        return { success: false, message: err?.response?.data?.details }
    });
}

export const CreateAccountPayment = (details) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios.post(`${baseUrl}/accounts/payment/create`, details, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    }).then((res) => {
        dispatch({ type: constants.CREATE_PAYMENT, payload: res?.data })
        return { success: true, message: '' }
    }).catch((err) => {
        return { success: false, message: err?.response?.data?.details }
    });
}

