import constants from "../constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
    switch (action.type) {
        case constants.ADMIN_TOKEN:
            return {
                ...state,
                admin_token: action.payload,
            };
        case constants.ADMIN_USER:
            return {
                ...state,
                admin_user: action.payload,
            };
        case constants.SYSTEM_CONFIG_RECORD:
            return {
                ...state,
                system_config_record: action.payload || [],
            }
        case constants.ALL_SUBSCRIPTION_RECORD:
            return {
                ...state,
                allSubscriptions: action.payload || [],
            }
        case constants.GET_ONE_SUBSCRIPTION:
            return {
                ...state,
                one_sub_record: action.payload,
            }
        case constants.ALL_SERVICES_RECORD:
            return {
                ...state,
                allServices: action.payload || [],
            }
        case constants.GET_ONE_SERVICE:
            return {
                ...state,
                one_service: action.payload,
            }
        default:
            return state;
    }
}