import React from 'react';
import { Menu, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Visibility, Edit, Delete, HighlightOff, Done } from "@material-ui/icons";

export const TableMenu = ({ record, onTableMenuClicked, ...props }) => {
  const menu = (
      <Menu className="tableaction">
        <Menu.Item key="0" onClick={() => props.GetOneFarmTask(record)}><Visibility /> View</Menu.Item>
        <Menu.Item key="1" onClick={() => props.UpdateFarmTask(record)}><Edit /> Modify</Menu.Item>
        <Menu.Item key="2" onClick={() => props.ReassignTask(record)}><Done /> Re-Assign</Menu.Item>
        <Menu.Item key="3" onClick={() => props.closeTask(record)}><HighlightOff /> Close</Menu.Item>
        <Menu.Item key="4" onClick={() => props.DeleteOneTask(record)}><Delete /> Delete</Menu.Item>
      </Menu>
  );
  return (
      <Dropdown overlay={menu} trigger={['click']}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
            href="#"
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            onKeyDown={(e) => e.preventDefault()}
        >
          <MoreOutlined />
        </a>
      </Dropdown>
  );
};
