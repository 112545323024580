import styled from 'styled-components';

export const ErrorContainer = styled.section`
  width: 100%;
  height: 100vh;
  text-align: center;
  position: absolute;
  font-family: Avenir Next;
  font-style: normal;
 
`;

export const ErrorCode = styled.h1`
  margin-top: 10vh;
  margin-bottom: 0em;
  color: rgba(5, 5, 5, 0.7);
  font-weight: bold;
  letter-spacing: 0.2em;
  font-size: 4em;
  z-index: 10000;
  @media screen and (max-width:769px){
      font-size:3em;
}
`;

export const ErrorImage = styled.div`
  width: 25em;
  height: fit-content;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;
  margin-bottom: 1em;
  margin-top: 0em;
  z-index: -10000;
  @media screen and (max-width:769px){
      width:80%;
}
`;

export const ErrorImg = styled.img`
  width: 25em;
  height: 18em;
  @media screen and (max-width:769px){
      width:100%;
}
`;

export const ErrorText = styled.h3`
  margin-top: 0em;
  color: rgba(5, 5, 5, 0.7);
  font-weight:bold;
  @media screen and (max-width:769px){
 font-size:1.3em;     
}
`;

export const ErrorSubText = styled.h5`
  margin-top: 1em;
  color: rgba(5, 5, 5, 0.5);
  @media screen and (max-width:769px){
 width:70%;
 margin-left:auto;
 margin-right:auto;
 line-height:1.3em;  
 margin-top:0.5em;   
}
`;

export const ErrorButton = styled.button`
  margin-top: 1em;
  height: 2.5em;
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  border: none;
  width: 10em;
  margin-top: 1.5em;
  margin-bottom: 1em;
  cursor: pointer;
  background: rgba(0, 106, 75, 1);
  outline: none;
  border-radius: 0.3em;
  transition: 0.7s background, 0.7s border, 0.7s color;
  &:hover {
    background: white;
    border: 1px solid rgba(0, 106, 75, 1);
    color: rgba(0, 106, 75, 1);
  }
`;
