import React from 'react';
import FarmActivitiesDetails from '../../components/farmActivity/FarmActivitiesDetails';

const SingleFarmDetails = () => {
    return (
        <>
            <FarmActivitiesDetails />
        </>
    )
}

export default SingleFarmDetails
