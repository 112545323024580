import React from "react";

import { useHistory } from "react-router-dom";
import {
  ErrorButton,
  ErrorCode,
  ErrorContainer,
  ErrorImage,
  ErrorImg,
  ErrorSubText,
  ErrorText,
} from "../../styles/errorHandling/ErrorStyles";

import brokenImage from "../../assets/img/error/ezgif.com-gif-maker.gif";

const BrokenLink = (props) => {
  let history = useHistory();

  const handleRefresh = () => {
    history.goBack();
  };

  return (
    <>
      <ErrorContainer>
        <ErrorCode>404</ErrorCode>
        <ErrorImage>
          <ErrorImg src={brokenImage} />
        </ErrorImage>
        <ErrorText>
          {props?.customText?.length > 0
            ? props?.customText
            : "Looks like you're lost!"}
        </ErrorText>
        <ErrorSubText>
          {props?.customSubText?.length
            ? props?.customSubText
            : "The page you want to access is broken"}
        </ErrorSubText>
        <ErrorButton onClick={handleRefresh}>Refresh Page</ErrorButton>
      </ErrorContainer>
    </>
  );
};

export default BrokenLink;
